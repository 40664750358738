import { useState } from "react";
import OwnerIcon from "../../assets/owner-logo.svg";
import PlayIcon from "../../assets/play-icon.svg";

const ImageWithSkeleton = ({ className, src, alt, aspect, showIcon }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };
  return (
    <div className="relative w-full !h-full min-h-[200px] md:min-h-[300px]">
      {showIcon && !isLoading && (
        <div>
          <div
            style={{ zIndex: 1 }}
            className="absolute inset-0 bg-black opacity-[0.05]"
          ></div>
          <div
            style={{ zIndex: 2 }}
            className="absolute inset-0 flex items-center justify-center transform -translate-y-[35px] md:-translate-y-[55px]"
          >
            <img src={PlayIcon} alt="play" className="w-10 h-10" />
          </div>
        </div>
      )}
      {isLoading && src && (
        <div className="absolute top-0 left-0 w-full !h-full bg-gray-200 animate-pulse !rounded-2xl flex items-center justify-center">
          <svg
            className="w-10 h-10 text-gray-200] -mt-[75px]"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 18"
          >
            <path d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z" />
          </svg>
        </div>
      )}
      {src ? (
        <img
          src={src}
          alt={alt}
          className={`object-cover ${className} ${
            isLoading ? "hidden" : "block"
          } ${aspect ?? ""}`}
          onLoad={handleImageLoad}
        />
      ) : (
        <div
          className={`w-full h-full ${aspect} flex items-center justify-center bg-gray-300 !rounded-2xl`}
        >
          <img
            src={OwnerIcon}
            alt=""
            className="w-10 h-10 text-gray-200] -mt-[75px]"
          />
        </div>
      )}
    </div>
  );
};

export default ImageWithSkeleton;
