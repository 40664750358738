import { useRef, useState } from "react";
import {
  Box,
  Button,
  Input,
  Progress,
  Text,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

import {
  handleDisplayTimeBySeconds,
  handleUploadAssetToCloud,
  handleValidateVideo,
} from "../twygs.helper";
import TemplateCreateTwygsComponent from "../template-create-twygs.component";
import DropzoneTwygsComponent from "../dropzone-twygs.component";
import {
  DISPLAY_MODAL_STUDIO_VIDEO,
  DRAG_DROP_DESC,
  ERROR_FILE_UPLOAD,
  ERROR_MESSAGE,
  SUCCESS_DRAFT,
  TWYGS_VIDEO_ACTION,
} from "../twygs.constant";

import { ReactComponent as FailLoadImage } from "../../../../assets/failed-to-load.svg";
import { ReactComponent as SuccessfullyIcon } from "../../../../assets/successful-icon.svg";
import { ReactComponent as ToastSuccess } from "../../../../assets/toast-success.svg";
import { ReactComponent as ThumbnailGreenIcon } from "../../../../assets/thumbnail-green-icon.svg";
import { ReactComponent as TrimGreenIcon } from "../../../../assets/trim-green-icon.svg";
import { ReactComponent as TwygsPercentageGreenIcon } from "../../../../assets/twygs-percentage-green-icon.svg";
import { ReactComponent as UploadIconGreen } from "../../../../assets/upload-green-icon.svg";

import { useAtom } from "jotai";
import SuccessfullyModal from "../../../ModalSuccess";
import { protectedRoutes } from "../../../../constants/routes";
import ImageContentModal from "../../../PreviewPopup";
import VideoComponent from "../../../Common/Video";
import { ASSET_TYPE } from "../../../../constants/value.constant";
import { CHUNK_SIZE, FILE_TYPES } from "../../../../constants/image.constant";
import { formartNumber } from "../../../../utils/helper.util";
import { profileAtom } from "../../../Settings/atom";
import { proceedAtom } from "../../../TopBar/atom";
import { useNavigate, useSearchParams } from "react-router-dom";
import ToastMessage from "../../../ToastMessage";
import { useMutation } from "@tanstack/react-query";
import {
  finalizeMultipartUpload,
  getMultipartUrl,
  initMultipleUpload,
  postAssetUpload,
  publishTwyg,
  saveTwygDraft,
} from "../../../../apis/studio.api";
import ConfirmModal from "../../../Modal/ConfirmModal";
import { hashtagAtom } from "../../../TextAreaCustom/atom";

const FormVideoComponent = (props) => {
  const { assetData, setAssetData, setAction } = props;

  const { getValues, formState, setError, handleSubmit } = useFormContext();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { mutateAsync: postAssetUploadAsync } = useMutation({
    mutationFn: postAssetUpload,
  });

  const { mutateAsync: publishTwygAsync } = useMutation({
    mutationFn: publishTwyg,
  });
  const { mutateAsync: saveTwygsDraftAsync } = useMutation({
    mutationFn: saveTwygDraft,
  });

  const { mutateAsync: initMultipleUploadAsync } = useMutation({
    mutationFn: initMultipleUpload,
  });

  const { mutateAsync: getMultipartUrlAsync } = useMutation({
    mutationFn: getMultipartUrl,
  });

  const { mutateAsync: finalizeMultipartUploadAsync } = useMutation({
    mutationFn: finalizeMultipartUpload,
  });

  const [titleErr, setTitleErr] = useState();
  const [modal, setModal] = useState("");
  const [hashtag] = useAtom(hashtagAtom);
  const [progressUpload, setProgressUpload] = useState(0);
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [infoPublish, setInfoPublish] = useState(null);
  const [isReadyTrim, setIsReadyTrim] = useState(false);
  const idTwyg = searchParams.get("id") || null;
  const fileInputRef = useRef();
  const toast = useToast();
  const [profile, setProfile] = useAtom(profileAtom);
  const [trimVideo, setTrimVideo] = useState(false);
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const handleUploadVideo = async (value) => {
    setAssetData(null);
    setProgressUpload(99);
    const { arr, errList } = await handleValidateVideo(value);
    if (errList.file.length > 0) {
      setTitleErr(errList.file);
      setModal(DISPLAY_MODAL_STUDIO_VIDEO.CONFIRM_ERR);
    } else if (arr?.length > 0) {
      setAssetData((pre) => {
        return {
          ...pre,
          asset: arr[0]?.asset,
          thumbnail: arr[0]?.thumbnail,
        };
      });
    } else {
      setAssetData(null);
    }
    setProgressUpload(0);
  };

  const handleClickReUpload = () => {
    fileInputRef.current.click();
  };

  const handleReUpload = (value) => {
    handleUploadVideo([value.target.files[0]]);
  };

  const handleAction = (value) => () => {
    setAction(value);
  };

  const handleContinues = () => {
    setModal("");
  };

  const submitForm = async (valueForm) => {
    try {
      setLoadingPublish(true);
      if (assetData?.asset) {
        let contentKeys;
        let thumbnail;
        let payload = {
          ...valueForm,
          hashtag: hashtag,
          contentType: "Video",
          twygsId: idTwyg,
        };

        if (assetData.trim?.timeline) {
          payload = {
            ...payload,
            trimVideo: {
              start: handleDisplayTimeBySeconds(assetData.trim.timeline[0]),
              duration: handleDisplayTimeBySeconds(
                assetData.trim.timeline[1] - assetData.trim.timeline[0]
              ),
            },
          };
        }

        if (assetData.asset.isUpdated) {
          if (assetData.asset.contentKeys) {
            payload = {
              ...payload,
              removedContentKeys: [assetData.asset.contentKeys],
            };
          }
          contentKeys = await handleVideoUpload([assetData.asset]);
        } else {
          contentKeys = [assetData.asset.contentKeys];
        }

        if (assetData.thumbnail.isUpdated) {
          thumbnail = await handleThumbnailUpload([assetData.thumbnail]);
        } else {
          thumbnail = [assetData.thumbnail.thumbnail];
        }

        if (contentKeys && thumbnail) {
          payload = {
            ...payload,
            contentKeys: contentKeys,
            thumbnail: thumbnail[0],
            videoAssetType: assetData.asset.assetType,
          };

          const data = await publishTwygAsync(payload);
          if (data) {
            setModal(DISPLAY_MODAL_STUDIO_VIDEO.PUBLISH_SUCCESS);
            setInfoPublish({
              ...payload,
              createdAt: new Date(),
              ...data?.data,
              contentUrlList: [assetData.asset.src],
              thumbnailUrl: assetData.thumbnail.src,
              videoAssetType: assetData.asset.assetType,
            });
            if (!profile.creatorWallet) {
              setProfile({
                ...profile,
                creatorWallet: data?.data?.walletAddress,
              });
            }
            toast({
              render: () => (
                <ToastMessage
                  backgroundColor="#FFD166"
                  color="jet-black"
                  Icon={ToastSuccess}
                  title={"Twyg Under Review"}
                ></ToastMessage>
              ),
              duration: 5000,
              isClosable: true,
              position: "top",
            });
            if (assetData.trim?.timeline) {
              toast({
                render: () => (
                  <ToastMessage
                    backgroundColor="#FFD166"
                    color="jet-black"
                    Icon={ToastSuccess}
                    title={"Trim video is in progress"}
                  ></ToastMessage>
                ),
                duration: 5000,
                isClosable: true,
                position: "top",
              });
              setTrimVideo(true);
            }
          }
        }
      }

      /* User Post Event Conversion Location */
      //Note - Find API code location
      //FOUND - studio.api & publishTwyg/publishTwygAsync. API using Axios
      //Next - add new endpoint to studio.api, learn more about TWYGS API implementation
      //Add Impact APIs to .env
      //DEPRECATED, PENDING DELETION
      /* User Post Event Conversion Location */
    } catch (error) {
      console.log(error);
      if (error?.data?.message === ERROR_MESSAGE.DUPLICATE_NAME) {
        setError(
          "twygsName",
          { message: error?.data?.message },
          { shouldFocus: true }
        );
      }
    } finally {
      setLoadingPublish(false);
    }
  };

  const saveDarft = async () => {
    try {
      setLoadingDraft(true);

      if (assetData?.asset) {
        let contentKeys;
        let thumbnail;
        let payload = {
          ...getValues(),
          hashtag: hashtag,
          contentType: "Video",
          twygsId: idTwyg,
        };

        if (assetData.trim?.timeline) {
          payload = {
            ...payload,
            trimVideo: {
              start: handleDisplayTimeBySeconds(assetData.trim.timeline[0]),
              duration: handleDisplayTimeBySeconds(
                assetData.trim.timeline[1] - assetData.trim.timeline[0]
              ),
            },
          };
        }

        if (assetData.asset.isUpdated) {
          if (assetData.asset.contentKeys) {
            payload = {
              ...payload,
              removedContentKeys: [assetData.asset.contentKeys],
            };
          }
          contentKeys = await handleVideoUpload([assetData.asset]);
        } else {
          contentKeys = [assetData.asset.contentKeys];
        }

        if (assetData.thumbnail.isUpdated) {
          thumbnail = await handleThumbnailUpload([assetData.thumbnail]);
        } else {
          thumbnail = [assetData.thumbnail.thumbnail];
        }

        if (contentKeys && thumbnail) {
          payload = {
            ...payload,
            contentKeys: contentKeys,
            thumbnail: thumbnail[0],
            videoAssetType: assetData.asset.assetType,
          };

          const data = await saveTwygsDraftAsync(payload);

          if (data) {
            setModal(DISPLAY_MODAL_STUDIO_VIDEO.DRAFT_SUCCESS);
          }
        }
      }
    } catch (error) {
      if (error?.data?.message === ERROR_MESSAGE.DUPLICATE_NAME) {
        setError(
          "twygsName",
          { message: error?.data?.message },
          { shouldFocus: true }
        );
      }
    } finally {
      setLoadingDraft(false);
    }
  };

  const handleThumbnailUpload = async (arr) => {
    try {
      if (arr?.length > 0) {
        const arrUploadName = arr.map((item) => item.fileName) || [];
        const payload = {
          fileName: [...arrUploadName],
        };
        const urlData = await postAssetUploadAsync(payload);
        if (urlData?.length > 0) {
          const arrData = urlData;
          const uploadUrlArr = arr.map((item, index) => {
            return {
              ...item,
              uploadUrl: arrData[index].url,
              fileName: arrData[index].key,
            };
          });
          await handleUploadAssetToCloud(uploadUrlArr);
          return arrData.map((elm) => elm.key);
        }
      } else {
        return null;
      }
    } catch (e) {
      console.log(e);
      return null;
    }
  };

  const handleVideoUpload = async (arr) => {
    try {
      if (arr?.length > 0) {
        const selectedFileSize = arr.reduce(
          (totalParts, file) => totalParts + file.file.size,
          0
        );
        const payloadFileName = {
          fileName: arr.map((elm) => elm.fileName),
        };
        const initializeResponse = await initMultipleUploadAsync(
          payloadFileName
        );
        const resFile = initializeResponse?.data;
        const payloadMultipartUrl = arr.map((elm, index) => ({
          ...resFile[index],
          totalPartsNumber: Math.ceil(elm.file.size / CHUNK_SIZE),
        }));
        const urlsResponse = await getMultipartUrlAsync({
          files: payloadMultipartUrl,
        });
        const payloadFinalize = urlsResponse;
        const newPayloadFinaly = [];
        for (let index = 0; index < payloadFinalize.length; index++) {
          const element = payloadFinalize[index];
          const parts = [];

          for (
            let indexPart = 0;
            indexPart < element.parts.length;
            indexPart++
          ) {
            const uploadedPart = await handleUploadPart(
              arr,
              selectedFileSize,
              element,
              indexPart,
              index
            );
            parts.push(uploadedPart);
          }

          newPayloadFinaly.push({
            ...element,
            fileId: payloadMultipartUrl[index].fileId,
            parts,
          });
        }

        await finalizeMultipartUploadAsync({
          files: newPayloadFinaly,
        });
        return resFile.map((elm) => elm.fileKey);
      } else {
        return null;
      }
    } catch (e) {
      console.error(e);
      return null;
    } finally {
      setProgressUpload(100);
    }
  };

  const handleUploadPart = async (
    arrUpload,
    selectedFileSize,
    element,
    indexPart,
    index
  ) => {
    const filePart = arrUpload[index].file.slice(
      indexPart * CHUNK_SIZE,
      (indexPart + 1) * CHUNK_SIZE
    );
    const uploadPartResult = await new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", element.parts[indexPart].presignedUrl, true);
      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const totalUpload =
            (index === 0
              ? indexPart + 1
              : indexPart +
                1 +
                Math.ceil(arrUpload[0].file.size / CHUNK_SIZE)) *
              CHUNK_SIZE +
            event.loaded;
          const cumulativeProgress = parseFloat(
            formartNumber((totalUpload / selectedFileSize) * 100)
          );
          setProgressUpload(
            cumulativeProgress > 100 ? 100 : cumulativeProgress
          );
        }
      });
      xhr.onload = () => {
        const headers = new Headers();
        xhr
          .getAllResponseHeaders()
          .split("\r\n")
          .forEach((header) => {
            const [name, value] = header.split(": ");
            if (name && value) {
              headers.append(name, value);
            }
          });
        resolve({
          ok: xhr.status >= 200 && xhr.status < 300,
          headers,
        });
      };
      xhr.onerror = () => {
        reject(new Error("Network request failed"));
      };
      xhr.send(filePart);
    });
    if (!uploadPartResult.ok) {
      return;
    }
    const partETag = uploadPartResult.headers.get("etag");
    return {
      partNumber: indexPart + 1,
      eTag: partETag.replaceAll(/[^a-zA-Z0-9]/g, ""),
    };
  };

  const redirectRouter = (url) => () => {
    navigate(url);
    setModal("");
  };

  const handleReadyTrim = (e) => {
    if (e.target.readyState >= 3) setIsReadyTrim(true);
  };

  return (
    <>
      <form>
        <TemplateCreateTwygsComponent
          title="Video Twyg"
          saveDarft={saveDarft}
          onSubmit={handleSubmit(submitForm)}
          loadingSaveDraft={loadingDraft}
          loadingPublish={loadingPublish}
          disable={!formState.isValid || !assetData}
        >
          {!progressUpload ? (
            !!assetData?.asset?.src ? (
              <Box>
                <Box
                  mb={{ base: "1.875rem", "2xl": "2.5rem" }}
                  display={"flex"}
                  columnGap={"0.75rem"}
                >
                  <Box>
                    <Button
                      h={"2.25rem"}
                      pl={"1.125rem"}
                      pr={"0.75rem"}
                      variant={"light-green"}
                      rightIcon={<UploadIconGreen alt="icon" />}
                      fontSize={"sm"}
                      onClick={handleClickReUpload}
                    >
                      Upload
                    </Button>
                    <Input
                      ref={fileInputRef}
                      type="file"
                      accept={FILE_TYPES.twygsVideo}
                      hidden
                      onChange={handleReUpload}
                    />
                  </Box>
                  <Button
                    h={"2.25rem"}
                    pl={"1.125rem"}
                    pr={"0.75rem"}
                    variant={"light-green"}
                    rightIcon={<ThumbnailGreenIcon alt="icon" />}
                    fontSize={"sm"}
                    onClick={handleAction(TWYGS_VIDEO_ACTION.THUMBNAIL)}
                  >
                    Thumbnail
                  </Button>
                  {!!idTwyg && (
                    <Button
                      h={"2.25rem"}
                      pl={"1.125rem"}
                      pr={"0.75rem"}
                      variant={"light-green"}
                      rightIcon={<TrimGreenIcon alt="icon" />}
                      fontSize={"sm"}
                      onClick={handleAction(TWYGS_VIDEO_ACTION.TRIM)}
                      isLoading={!assetData?.trim && !isReadyTrim}
                    >
                      Trim
                    </Button>
                  )}
                </Box>
                <Box>
                  {assetData?.thumbnail?.src && (
                    <VideoComponent
                      src={assetData?.asset?.src}
                      controls
                      width={
                        isMobile
                          ? "auto"
                          : assetData?.asset?.assetType === ASSET_TYPE.PORTRAIT
                          ? `${(9 / 16) * 22.5}rem`
                          : `${(16 / 9) * 22.5}rem`
                      }
                      height={isMobile ? "auto" : "22.5rem"}
                      poster={assetData?.thumbnail?.src}
                      loadMetadata={handleReadyTrim}
                    />
                  )}
                </Box>
              </Box>
            ) : (
              <DropzoneTwygsComponent
                acceptFileTypes={FILE_TYPES.twygsVideo}
                notMutiple
                validateFiles={handleUploadVideo}
                desc={DRAG_DROP_DESC.video.map((item, index) => (
                  <Text
                    key={index}
                    fontWeight="400"
                    color="dim-gray"
                    fontSize="xs"
                  >
                    {item}
                  </Text>
                ))}
                isSingle={true}
              />
            )
          ) : (
            <Box className="dropzone-twygs">
              <Box
                p={"3.375rem 3.75rem"}
                background={`center no-repeat url(${TwygsPercentageGreenIcon.src})`}
              >
                <Box
                  margin={"auto"}
                  w={"15rem"}
                  h={"15rem"}
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Text fontSize={"xl"} fontWeight={800} color={"dark-green"}>
                    Uploading...
                  </Text>
                  <Text fontSize={"5rem"} fontWeight={900} color={"dark-green"}>
                    {progressUpload.toFixed(0)}%
                  </Text>
                </Box>
                <Progress
                  bgColor={"anti-flash-white"}
                  height={"0.75rem"}
                  borderRadius="2.5rem"
                  value={progressUpload}
                  sx={{
                    "& > *": {
                      bg: "linear-gradient(270deg, #F06BA8 3.55%, #A6D38D 100%)",
                    },
                  }}
                />
              </Box>
            </Box>
          )}
        </TemplateCreateTwygsComponent>
      </form>

      {modal === DISPLAY_MODAL_STUDIO_VIDEO.CONFIRM_ERR && (
        <ConfirmModal
          isOpen={modal === DISPLAY_MODAL_STUDIO_VIDEO.CONFIRM_ERR}
          Icon={FailLoadImage}
          title={titleErr ?? ERROR_FILE_UPLOAD.VIDEO.TITLE}
          desc={""}
          titleBtn={"Try Again"}
          onClick={handleContinues}
          onClose={() => setModal("")}
          type="Video"
        />
      )}
      {modal === DISPLAY_MODAL_STUDIO_VIDEO.DRAFT_SUCCESS && (
        <SuccessfullyModal
          isOpen
          Icon={SuccessfullyIcon}
          title={SUCCESS_DRAFT.title}
          desc={SUCCESS_DRAFT.desc}
          titleBtn={"Go to My Draft"}
          onClick={redirectRouter(protectedRoutes.MY_DRAFTS)}
        />
      )}
      {modal === DISPLAY_MODAL_STUDIO_VIDEO.PUBLISH_SUCCESS && (
        <ImageContentModal
          trimVideo={trimVideo}
          isDisabled
          data={infoPublish}
          isOpen
          onClose={redirectRouter(protectedRoutes.MY_TWYGS)}
          onDelete={redirectRouter(protectedRoutes.MY_TWYGS)}
        />
      )}
    </>
  );
};

export default FormVideoComponent;
