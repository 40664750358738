import React, { useEffect, useState } from "react";
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useMediaQuery,
  VStack,
} from "@chakra-ui/react";

import { ReactComponent as LikeIcon } from "../../assets/like-icon.svg";
import { ReactComponent as FailLoadImage } from "../../assets/failed-to-load.svg";
import { ReactComponent as PinterestIcon } from "../../assets/pinterest-icon.svg";
import { ReactComponent as WhatsappIcon } from "../../assets/whatsapp-icon.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/linkedin-icon.svg";
import { ReactComponent as TwitterIcon } from "../../assets/twitter-icon.svg";

import { ReactComponent as CopyGreenIcon } from "../../assets/copy-green-icon.svg";
import { ReactComponent as FacebookIcon } from "../../assets/facebook-icon.svg";

import { ReactComponent as BinRedIcon } from "../../assets/bin-red-icon.svg";
import { ReactComponent as PencilGreenIcon } from "../../assets/pencil-green-icon.svg";
import { ReactComponent as CloseModalWhiteIcon } from "../../assets/close-modal-white-icon.svg";

import {
  ASSET_TYPE,
  LIST_COLOR_CATEGORY,
  TYPE_AD,
} from "../../constants/value.constant";

import CarouselComponent from "../Common/Carousel";
import VideoComponent from "../Common/Video";
import PDFPreviewComponent from "./pdf-preview/pdf-preview.component";
import {
  formatDate,
  formatNumber,
  getTooltipLabel,
} from "../../utils/helper.util";
import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import ConfirmModal from "../Modal/ConfirmModal";
import { MODAL_DRAFT } from "../../constants/draft.constant";
import { protectedRoutes } from "../../constants/routes";
import {
  CONTENT_TYPE_ENUM,
  TWYGS_STUDIO_ACTION,
} from "../CreateTwygsForm/components/twygs.constant";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { deleteTwygs } from "../../apis/studio.api";

const ImageContentModal = (props) => {
  const {
    onClose,
    isOpen,
    bgColorOverlay = "jet-black",
    data,
    onDelete,
    isDisabled,
    trimVideo,
  } = props;

  const { mutateAsync, isPending } = useMutation({
    mutationFn: deleteTwygs,
  });

  const [modal, setModal] = useState("");
  const [isCopy, setIsCopy] = useState(false);
  const [isShowMore, setIsShowMore] = useState(false);
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [isDesktop] = useMediaQuery("(max-width: 1536px)");

  const categoryColor =
    data?.contentCategory || data?.categoryId
      ? LIST_COLOR_CATEGORY.find(
          (item) =>
            item.name === data?.contentCategory || item.id === data?.categoryId
        )
      : null;

  const redirectEditTwygs = () => () => {
    if (!isDisabled) {
      navigate(
        `${protectedRoutes.EDIT_TWYGS}/${
          CONTENT_TYPE_ENUM[data.contentType]
        }?id=${data.id}&action=${TWYGS_STUDIO_ACTION.EDIT}`
      );
    }
  };

  const renderEle = (value) => {
    switch (value.contentType) {
      case TYPE_AD.IMAGE:
        return (
          <Box
            width={{ base: "100%", md: "30rem", "2xl": "40rem" }}
            height={{ base: "100%", md: "30rem", "2xl": "40rem" }}
          >
            <CarouselComponent data={value.contentUrlList} />
          </Box>
        );
      case TYPE_AD.VIDEO:
        return (
          <Box
            width={{ base: "100%", md: "30rem", "2xl": "40rem" }}
            height={{ base: "100%", md: "30rem", "2xl": "40rem" }}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            bgColor={"jet-black"}
            borderRadius={"0.75rem"}
          >
            <VideoComponent
              src={value.contentUrlList?.[0]}
              controls
              width={
                isMobile
                  ? "auto"
                  : isDesktop
                  ? "30rem"
                  : value?.videoAssetType === ASSET_TYPE.PORTRAIT
                  ? `${40 * (9 / 16)}rem`
                  : "40rem"
              }
              height={
                isMobile
                  ? "auto"
                  : isDesktop
                  ? "30rem"
                  : value?.videoAssetType === ASSET_TYPE.LANDSCAPE
                  ? `${40 * (9 / 16)}rem`
                  : "40rem"
              }
              rounded={"unset"}
              poster={value?.thumbnailUrl || value.videoThumbnailUrl}
            />
          </Box>
        );
      case TYPE_AD.PDF:
        return (
          <Box
            width={{ base: "100%", md: "30rem", "2xl": "40rem" }}
            height={{ base: "100%", md: "30rem", "2xl": "40rem" }}
          >
            <PDFPreviewComponent
              data={value.originalPdf || value.originalContent}
            />
          </Box>
        );
      default:
        return <></>;
    }
  };

  const handleCopy = (value) => () => {
    if (!isCopy && !isDisabled) {
      setIsCopy(true);
      setTimeout(() => {
        setIsCopy(false);
      }, 3000);

      if (value) {
        navigator.clipboard.writeText(value);
      }
    }
  };

  const handleBlockCopy = (e) => {
    e.preventDefault();
  };

  const removeTwygs = async () => {
    await mutateAsync({ deleteIds: [data?.twygsId ?? data?.id] });
    onDelete();
  };

  const formatTextWithHashtags = (text) => {
    // Regular expression to match hashtags
    const hashtagRegex = /#(\w+)/g;

    // Split text into an array of strings and matches
    const parts = text.split(hashtagRegex);

    return parts.map((part, index) => {
      // Check if the part is a hashtag by matching the index
      if (index % 2 === 1) {
        return (
          <a
            key={index}
            style={{ color: "#012bc2", fontWeight: "bold", fontSize: "14px" }}
            href={`${
              process.env.REACT_APP_EXPLORE_URL
            }?search=${encodeURIComponent(`#${part}`)}`}
            target={"_blank"}
            rel="noreferrer"
          >
            #{part}
          </a>
        );
      }
      // Return the non-hashtag text as-is
      return part;
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Modal isOpen={isOpen} isCentered onClose={onClose}>
        <ModalOverlay backgroundColor={bgColorOverlay} />
        <ModalContent
          p={{ base: "1.25rem", md: "1.5rem" }}
          width={{
            base: "calc(100vw - 20px)",
            md: "900px",
            "2xl": "1112px",
          }}
          height={{
            base: "calc(100vh - 190px)",
            md: "33rem",
            "2xl": "43rem",
          }}
          maxWidth={{
            base: "calc(100vw - 20px)",
            md: "900px",
            "2xl": "1112px",
          }}
          maxHeight={{
            base: "calc(100vh - 190px)",
            md: "33rem",
            "2xl": "43rem",
          }}
          borderRadius="0.75rem"
          bgColor="light.100"
          boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)"
          mt={{ base: "0px", md: "0px" }}
          transform="translateY(-50%) !important"
          top="50%"
          overflow={{ base: "visible", md: "unset" }}
          position="relative"
          paddingRight={{ base: "0", md: "1.5rem" }}
        >
          <ModalHeader
            p={0}
            position="absolute"
            top={{ base: "-35px", md: "-5px" }}
            right={{ base: "3px", md: "-28px" }}
            zIndex={999}
            width="30px"
            height="30px"
            display="block"
            overflow="hidden"
          >
            <CloseModalWhiteIcon
              position="absolute"
              alt="close-icon"
              onClick={onClose}
              cursor="pointer"
              sx={{
                top: { base: 0 },
                right: 0,
                width: "25px",
                height: "25px",
                display: "block",
                zIndex: 500,
              }}
            />
          </ModalHeader>

          <ModalBody p={0} overflow={"auto"}>
            <Box
              display={"flex"}
              flexDir={{ base: "column", md: "row" }}
              gap={{ base: "0.75rem", "2xl": "1.25rem" }}
            >
              <Box
                maxWidth={{
                  base: "calc(100vw - 60px)",
                  md: "40rem",
                }}
                maxHeight={{ base: "unset", md: "40rem" }}
              >
                {renderEle(data)}
              </Box>
              <Box
                pr={{ base: "20px", md: "unset" }}
                width={{ base: "auto", "2xl": "25rem" }}
                maxHeight={{ base: "auto", "2xl": "40rem" }}
                overflowY={{ base: "hidden", md: "auto" }}
                sx={{
                  "&::-webkit-scrollbar": {
                    width: "6px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#c6ceda",
                    borderRadius: "20px",
                  },
                }}
              >
                <Box
                  py={"0.5rem"}
                  px="1rem"
                  display={"flex"}
                  flexDirection={"column"}
                  borderRadius={"1.5rem"}
                  bgColor={"seasalt-white"}
                >
                  <Box
                    mt={"0.5rem"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Text fontSize={"sm"} fontWeight={400} color={"dim-gray"}>
                      Created on {formatDate(data?.createdAt, true)}
                    </Text>
                    <Box
                      display={"flex"}
                      alignItems={"center"}
                      columnGap={"2rem"}
                    >
                      <Tooltip
                        label={
                          trimVideo
                            ? "Trim video in progress"
                            : getTooltipLabel(data)
                        }
                        aria-label="Edit item tooltip"
                      >
                        <PencilGreenIcon
                          onClick={redirectEditTwygs()}
                          opacity={isDisabled ? 0.5 : 1}
                          cursor={isDisabled ? "not-allowed" : "pointer"}
                          alt="icon"
                        />
                      </Tooltip>

                      <BinRedIcon
                        alt="icon"
                        onClick={() => setModal("remove-twyg")}
                        cursor={"pointer"}
                      />
                    </Box>
                  </Box>
                  <Box
                    mt={"1rem"}
                    display={"flex"}
                    flexDirection={"column"}
                    rowGap={"1rem"}
                  >
                    <Text
                      fontSize={{ base: "xl", "2xl": "2xl" }}
                      fontWeight={800}
                      color={"jet-black"}
                    >
                      {data?.name ?? data?.twygsName ?? ""}
                    </Text>
                    <Text fontSize={"sm"} fontWeight={400} color={"jet-black"}>
                      {!isShowMore && data?.description?.length > 150
                        ? formatTextWithHashtags(
                            data?.description.slice(0, 150)
                          ).concat("...")
                        : formatTextWithHashtags(data?.description ?? "")}
                      {data?.description?.length > 150 && (
                        <span
                          style={{
                            cursor: "pointer",
                            color: "#00a375",
                            fontWeight: 800,
                            fontSize: "14px",
                            fontStyle: "italic",
                            marginLeft: "4px",
                          }}
                          onClick={() => setIsShowMore(!isShowMore)}
                        >
                          {!isShowMore ? "Read more" : "Show less"}
                        </span>
                      )}
                    </Text>
                    <Box
                      display={"flex"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      {categoryColor ? (
                        <Box>
                          <Text
                            p={"10px 8px"}
                            h={"2.25rem"}
                            borderRadius={"0.5rem"}
                            bgColor={categoryColor.bgColor}
                            fontSize={"xs"}
                            fontWeight={800}
                            color={categoryColor.color}
                            display={"inline-block"}
                          >
                            {categoryColor.name}
                          </Text>
                        </Box>
                      ) : null}
                      <Box
                        bgColor={"light-green"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        rounded={"1.25rem"}
                        p={"0.625rem"}
                      >
                        <LikeIcon w={"1.25rem"} h={"1.25rem"} alt="icon" />
                        <Text
                          ml="4px"
                          color="dark-green"
                          fontWeight={600}
                          fontSize={"xs"}
                        >
                          {formatNumber(data?.numberOfLike) ?? 0}
                        </Text>
                      </Box>
                    </Box>
                  </Box>
                  <VStack
                    py="8px"
                    gap="10px"
                    alignItems={"start"}
                    display={"flex"}
                    mt="1rem"
                  >
                    <Box display={"flex"} gap="12px" alignItems={"center"}>
                      <Text fontWeight={600} fontSize={"sm"}>
                        Share:
                      </Text>
                      <FacebookShareButton
                        disabled={isDisabled}
                        url={data?.contentUrl}
                        hashtag={"#twygs"}
                      >
                        <FacebookIcon
                          _hover={{
                            opacity: 0.8,
                          }}
                          cursor={isDisabled ? "not-allowed" : "pointer"}
                          opacity={isDisabled ? 0.5 : 1}
                          alt="icon"
                        />
                      </FacebookShareButton>
                      <TwitterShareButton
                        disabled={isDisabled}
                        url={data?.contentUrl}
                        title={
                          "I'm thrilled to share my latest Twyg with you! See what's new"
                        }
                      >
                        <TwitterIcon
                          _hover={{
                            opacity: 0.8,
                          }}
                          cursor={isDisabled ? "not-allowed" : "pointer"}
                          opacity={isDisabled ? 0.5 : 1}
                          alt="icon"
                        />
                      </TwitterShareButton>
                      <LinkedinShareButton
                        disabled={isDisabled}
                        url={data?.contentUrl}
                      >
                        <LinkedinIcon
                          _hover={{
                            opacity: 0.8,
                          }}
                          cursor={isDisabled ? "not-allowed" : "pointer"}
                          opacity={isDisabled ? 0.5 : 1}
                          alt="icon"
                        />
                      </LinkedinShareButton>
                      <WhatsappShareButton
                        disabled={isDisabled}
                        url={data?.contentUrl}
                        title={
                          "I'm thrilled to share my latest Twyg with you! See what's new"
                        }
                        windowWidth={700}
                        windowHeight={600}
                        separator=" "
                      >
                        <WhatsappIcon
                          _hover={{
                            opacity: 0.8,
                          }}
                          cursor={isDisabled ? "not-allowed" : "pointer"}
                          opacity={isDisabled ? 0.5 : 1}
                          alt="icon"
                        />
                      </WhatsappShareButton>
                      <PinterestShareButton
                        disabled={isDisabled}
                        description="twygs.io"
                        url={data?.contentUrl}
                        media={""}
                      >
                        <PinterestIcon
                          _hover={{
                            opacity: 0.8,
                          }}
                          cursor={isDisabled ? "not-allowed" : "pointer"}
                          opacity={isDisabled ? 0.5 : 1}
                          alt="icon"
                        />
                      </PinterestShareButton>
                    </Box>
                    <Box>
                      <Text
                        fontSize={"xs"}
                        fontWeight={400}
                        color={"dim-gray"}
                        fontStyle={"italic"}
                      >
                        Expand your reach in a click! Share it on social media,
                        embed it on your website, or send it directly to your
                        audience for greater visibility and engagement.
                      </Text>
                    </Box>
                    <Tooltip
                      placement="bottom"
                      label={
                        isDisabled
                          ? "Your Twyg is currently under review. You'll be able to share it once it is approved "
                          : ""
                      }
                      aria-label="Edit item tooltip"
                    >
                      <Box
                        p={{ base: "1rem", "2xl": "1rem 1rem 1rem 1.5rem" }}
                        h={{ base: "3rem", "2xl": "4rem" }}
                        w={"100%"}
                        display={"flex"}
                        alignItems={"center"}
                        columnGap={"1rem"}
                        borderRadius={"0.75rem"}
                        bgColor={"anti-flash-lite"}
                        justifyContent={"space-between"}
                      >
                        <Text
                          onCopy={handleBlockCopy}
                          isTruncated
                          w={{ base: "calc(100% - 80px)", "2xl": "16.625rem" }}
                          fontSize={{ base: "sm", "2xl": "md" }}
                          fontWeight={600}
                          color={"jet-black"}
                          title={data?.contentUrl ?? ""}
                        >
                          {data?.contentUrl ?? ""}
                        </Text>

                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          columnGap={"0.5rem"}
                          onClick={handleCopy(data?.contentUrl)}
                          maxW={"100%"}
                          opacity={isDisabled ? 0.5 : 1}
                          cursor={!isDisabled ? "pointer" : "not-allowed"}
                        >
                          <Text
                            fontSize={"sm"}
                            fontWeight={900}
                            color={"go-green"}
                          >
                            {isCopy ? "Copied" : "Copy"}
                          </Text>
                          <CopyGreenIcon alt="icon" />
                        </Box>
                      </Box>
                    </Tooltip>
                  </VStack>
                </Box>
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter p={0}></ModalFooter>
        </ModalContent>

        {modal === "remove-twyg" ? (
          <ConfirmModal
            isOpen
            Icon={FailLoadImage}
            title={MODAL_DRAFT.title}
            desc={MODAL_DRAFT.description}
            titleBtn={"Remove"}
            onClick={removeTwygs}
            isLoading={isPending}
            onClose={() => {
              setModal("");
            }}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default ImageContentModal;
