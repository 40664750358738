import React from "react";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

function SuccessModal(props) {
  const {
    isOpen,
    onClick,
    Icon,
    title,
    desc,
    titleBtn,
    bgColorOverlay,
    isLoading,
  } = props;

  return (
    <>
      <Modal isOpen={isOpen} isCentered onClose={() => {}}>
        <ModalOverlay backgroundColor={bgColorOverlay} />
        <ModalContent
          borderRadius={"1.5rem"}
          maxWidth={"30rem"}
          p={"3rem 3.28rem"}
          bgColor={"light.200"}
        >
          <ModalHeader fontSize={"4xl"} p={0} margin="auto">
            <Box w={"12.5rem"} h={"12.5rem"} p={"1.5625rem"}>
              {Icon && <Icon alt={""} w={"9.375rem"} height={"9.375rem"} />}
            </Box>
          </ModalHeader>
          <ModalBody p={0} mt={Icon ? "0px" : "21px"} mb={"32px"}>
            <Flex flexDirection={"column"} gap={"12px"}>
              <Text
                fontSize={"32px"}
                textAlign={"center"}
                fontWeight={"900"}
                color={"dark-green"}
              >
                {title}
              </Text>
              {desc && typeof desc === "string" ? (
                <Text
                  fontSize={"18px"}
                  textAlign={"center"}
                  fontWeight={"400"}
                  fontStyle={"normal"}
                >
                  {desc}
                </Text>
              ) : (
                <Box>{desc}</Box>
              )}
            </Flex>
          </ModalBody>
          <ModalFooter w="100%" p="0">
            <Button
              type="submit"
              w="100%"
              onClick={onClick}
              height={"58px"}
              variant={"dark-green"}
              isLoading={isLoading}
            >
              {titleBtn}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default SuccessModal;
