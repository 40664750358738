import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Stack,
  Text,
  useToast,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import {
  autoCropImageTwygs,
  handleFileNameExtension,
  handleUploadAssetToCloud,
  handleValidateImage,
} from "../twygs.helper";
import TempalteCreateTwygsComponent from "../template-create-twygs.component";
import DropzoneTwygsComponent from "../dropzone-twygs.component";
import {
  FILE_TYPES,
  MAX_FILE_SIZE_10,
} from "../../../../constants/image.constant";
import {
  DRAG_DROP_DESC,
  ERROR_FILE_UPLOAD,
  ERROR_MESSAGE,
  SUCCESS_DRAFT,
} from "../twygs.constant";
import { ReactComponent as EditImageIcon } from "../../../../assets/edit-image-icon.svg";
import { ReactComponent as FailLoadImage } from "../../../../assets/failed-to-load.svg";
import { ReactComponent as SuccessfullyIcon } from "../../../../assets/successful-icon.svg";
import { ReactComponent as ToastSuccess } from "../../../../assets/toast-success.svg";

import SuccessModal from "../../../Modal/SuccessModal";
import DraggableImage from "./draggable-image.component";
import ImageContentModal from "../../../PreviewPopup";
import { useAtom } from "jotai";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import {
  postAssetUpload,
  publishTwyg,
  saveTwygDraft,
} from "../../../../apis/studio.api";
import { proceedAtom } from "../../../TopBar/atom";
import { profileAtom } from "../../../Settings/atom";
import ToastMessage from "../../../ToastMessage";
import { protectedRoutes } from "../../../../constants/routes";
import { schema } from "./schema";
import ConfirmModal from "../../../Modal/ConfirmModal";

const FormImageComponent = (props) => {
  const {
    imageSelected,
    setImageSelected,
    imageList,
    setImageList,
    setEditImage,
    currentData,
  } = props;

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [, setIsProceedDone] = useAtom(proceedAtom);
  const toast = useToast();
  const [profile, setProfile] = useAtom(profileAtom);
  const type = searchParams.get("action");

  const { mutateAsync: publishTwygAsync } = useMutation({
    mutationFn: publishTwyg,
  });
  const { mutateAsync: saveTwygsDraftAsync } = useMutation({
    mutationFn: saveTwygDraft,
  });
  const { mutateAsync: postAssetUploadAsync } = useMutation({
    mutationFn: postAssetUpload,
  });

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const [modal, setModal] = useState("");
  const [loadingDraft, setLoadingDraft] = useState(false);
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [infoPublish, setInfoPublish] = useState(null);
  const [titleErr, setTitleErr] = useState();
  const [progressCrop, setProgressCrop] = useState(false);

  const handCropImage = async (arr) => {
    const newArr = await autoCropImageTwygs(arr ?? imageList);
    setImageList(newArr);
    setModal("");
    setProgressCrop(false);
  };

  const handleUploadImage = (value) => {
    const { arr, errList } = handleValidateImage(
      value,
      10,
      MAX_FILE_SIZE_10,
      imageList?.length
    );

    if (imageList?.length > 0) {
      setImageList([...imageList, ...arr]);
    } else {
      setImageList(arr);
    }
    if (errList.file.length > 0) {
      setTitleErr(errList.file);
      setModal("confirm-err");
    } else if (errList.limit) {
      setModal("success-limit");
    } else {
      handCropImage([...imageList, ...arr]);
    }
  };

  const handleContinues = () => {
    if (imageList.length > 0) {
      setProgressCrop(true);
      handCropImage();
    } else {
      setModal("");
    }
  };

  const handleCancel = () => {
    setModal("");
  };

  const handleSelectImage = (image) => {
    const index = imageSelected.findIndex((elm) => elm.id === image.id);
    let newList = [...imageSelected];
    if (index === -1) newList = [...newList, image];
    else {
      newList.splice(index, 1);
    }
    setImageSelected(newList);
  };

  const moveImage = useCallback(
    (dragIndex, hoverIndex) => {
      setImageList((prevCards) => {
        const clonedCards = [...prevCards];
        const removedItem = clonedCards.splice(dragIndex, 1)[0];

        clonedCards.splice(hoverIndex, 0, removedItem);
        return clonedCards;
      });
    },
    [setImageList]
  );

  const handleAssetUpload = async () => {
    let listIndexKeyCurrent = [];
    let listIndexKeyNew = [];
    const arrUploadName =
      imageList
        // eslint-disable-next-line array-callback-return
        .filter((elm, index) => {
          if (elm.file) {
            listIndexKeyNew = [...listIndexKeyNew, index];
            return elm;
          } else listIndexKeyCurrent = [...listIndexKeyCurrent, index];
        })
        .map((item) => {
          return handleFileNameExtension(item.fileName, item.file.type);
        }) || [];
    if (arrUploadName.length > 0) {
      const urlData = await postAssetUploadAsync({
        fileName: [...arrUploadName],
      });

      if (urlData?.length > 0) {
        const uploadUrlArr = imageList
          // eslint-disable-next-line array-callback-return
          .filter((elm) => {
            if (elm.file) {
              return elm;
            }
          })
          .map((item, index) => {
            return {
              ...item,
              uploadUrl: urlData[index].url,
              fileName: urlData[index].key,
            };
          });
        await handleUploadAssetToCloud(uploadUrlArr);
        let newKeys = [];
        imageList.forEach((elm, index) => {
          if (listIndexKeyCurrent.includes(index))
            newKeys = [...newKeys, elm.fileName];
          else
            newKeys = [
              ...newKeys,
              urlData[
                listIndexKeyNew.findIndex((newIndex) => newIndex === index)
              ].key,
            ];
        });
        return newKeys;
      }
    } else return imageList.map((elm) => elm.fileName);
  };

  const submitForm = async (valueForm) => {
    try {
      setLoadingPublish(true);
      let payload = {
        ...valueForm,
        // hashtag: hashtag,
        contentType: "Image",
      };
      const contentKeys = await handleAssetUpload();
      payload = { ...payload, contentKeys: contentKeys };
      if (type && currentData) {
        const removedContentKeys = currentData.contentKeys.filter((elm) => {
          return contentKeys?.findIndex((key) => key === elm) === -1;
        });
        payload = { ...payload, removedContentKeys };
      }
      const response = await publishTwygAsync(payload);
      setLoadingPublish(false);
      setModal("publish-success");
      setInfoPublish({
        ...payload,
        createdAt: new Date(),
        ...response,
        contentUrlList: imageList.map((elm) => elm.src),
      });

      /* User Post Event Conversion Location */
      //Add Impact APIs to .env
      //DEPRECATED, PENDING DELETION
      /* User Post Event Conversion Location */

      setIsProceedDone(true);
      if (!profile.creatorWallet) {
        setProfile({ ...profile, creatorWallet: response?.walletAddress });
      }
      toast({
        render: () => (
          <ToastMessage
            backgroundColor="#FFD166"
            color="jet-black"
            Icon={ToastSuccess}
            title={"Twyg Under Review"}
          ></ToastMessage>
        ),
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      setLoadingPublish(false);
      if (error?.data?.message === ERROR_MESSAGE.DUPLICATE_NAME) {
        methods.setError(
          "twygsName",
          { message: error?.data?.message },
          { shouldFocus: true }
        );
      }
    }
  };

  const saveDarft = async () => {
    try {
      setLoadingDraft(true);
      let payload = {
        ...methods.getValues(),
        contentType: "Image",
      };
      if (imageList.length > 0) {
        const contentKeys = await handleAssetUpload();
        payload = { ...payload, contentKeys: contentKeys };
      }
      await saveTwygsDraftAsync(payload);
      setModal("draft-success");
      setLoadingDraft(false);
      setIsProceedDone(true);
    } catch (error) {
      console.log(error);
      setLoadingDraft(false);
      if (error?.data?.message === ERROR_MESSAGE.DUPLICATE_NAME) {
        methods.setError(
          "twygsName",
          { message: error?.data?.message },
          { shouldFocus: true }
        );
      }
    }
  };

  const redirectRouter = (url) => () => {
    navigate(url);
    setModal("");
  };

  useEffect(() => {
    if (currentData) methods.reset(currentData);
  }, [currentData, methods]);

  return (
    <FormProvider {...methods}>
      <form>
        <TempalteCreateTwygsComponent
          saveDarft={saveDarft}
          onSubmit={methods.handleSubmit(submitForm)}
          loadingSaveDraft={loadingDraft}
          loadingPublish={loadingPublish}
          title="Image Twyg"
          disable={!methods.formState.isValid || !(imageList?.length > 0)}
        >
          <Box display={imageList?.length > 0 ? "block" : "none"}>
            <Button
              h={"2.25rem"}
              pl={"1.125rem"}
              pr={"0.75rem"}
              variant={"light-green"}
              rightIcon={<EditImageIcon alt="" />}
              mb={{ base: "1rem", "2xl": "1.5rem" }}
              fontSize={"sm"}
              _disabled={{
                opacity: 1,
                cursor: "not-allowed",
              }}
              onClick={() => {
                if (imageSelected.length === 0) setImageSelected(imageList);
                setEditImage(true);
              }}
            >
              Edit image
            </Button>
            <Wrap spacing={"1rem"} w={"full"} flexWrap={"wrap"}>
              {imageList?.map((elm, index) => {
                const checked =
                  imageSelected.findIndex((image) => image.id === elm.id) > -1;
                return (
                  <WrapItem
                    boxSizing={"border-box"}
                    key={index}
                    w={{
                      base: "calc(50% - 12px)",
                      md: "calc(25% - 12px)",
                    }}
                    minW={{
                      base: "calc(50% - 12px)",
                      md: "calc(25% - 12px)",
                    }}
                    border={{
                      base:
                        imageList?.length === 1
                          ? "none"
                          : `${checked ? 2 : 1}px solid`,
                      md: `${checked ? 2 : 1}px solid`,
                    }}
                    borderColor={{
                      base:
                        imageList?.length === 1
                          ? "anti-flash-lite"
                          : checked
                          ? "twygs-green"
                          : "anti-flash-lite",
                      md: checked ? "twygs-green" : "anti-flash-lite",
                    }}
                    boxShadow={
                      "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)"
                    }
                    borderRadius={"0.5rem"}
                    p={"0.25rem"}
                    bg={"white"}
                    flexFlow={"column"}
                    cursor={"pointer"}
                    _hover={{
                      opacity: 0.9,
                    }}
                    onClick={() => handleSelectImage(elm)}
                  >
                    <DraggableImage
                      id={elm.id}
                      src={elm.src}
                      index={index}
                      moveImage={moveImage}
                      onlyOneImage={imageList?.length === 1 ? true : false}
                    />
                  </WrapItem>
                );
              })}
              <WrapItem
                display={
                  imageList?.length === 10 || type === "EDIT" ? "none" : "block"
                }
                boxSizing={"border-box"}
                w={{
                  base: "calc(50% - 12px)",
                  md: "calc(25% - 12px)",
                }}
                minW={{
                  base: "calc(50% - 12px)",
                  md: "calc(25% - 12px)",
                }}
                aspectRatio={1}
                maxH={{
                  base: "calc(10.5rem + 38px)",
                  md: "calc(22.5rem + 38px)",
                }}
              >
                <DropzoneTwygsComponent
                  acceptFileTypes={FILE_TYPES.twygsImage}
                  validateFiles={handleUploadImage}
                  hideContent
                  height={"full"}
                />
              </WrapItem>
            </Wrap>
          </Box>
          <Box display={imageList?.length > 0 ? "none" : "block"}>
            <DropzoneTwygsComponent
              acceptFileTypes={FILE_TYPES.twygsImage}
              validateFiles={handleUploadImage}
              desc={DRAG_DROP_DESC.image.map((item, index) => (
                <Text
                  key={index}
                  fontWeight="400"
                  color="dim-gray"
                  fontSize="xs"
                >
                  {item}
                </Text>
              ))}
            />
          </Box>
        </TempalteCreateTwygsComponent>
      </form>
      {modal === "confirm-err" && (
        <ConfirmModal
          isOpen
          Icon={FailLoadImage}
          title={titleErr ?? ERROR_FILE_UPLOAD.SOMETHING_WRONG.TITLE}
          desc={ERROR_FILE_UPLOAD.SOMETHING_WRONG.DESC}
          titleBtn={"Next"}
          onClick={handleContinues}
          onClose={handleCancel}
          type="Image"
          isLoading={progressCrop}
        />
      )}
      {modal === "success-limit" && (
        <SuccessModal
          isOpen
          Icon={FailLoadImage}
          title={ERROR_FILE_UPLOAD.EXCEED_LIMIT.IMAGE}
          desc={""}
          titleBtn={"Okay"}
          onClick={handleContinues}
          isLoading={progressCrop}
        />
      )}
      {modal === "draft-success" && (
        <SuccessModal
          isOpen
          Icon={SuccessfullyIcon}
          title={SUCCESS_DRAFT.title}
          desc={SUCCESS_DRAFT.desc}
          titleBtn={"Go to My Draft"}
          onClick={redirectRouter(protectedRoutes.MY_DRAFTS)}
        />
      )}
      {modal === "publish-success" && (
        <ImageContentModal
          isDisabled
          data={infoPublish}
          isOpen
          onClose={redirectRouter(protectedRoutes.MY_TWYGS)}
          onDelete={redirectRouter(protectedRoutes.MY_TWYGS)}
        />
      )}
    </FormProvider>
  );
};

export default FormImageComponent;
