export const TagTheme = {
  baseStyle: {
    container: {
      bg: "#FFFFFF",
      border: "1px solid #6C59FF",
      borderRadius: "30px",
      px: "10px",
      height: "40px"
    },
    label: {
      fontSize: "sm"
    },
    closeButton: {
      color: "#6C59FF",
      opacity: "1"
    }
  },

  variants: {
    "secondary-color": {
      container: {
        height: "36px",
        bg: "#F9F5FF",
        border: "1px solid #F9F5FF",
        borderRadius: "8px",
        px: "8px"
      },
      label: {
        fontSize: "sm"
      },
      closeButton: {
        color: "#A3A3A3",
        opacity: "1"
      }
    }
  },

  defaultProps: {
    size: "sm",
    variant: "secondary-color"
  }
};
