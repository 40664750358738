import LikeIcon from "../../../assets/like-icon.svg";
import CommnentIcon from "../../../assets/comment-icon.svg";
import ShareIcon from "../../../assets/share-icon.svg";
import RedirectIcon from "../../../assets/redirect-icon.svg";
import NoDataIcon from "../../../assets/no-data-icon.svg";

import ImageWithSkeleton from "../../ImageWithSkeleton";
import { convertCountLike } from "../../../constants";

const GalleryLayout = ({
  list,
  haveExploreMoreButton,
  anotherLayout,
  loading,
  isHome,
  tracking,
}) => {
  const divideArray = () => {
    if (!list || list.length < 1) return [];

    const windowWidth = window.innerWidth;
    const numColumns =
      windowWidth > 2227
        ? 6
        : windowWidth > 1900
        ? 5
        : windowWidth > 786
        ? 4
        : 2;
    const dividedArrays = Array.from({ length: numColumns }, () => []);

    list.forEach((element, index) => {
      const chunkIndex = index % numColumns;
      dividedArrays[chunkIndex].push(element);
    });
    const lastItem = dividedArrays[dividedArrays.length - 1];
    if (haveExploreMoreButton) {
      lastItem.push({});
    }
    return dividedArrays;
  };

  const gridTemplates =
    window.innerWidth > 2227
      ? 6
      : window.innerWidth > 1900
      ? 5
      : window.innerWidth > 786
      ? 4
      : 2;

  return (
    <div>
      {!loading && divideArray().length === 0 ? (
        <div className="flex flex-col items-center">
          <img
            className="w-[300px] h-[300px] 2xl:w-[400px] 2xl:h-[400px] md:w-"
            src={NoDataIcon}
            alt="no data"
          />
          <p className="mt-0 2xl:mt-5 text-xl 2xl:text-[32px] font-black">
            No results found!
          </p>
        </div>
      ) : (
        ""
      )}
      {!loading && (
        <div
          className={`grid gap-4 mb-5`}
          style={{
            gridTemplateColumns: `repeat(${gridTemplates}, minmax(0, 1fr))`,
          }}
        >
          {divideArray().map((elm, index) => {
            return (
              <div className={`flex flex-col gap-4`} key={index}>
                {elm.map((subElm, index) => {
                  if (Object.keys(subElm)?.length === 0) {
                    return (
                      <a
                        key={index}
                        className="flex flex-col justify-between cursor-pointer p-4 md:p-[30px] bg-jet-black !rounded-[14px] w-full !max-h-[250px] md:!max-h-[350px] aspect-square"
                        href={`${process.env.REACT_APP_EXPLORE_URL}`}
                        target="_blank"
                        without
                        rel="noreferrer"
                      >
                        <p className="!text-twygs-green text-[30px] md:text-[56px]">
                          Explore more
                        </p>
                        <div className="flex justify-between ">
                          <div></div>
                          <img
                            className="max-w-[60px] max-h-[60px] md:max-w-[120px] md:max-h-[120px]"
                            src={RedirectIcon}
                            alt="redirect"
                          />
                        </div>
                      </a>
                    );
                  } else {
                    return anotherLayout ? (
                      <a
                        key={index}
                        className="relative cursor-pointer max-h-[400px] shadow-ms overflow-hidden !rounded-xl border-1 border-white"
                        href={`${
                          process.env.REACT_APP_EXPLORE_URL
                        }creator-detail/${encodeURIComponent(subElm.username)}`}
                        target={isHome ? "_blank" : "_self"}
                        rel="noreferrer"
                      >
                        <ImageWithSkeleton
                          className="!h-full w-full !rounded-2xl"
                          src={subElm.image}
                          alt="twyg preview"
                          aspect={anotherLayout ? "aspect-square" : null}
                        />
                        <div className="!rounded-b-[14px] pl-5 pr-4 flex flex-col justify-center absolute bottom-0 bg-white opacity-90 w-full h-[70px] md:h-[110px]">
                          <p className="text-drak-green font-extrabold truncate mb-[10px] text-sm md:text-base">
                            {subElm.username}
                          </p>
                          <div className="flex items-center gap-10">
                            <div className="">
                              <p className="text-sm">Twygs created</p>
                              <p className="text-base font-extrabold">
                                {convertCountLike(subElm.twygsCount)}
                              </p>
                            </div>
                            <div>
                              <p className="text-sm">Likes received</p>
                              <p className="text-base font-extrabold">
                                {convertCountLike(subElm.totalLikeCount)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </a>
                    ) : (
                      <a
                        key={index}
                        className="relative cursor-pointer max-h-[400px] shadow-ms overflow-hidden !rounded-xl border-1 border-white group"
                        href={`${process.env.REACT_APP_EXPLORE_URL}twygs-detail?id=${subElm.id}`}
                        target={isHome ? "_blank" : "_self"}
                        rel="noreferrer"
                        title={subElm.name}
                        onClick={() => {
                          window.dataLayer.push({
                            event: "view_twyg",
                            twyg_id: subElm.id,
                            twyg_category: subElm.contentCategory,
                          });
                          if (tracking) {
                            window.TwygsSDK.trackPageView();
                          }
                        }}
                      >
                        <ImageWithSkeleton
                          className="!h-full w-full"
                          src={subElm.thumbnail}
                          alt="twyg preview"
                          showIcon={subElm.contentType === "Video"}
                        />
                        <div
                          style={{ zIndex: 3 }}
                          className="pl-2 pr-2 md:pl-5 md:pr-4 flex flex-col justify-center absolute bottom-0 bg-white opacity-90 w-full h-[70px] md:h-[110px]"
                        >
                          <p className="text-drak-green font-extrabold truncate mb-0.5 text-sm md:text-base">
                            {subElm.name}
                          </p>
                          <a
                            href={`${
                              process.env.REACT_APP_EXPLORE_URL
                            }creator-detail/${encodeURIComponent(
                              subElm.ownerUsername
                            )}`}
                            title={`${subElm.ownerUsername}`}
                            className="creator-name"
                          >
                            <p className="text-drak-green text-ms truncate mb-0.5 md:mb-2 text-sm md:text-base group-hover:text-red">
                              {subElm.ownerUsername}
                            </p>
                          </a>
                          <div className="flex items-center gap-5">
                            <div className="flex items-center">
                              <img
                                src={LikeIcon}
                                alt="Like"
                                className="mr-1 w-4 h-4 md:w-6 md:h-6"
                              />
                              <p className="text-sm md:text-base">
                                {convertCountLike(subElm.numberOfLike)}
                              </p>
                            </div>
                            <div className="flex items-center">
                              <img
                                src={CommnentIcon}
                                alt="Comment"
                                className="mr-1 w-4 h-4 md:w-6 md:h-6"
                              />
                              <p className="text-sm md:text-base">
                                {convertCountLike(subElm.numberOfComments || 0)}
                              </p>
                            </div>
                            <div className="flex items-center">
                              <img
                                src={ShareIcon}
                                alt="Share"
                                className="w-4 h-4 md:w-6 md:h-6"
                              />
                            </div>
                          </div>
                        </div>
                      </a>
                    );
                  }
                })}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default GalleryLayout;
