import { useState } from "react";
import { Box, forwardRef, Image, Spinner } from "@chakra-ui/react";

const ImageComponent = forwardRef((props, ref) => {
  const { ...rest } = props;
  const [isLoading, setIsLoading] = useState(true);

  const onLoad = (e) => {
    setIsLoading(false);
    if (rest.onLoad) rest.onLoad(e);
  };

  const onError = (e) => {
    setIsLoading(false);
    if (rest.onError) rest.onError(e);
  };

  return (
    <Box
      position={"relative"}
      width={rest.width}
      height={rest.height}
      borderRadius={rest.borderRadius}
    >
      <Box
        opacity={isLoading ? 0 : 1}
        display={"flex"}
        justifyContent={"center"}
      >
        <Image
          alt=""
          fallbackSrc=""
          fallbackStrategy={"onError"}
          {...rest}
          onLoad={onLoad}
          onError={onError}
          ref={ref}
        />
      </Box>
      {isLoading && (
        <Box
          position={"absolute"}
          top={0}
          left={0}
          width={"full"}
          height={"full"}
          minWidth={"24px"}
          minHeight={"24px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="dark-green"
            size={{ base: "lg", "2xl": "xl" }}
          />
        </Box>
      )}
    </Box>
  );
});

ImageComponent.displayName = "ImageComponent";

export default ImageComponent;
