export const PinInputTheme = {
  baseStyle: {
    background: "#FFFFFF",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    _focusVisible: {
      borderColor: "#6C59FF",
      color: "#6C59FF"
    },
    marginRight: "24px",
    _invalid: {
      borderColor: "#DE1414",
      color: "#DE1414"
    }
  },

  sizes: {
    sm: {
      height: "64px",
      minWidth: "54px",
      fontSize: "16px",
      border: "1px solid #D0D5DD",
      borderRadius: "15px",
      padding: "12px 4px"
    }
  },

  defaultProps: {
    size: "sm",
    focusBorderColor: "#6C59FF"
  }
};
