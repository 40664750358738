"use client";

const RequireLoginPopupComponent = ({ title, desc }) => {
  return (
    <div className="w-[288px] p-6 bg-white shadow-custom !rounded-3xl flex flex-col items-center">
      <p className="mb-3 text-xl font-extrabold">{title}</p>
      <p className="font-semibold text-sm mb-4">{desc}</p>
      <a
        href={`${process.env.REACT_APP_TWYGS_AUTH_URL}login?from=${window.location.href}`}
        target="_blank"
        id="btn"
        className="cursor-pointer bg-custom !h-[36px] w-full !rounded-lg !text-white text-sm font-black hover:opacity-80"
        rel="noreferrer"
      >
        Log in
      </a>
    </div>
  );
};

export default RequireLoginPopupComponent;
