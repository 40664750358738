import { useEffect, useState } from "react";
import { Center, Spinner } from "@chakra-ui/react";
import FormVideoComponent from "../components/video-twygs/form-video.conponent";
import ThumbnailComponent from "../components/video-twygs/thumbnail.component";
import TrimComponent from "../components/video-twygs//trim.component";
import { TWYGS_VIDEO_ACTION } from "../components/twygs.constant";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "../components/video-twygs/schema";
import { useQuery } from "@tanstack/react-query";
import { fetchTwygsDetail } from "../../../apis/studio.api";
import { useSearchParams } from "react-router-dom";

const VideoTwygsPage = () => {
  const [searchParams] = useSearchParams();

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const [assetData, setAssetData] = useState(null);

  const [action, setAction] = useState("");

  const twygsId = searchParams.get("id");
  const { data, error, isFetching, isError } = useQuery({
    queryKey: ["twygsDetail", searchParams.get("id")],
    queryFn: () => fetchTwygsDetail(twygsId),
    enabled: !!twygsId,
  });

  useEffect(() => {
    if (data) {
      const resData = data;

      const obj = {
        asset: {
          fileName: resData.contentKeys[0],
          contentKeys: resData.contentKeys[0],
          assetType: resData.videoAssetType,
          src: resData.contentUrls[0],
          duration: 0,
          isUpdated: false,
        },
        thumbnail: {
          fileName: resData.thumbnail,
          thumbnail: resData.thumbnail,
          assetType: resData.videoAssetType,
          src: resData.thumbnailUrl,
          rootSrc: resData.thumbnailUrl,
          isUpdated: false,
        },
      };

      setAssetData(obj);

      methods.reset({
        twygsName: resData.twygsName,
        description: resData.description,
        categoryId: resData.categoryId,
      });
    }
  }, [data]);

  // render action component
  const renderAction = () => {
    switch (action) {
      case TWYGS_VIDEO_ACTION.THUMBNAIL:
        return (
          <ThumbnailComponent
            assetData={assetData}
            setAssetData={setAssetData}
            setAction={setAction}
          />
        );
      case TWYGS_VIDEO_ACTION.TRIM:
        return (
          <TrimComponent
            assetData={assetData}
            setAssetData={setAssetData}
            setAction={setAction}
          />
        );
      default:
        return (
          <FormVideoComponent
            assetData={assetData}
            setAssetData={setAssetData}
            setAction={setAction}
          />
        );
    }
  };

  if (isFetching)
    return (
      <Center h={"25rem"}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="dark-green"
          size={{ base: "lg", "2xl": "xl" }}
        />
      </Center>
    );

  return <FormProvider {...methods}>{renderAction()}</FormProvider>;
};

export default VideoTwygsPage;
