import { useEffect, useState } from "react";

import ChevronDown from "../../../assets/chevron-down-icon.svg";
import AvatarIcon from "../../../assets/ava-icon.svg";
import AutoResizeTextArea from "../../TextArea";
import { fetchListComment, postComment } from "../../../apis/twygs-detail";
import CommentDetailComponent from "./CommentDetailComponent";
import { Loading } from "../../Loading";
import RequireLoginCommentComponent from "../RequireLoginComment";
import Cookies from "js-cookie";
import useSearchParams from "../../../hooks/router/useSearchParams";
const staging = process.env.REACT_APP_IS_STAGING;

const CommentSectionComponent = (props) => {
  const { twygs } = props;
  const { id } = useSearchParams().searchParams;

  const token = Cookies.get(`${staging ? "staging" : ""}token`);

  const [listComment, setListComment] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [hasShowMore, setHasShowMore] = useState(false);
  const [commentBox, setCommentBox] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 5,
  });
  const [error, setError] = useState("");

  const handleSubmitComment = async () => {
    const date = new Date();
    try {
      setLoadingSubmit(true);
      const res = await postComment(
        {
          text: commentBox,
        },
        id
      );
      window.dataLayer.push({
        event: "comment",
        twyg_id: id,
        twyg_category: twygs.twygDetails.contentCategory,
      });
      setLoadingSubmit(false);
      setCommentBox("");
      setListComment([
        {
          id: res.id,
          text: commentBox,
          createdAt: date.toISOString(),
          likeNumber: 0,
          createdByUser: {
            userId: userInfo?.id,
            username: userInfo?.username,
            image: userInfo?.imgUrl,
          },
        },
        ...listComment,
      ]);
      setError("");
    } catch (error) {
      window.dataLayer.push({
        event: "error",
        error_type: "comment error",
        error_message: error?.data?.message,
      });
      setLoadingSubmit(false);
      if (error.response.status === 400) {
        setError("Returned error");
      } else {
        setError("Something went wrong");
      }
    }
  };

  const resetTextareaHeight = () => {
    const textarea = document.getElementById("comment"); // Replace with the actual ID
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  const handleCancel = () => {
    setCommentBox("");
    setError("");
    resetTextareaHeight();
  };

  const handleShowMore = () => {
    setLoading(true);
    setPagination({
      ...pagination,
      page: pagination.page + 1,
    });
  };

  useEffect(() => {
    const textarea = document.getElementById("comment"); // Replace with the actual ID
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  }, [commentBox]);

  useEffect(() => {
    const fetchComments = async () => {
      const result = await fetchListComment(id, pagination);
      setLoading(false);

      if (pagination.page > 1) {
        setListComment((pre) => [...pre, ...result.comments]);
      } else {
        setListComment(result.comments);
      }

      if (result.totalPage > pagination.page) {
        setHasShowMore(true);
      } else {
        setHasShowMore(false);
      }
    };
    if (id) {
      fetchComments();
    }
  }, [id, pagination]);

  const handleKeyPress = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === "Enter") {
      handleSubmitComment();
    }
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    setUserInfo(user);
  }, []);

  return (
    <div>
      {/* Leave Comment */}
      {!token ? (
        <RequireLoginCommentComponent />
      ) : (
        <div className="border custom-border-mid-gray !rounded-[20px] py-2 md:py-[16px] px-3 md:px-[24px] flex flex-col gap-1.5 md:gap-[12px]">
          <div className="flex items-center">
            <img
              src={userInfo?.imgUrl || AvatarIcon}
              className="w-[32px] h-[32px] rounded-full"
              alt="icon"
            />
            <p className="ml-[12px] font-semibold text-xs">
              {userInfo?.username}
            </p>
          </div>
          <AutoResizeTextArea
            id="comment"
            value={commentBox}
            onChange={(e) => setCommentBox(e.target.value)}
            placeholder="Add a comment..."
            handleKeyPress={handleKeyPress}
          />
          <div className="justify-end flex">
            <div
              id="btn"
              onClick={() => handleCancel()}
              className="justify-center cursor-pointer !border-none !rounded-[40px] bg-none px-2 md:px-3 py-1 md:py-2 text-[12px] md:text-sm font-black w-auto !hover:bg-none"
            >
              Cancel
            </div>
            <div
              id="btn"
              onClick={() => commentBox.trim() && handleSubmitComment()}
              className={`rounded-[40px] ml-[8px]  ${
                !commentBox.trim()
                  ? "cursor-not-allowed opacity-[0.5] !hover:opacity-[0.5] bg-[#084F4B]"
                  : "bg-[#084F4B] cursor-pointer  opacity-[1] !hover:opacity-[0.9]"
              } px-2 md:px-3 py-1 md:py-2 text-[12px] md:text-sm font-black !text-white w-auto`}
            >
              {loadingSubmit ? (
                <div className="flex gap-2 items-center !text-white opacity-[0.7]  text-[12px] md:text-sm">
                  <svg
                    aria-hidden="true"
                    className="inline w-3 h-3 text-gray-200 animate-spin dark:text-gray-600 fill-gray-300"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  Comment
                </div>
              ) : (
                "Comment"
              )}
            </div>
          </div>
        </div>
      )}
      {error && <p className="!text-red-500">{error}</p>}

      {/* Show List Comment */}
      <div className="mt-5 md:mt-[40px]">
        {listComment?.map((item) => (
          <div key={item.id}>
            <CommentDetailComponent
              setListComment={setListComment}
              listComment={listComment}
              twygsId={id}
              contentCategory={twygs?.twygDetails?.contentCategory}
              twygCreatorId={twygs?.twygCreator?.id}
              item={item}
              userInfo={userInfo}
            />
          </div>
        ))}
      </div>
      {loading && <Loading></Loading>}

      {hasShowMore && !loading ? (
        <div>
          <div
            className="flex items-center justify-center cursor-pointer hover:opacity-80"
            onClick={() => handleShowMore()}
          >
            <p className="font-black mr-[8px] text-sm 2xl:text-base">
              Show more
            </p>
            <img src={ChevronDown} alt="icon" />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CommentSectionComponent;
