const RequireLoginCommentComponent = () => {
  return (
    <div className="border custom-border-mid-gray !rounded-[20px] p-5 flex flex-col items-center">
      <p className="text-base font-semibold mb-3">Log in to leave a comment</p>
      <div className="flex items-center w-full justify-center gap-2">
        <a
          href={`${process.env.REACT_APP_TWYGS_AUTH_URL}login?from=${window.location.href}`}
          target="_self"
          id="btn"
          className="cursor-pointer bg-white h-[40px] max-w-[140px] w-full custom-color border custom-border-dark-green !rounded-[40px] text-sm font-black hover:opacity-80"
        >
          Log in
        </a>
        <a
          href={`${process.env.REACT_APP_TWYGS_AUTH_URL}signup?from=${window.location.href}`}
          target="_self"
          id="btn"
          className="cursor-pointer bg-custom h-[40px] max-w-[140px] w-full !rounded-[40px] !text-white text-sm font-black hover:opacity-80"
        >
          Sign up
        </a>
      </div>
    </div>
  );
};

export default RequireLoginCommentComponent;
