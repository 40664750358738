import React, { Fragment, useEffect, useState } from "react";
import { OPTION_TWYGS } from "../../constants/create-twygs.constant";
import {
  Box,
  Button,
  Stack,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import IntroSection from "../IntroSection/intro-section.component";
import { TopBar } from "../TopBar";
import { ReactComponent as ArrowRightIconSolid } from "../../assets/arrow-right-solid.svg";

const CreateTwygs = () => {
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery("(max-width: 768px)");
  const [option, setOption] = useState(null);

  useEffect(() => {
    if (!isMobile && !option) {
      setOption(OPTION_TWYGS[0]);
    }
  }, [isMobile, option]);

  const handleRedirect = (elm) => {
    window.dataLayer.push({
      event: "twyg_step_start",
      twyg_id: uuidv4(),
      twyg_category: elm.type || option.type,
    });
    if (elm) {
      navigate(elm.url);
    } else {
      navigate(option.url);
    }
  };

  const handleMobileClick = (elm) => {
    setOption((elm) => elm);
    handleRedirect(elm);
  };

  return (
    <Fragment>
      <TopBar />

      <VStack
        alignItems={"start"}
        spacing={{ base: "1.25rem", "2xl": "2.5rem" }}
        position={"relative"}
        minH={{ base: "calc(100vh - 173px)", "2xl": "calc(100vh - 181px)" }}
        p={{ base: "1rem", "2xl": "2rem" }}
      >
        <IntroSection
          title="Create Twygs"
          description="Select a content type to bring your ideas to life"
        />
        <Stack
          direction={{ base: "column", md: "row" }}
          w={"full"}
          spacing={"1.5rem"}
          mb={{ base: "81px", md: "0px" }}
        >
          {OPTION_TWYGS.map((elm) => {
            const isSelected = option?.id === elm.id;
            return (
              <VStack
                key={elm.id}
                flex={1}
                w={"full"}
                h={"full"}
                border={"2px solid"}
                borderColor={"dark-green"}
                rounded={"1rem"}
                p={"1.5rem"}
                alignItems={"center"}
                bg={isSelected && !isMobile ? "#d0eac3" : "light.200"}
                spacing={"0.75rem"}
                color={"dark-green"}
                cursor={"pointer"}
                _hover={{
                  opacity: isSelected ? 1 : 0.8,
                  bg: isSelected ? "#d0eac3" : "light.200",
                }}
                onClick={() =>
                  isMobile ? handleMobileClick(elm) : setOption(elm)
                }
                transition={"all 0.3s ease"}
              >
                <elm.icon
                  src={elm.icon}
                  className="w-[130px] h-[130px] 2xl:w-[200px] 2xl:h-[200px]"
                  alt=""
                />
                <Text
                  fontSize={{ base: "md", "2xl": "xl" }}
                  fontWeight={"800"}
                  textAlign={"center"}
                  color={"dark-green"}
                >
                  {elm.title}
                </Text>
                <Text
                  fontSize={"0.75rem"}
                  fontWeight={"600"}
                  textAlign={"center"}
                  color={"dark-green"}
                >
                  {elm.description}
                </Text>
              </VStack>
            );
          })}
        </Stack>
        {!isMobile && (
          <Box
            position={"absolute"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"end"}
            py={{ base: "0.938rem", "2xl": "1.25rem" }}
            bottom={{ base: "-40px", "2xl": "-38px" }}
            left={"0"}
            bg={"white"}
            borderTop={"1px"}
            borderColor={"anti-flash-white"}
            w={"100%"}
            px={"2.5rem"}
            boxShadow={
              "0px -1px 2px 0px rgba(16, 24, 40, 0.06), 0px -1px 3px 0px rgba(16, 24, 40, 0.10)"
            }
          >
            <Button
              variant={"dark-green"}
              pl={"1.25rem"}
              pr={"0.75rem"}
              h={{ base: "38px", "2xl": "42px" }}
              fontSize={{ base: "sm", "2xl": "md" }}
              rightIcon={<ArrowRightIconSolid alt="" />}
              onClick={() => handleRedirect("")}
            >
              Create
            </Button>
          </Box>
        )}
      </VStack>
    </Fragment>
  );
};

export default CreateTwygs;
