import React from "react";

const PaginationComponent = ({
  currentPage,
  totalPages,
  onChangePage,
  isSpacebBetween,
  hiddenBgDefault,
  darkMode,
  showTwoPage,
}) => {
  const getDisplayedPages = (currentPage, totalPages) => {
    let pages = [];
    if (totalPages <= 7) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else if (currentPage <= 4) {
      pages = [1, 2, 3, 4, 5, "...", totalPages - 1, totalPages];
    } else if (currentPage >= totalPages - 3) {
      pages = [
        1,
        2,
        "...",
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ];
    } else {
      pages = [1, 2, "..."];
      for (let i = currentPage - 1; i <= currentPage + 2; i++) {
        pages.push(i);
      }
      pages.push("...", totalPages - 1, totalPages);
    }
    return pages;
  };

  const handleClick = (page) => {
    if (page !== "..." && page !== currentPage) {
      onChangePage(page);
    }
  };

  const renderPages = () => {
    const pages = getDisplayedPages(currentPage, totalPages);
    return pages.map((page, index) => (
      <button
        key={index}
        id="btn"
        className={`cursor-pointer ${
          page === currentPage || (showTwoPage && page === currentPage + 1)
            ? darkMode
              ? "!bg-[#333333]"
              : "!bg-[#DCEED3]"
            : !hiddenBgDefault
            ? "!bg-[#FAFAFA]"
            : "!bg-unset"
        } ${
          darkMode ? "!text-white" : "!text-[#084F4B]"
        } px-2 md:px-4 py-1 md:py-2 rounded-md ${
          !hiddenBgDefault ? "hover:bg-gray-200" : ""
        } focus:outline-none font-extrabold text-[12px] md:text-base`}
        onClick={() => handleClick(page)}
        disabled={page === "..."}
      >
        {page}
      </button>
    ));
  };

  return (
    <div
      className={`flex ${
        isSpacebBetween ? "justify-between" : "justify-center"
      } w-full`}
    >
      <div className="flex gap-0.5" style={{ zIndex: 2 }}>
        {renderPages()}
      </div>
    </div>
  );
};

export default PaginationComponent;
