// import { LandscapeGreenIcon, PortraitGreenIcon, SquareGreenIcon } from "@Assets/icons";

import { ReactComponent as LandscapeGreenIcon } from "../../../assets/landscape-green-icon.svg";
import { ReactComponent as PortraitGreenIcon } from "../../../assets/portrait-green-icon.svg";
import { ReactComponent as SquareGreenIcon } from "../../../assets/square-green-icon.svg";

export const DRAG_DROP_DESC = {
  image: [
    "Supported file types: PNG, JPG, JPEG, GIF",
    "Maximum file amount: 10 images",
    "Maximum file size: 10MB each",
  ],
  video: [
    "Supported file types: MP4, MOV, M4V, 3GP",
    "Maximum file size: 4GB",
    "Maximum duration: 2 hours",
    "Required ratios: 16:9 landscape and 9:16 portrait",
  ],
  pdf: ["Supported file types: PDF", "Maximum file size: 50MB"],
};

export const SUCCESS_DRAFT = {
  title: "Twygs draft saved successfully",
  desc: "You can resume at any time.",
};

export const DISCARD_CREATE_TWYG = {
  title: "Are you sure you want to discard this Twyg?",
  desc: "Warning: This action cannot be undone.",
};

export const DISCARD_DRAFT_TWYG = {
  title: "Are you sure you want to discard this Twyg draft?",
  desc: "Warning: This action cannot be undone.",
};

export const DISCARD_EDIT_TWYG = {
  title: "Are you sure you want to discard changes made to this Twyg?",
  desc: "Warning: Any unsaved changes will be lost.",
};

export const ERROR_FILE_UPLOAD = {
  SOMETHING_WRONG: {
    TITLE: "It looks like some files were uploaded in the wrong format.",
    DESC: "You can proceed to the next step. We’ve kept all the correct format files, so there’s no need to upload them again.",
  },
  EXCEED_LIMIT: {
    IMAGE: "Images exceed the maximum allowed 10",
    VIDEO: "Videos exceed the maximum allowed 2",
  },
  OVERVIEW: {
    PDF: "Oops! You’ve uploaded the wrong file type or size. Please upload a valid PDF.",
  },
  THUMBNAIL: {
    TITLE: "Please re-upload image with the correct format.",
    DESC: ["Supported file type PNG, JPG, JPEG, GIF", "Maximum file size 30MB"],
  },
  VIDEO: {
    TITLE:
      "Oops! You've uploaded the wrong format. Please upload a valid video.",
  },
};

export const TWYGS_CATEGORY_DEFAULT = {
  id: 1,
  name: "Art & Creativity",
};

export const RATIO_TYPES_LIST = [
  {
    type: "landscape",
    typeValue: "LANDSCAPE",
    ratio: "16:9",
    ratioValue: 1.91,
    icon: LandscapeGreenIcon,
  },
  {
    type: "portrait",
    typeValue: "PORTRAIT",
    ratio: "4:5",
    ratioValue: 0.8,
    icon: PortraitGreenIcon,
  },
  {
    type: "square",
    typeValue: "SQUARE",
    ratio: "1:1",
    ratioValue: 1,
    icon: SquareGreenIcon,
  },
];

export const VIDEO_RATIO_TYPES_LIST = [
  {
    type: "landscape",
    typeValue: "LANDSCAPE",
    ratio: "16:9",
    ratioValue: 1.91,
    icon: LandscapeGreenIcon,
  },
  {
    type: "portrait",
    typeValue: "PORTRAIT",
    ratio: "9:16",
    ratioValue: 0.56,
    icon: PortraitGreenIcon,
  },
];

export const statsStatus = {
  default: "",
  loading: "loading",
  loaded: "loaded",
  error: "error",
};

export const TWYGS_VIDEO_ACTION = {
  THUMBNAIL: "thumbnal",
  TRIM: "trim",
};

export const DISPLAY_MODAL_STUDIO_VIDEO = {
  CONFIRM_ERR: "confirm-err",
  DISCARD_CHANGES: "discard-changes",
  DRAFT_SUCCESS: "draft-success",
  PUBLISH_SUCCESS: "publish-success",
};

export const STUDIO_VIDEO_MODAL = {
  TITLE: "Are you sure you want to discard changes made to this Twyg?",
  DESC: "Warning: Any unsaved changes will be lost.",
  CONFIRM: "Discard",
};

export const ERROR_MESSAGE = {
  DUPLICATE_NAME: "Twyg name already exists",
};

export const CONTENT_TYPE_ENUM = {
  Image: "image-twyg",
  Video: "video-twyg",
  PDF: "pdf-twyg",
};

export const TWYGS_STUDIO_ACTION = {
  CREATE: "CREATE",
  RESUME: "RESUME",
  EDIT: "EDIT",
};

export const TWYGS_STUDIO_TYPE_MODAL = {
  DISCARD_CREATE_TWYG: "DISCARD_CREATE_TWYG",
  DISCARD_DRAFT_TWYG: "DISCARD_DRAFT_TWYG",
  DISCARD_EDIT_TWYG: "DISCARD_EDIT_TWYG",
};

export const TEXT_ALLOWED_RELATED_CONTENT = {
  content: "Allow related content to boost earnings.",
  textInfo:
    "Activate to display related content alongside yours, boosting earnings through ad revenue generation and enhanced engagement.",
};

export const HASHTAG_SUPPORT =
  "Adding hashtags boosts your Twyg's visibility and engagement by reaching a broader, targeted audience. It connects your content to trends and makes related Twygs easier to find. Use relevant hashtags to maximize benefits.";
