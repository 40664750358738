import * as yup from "yup";
import {
  errorEmail,
  errorName,
  errorPassword,
  errorRequired,
  isValidEmail,
  isValidPassword,
  isValidUsername,
} from "../../constants/validation";

const usernameRegex = /^[a-zA-Z0-9]*$/;

export const profileSchema = yup.object().shape({
  firstName: yup
    .string()
    .trim()
    .required(errorRequired)
    .min(2, "First name must be at least 2 characters")
    .max(15, "Maximum 15 characters allowed")
    .test("is-valid-username", errorName, isValidUsername),
  lastName: yup
    .string()
    .trim()
    .max(15, "Maximum 15 characters allowed")
    .test("is-valid-username", errorName, isValidUsername),
  username: yup
    .string()
    .trim()
    .matches(
      usernameRegex,
      "Username cannot contain spaces or special characters."
    )
    .required(errorRequired)
    .min(2, "username must be at least 2 characters")
    .max(15, "Maximum 15 characters allowed"),
  email: yup.string().test("is-valid-email", errorEmail, isValidEmail),
  imgUrl: yup.string().optional(),
  birthday: yup.string().optional().nullable(),
  gender: yup.object().optional(),
});

export const passwordSchema = yup.object().shape({
  currentPassword: yup.string().required(errorRequired),
  newPassword: yup
    .string()
    .required(errorRequired)
    .test("is-valid-password", errorPassword, isValidPassword),
  confirmPassword: yup
    .string()
    .required(errorRequired)
    .oneOf([yup.ref("newPassword")], "Your entries must be the same"),
});
