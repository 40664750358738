import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Checkbox, Text } from "@chakra-ui/react";

import { ReactComponent as BinRedIcon } from "../../../../assets/bin-red-icon.svg";
import { ReactComponent as FailLoadImage } from "../../../../assets/failed-to-load.svg";
import { ReactComponent as PencilGreenIcon } from "../../../../assets/pencil-green-icon.svg";
import { ReactComponent as ImageIcon } from "../../../../assets/image-icon.svg";
import { ReactComponent as FileIcon } from "../../../../assets/file-icon.svg";
import { ReactComponent as InformIcon } from "../../../../assets/inform-icon.svg";
import { ReactComponent as CarouselIcon } from "../../../../assets/carousel-icon.svg";
import { ReactComponent as VideoIcon } from "../../../../assets/video-icon.svg";

import {
  LIST_COLOR_CATEGORY,
  MODAL_DRAFT,
  TYPES_DRAFT,
} from "./draft.constant";
import TableComponent from "../../../Table";
import PaginationComponent from "../../../Pagination";
import ConfirmModal from "../../../Modal/ConfirmModal";
import NoDataComponent from "../../../NoData";
import { formatDate } from "../../../../utils/helper.util";
import { VIDEO_PROCESS_STATUS } from "../../../../constants/value.constant";
import ImageComponent from "../../../Common/Image";
import {
  CONTENT_TYPE_ENUM,
  TWYGS_STUDIO_ACTION,
} from "../../../CreateTwygsForm/components/twygs.constant";
import { protectedRoutes } from "../../../../constants/routes";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getDraftTwygs } from "../../../../apis/twygs.api";
import { deleteTwygs, getContentCategories } from "../../../../apis/studio.api";

const DraftTableComponent = () => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState({
    limit: 10,
    page: 1,
    sort: undefined,
    order: undefined,
  });

  const params = {
    ...(pagination.sort ? { sort: pagination.sort } : {}),
    ...(pagination.sort ? { order: pagination.order } : {}),
    limit: pagination.limit,
    page: pagination.page,
  };

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["twygs/list-draft", params],
    queryFn: () => getDraftTwygs(params),
  });
  const { mutateAsync: deleteTwygsAsync, isPending: isDeleteTwygPending } =
    useMutation({
      mutationFn: deleteTwygs,
    });

  const { data: dataCategory } = useQuery({
    queryKey: ["content-categories"],
    queryFn: getContentCategories,
  });

  const [listIdAd, setListIdAd] = useState([]);
  const [idRemove, setIdRemove] = useState([]);
  const [deleteMultiple, setDeleteMutiple] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [modal, setModal] = useState("");
  const ref = useRef();

  const handleChangePage = (page) => {
    setDataTable(() => []);
    setPagination((pre) => {
      return {
        ...pre,
        page,
      };
    });
    if (ref.current) ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const deleteDraft = async () => {
    const listDelete = deleteMultiple ? idRemove : listIdAd;
    try {
      await deleteTwygsAsync({ deleteIds: listDelete });
      const newListId = [...listIdAd].filter((elm) => {
        return listDelete.findIndex((id) => id === elm) === -1;
      });
      setPagination((pre) => {
        const newPre = {
          ...pre,
          page:
            Math.ceil((data?.count - listDelete.length) / 10) < pre.page
              ? Math.ceil((data?.count - listDelete.length) / 10)
              : pre.page,
        };
        refetch(newPre);
        return newPre;
      });
      setListIdAd(() => newListId);
      setModal("");
      setDeleteMutiple(false);
    } catch (error) {}
  };

  const selectDraft = useCallback((adId, checked) => {
    if (checked) {
      setListIdAd((prevList) => [...prevList, adId]);
    } else {
      setListIdAd((prevList) => prevList.filter((id) => id !== adId));
    }
  }, []);

  const handleOnEdit = (value) => () => {
    if (value.videoProcessStatus !== VIDEO_PROCESS_STATUS.PROCESSING) {
      navigate(
        `${protectedRoutes.CREATE_TWYGS}/${
          CONTENT_TYPE_ENUM[value.contentType]
        }?id=${value.id}&action=${TWYGS_STUDIO_ACTION.RESUME}`
      );
    }
  };

  const convertAdName = useCallback(
    (row) => {
      const checked = listIdAd.includes(row.id);
      return (
        <Box
          display={"flex"}
          alignItems={"center"}
          width={{ base: "calc(100vw - 128px)", md: "unset" }}
        >
          <Checkbox
            mr="0.75rem"
            isChecked={checked}
            onChange={() => {
              selectDraft(row.id, !checked);
            }}
          />
          <Box h={"fit-content"} w={"fit-content"} position={"relative"}>
            <ImageComponent
              rounded={"0.5rem"}
              minW={"3.5rem"}
              minH={"3.5rem"}
              maxW={"3.5rem"}
              maxH={"3.5rem"}
              src={row?.thumbnail}
              alt="select"
              objectFit={"cover"}
              border={"1px solid #F2F3F7"}
            />
            {row?.contentUrlList?.length > 1 && (
              <CarouselIcon
                alt="multiple image"
                className="absolute top-[0.25rem] right-[0.25rem]"
              />
            )}
          </Box>
          <Box ml={"0.75rem"} w={"full"} maxW={"100%"}>
            <Box>
              <Text
                fontWeight={800}
                title={row?.name}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
                sx={{
                  display: "-webkit-inline-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                }}
                wordBreak={"break-word"}
              >
                {row?.name}
              </Text>
            </Box>

            <Text
              fontSize={"0.875rem"}
              title={row?.description}
              overflow={"hidden"}
              textOverflow={"ellipsis"}
              sx={{
                display: "-webkit-inline-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
              }}
              wordBreak={"break-word"}
            >
              {row?.description}
            </Text>
          </Box>
        </Box>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listIdAd]
  );

  const convertType = useCallback((row) => {
    let typeInfo = {
      icon: ImageIcon,
      alt: TYPES_DRAFT["image"],
    };

    switch (row.contentType) {
      case TYPES_DRAFT["video"]:
        typeInfo = {
          Icon: VideoIcon,
          alt: TYPES_DRAFT["video"],
        };
        break;
      case TYPES_DRAFT["pdf"]:
        typeInfo = {
          Icon: FileIcon,
          alt: TYPES_DRAFT["pdf"],
        };
        break;
      default:
        typeInfo = {
          Icon: ImageIcon,
          alt: TYPES_DRAFT["image"],
        };
        break;
    }

    return (
      <Box
        bgColor={"light-green"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        rounded={"1.25rem"}
        p={"0.625rem"}
      >
        <typeInfo.Icon
          w={"1.25rem"}
          h={"1.25rem"}
          className="w-[1.25rem] h-[1.25rem]"
          alt={typeInfo.alt}
        />
      </Box>
    );
  }, []);

  const convertCategory = useCallback(
    (row) => {
      let defaultColor = {
        color: "dark-brown",
        bgColor: "alert-yellow",
      };
      const index = dataCategory?.data?.data?.findIndex(
        (elm) => elm.name === row?.contentCategory
      );
      if (index > -1) {
        defaultColor = {
          color: LIST_COLOR_CATEGORY[index].color,
          bgColor: LIST_COLOR_CATEGORY[index].bgColor,
        };
      }
      return (
        <Box
          px="8px"
          py="10px"
          w={"fit-content"}
          borderRadius={"8px"}
          bgColor={defaultColor.bgColor}
        >
          <Text
            color={defaultColor.color}
            fontSize={"0.75rem"}
            fontWeight={"800"}
            whiteSpace={"nowrap"}
          >
            {row?.contentCategory}
          </Text>
        </Box>
      );
    },
    [dataCategory]
  );

  const convertAction = useCallback(
    (row) => {
      return (
        <Box display={"flex"} gap={"24px"}>
          <PencilGreenIcon
            src={PencilGreenIcon}
            alt="resume"
            title="resume"
            onClick={handleOnEdit(row)}
            className={`${
              row.videoProcessStatus !== VIDEO_PROCESS_STATUS.PROCESSING
                ? "opacity-100 cursor-pointer"
                : "opacity-50 cursor-default"
            }`}
          />
          <BinRedIcon
            cursor={"pointer"}
            className="cursor-pointer"
            onClick={() => {
              setIdRemove([row?.id]);
              setDeleteMutiple(true);
              setModal("remove-draft");
            }}
            alt="delete"
            title="delete"
          />
        </Box>
      );
    },
    [navigate]
  );

  const handleSort = (sortName) => {
    let objSort = {
      sort: pagination.sort,
      order: pagination.order,
    };
    if (sortName !== objSort.sort) objSort = { sort: sortName, order: "asc" };
    else if (sortName === objSort.sort && objSort.order === "asc")
      objSort = {
        ...objSort,
        order: "desc",
      };
    else if (sortName === objSort.sort && objSort.order === "desc")
      objSort = {
        ...objSort,
        order: "asc",
      };
    setPagination({ ...pagination, ...objSort });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Twygs",
        accessor: (row) => convertAdName(row),
        id: "name",
        isSortField: true,
        widthCustom: "calc(100% - 35.5rem)",
      },
      {
        Header: "Type",
        accessor: (row) => convertType(row),
        id: "contentType",
        widthCustom: "4rem",
        minWidthCustom: "4rem",
      },
      {
        Header: "Category",
        accessor: (row) => convertCategory(row),
        id: "contentCategory",
        widthCustom: "10rem",
        minWidthCustom: "10rem",
      },
      {
        Header: "Date Created",
        accessor: (row) => (
          <Text fontSize={"sm"}>{formatDate(row?.createdAt, true)}</Text>
        ),
        id: "createdAt",
        isSortField: true,
        widthCustom: "10rem",
        minWidthCustom: "10rem",
      },
      {
        Header: "Action",
        accessor: (row) => convertAction(row),
        id: "action",
        widthCustom: "10rem",
        minWidthCustom: "10rem",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [convertAction, convertAdName, convertCategory, convertType, handleSort]
  );

  useEffect(() => {
    if (data) {
      setDataTable(data.data);
    }
  }, [data]);

  return (
    <Box
      w={"full"}
      border={"1px solid"}
      borderColor={"anti-flash-lite"}
      borderRadius={"0.75rem"}
      shadow={"outline"}
    >
      <Box
        bg={"white"}
        p={{ base: "16px", "2xl": "16px 24px" }}
        display={"flex"}
        alignItems={"start"}
        minH={"4.5rem"}
        borderTopRadius={"0.75rem"}
        justifyContent={"space-between"}
        ref={ref}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          width={"full"}
          h={"2.5rem"}
        >
          <Text fontSize={{ base: "md", "2xl": "lg" }} fontWeight={"800"}>
            Draft
          </Text>
          {dataTable?.length > 0 && listIdAd.length > 0 ? (
            <Box
              display={"flex"}
              alignItems={"center"}
              ml={"auto"}
              gap={"0.75rem"}
            >
              <Box
                py={"0.75rem"}
                pl={"0.75rem"}
                pr={"1.25rem"}
                h={"2rem"}
                display={"flex"}
                bgColor={"light-green"}
                rounded={"2.375rem"}
                alignItems={"center"}
                gap={"0.625rem"}
              >
                <InformIcon alt="amount selected" />
                <Text fontSize={"xs"}>{listIdAd.length} selected</Text>
              </Box>
              <BinRedIcon
                onClick={() => setModal("remove-draft")}
                className="cursor-pointer"
                alt="delete"
                title="delete"
              />
            </Box>
          ) : null}
        </Box>
      </Box>
      <Box
        bg={"white"}
        rounded={"0 0 0.75rem 0.75rem"}
        display={"flex"}
        minH={isFetching ? "50rem" : "500px"}
        flexFlow={"column"}
        overflow={{ base: "auto", md: "hidden" }}
        css={{
          "&::-webkit-scrollbar": {
            height: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#e6e7eb",
            borderRadius: "30px",
          },
        }}
      >
        <TableComponent
          isLoading={isFetching}
          columns={columns}
          data={isFetching ? [] : dataTable}
          headerHeight={"46px"}
          fontsizeTable={{ base: "sm", "2xl": "md" }}
          handleSort={handleSort}
          currentSort={{ sort: pagination.sort, order: pagination.order }}
          haveFooter={false}
        />
        {dataTable?.length === 0 && !isFetching ? (
          <Box
            minH={"18.75rem"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <NoDataComponent />
          </Box>
        ) : null}

        {data?.totalPage > 0 ? (
          <Box
            borderBottomRadius={"0.75rem"}
            p={"1.25rem 1.5rem"}
            mt={"auto"}
            borderTop="1px solid"
            borderColor={"anti-flash-lite"}
            background={"light.100"}
          >
            <PaginationComponent
              currentPage={pagination.page}
              totalPages={data?.totalPage}
              onChangePage={handleChangePage}
            />
          </Box>
        ) : null}
      </Box>

      {modal === "remove-draft" ? (
        <ConfirmModal
          isOpen
          Icon={FailLoadImage}
          title={
            listIdAd.length > 1
              ? MODAL_DRAFT["title-mutilple"]
              : MODAL_DRAFT.title
          }
          desc={MODAL_DRAFT.description}
          titleBtn={"Remove"}
          onClick={deleteDraft}
          isLoading={isDeleteTwygPending}
          onClose={() => {
            setDeleteMutiple(false);
            setModal("");
          }}
        />
      ) : null}
    </Box>
  );
};

export default DraftTableComponent;
