import React, { useRef, useState, useEffect } from "react";
import Cookies from "js-cookie";
import { convertTimeCreatedAt } from "../../../constants";
import ThreeDotIcon from "../../../assets/threedot-icon.svg";
import {
  GET_DISMISS,
  GET_MARK_AS_READ,
  GET_MARK_AS_UNREAD,
} from "../../../apis/notification.api";

const NotificationDetailComponent = ({
  elm,
  avatar,
  setListNotification,
  index,
  setPagination,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  const [currentElm, setCurrentElm] = useState(elm);

  const handleOutsideClick = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setCurrentElm(elm);
  }, [elm]);

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const markAsRead = async () => {
    if (!currentElm.markAsRead) {
      const response = await fetch(`${GET_MARK_AS_READ}/${currentElm.id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get(
            `${process.env.REACT_APP_IS_STAGING ? "staging" : ""}token`
          )}`,
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      if (result.success) {
        setCurrentElm((pre) => {
          return { ...pre, markAsRead: true };
        });
        setPagination((pre) => {
          return { ...pre, unreadCount: pre.unreadCount - 1 };
        });
      }
    } else {
      const response = await fetch(`${GET_MARK_AS_UNREAD}/${currentElm.id}`, {
        headers: {
          Authorization: `Bearer ${Cookies.get(
            `${process.env.REACT_APP_IS_STAGING ? "staging" : ""}token`
          )}`,
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      if (result.success) {
        setCurrentElm((pre) => {
          return { ...pre, markAsRead: false };
        });
        setPagination((pre) => {
          return { ...pre, unreadCount: pre.unreadCount + 1 };
        });
      }
    }
    setIsOpen(false);
  };

  const handleRedirect = (e) => {
    e.stopPropagation();
    if (!currentElm.markAsRead) {
      markAsRead();
      window.location.replace(currentElm.redirectUrl);
    } else {
      window.location.replace(currentElm.redirectUrl);
    }
  };

  const handleDismiss = async () => {
    const response = await fetch(`${GET_DISMISS}/${currentElm.id}`, {
      headers: {
        Authorization: `Bearer ${Cookies.get(
          `${process.env.REACT_APP_IS_STAGING ? "staging" : ""}token`
        )}`,
        "Content-Type": "application/json",
      },
    });
    const result = await response.json();
    if (result.success) {
      setListNotification((prev) => {
        const newList = [...prev];
        newList.splice(index, 1);
        return newList;
      });
      if (!currentElm.markAsRead) {
        setPagination((prev) => ({
          ...prev,
          unreadCount: prev.unreadCount - 1,
        }));
      }
      setIsOpen(false);
    }
  };

  return (
    <div
      className="hover:bg-[#A6D38D] rounded-md mb-4 p-2 relative cursor-pointer transition-all"
      onClick={(e) => handleRedirect(e)}
    >
      <div className="flex w-full gap-3 items-start rounded-md p-0 z-10">
        <div className="flex items-center gap-3">
          <div
            className={`w-2 h-2 rounded-full bg-pink-500 ${
              currentElm.markAsRead ? "opacity-0" : "opacity-100"
            }`}
          />
          <img
            className="min-w-8 min-h-8 max-w-8 max-h-8 rounded-full"
            src={avatar}
            alt="avatar"
          />
        </div>
        <div>
          <p className="font-normal text-sm mb-2">{currentElm.description}</p>
          <div className="flex gap-2 items-center">
            <div className="py-1 px-2.5 rounded-full bg-[#DCEED3]">
              <p className="text-xs font-[700] text-[#084F4B]">
                {currentElm.platform}
              </p>
            </div>
            <span className="text-xs text-gray-500">
              {convertTimeCreatedAt(currentElm.createdAt)}
            </span>
          </div>
        </div>
        <div className="ml-auto" ref={ref}>
          <img
            src={ThreeDotIcon}
            alt="more option"
            className="min-w-5 min-h-5 max-w-5 max-h-5 cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen((prev) => !prev);
            }}
          />
          {isOpen && (
            <div className="absolute right-0 top-10 w-40 p-2 rounded-lg shadow-md border border-gray-200 z-50 bg-white flex flex-col gap-2">
              <div
                className="py-2 px-3 hover:bg-[#A6D38D] rounded-md transition-all cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  markAsRead();
                }}
              >
                <span className="text-sm font-normal">
                  {currentElm.markAsRead ? "Mark as Unread" : "Mark as Read"}
                </span>
              </div>
              <div
                className="py-2 px-3 hover:bg-[#A6D38D] rounded-md transition-all cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDismiss();
                }}
              >
                <span className="text-sm font-normal">Dismiss</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationDetailComponent;
