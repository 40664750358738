import React, { useRef, useState } from "react";
import { Box, Center, Spinner } from "@chakra-ui/react";
import { Document, Page, pdfjs } from "react-pdf";
import { statsStatus } from "../../CreateTwygsForm/components/twygs.constant";
import PaginationComponent from "./pagination";

pdfjs.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js";

const options = {
  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
};

const PDFPreviewComponent = ({ data }) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [progressUpload, setProgressUpload] = useState(statsStatus["default"]);

  const ref = useRef();

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setProgressUpload(statsStatus["loaded"]);
  };

  const handleChangePage = (page) => {
    setProgressUpload(statsStatus["loading"]);
    setPageNumber(page);
    if (ref.current) ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box
      width="100%"
      height="100%"
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-around"}
      rowGap={"24px"}
    >
      <Box
        position={"relative"}
        overflowY={"scroll"}
        sx={{
          "&::-webkit-scrollbar": {
            width: "6px",
            height: "6px",
          },

          "&::-webkit-scrollbar-thumb": {
            background: "#c6ceda",
            borderRadius: "20px",
          },
        }}
        className="pdf-document"
        ref={ref}
      >
        <Document
          className={"pdf-container"}
          file={data}
          onLoadSuccess={onDocumentLoadSuccess}
          options={options}
          loading={
            <Center h={"40rem"}>
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="dark-green"
                size={{ base: "lg", "2xl": "xl" }}
              />
            </Center>
          }
        >
          <Page
            pageNumber={pageNumber}
            renderMode="canvas"
            onLoadSuccess={() => setProgressUpload(statsStatus["loaded"])}
            className={"page-canvas"}
            loading={
              <Center h={ref.current ? ref.current.clientHeight : "auto"}>
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="dark-green"
                  size={{ base: "lg", "2xl": "xl" }}
                />
              </Center>
            }
          />
        </Document>
      </Box>
      {numPages > 0 && progressUpload !== statsStatus["loading"] ? (
        <Box mt={"1.5rem"}>
          <PaginationComponent
            currentPage={pageNumber}
            totalPages={numPages}
            onChangePage={handleChangePage}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default PDFPreviewComponent;
