import { Fragment, useEffect, useState } from "react";

import { convertCountLike } from "../../constants";
import MailIcon from "../../assets/mail-icon.svg";
import WebsiteIcon from "../../assets/website-icon.svg";
import ChevronDownIcon from "../../assets/chevron-down-icon.svg";
import ShowMoreIcon from "../../assets/show-more-icon.svg";
import AvaDefaultIcon from "../../assets/ava-icon.svg";
import EditIcon from "../../assets/edit-icon.svg";
import Cookies from "js-cookie";

import {
  fetchListTwygsByCreatorId,
  fetchTwygHubByCreatorId,
} from "../../apis/explore.api";
import GalleryLayout from "../../components/ExploreComponent/GalleryLayout";
import { Loading } from "../../components/Loading";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
const staging = process.env.REACT_APP_IS_STAGING;

const CreatorDetails = () => {
  const { username } = useParams();

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    limit: 12,
    page: 1,
    filter: 0,
    totalPage: 0,
  });
  const [listTwyg, setListTwyg] = useState([]);
  const [twygHubInfo, setTwygHubInfo] = useState({});
  const [isShowMore, setIsShowMore] = useState(false);
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const token = Cookies.get(`${staging ? "staging" : ""}token`);

  useEffect(() => {
    const fetchInfoCreator = async () => {
      try {
        const result = await fetchTwygHubByCreatorId(
          decodeURIComponent(username || "")
        );
        setTwygHubInfo(result);
      } catch (error) {}
    };
    fetchInfoCreator();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const gridTemplates =
        window.innerWidth > 2227
          ? 6
          : window.innerWidth > 1900
          ? 5
          : window.innerWidth > 1280
          ? 4
          : window.innerWidth > 786
          ? 3
          : 2;
      try {
        const result = await fetchListTwygsByCreatorId(
          decodeURIComponent(username || ""),
          {
            ...pagination,
            limit: gridTemplates * 3,
          }
        );
        if (pagination.page === 1) setListTwyg(result.twygs);
        else setListTwyg([...listTwyg, ...result.twygs]);
        if (pagination.totalPage !== result.totalPage)
          setPagination({ ...pagination, totalPage: result.totalPage });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    if (!loading) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.filter, pagination.page, username]);

  return (
    <Fragment>
      <Helmet>
        <title>Creator Details</title>
      </Helmet>
      <div className="bg-gray-100">
        <div className="w-full mx-auto bg-white !rounded-b-[50px] md:!rounded-b-[100px] 2xl:!rounded-b-[160px] shadow-[0px_8px_16px_0px_#8080801A]">
          <div className="m-auto w-full px-5">
            <div className="-mt-1 pt-[20px] pb-[22px] xl:py-[26px] flex justify-between w-full gap-[16px] md:gap-[32px] flex-col md:flex-row items-start">
              <div className="flex gap-[16px] md:gap-[32px] w-full md:w-[65%] ml-10">
                {twygHubInfo?.creator?.imgUrl ? (
                  <img
                    className="w-[50px] h-[50px] lg:w-[100px] lg:h-[100px] xl:w-[120px] xl:h-[120px] 2xl:w-[150px] 2xl:h-[150px] !rounded-full bg-gray-100"
                    src={twygHubInfo?.creator?.imgUrl}
                    alt="avatar"
                  />
                ) : (
                  <img
                    className="w-[50px] h-[50px] lg:w-[100px] lg:h-[100px] xl:w-[150px] xl:h-[150px] !rounded-full bg-gray-100"
                    src={AvaDefaultIcon}
                    alt="avatar"
                  />
                )}

                <div className="max-w-[70%]">
                  <div>
                    <p className="text-[18px] 2xl:text-[32px] font-extrabold font-montserrat jet-black flex items-center">
                      {twygHubInfo?.creator?.username}
                      {token && twygHubInfo?.creator?.id === user?.id && (
                        <a
                          href={`${process.env.REACT_APP_CREATOR_URL}studio/my-twygs`}
                        >
                          <img
                            alt="icon edit"
                            src={EditIcon}
                            className="ml-6 cursor-pointer"
                          />
                        </a>
                      )}
                    </p>
                  </div>
                  {twygHubInfo?.creatorHub?.description && (
                    <p
                      title={twygHubInfo?.creatorHub?.description}
                      className="mt-[16px] break-words text-xs md:text-sm"
                    >
                      {twygHubInfo?.creatorHub?.description.length > 200 &&
                      !isShowMore
                        ? `${twygHubInfo?.creatorHub?.description.substring(
                            0,
                            200
                          )}...`
                        : twygHubInfo?.creatorHub?.description}
                    </p>
                  )}
                  {twygHubInfo?.creatorHub?.description.length > 200 && (
                    <div
                      onClick={() => {
                        setIsShowMore(!isShowMore);
                      }}
                      className="flex w-[131px] gap-[8px] items-center pt-[16px] cursor-pointer hover:opacity-75"
                    >
                      <p className="font-black custom-color text-xs md:text-sm">
                        {!isShowMore ? "Show more" : "Show less"}
                      </p>
                      <img
                        src={ShowMoreIcon}
                        className={`${
                          !isShowMore ? "rotate-0" : "-rotate-180"
                        } transition`}
                        alt="show more"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="md:ml-auto w-full md:w-[35%]">
                {twygHubInfo?.creatorHub?.website && (
                  <div className="flex gap-[16px] items-center">
                    <img
                      className="w-[24px] 2xl:w-[32px]"
                      src={WebsiteIcon}
                      alt="website"
                    />
                    <a
                      href={`${
                        twygHubInfo?.creatorHub?.website?.includes("https")
                          ? twygHubInfo?.creatorHub?.website
                          : "https://" + twygHubInfo?.creatorHub?.website
                      }`}
                      target="_blank"
                      className="creator-name"
                      rel="noreferrer"
                    >
                      <p
                        title={twygHubInfo?.creatorHub?.website}
                        className="truncate text-sm 2xl:text-base"
                      >
                        {twygHubInfo?.creatorHub?.website}
                      </p>
                    </a>
                  </div>
                )}
                {twygHubInfo?.creatorHub?.email && (
                  <div className="flex mt-[12px] gap-[16px] items-center">
                    <img
                      className="w-[24px] 2xl:w-[32px]"
                      src={MailIcon}
                      alt="mail"
                    />
                    <a
                      href={`mailto:${twygHubInfo?.creatorHub?.email}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="creator-name"
                    >
                      <p
                        title={twygHubInfo?.creatorHub?.email}
                        className="truncate text-sm 2xl:text-base"
                      >
                        {twygHubInfo?.creatorHub?.email}
                      </p>
                    </a>
                  </div>
                )}
                <div className="mt-[22px] block md:flex gap-[24px] ">
                  <div>
                    <p className="text-sm 2xl:text-base">Twygs created</p>
                    <p className="font-extrabold text-sm 2xl:text-lg mt-[8px]">
                      {twygHubInfo?.numTwygsCreated ?? 0}
                    </p>
                  </div>
                  <div>
                    <p className="text-sm 2xl:text-base">Likes received</p>
                    <p
                      title={twygHubInfo?.totalLikesReceived}
                      className="font-extrabold text-sm 2xl:text-lg mt-[8px]"
                    >
                      {convertCountLike(twygHubInfo?.totalLikesReceived)}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="m-auto px-[20px] w-full py-5 2xl:py-10">
            <div className="flex flex-col xl:flex-row xl:items-center xl:justify-center mb-5 2xl:mb-10">
              <div className="flex items-center flex-wrap w-full gap-[16px]">
                <div
                  className={`p-3 2xl:p-4 transition cursor-pointer !rounded-full whitespace-nowrap ${
                    pagination.filter === 0 ? "custom-bg" : "bg-white"
                  }`}
                  onClick={() =>
                    setPagination({ ...pagination, filter: 0, page: 1 })
                  }
                >
                  <p className="text-sm 2xl:text-base font-extrabold font-montserrat custom-color">
                    All Twygs
                  </p>
                </div>
                {twygHubInfo?.creatorCategories?.map((elm, index) => {
                  return (
                    <div
                      key={index}
                      className={`p-3 2xl:p-4 transition cursor-pointer !rounded-full whitespace-nowrap ${
                        pagination.filter === elm.contentCategoryId
                          ? "custom-bg"
                          : "bg-white"
                      }`}
                      onClick={() =>
                        setPagination({
                          ...pagination,
                          filter: elm.contentCategoryId,
                          page: 1,
                        })
                      }
                    >
                      <p className="text-sm 2xl:text-base font-extrabold font-montserrat custom-color">
                        {elm.contentCategory.name}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div>
              <GalleryLayout
                list={listTwyg}
                loading={loading && pagination.page === 1}
                tracking
              />

              {loading && <Loading></Loading>}
              {pagination.page < pagination.totalPage && !loading && (
                <div className="flex">
                  <div
                    className="flex items-center mx-auto fit-content cursor-pointer hover:opacity-75"
                    onClick={() => {
                      if (!loading)
                        setPagination({
                          ...pagination,
                          page: pagination.page + 1,
                        });
                    }}
                  >
                    <p className="text-lg font-black mr-2">Show more</p>
                    <img src={ChevronDownIcon} alt="Chevron Down" />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CreatorDetails;
