import React from "react";

function SuccessfullyModal({
  isOpen,
  onClick,
  srcImg,
  title,
  desc,
  titleBtn,
  bgColorOverlay,
  isLoading,
}) {
  if (!isOpen) return null;

  return (
    <div
      className={`fixed inset-0 z-50 flex items-center justify-center ${
        bgColorOverlay ? bgColorOverlay : "bg-black bg-opacity-50"
      }`}
    >
      <div className="relative bg-white rounded-[1.5rem] max-w-auto md:max-w-[30rem] w-full mx-4 p-5 md:p-12 overflow-y-auto max-h-[85vh]">
        <div className="text-center ">
          <img src={srcImg} alt="" className="mx-auto" />
        </div>
        <div className="text-center mb-4">
          <h2 className="text-[32px] font-black text-dark-green mb-4">
            {title}
          </h2>
          {desc && typeof desc === "string" ? (
            <p className="text-lg font-normal">{desc}</p>
          ) : (
            <div>{desc}</div>
          )}
        </div>
        <div className="w-full mt-4">
          <button
            type="button"
            onClick={onClick}
            className={`w-full h-14 !text-white bg-[#084F4B] text-lg font-black rounded-lg ${
              isLoading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={isLoading}
          >
            {titleBtn}
          </button>
        </div>
      </div>
    </div>
  );
}

export default SuccessfullyModal;
