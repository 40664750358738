import React, { useEffect, useRef } from "react";

const AutoResizeTextArea = ({
  value,
  onChange,
  placeholder,
  autoFocus,
  id,
  handleKeyPress,
}) => {
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto"; // Reset height to auto
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
      if (autoFocus) {
        textAreaRef.current.focus();
      }
    }
  }, [value, autoFocus]);

  const handleInputChange = (e) => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto"; // Reset height to auto
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
    if (onChange) {
      onChange(e);
    }
  };
  return (
    <textarea
      id={id}
      value={value}
      ref={textAreaRef}
      placeholder={placeholder}
      onChange={handleInputChange}
      style={{ borderBottom: "1px solid" }}
      onKeyDown={handleKeyPress}
      className="outline-none resize-none border-none overflow-hidden pb-[4px] w-full text-sm 2xl:text-base px-2 pt-2 rounded-sm placeholder:text-sm 2xl:placeholder:text-base"
    />
  );
};

export default AutoResizeTextArea;
