import { useState, useEffect } from "react";
import { Center, Spinner } from "@chakra-ui/react";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSearchParams } from "react-router-dom";
import { schema } from "../../../CreateTwygsForm/components/video-twygs/schema";
import { TWYGS_VIDEO_ACTION } from "../../../CreateTwygsForm/components/twygs.constant";
import ThumbnailComponent from "../../../CreateTwygsForm/components/video-twygs/thumbnail.component";
import TrimComponent from "../../../CreateTwygsForm/components/video-twygs/trim.component";
import FormVideoComponent from "../../../CreateTwygsForm/components/video-twygs/form-video.conponent";
import { useQuery } from "@tanstack/react-query";
import { fetchTwygsDetail } from "../../../../apis/studio.api";

const VideoTwygsPage = () => {
  const [searchParams] = useSearchParams();

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const [assetData, setAssetData] = useState(null);

  const [action, setAction] = useState("");

  const idTwyg = searchParams.get("id");
  const { data, error, isLoading, isError } = useQuery({
    queryKey: ["twygsDetail", idTwyg],
    queryFn: () => fetchTwygsDetail(idTwyg),
    enabled: !!idTwyg,
  });

  useEffect(() => {
    if (data) {
      const obj = {
        asset: {
          fileName: data.contentKeys[0],
          contentKeys: data.contentKeys[0],
          assetType: data.videoAssetType,
          src: data.contentUrls[0],
          duration: 0,
          isUpdated: false,
        },
        thumbnail: {
          fileName: data.thumbnail,
          thumbnail: data.thumbnail,
          assetType: data.videoAssetType,
          src: data.thumbnailUrl,
          rootSrc: data.thumbnailUrl,
          isUpdated: false,
        },
      };

      setAssetData(obj);

      methods.reset({
        twygsName: data.twygsName,
        description: data.description,
        categoryId: data.categoryId,
        allowOtherRelatedContent: data.allowOtherRelatedContent,
      });
    }
  }, [data]);

  // render action component
  const renderAction = () => {
    switch (action) {
      case TWYGS_VIDEO_ACTION.THUMBNAIL:
        return (
          <ThumbnailComponent
            assetData={assetData}
            setAssetData={setAssetData}
            setAction={setAction}
          />
        );
      case TWYGS_VIDEO_ACTION.TRIM:
        return (
          <TrimComponent
            assetData={assetData}
            setAssetData={setAssetData}
            setAction={setAction}
          />
        );
      default:
        return (
          <FormVideoComponent
            assetData={assetData}
            setAssetData={setAssetData}
            setAction={setAction}
          />
        );
    }
  };

  if (isLoading)
    return (
      <Center h={"25rem"}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="dark-green"
          size="xl"
        />
      </Center>
    );

  return <FormProvider {...methods}>{renderAction()}</FormProvider>;
};

export default VideoTwygsPage;
