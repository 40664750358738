import { Fragment, useEffect, useState } from "react";

import ExploreTwygs from "../../components/ExploreComponent/ExploreTwygs";
// import ExploreCategory from "../../components/ExploreComponent";
import useSearchParams from "../../hooks/router/useSearchParams";
import TwygsBySearchComponent from "../../components/ExploreComponent/TwygsBySearch";
import { Helmet } from "react-helmet";
import { CATEGORY_LIST, CONTENT_TYPES } from "../../constants";

const ExplorPage = () => {
  const { search, category, filter, contentType } =
    useSearchParams().searchParams;

  const [pagination, setPagination] = useState({
    limit: 12,
    page: 1,
    search: "",
    filter: filter === "Trending" ? "trending" : "top",
    totalPage: 0,
    categoryId: category
      ? CATEGORY_LIST.find((elm) => elm.name === decodeURIComponent(category))
          ?.id
      : -1,
    contentTypeId: contentType
      ? CONTENT_TYPES.find(
          (elm) => elm.name === decodeURIComponent(contentType)
        )?.id
      : -1,
  });

  useEffect(() => {
    const newId = category
      ? CATEGORY_LIST.find((elm) => elm.name === decodeURIComponent(category))
          ?.id
      : -1;
    const newContentTypeId = contentType
      ? CONTENT_TYPES.find(
          (elm) => elm.name === decodeURIComponent(contentType)
        )?.id
      : -1;
    if (
      newId !== pagination.categoryId ||
      pagination.filter !== filter ||
      newContentTypeId !== pagination.contentTypeId
    ) {
      setPagination({
        ...pagination,
        page: 1,
        categoryId: newId,
        contentTypeId: newContentTypeId,
        filter: filter === "Trending" ? "trending" : "top",
      });
    }
  }, [category, filter, contentType]);

  return (
    <div>
      <Helmet>
        <title>Explore</title>
      </Helmet>
      {!search ? (
        <Fragment>
          <ExploreTwygs pagination={pagination} setPagination={setPagination} />
          {/* <ExploreCategory setPagination={setPagination} /> */}
        </Fragment>
      ) : (
        <TwygsBySearchComponent />
      )}
    </div>
  );
};

export default ExplorPage;
