export const ContainerTheme = {
  baseStyle: {
    maxWidth: "72.5rem",
    paddingX: "2.5rem"
  },

  variants: {},

  defaultProps: {}
};
