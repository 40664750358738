import { Link } from "react-router-dom";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";

import useSearchParams from "../../../hooks/router/useSearchParams";
import { useClickOutside } from "../../../hooks/useOutsideClick";
import { CATEGORY_LIST } from "../../../constants";
import CustomCheckbox from "../../CustomCheckbox";
import { fetchListTwygBySearch } from "../../../apis/explore.api";
import GalleryLayout from "../GalleryLayout";

import ArrowRightIcon from "../../../assets/arrow-right-icon.svg";
import SortIcon from "../../../assets/sort-icon.svg";
import MenuFilterIcon from "../../../assets/menu-filter-icon.svg";
import ChevronDownIcon from "../../../assets/chevron-down-icon.svg";
import { formatTextWithHashtags } from "../../../utils/helper.util";

const TwygsBySearchComponent = () => {
  const { searchParams, setSearchParams } = useSearchParams();
  const { search, tab } = searchParams;
  const [tabType, setTabType] = useState(tab || "twygs");
  const [focusSort, setFocusSort] = useState(false);
  const [focusFilter, setFocusFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [pagination, setPagination] = useState({
    limit: 12,
    page: 1,
    totalPage: {
      twygs: 0,
      creators: 0,
    },
    sort: tab === "creator" ? "most like" : "newest",
    filter: [],
    sortBy: tab === "creator" ? "likeNumber" : "createdAt",
  });
  const [list, setList] = useState({
    twygs: [],
    creators: [],
  });

  const refFocusSort = useRef();
  useClickOutside(refFocusSort, () =>
    focusSort ? setFocusSort((pre) => !pre) : null
  );

  const refFocusFilter = useRef();
  useClickOutside(refFocusFilter, () =>
    focusFilter ? setFocusFilter((pre) => !pre) : null
  );

  const handleFilter = (value) => {
    setPagination({ ...pagination, filter: value, page: 1 });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceFn = useCallback(_.debounce(handleFilter, 600), []);

  const changeFilter = (value) => {
    let newList = [...filterList];
    const indexValue = newList.findIndex((elm) => elm === value);
    if (indexValue > -1) newList.splice(indexValue, 1);
    else newList = [...newList, value];
    setFilterList(newList);
    debounceFn(newList);
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const gridTemplates =
        window.innerWidth > 2227
          ? 6
          : window.innerWidth > 1900
          ? 5
          : window.innerWidth > 1280
          ? 4
          : window.innerWidth > 786
          ? 3
          : 2;
      try {
        const result = await fetchListTwygBySearch({
          page: pagination.page,
          limit: gridTemplates * 3,
          search: search,
          categoryId:
            pagination.filter.length === 0
              ? CATEGORY_LIST.map((elm) => elm.id)
              : pagination.filter,
          sortBy: pagination.sortBy,
          sortOrder:
            tabType === "twygs"
              ? pagination.sort === "newest"
                ? "desc"
                : "asc"
              : pagination.sortBy === "likeNumber"
              ? "asc"
              : pagination.sort === "A-Z"
              ? "asc"
              : "desc",
          entity: tabType,
        });
        setPagination({
          ...pagination,
          totalPage: {
            twygs: result.totalTwygsPage,
            creators: result.totalCreatorsPage,
          },
        });

        setList({
          twygs:
            (tabType || "twygs") === "twygs" && pagination.page > 1
              ? [...list.twygs, ...result.twygs]
              : result.twygs,
          creators:
            tabType === "creator" && pagination.page > 1
              ? [...list.creators, ...result.creators]
              : result.creators,
        });
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pagination.page,
    search,
    pagination.filter,
    pagination.sort,
    pagination.entity,
  ]);

  return (
    <div className="bg-gray-100 flex">
      <div className="m-auto w-full px-5 py-5 md:py-5">
        <ul className="flex space-x-3 mb-2 md:mb-4">
          <li>
            <Link href="/" className="text-sm 2xl:text-base">
              Explore
            </Link>
          </li>
          <li className="flex items-center">
            <img src={ArrowRightIcon} alt="arrow" />
          </li>
          <li className="ltr:before:mr-2 rtl:before:ml-2">
            <span className="text-sm 2xl:text-base">Search Result</span>
          </li>
        </ul>
        <p className="text-xl 2xl:text-[26px] font-extrabold mb-2 md:mb-6">
          {formatTextWithHashtags(search) ?? ""}
        </p>
        <div className="flex flex-col md:flex-row items-start md:items-center md:justify-between">
          <div className="flex items-center mb-2 md:mb-0">
            <div
              className={`w-fit-content mr-2 md:mr-4 px-3 2xl:px-6 flex items-center justify-center transition cursor-pointer !rounded-full !h-10 2xl:!h-[48px] ${
                tabType === "twygs" ? "custom-bg" : "bg-white"
              }`}
              onClick={() => {
                setTabType("twygs");
                setSearchParams({ tab: "twygs" });
                setPagination({
                  ...pagination,
                  sortBy: "createdAt",
                  sort: "newest",
                  page: 1,
                });
              }}
            >
              <p className="text-sm 2xl:text-base font-extrabold custom-color">
                Twygs
              </p>
            </div>
            <div
              className={`w-fit-content mr-2 md:mr-4 px-3 2xl:px-6 flex items-center justify-center transition cursor-pointer !rounded-full !h-10 2xl:!h-[48px] ${
                tabType === "creator" ? "custom-bg" : "bg-white"
              }`}
              onClick={() => {
                setTabType("creator");
                setSearchParams({ tab: "creator" });
                setPagination({
                  ...pagination,
                  sortBy: "likeNumber",
                  sort: "most likes",
                  page: 1,
                  filter: [],
                  sortOrder: "desc",
                });
              }}
            >
              <p className="text-sm 2xl:text-base font-extrabold custom-color">
                Creator
              </p>
            </div>
          </div>
          {tab === "creator" ? (
            <div className="relative" ref={refFocusSort}>
              <div
                className="flex items-center h-10 2xl:!h-12 !rounded-[40px] border-2 border-[#084F4B] p-4 cursor-pointer"
                onClick={() => setFocusSort((pre) => !pre)}
              >
                <img src={SortIcon} alt="sort icon" />
                <div className="text-sm 2xl:text-base font-extrabold ml-2 mr-3">
                  Sort by {pagination.sort}
                </div>
                <img src={ChevronDownIcon} alt="chevron down icon" />
              </div>
              {focusSort && (
                <div className="bg-white p-2 flex flex-col !rounded-[12px] gap-1 shadow absolute w-full left-0 top-[52px] z-10">
                  <div
                    className="p-2 pl-[10px] !rounded-[8px] text-sm text-[#101828] font-extrabold cursor-pointer hover:bg-[#E6E7EB]"
                    onClick={() => {
                      setFocusSort(false);
                      setPagination({
                        ...pagination,
                        page: 1,
                        sort: "most likes",
                        sortBy: "likeNumber",
                        sortOrder: "desc",
                      });
                    }}
                  >
                    Most likes
                  </div>
                  <div
                    onClick={() => {
                      setFocusSort(false);
                      setPagination({
                        ...pagination,
                        page: 1,
                        sort: "A-Z",
                        sortBy: "name",
                      });
                    }}
                    className="p-2 pl-[10px] !rounded-[8px] text-sm text-[#101828] font-extrabold  cursor-pointer hover:bg-[#E6E7EB]"
                  >
                    A-Z
                  </div>
                  <div
                    onClick={() => {
                      setFocusSort(false);
                      setPagination({
                        ...pagination,
                        page: 1,
                        sort: "Z-A",
                        sortBy: "name",
                      });
                    }}
                    className="p-2 pl-[10px] !rounded-[8px] text-sm text-[#101828] font-extrabold  cursor-pointer hover:bg-[#E6E7EB]"
                  >
                    Z-A
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="flex items-center gap-2 md:gap-3">
              <div className="relative w-1/2 md:w-[200px]" ref={refFocusFilter}>
                <div
                  className="flex items-center h-10 2xl:!h-12 !rounded-[40px] border-2 border-[#084F4B] p-4 cursor-pointer w-full"
                  onClick={() => setFocusFilter((pre) => !pre)}
                >
                  <img src={MenuFilterIcon} alt="sort icon" />
                  <div className="text-sm 2xl:text-base font-extrabold mx-2">
                    Category
                  </div>
                  {filterList.length > 0 ? (
                    <div
                      id="btn"
                      className="mr-3 w-5 !h-5 bg-custom !rounded-full flex items-center justify-center !text-[#A6D38D] text-[10px] font-extrabold"
                    >
                      {filterList.length}
                    </div>
                  ) : (
                    ""
                  )}
                  <img
                    src={ChevronDownIcon}
                    alt="chevron down icon"
                    className="ml-auto"
                  />
                </div>
                {focusFilter && (
                  <div className="pr-1 bg-white !rounded-[12px] shadow absolute w-full left-0 top-[52px] z-10">
                    <div className="scroll-custom  p-2 pr-[2px] flex flex-col  gap-1 max-h-[336px] overflow-y-auto">
                      {CATEGORY_LIST.map((elm) => {
                        const checked =
                          filterList.findIndex((value) => value === elm.id) >
                          -1;
                        return (
                          <div
                            key={elm.id}
                            className="p-2 pl-[10px] !rounded-[8px] text-sm text-[#101828] font-extrabold cursor-pointer hover:bg-[#E6E7EB]"
                          >
                            <CustomCheckbox
                              checked={checked}
                              onChange={() => changeFilter(elm.id)}
                              label={elm.name}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
              <div className="relative w-7/12 md:w-[200px]" ref={refFocusSort}>
                <div
                  className="flex items-center h-10 2xl:!h-12 !rounded-[40px] border-2 border-[#084F4B] py-4 px-2 md:px-4 cursor-pointer"
                  onClick={() => setFocusSort((pre) => !pre)}
                >
                  <img className="" src={SortIcon} alt="sort icon" />
                  <div className="text-sm 2xl:text-base whitespace-nowrap font-extrabold ml-2 mr-3">
                    Sort by {pagination.sort}
                  </div>
                  <img src={ChevronDownIcon} alt="chevron down icon" />
                </div>
                {focusSort && (
                  <div className="bg-white p-2 flex flex-col !rounded-[12px] gap-1 shadow absolute w-full left-0 top-[52px] z-10">
                    <div
                      className="p-2 pl-[10px] !rounded-[8px] text-sm text-[#101828] font-extrabold cursor-pointer hover:bg-[#E6E7EB]"
                      onClick={() => {
                        setFocusSort(false);
                        setPagination({
                          ...pagination,
                          page: 1,
                          sort: "newest",
                        });
                      }}
                    >
                      Newest
                    </div>
                    <div
                      onClick={() => {
                        setFocusSort(false);
                        setPagination({
                          ...pagination,
                          page: 1,
                          sort: "oldest",
                        });
                      }}
                      className="p-2 pl-[10px] !rounded-[8px] text-sm text-[#101828] font-extrabold  cursor-pointer hover:bg-[#E6E7EB]"
                    >
                      Oldest
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="mt-[24px] 2xl:mt-[40px] min-h-[100vh]">
          <GalleryLayout
            list={tabType === "twygs" ? list.twygs : list.creators}
            anotherLayout={tabType !== "twygs"}
            loading={loading && pagination.page === 1}
          />
          {loading && (
            <div className="flex min-h-[300px] items-center justify-center">
              <div role="status" className="my-2 mx-auto">
                <svg
                  aria-hidden="true"
                  className="inline w-10 !h-10 text-gray-200 animate-spin dark:text-gray-600 fill-gray-300"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {pagination.page <
            (tabType === "twygs"
              ? pagination.totalPage.twygs
              : pagination.totalPage.creators) &&
            !loading && (
              <div className="flex">
                <div
                  className="flex items-center mx-auto fit-content cursor-pointer hover:opacity-75"
                  onClick={() => {
                    if (!loading)
                      setPagination({
                        ...pagination,
                        page: pagination.page + 1,
                      });
                  }}
                >
                  <p className="text-lg font-black mr-2">Show more</p>
                  <img src={ChevronDownIcon} alt="Chevron Down" />
                </div>
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default TwygsBySearchComponent;
