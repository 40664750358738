export const CheckboxTheme = {
  baseStyle: {
    control: {
      bg: "#FFFFFF",
      borderRadius: "6px",
      border: "1px solid",
      borderColor: "#cccccc",
      _hover: {
        opacity: 0.8
      },
      _disabled: {
        border: "none",
        bg: "#F2F4F7",
        opacity: "0.8"
      },
      _checked: {
        bg: "#dceed3",
        border: "1px solid #084F4B",
        _focus: {
          border: "1px solid #084F4B"
        }
      }
    },

    icon: {
      color: "#084F4B"
    }
  },

  sizes: {
    sm: {
      control: {
        w: "16px",
        h: "16px"
      },
      label: {
        fontSize: "14px"
      }
    },

    md: {
      control: {
        w: "20px",
        h: "20px"
      },
      label: {
        fontSize: "16px"
      }
    }
  },
  variants: {
    circle: {
      control: {
        bg: "#FFFFFF",
        borderRadius: "10px",
        border: "1px solid #D0D5DD",
        _hover: {
          borderColor: "#6C59FF",
          bg: "#F4EBFF"
        },
        _focus: {
          borderColor: "#D6BBFB",
          boxShadow: "0px 0px 0px 4px #F4EBFF"
        },
        _disabled: {
          bg: "#F2F4F7",
          opacity: "0.5"
        },
        _checked: {
          bg: "#6C59FF",
          borderColor: "#6C59FF",
          colorScheme: "white",
          _hover: {
            bg: "#6C59FF",
            borderColor: "#6C59FF",
            colorScheme: "white"
          }
        }
      },
      icon: {
        color: "white"
      }
    }
  },

  defaultProps: {
    size: "md",
    colorScheme: "#084F4B"
  }
};
