import React from "react";

function SuccessfullyModal({
  onClick,
  isOpen,
  srcImg,
  title,
  desc,
  titleBtn,
  bgColorOverlay,
}) {
  return (
    <>
      {isOpen && (
        <div
          className={`fixed inset-0 flex items-center justify-center z-50 bg-[${bgColorOverlay}]`}
        >
          <div className="bg-white rounded-[16px] p-8 max-w-md w-full">
            {srcImg && <img src={srcImg} alt="" className="mx-auto mb-4" />}
            {title && (
              <h2 className="text-3xl font-black text-center !text-[#084F4B]">
                {title}
              </h2>
            )}
            {desc && (
              <p className="text-lg text-center mb-4">
                {typeof desc === "string" ? desc : null}
              </p>
            )}
            {desc && typeof desc !== "string" && desc}
            <button
              onClick={onClick}
              className="w-full bg-[#084F4B] mt-10 !text-white h-[56px] py-2 font-black text-[20px] rounded-md"
            >
              {titleBtn}
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default SuccessfullyModal;
