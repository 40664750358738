import { useEffect, useRef, useState } from "react";

import PlusIcon from "../../../../assets/plus-icon.svg";
import AvatarIcon from "../../../../assets/ava-icon.svg";
import LikeBlurIcon from "../../../../assets/like-blur-icon.svg";
import LikeSolidIcon from "../../../../assets/like-solid-icon.svg";
import ThreeDotsIcon from "../../../../assets/three-dot-icon.svg";
import TrashIcon from "../../../../assets/trash-icon.svg";
import ReplyIcon from "../../../../assets/reply-icon.svg";
import ArrowDown from "../../../../assets/arrow-down-icon.svg";
import { convertCountLike, convertTimeCreatedAt } from "../../../../constants";
import AutoResizeTextArea from "../../../TextArea";
import {
  deleteComment,
  fetchListReply,
  postLikeComment,
  postReply,
} from "../../../../apis/twygs-detail";
import ReplyDetailComponent from "../ReplyComponent";
import { useClickOutside } from "../../../../hooks/useOutsideClick";
import { Loading } from "../../../Loading";
import useTruncatedElement from "../../../../hooks/useTruncatedElement ";
import Cookies from "js-cookie";
const staging = process.env.REACT_APP_IS_STAGING;

const CommentDetailComponent = (props) => {
  const {
    item,
    twygsId,
    contentCategory,
    userInfo,
    listComment,
    setListComment,
    twygCreatorId,
  } = props;

  const [selectedComment, setSelectedComment] = useState();
  const [openPopover, setOpenPopover] = useState(false);
  const [openReplySection, setOpenReplySection] = useState(false);
  const [replyBox, setReplyBox] = useState("");
  const [listReply, setListReply] = useState([]);
  const [isOpenReplyList, setIsOpenReplyList] = useState(false);
  const [newReply, setNewReply] = useState([]);
  const [hasShowMore, setHasShowMore] = useState(false);
  const [isLike, setIsLike] = useState(item?.isLikedByUser);
  const [likeCount, setLikeCount] = useState(item?.likeNumber);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [paginationReply, setPaginationReply] = useState({
    page: 1,
    limit: 5,
  });
  const [error, setError] = useState("");

  const popoverRef = useRef(null);
  const readMoreRef = useRef(null);

  const token = Cookies.get(`${staging ? "staging" : ""}token`);

  const { isTruncated, isReadingMore, toggleIsShowingMore } =
    useTruncatedElement({
      ref: readMoreRef,
    });

  useClickOutside(popoverRef, () => {
    setOpenPopover(false);
  });

  const handleShowMoreReply = () => {
    setLoading(true);
    setPaginationReply({
      ...paginationReply,
      page: paginationReply.page + 1,
    });
  };

  const handleOpenPopover = () => {
    setOpenPopover(!openPopover);
  };

  const handleCancelReply = () => {
    setError("");
    setReplyBox("");
    setOpenReplySection(false);
  };

  const handleOpenReplyInput = () => {
    if (token) {
      setOpenReplySection(!openReplySection);
    }
  };

  const handleOpenReplyList = (commentId) => {
    setIsOpenReplyList(!isOpenReplyList);
    setSelectedComment(commentId);
  };

  const handleLike = async () => {
    if (token) {
      try {
        setIsLike((prev) => !prev);
        setLikeCount((prev) => (isLike ? prev - 1 : prev + 1));

        await postLikeComment({
          commentId: item?.id,
          isLike: !isLike,
        });
      } catch (error) {
        console.log(error);
        setIsLike((prevIsLike) => !prevIsLike);
        setLikeCount((prev) => (isLike ? prev - 1 : prev + 1));
      }
    }
  };

  const handleDelete = async () => {
    try {
      await deleteComment(twygsId, item?.id);
      setOpenPopover(false);
      setListComment(
        listComment.map((comment) =>
          comment.id === item?.id ? { ...comment, isDeleted: true } : comment
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitReply = async (commentId) => {
    const date = new Date();
    try {
      setLoadingSubmit(true);
      const res = await postReply(
        {
          text: replyBox,
        },
        twygsId,
        commentId
      );
      window.dataLayer.push({
        event: "comment",
        twyg_id: twygsId,
        twyg_category: contentCategory,
      });
      setLoadingSubmit(false);
      setReplyBox("");
      setOpenReplySection(false);
      setNewReply([
        ...newReply,
        {
          id: res.id,
          text: replyBox,
          createdAt: date.toISOString(),
          likeNumber: 0,
          createdByUser: {
            userId: userInfo?.id,
            username: userInfo?.username,
            image: userInfo?.imgUrl,
          },
        },
      ]);
      setError("");
    } catch (error) {
      setLoadingSubmit(false);
      window.dataLayer.push({
        event: "error",
        error_type: "comment error",
        error_message: error?.data?.message,
      });
      if (error.response.status === 400) {
        setError("Returned error");
      } else {
        setError("Something went wrong");
      }
    }
  };

  const handleKeyPress = (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === "Enter") {
      handleSubmitReply(item.id);
    }
  };

  useEffect(() => {
    const fetchReplys = async () => {
      setLoading(true);
      const result = await fetchListReply(
        twygsId,
        selectedComment,
        paginationReply
      );
      setLoading(false);

      if (paginationReply.page > 1) {
        setListReply((pre) => [...pre, ...result.replies]);
      } else {
        setListReply(result.replies);
      }

      if (result.totalPage > paginationReply.page) {
        setHasShowMore(true);
      } else {
        setHasShowMore(false);
      }
    };
    if (twygsId && selectedComment) fetchReplys();
  }, [twygsId, selectedComment, paginationReply]);

  return (
    <div className="py-[16px] border-t border-[#E6E7EB] relative">
      <div className="flex items-start gap-[12px] justify-start">
        <a
          href={`/creator-detail/${encodeURIComponent(
            item?.createdByUser?.username
          )}`}
          target="_self"
        >
          <img
            src={item?.createdByUser?.image || AvatarIcon}
            className="min-w-[32px] min-h-[32px] max-w-[32px] max-h-[32px] rounded-full"
            alt="icon"
          />
        </a>
        <div className="w-full">
          <div className="flex">
            <a
              href={`/creator-detail/${encodeURIComponent(
                item?.createdByUser?.username
              )}`}
              target="_self"
            >
              <p className="text-xs font-semibold">
                {item?.createdByUser?.username}
              </p>
            </a>
            <p className="ml-[12px] !text-[#808080] text-xs">
              {convertTimeCreatedAt(item?.createdAt)}
            </p>
          </div>
          <pre
            ref={readMoreRef}
            className={`mt-[8px] font-sm text-sm 2xl:text-base font-nunito ${
              !isReadingMore && "line-clamp-4"
            } break-all	whitespace-pre-wrap`}
          >
            {item?.isDeleted ? (
              <i className="!text-[#808080]">[Deleted By User]</i>
            ) : (
              item?.text
            )}
          </pre>

          {/* handle Read More */}
          {isTruncated && (
            <div onClick={toggleIsShowingMore} className="cursor-pointer">
              <p className="text-xs !text-[#808080] cursor-pointer hover:opacity-80 mt-[8px]">
                {isReadingMore ? "Read less" : "Read more"}
              </p>
            </div>
          )}
          <div className="flex">
            <div className="flex mt-[12px] content-center">
              <img
                onClick={() => handleLike()}
                src={isLike ? LikeSolidIcon : LikeBlurIcon}
                className="hover:opacity-80 cursor-pointer"
                alt="icon"
              />
              <p className="ml-[4px] text-xs !text-[#808080]">
                {convertCountLike(likeCount)}
              </p>
            </div>
            <div
              onClick={() => handleOpenReplyInput()}
              className="flex mt-[12px] ml-[16px] content-center cursor-pointer hover:opacity-80"
            >
              <img src={ReplyIcon} alt="icon" />
              <p className="ml-[4px] text-xs !text-[#808080]">Reply</p>
            </div>
          </div>

          {/* Reply input */}
          {openReplySection && (
            <div className="w-[100%] mt-[12px]">
              <div className="flex gap-[12px]">
                <img
                  className="w-[20px] h-[20px] rounded-full"
                  src={userInfo?.imgUrl || AvatarIcon}
                  alt={userInfo?.imgUrl}
                />
                <AutoResizeTextArea
                  value={replyBox}
                  onChange={(e) => setReplyBox(e.target.value)}
                  placeholder="Reply..."
                  autoFocus={openReplySection}
                  handleKeyPress={handleKeyPress}
                />
              </div>

              <div className="justify-end flex mt-[8px]">
                <div
                  id="btn"
                  onClick={() => handleCancelReply()}
                  className="cursor-pointer justify-center rounded-md bg-none px-3 py-2 text-sm font-black w-auto"
                >
                  Cancel
                </div>
                <div
                  id="btn"
                  onClick={() => replyBox.trim() && handleSubmitReply(item?.id)}
                  className={`rounded-[40px] ml-[8px]  ${
                    !replyBox.trim()
                      ? "cursor-not-allowed opacity-[0.5] bg-[#084F4B]"
                      : "cursor-pointer bg-[#084F4B] opacity-[1] hover:opacity-[0.9]"
                  }  px-3 py-2 text-sm font-black !text-white w-auto`}
                >
                  {loadingSubmit ? (
                    <div className="flex gap-2 items-center !text-white opacity-[0.7]">
                      <svg
                        aria-hidden="true"
                        className="inline w-3 h-3 text-gray-200 animate-spin dark:text-gray-600 fill-gray-300"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      Comment
                    </div>
                  ) : (
                    "Comment"
                  )}
                </div>
              </div>
            </div>
          )}

          {error && <p className="!text-red-500">{error}</p>}

          {/* Show response amount */}
          {item?.numReplies > 0 && (
            <div
              onClick={() => handleOpenReplyList(item?.id)}
              className="flex cursor-pointer mt-[12px] items-center"
            >
              <img
                src={ArrowDown}
                className={isOpenReplyList ? "rotate-180" : ""}
                alt="Arrow down"
              />
              <div className="text-sm !text-twygs-green font-semibold hover:opacity-80">
                {`${item?.numReplies} ${
                  item?.numReplies > 0 ? "responses" : "response"
                }`}
              </div>
            </div>
          )}

          {/* Show list reply */}
          {item?.numReplies > 0 &&
            isOpenReplyList &&
            listReply?.map((item2) => (
              <div key={item2?.id}>
                <ReplyDetailComponent
                  item2={item2}
                  userInfo={userInfo}
                  listReply={listReply}
                  setListReply={setListReply}
                  commentId={item?.id}
                  twygsId={twygsId}
                />
              </div>
            ))}
          {newReply.map((item2) => (
            <div key={item2?.id}>
              <ReplyDetailComponent
                twygsId={twygsId}
                item2={item2}
                userInfo={userInfo}
                listReply={newReply}
                setListReply={setNewReply}
                commentId={item?.id}
              />
            </div>
          ))}

          {/* Loading */}
          {loading && <Loading size="w-5 h-5"></Loading>}

          {/* Show more comment */}
          {hasShowMore && isOpenReplyList && (
            <div>
              <div
                className="flex items-center justify-start cursor-pointer"
                onClick={() => handleShowMoreReply()}
              >
                <img src={PlusIcon} alt="icon" />
                <p className="ml-[8px] text-[#808080] text-xs">
                  Show more replies
                </p>
              </div>
            </div>
          )}
        </div>

        {/* Open popover */}
        {(userInfo?.id === item?.createdByUser?.userId ||
          userInfo?.id === twygCreatorId) &&
        !item?.isDeleted ? (
          <img
            onClick={() => {
              handleOpenPopover(item?.id);
            }}
            className="w-[20px] cursor-pointer hover:opacity-80"
            src={ThreeDotsIcon}
            alt="Three dots icon"
          />
        ) : null}
      </div>
      {/* Handle Popover */}
      {openPopover && (
        <div
          ref={popoverRef}
          onClick={() => handleDelete()}
          className="absolute right-0 top-[40px] bg-white p-[18px] cursor-pointer flex border border-1 border-[#F2F3F7] rounded-[12px] shadow-[0px_8px_12px_0px_#80808026] hover:bg-gray-100"
        >
          <img src={TrashIcon} alt="Trash icon" />
          <p className="font-bold text-sm ml-[8px]"> Delete </p>
        </div>
      )}
    </div>
  );
};

export default CommentDetailComponent;
