import React, { Fragment } from "react";
import { VStack } from "@chakra-ui/react";
import IntroSection from "../../IntroSection/intro-section.component";
import { INTROSECTION } from "../../../constants/draft.constant";
import DraftTableComponent from "../components/draft/draft-table.component";

const MyDraft = () => {
  return (
    <Fragment>
      <VStack
        alignItems={"center"}
        spacing={{ base: "1.5rem", "2xl": "2.5rem" }}
        p={{ base: "1rem", "2xl": "2rem" }}
        position={"relative"}
        minH={"calc(100vh - 116px)"}
      >
        <IntroSection
          title={INTROSECTION.title}
          description={INTROSECTION.description}
        />
        <DraftTableComponent />
      </VStack>
    </Fragment>
  );
};

export default MyDraft;
