import { useEffect, useState } from "react";
import ArrowRightIcon from "../../assets/arrow-right-icon.svg";
import { ChangeInfo } from "../../components/Settings/ChangeInfo";
import ChangePassword from "../../components/Settings/ChangePassword";
import ChangeTwoFactorAuthentication from "../../components/Profile/change-two-factor-authentication/change-two-factor-authentication.component";

export const Settings = () => {
  const [profileData, setProfileData] = useState(null);

  const handleChangeProfile = (newProfile) => {
    setProfileData(newProfile);
    localStorage.setItem("user", JSON.stringify(newProfile));
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") ?? "{}");
    if (user) setProfileData(user);
  }, []);

  return (
    <div className="m-auto px-5 py-5 w-full 2xl:py-8">
      <div className="flex items-center gap-[12px]">
        <p className="text-sm 2xl:text-base">Settings</p>
        <img src={ArrowRightIcon} alt="arrow" />
        <p className="!text-[#084F4B] text-sm 2xl:text-base">
          Account Management
        </p>
      </div>
      <ChangeInfo
        profileData={profileData}
        setProfileData={handleChangeProfile}
      />
      <ChangePassword profileData={profileData} />

      <ChangeTwoFactorAuthentication profileData={profileData} />
    </div>
  );
};
