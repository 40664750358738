import { Box, Stack, Text } from "@chakra-ui/react";

import RightContentComponet from "./right-content.component";
import { TopBar } from "../../TopBar";

const TempalteCreateTwygsComponent = (props) => {
  const {
    children,
    title,
    saveDarft,
    onSubmit,
    loadingSaveDraft,
    disable,
    loadingPublish,
  } = props;

  return (
    <Stack
      direction={{ base: "column", md: "row" }}
      spacing={{ base: "0rem", md: "2.5rem" }}
      position={"relative"}
    >
      <Box
        w={{
          base: "100%",
          md: "calc(100% - 20.5rem)",
          "2xl": "calc(100% - 27.5rem)",
        }}
        maxH={{ base: "100%", md: "calc(100vh - 8.5rem)" }}
        pb={{ base: "1.25rem", md: "1rem" }}
        overflow={"auto"}
        _hover={{
          overflow: "auto",
        }}
        css={{
          "&::-webkit-scrollbar": {
            width: "6px",
            height: "6px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
            height: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#c6ceda",
            borderRadius: "20px",
          },
        }}
        mr={"-2.5rem"}
      >
        <TopBar />
        <Box px={{ base: "1rem", "2xl": "2rem" }}>
          <Text
            w={"100%"}
            mx={"auto"}
            fontSize={{ base: "xl", "2xl": "2xl" }}
            fontWeight={"800"}
            my={{ base: "1rem", md: "1.5rem" }}
          >
            {title}
          </Text>
          <Box w={"100%"} mx={"auto"}>
            {children}
          </Box>
        </Box>
      </Box>
      <RightContentComponet
        saveDarft={saveDarft}
        onSubmit={onSubmit}
        loadingSaveDraft={loadingSaveDraft}
        loadingPublish={loadingPublish}
        disable={disable}
      />
    </Stack>
  );
};

export default TempalteCreateTwygsComponent;
