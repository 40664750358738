import { useState } from "react";
import styles from "./index.module.css";
import ArrowDownIcon from "../../assets/arrow-down-icon.svg";
import { enumReasonReport } from "../../constants";
const Dropdown = (props) => {
  const { selectedReason, setSelectedReason, listReport } = props;
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div
        onClick={() => setOpen(!open)}
        className={`relative cursor-pointer h-[56px] bg-light-gray w-full border !rounded-[12px] border-semi-gray px-[24px] text-[16px] flex justify-between items-center !rounded ${
          !selectedReason ? "text-gray-700" : ""
        }`}
      >
        {selectedReason === ""
          ? "Select reason for report"
          : enumReasonReport[selectedReason]}
        <img
          className={open ? "rotate-180 transition" : ""}
          src={ArrowDownIcon}
          alt="arrow down"
        />
      </div>
      <ul
        className={
          `bg-white mt-2 py-[10px] overflow-y-auto absolute w-[592px] max-h-[300px] border border-solid border-semi-gray !rounded-[12px] ${
            open ? "block" : "hidden"
          } ` + styles
        }
      >
        {listReport?.map((item) => (
          <li
            key={item?.id}
            className="px-[8px] text-sm font-extrabold cursor-pointer"
            onClick={() => {
              if (item?.name !== selectedReason) {
                setSelectedReason(item?.id);
                setOpen(false);
              }
            }}
          >
            <p
              className={`hover:bg-normal-gray !rounded-[8px] p-[10px]  ${
                item?.name === selectedReason ? "bg-normal-gray" : ""
              }`}
            >
              {item?.name}
              {item?.id === 9 && (
                <span className="font-normal ml-[8px] text-[#475467]">
                  Please provide details below
                </span>
              )}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Dropdown;
