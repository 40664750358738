const SuccessModal = (props) => {
  const { title, image, description, titleBtn, setModal } = props;
  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full  justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative  transform overflow-hidden !rounded-[24px] bg-white text-left shadow-xl transition-all sm:my-8 max-h-[700px] w-[480px]">
            <div className="bg-white px-[52.5px] py-[48px] flex flex-col items-center	">
              <img src={image} alt="icon" />
              <p className="text-[32px] mt-[12px] font-black text-[#084F4B]">
                {title}
              </p>
              <p className="text-[18px] mt-[12px] text-center">{description}</p>
              <div
                id="btn"
                onClick={() => setModal("")}
                className="cursor-pointer mt-[32px] bg-custom h-[56px] w-full !rounded-lg !text-white text-sm font-black hover:opacity-80"
              >
                {titleBtn}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
