import axiosInstance from "./axios-instance";

export const deleteTwygs = async ({ deleteIds }) => {
  try {
    const result = await axiosInstance.post("twygs/delete-twygs", { deleteIds });
    if (result.status === 200) {
      return result.data.data;
    }
  } catch (error) {
    console.error("Error deleting twygs:", error);
    throw error;
  }
};

export const postAssetUpload = async (data) => {
  try {
    const result = await axiosInstance.post(
      `campaign/presigned-upload-asset`,
      data
    );
    if (result.status === 200) {
      return result.data.data;
    }
  } catch (error) {
    console.error("Error uploading asset:", error);
    throw error;
  }
};

export const saveTwygDraft = async (data) => {
  try {
    const result = await axiosInstance.post(`twygs/save-draft`, data);
    if (result.status === 200) {
      return result.data.data;
    }
    return null;
  } catch (error) {
    console.error("Error publishing twyg:", error);
    throw error;
  }
};

export const publishTwyg = async (data) => {
  try {
    const result = await axiosInstance.post(`twygs/publish`, data);
    if (result.status === 200) {
      return result.data.data;
    }
    return null;
  } catch (error) {
    console.error("Error publishing twyg:", error);
    throw error;
  }
};

export const getContentCategories = async () => {
  try {
    const result = await axiosInstance.get(
      `campaign-target/get-content-categories?limit=${1000}`
    );
    if (result.status === 200) {
      return result.data;
    }
  } catch (error) {
    console.error("Error getting content categories:", error);
    throw error;
  }
};

export const getTwygNames = async () => {
  try {
    const result = await axiosInstance.get("twygs/list-twygs-name");
    if (result.status === 200) {
      return result.data.data;
    }
    return null;
  } catch (error) {
    console.error("Error getting twyg names:", error);
    throw error;
  }
};

export const initMultipleUpload = async (data) => {
  try {
    const result = await axiosInstance.post(`media/init-multipart-upload`, data);
    if (result.status === 200) {
      return result.data.data;
    }
  } catch (error) {
    console.error("Error With Initializing Multipart Upload:", error);
    throw error;
  }
};

export const getMultipartUrl = async (data) => {
  try {
    const result = await axiosInstance.post(`media/get-multipart-url`, data);
    if (result.status === 200) {
      return result.data.data;
    }
  } catch (error) {
    console.error("Error Getting Multipart Url:", error);
    throw error;
  }
};

export const finalizeMultipartUpload = async (data) => {
  try {
    const result = await axiosInstance.post(
      `media/finalize-multipart-upload`,
      data
    );
    if (result.status === 200) {
      return result.data.data;
    }
  } catch (error) {
    console.error("Error Finalizing Multipart Upload", error);
    throw error;
  }
};

export const getTwygsHub = async () => {
  try {
    const result = await axiosInstance.get("twygs/get-twygs-hub");
    if (result.status === 200) {
      return result.data.data;
    }
  } catch (error) {
    console.error("Error Getting Twygs Hub:", error);
    throw error;
  }
};

export const getFinishedTwygs = async (params) => {
  const { sort, order, limit, page, search, contentTypeId, contentCategoryId } =
    params;
  const queryParams = new URLSearchParams();

  if (sort) queryParams.append("sort", sort);
  if (order) queryParams.append("order", order);
  if (limit) queryParams.append("limit", limit);
  if (page) queryParams.append("page", page);
  if (search) queryParams.append("search", search);
  if (contentTypeId) queryParams.append("contentTypeId", contentTypeId);
  if (contentCategoryId)
    queryParams.append("contentCategoryId", contentCategoryId);

  try {
    const result = await axiosInstance.get(
      `twygs/list-finished-twygs?${queryParams.toString()}`
    );
    if (result.status === 200) {
      return result.data;
    }
    return null;
  } catch (error) {
    console.error("Error fetching twygs:", error);
    throw error;
  }
};

export const fetchTwygsDetail = async (id) => {
  const result = await axiosInstance.get(`twygs/${id}/detail`);
  try {
    if (result.status === 200) return result.data.data;
    return null;
  } catch (error) {
    console.error("Error fetching twyg details:", error);
    throw error;
  }
};
export const saveTwygsHubInfo = async (data) => {
  try {
    const result = await axiosInstance.post(`twygs/update-twygs-hub`, data);
    if (result.status === 200) {
      return result.data.data;
    }
  } catch (error) {
    console.error("Error Finalizing Multipart Upload", error);
    throw error;
  }
};
