import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";
import { Box, Image, Text } from "@chakra-ui/react";

import { ReactComponent as DndIcon } from "../../../../assets/dnd-icon.svg";

const DraggableImage = ({ src, id, index, moveImage, onlyOneImage }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: "image",
    hover: (item, monitor) => {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveImage(dragIndex, hoverIndex);

      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "image",
    item: () => {
      return { id, index };
    },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });

  const opacity = isDragging ? 0.5 : 1;
  drag(drop(ref));

  return (
    <Box ref={ref} opacity={opacity} w={"full"}>
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        mb={"0.25rem"}
        w={"full"}
      >
        <DndIcon
          className={`object-cover ${
            onlyOneImage ? "hidden" : "block"
          } md:block`}
          alt=""
        />
        <Text
          display={{ base: onlyOneImage ? "none" : "block", md: "block" }}
          fontSize={"sm"}
          fontWeight={"900"}
          mr={"0.5rem"}
        >
          {index + 1}
        </Text>
      </Box>
      <Image
        src={src}
        w={"full"}
        maxH={{ base: "10.5rem", md: "22.5rem" }}
        alt=""
        aspectRatio={1}
        rounded={"0.25rem"}
      />
    </Box>
  );
};

export default DraggableImage;
