import { Center, Spinner } from "@chakra-ui/react";

const EditTwygs = () => {
  return (
    <Center h={"20rem"}>
      <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="dark-green" size="xl" />
    </Center>
  );
};

export default EditTwygs;
