import axiosInstance from "./axios-instance";

export const getDraftTwygs = async (params) => {
  const { sort, order, limit, page } = params;
  const queryParams = new URLSearchParams();

  if (sort) queryParams.append("sort", sort);
  if (order) queryParams.append("order", order);
  if (limit) queryParams.append("limit", limit);
  if (page) queryParams.append("page", page);

  try {
    const result = await axiosInstance.get(
      `twygs/list-draft?${queryParams.toString()}`
    );
    if (result.status === 200) {
      return result.data.data;
    }
    return null;
  } catch (error) {
    console.error("Error fetching draft twygs:", error);
    throw error;
  }
};

export const UPLOAD_PDF =
  `${process.env.REACT_APP_TWYGS_API_URL}twygs/upload-pdf` || "";
