export const TYPES_DRAFT = {
  image: "Image",
  video: "Video",
  pdf: "PDF"
};

export const MODAL_DRAFT = {
  description: "Warning: This action cannot be undone.",
  "title-mutilple": "Are you sure you want to remove these Twygs?",
  title: "Are you sure you want to remove this Twyg?"
};

export const INTROSECTION = {
  title: "My Twygs Draft",
  description: "Select a Twyg draft to finish"
};

export const LIST_COLOR_CATEGORY = [
  {
    color: "#DF1674",
    bgColor: "#FFE5ED"
  },
  {
    color: "#FFFFFF",
    bgColor: "#012BC2"
  },
  {
    color: "#E5702E",
    bgColor: "#FFF2E8"
  },
  {
    color: "#012BC2",
    bgColor: "#DBE0F2"
  },
  {
    color: "#471FC3",
    bgColor: "#F0E2FF"
  },
  {
    color: "#FFFFFF",
    bgColor: "#C41212"
  },
  {
    color: "#7A053C",
    bgColor: "#F6A2C9"
  },
  {
    color: "#FFFFFF",
    bgColor: "#6D49DF"
  },
  {
    color: "#C41212",
    bgColor: "#FCEEEE"
  },
  {
    color: "#333333",
    bgColor: "#B9DBA7"
  },
  {
    color: "#FFFFFF",
    bgColor: "#E5702E"
  },
  {
    color: "#624D02",
    bgColor: "#FFD166"
  },
  {
    color: "#624D02",
    bgColor: "#FFF3C9"
  },
  {
    color: "#DCEED3",
    bgColor: "#335521"
  },
  {
    color: "#FFFFFF",
    bgColor: "#016F76"
  },
  {
    color: "#016F76",
    bgColor: "#C4ECEE"
  }
];
