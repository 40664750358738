import React from "react";
import Step1 from "./step1.component";
import Step2 from "./step2.component";
import Step3 from "./step3.component";
import IntroSection from "../../intro-section/intro-section.component";

const AuthenticatorSetup = ({ appData }) => {
  return (
    <>
      <IntroSection
        title="Authenticator Setup"
        description="Follow steps below on your phone."
        colorDesc="!text-[#808080]"
      />
      <div className="mt-4 md:mt-10 flex flex-col gap-4">
        <Step1 />
        <Step2 appData={appData} />
        <Step3 appData={appData} />
      </div>
    </>
  );
};

export default AuthenticatorSetup;
