import { Route, Routes } from "react-router-dom";
import { TouchBackend } from "react-dnd-touch-backend";
import { DndProvider } from "react-dnd";
import MyTwygs from "../../components/MyTwygs";
import EditTwygs from "../../components/MyTwygs/edit-twygs";
import EditVideo from "../../components/MyTwygs/edit-twygs/video-type";
import EditPdf from "../../components/MyTwygs/edit-twygs/pdf-type";
import EditImage from "../../components/MyTwygs/edit-twygs/image-type";
import Draft from "../../components/MyTwygs/draft";

const CreateTwygsRoute = () => {
  return (
    <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
      <Routes>
        <Route path="/" element={<MyTwygs />} />
        <Route path="/draft" element={<Draft />} />
        <Route path="/edit-twyg" element={<EditTwygs />} />
        <Route path="/edit-twyg/video-twyg" element={<EditVideo />} />
        <Route path="/edit-twyg/image-twyg" element={<EditImage />} />
        <Route path="/edit-twyg/pdf-twyg" element={<EditPdf />} />
      </Routes>
    </DndProvider>
  );
};

export default CreateTwygsRoute;
