import React from "react";
import {
  GOOGLE_AUTHENTICATION_APP,
  IOS_AUTHENTICATION_APP,
} from "../../constants";

const Step1 = () => {
  return (
    <div className="p-5 md:p-6 border border-gray-100 rounded-xl bg-white shadow-sm flex flex-col md:flex-row justify-between items-start md:items-center gap-6 md:gap-0">
      <div className="md:flex items-center justify-between w-full">
        <p className="text-sm 2xl:text-base font-black">
          Step 1:{" "}
          <span className="font-semibold">Open your Authenticator app</span>
        </p>
        <p className="text-sm font-normal mt-2 md:mt-0 text-black">
          Don’t have one? Download from{" "}
          <a
            href={GOOGLE_AUTHENTICATION_APP}
            target="_blank"
            rel="noopener noreferrer"
            className="font-black !text-[#00A375] cursor-pointer text-sm 2xl:text-base"
          >
            Google Play
          </a>{" "}
          or{" "}
          <a
            href={IOS_AUTHENTICATION_APP}
            target="_blank"
            rel="noopener noreferrer"
            className="font-black !text-[#00A375] cursor-pointer text-sm 2xl:text-base"
          >
            iOS App Store
          </a>
        </p>
      </div>
    </div>
  );
};

export default Step1;
