import Category1 from "../assets/category-1.png";
import Category2 from "../assets/category-2.png";
import Category3 from "../assets/category-3.png";
import Category4 from "../assets/category-4.png";
import Category5 from "../assets/category-5.png";
import Category6 from "../assets/category-6.png";
import Category7 from "../assets/category-7.png";
import Category8 from "../assets/category-8.png";
import Category9 from "../assets/category-9.png";
import Category10 from "../assets/category-10.png";
import Category11 from "../assets/category-11.png";
import Category12 from "../assets/category-12.png";
import Category13 from "../assets/category-13.png";
import Category14 from "../assets/category-14.png";
import Category15 from "../assets/category-15.png";
import Category16 from "../assets/category-16.png";
import GenderFemaleBlackIcon from "../assets/female-icon.svg";
import GenderMaleBlackIcon from "../assets/male-icon.svg";
import GenderOtherBlackIcon from "../assets/gender-other.svg";

export const statsStatus = {
  default: "",
  loading: "loading",
  loaded: "loaded",
  error: "error",
};

export const FILTER_LIST = [
  {
    name: "Top Twygs",
    value: "top",
  },
  {
    name: "Trending",
    value: "trending",
  },
  {
    name: "Latest",
    value: "latest",
  },
  {
    name: "Last week",
    value: "lastWeek",
  },
];

export const enumReasonReport = {
  1: "Gambling",
  2: "Political content",
  3: "Profanity and rough language",
  4: "Sensational and shocking",
  5: "Sensitive social issues",
  6: "Sexually suggestive",
  7: "Tragedy and conflict",
  8: "Legal issue",
  9: "Others",
};

export const CATEGORY_LIST = [
  {
    id: 0,
    name: "All Twygs",
    desc: "All Twygs",
    imageUrl: Category1,
  },
  {
    id: 1,
    name: "Art & Creativity",
    desc: "Artistic creations and visual arts",
    imageUrl: Category1,
  },
  {
    id: 2,
    name: "Business & Finance",
    desc: "Entrepreneurship and finance",
    imageUrl: Category2,
  },
  {
    id: 3,
    name: "DIY & Crafts",
    desc: "Creative projects",
    imageUrl: Category10,
  },
  {
    id: 4,
    name: "Education & Learning",
    desc: "Educational topics and courses",
    imageUrl: Category12,
  },
  {
    id: 5,
    name: "Entertainment",
    desc: "Movies, music, and pop culture",
    imageUrl: Category15,
  },
  {
    id: 6,
    name: "Gaming",
    desc: "Video games and reviews",
    imageUrl: Category3,
  },
  {
    id: 7,
    name: "Fashion & Beauty",
    desc: "Trends and style",
    imageUrl: Category4,
  },
  {
    id: 8,
    name: "Fitness & Wellness",
    desc: "Health and fitness routines",
    imageUrl: Category11,
  },
  {
    id: 9,
    name: "Food & Cooking",
    desc: "Recipes and culinary experiences",
    imageUrl: Category7,
  },
  {
    id: 10,
    name: "Health & Medical",
    desc: "Medical conditions and general health information",
    imageUrl: Category16,
  },
  {
    id: 11,
    name: "Lifestyle",
    desc: "Daily life and personal interests",
    imageUrl: Category5,
  },
  {
    id: 12,
    name: "Parenting & Family",
    desc: "Family life and child-rearing",
    imageUrl: Category13,
  },
  {
    id: 13,
    name: "Pets & Animals",
    desc: "Pets and wildlife",
    imageUrl: Category8,
  },
  {
    id: 14,
    name: "Science & Nature",
    desc: "Scientific discoveries and nature",
    imageUrl: Category14,
  },
  {
    id: 15,
    name: "Travel",
    desc: "Destinations and experiences",
    imageUrl: Category9,
  },
  {
    id: 16,
    name: "Technology",
    desc: "Gadgets and innovations",
    imageUrl: Category6,
  },
];

export const checkResponsive = () => {
  if (window.innerWidth <= 1024) return true;
  return false;
};

export const convertTimeCreatedAt = (dateString) => {
  const date = new Date(dateString);
  const today = new Date();
  const diffTime = Math.abs(today - date) / 1000;
  let result = "";
  if (diffTime < 60) {
    result = `${Math.floor(diffTime)} second${
      Math.floor(diffTime) > 1 ? "s" : ""
    } ago`;
  } else if (diffTime < 3600) {
    result = `${Math.floor(diffTime / 60)} minute${
      Math.floor(diffTime / 60) > 1 ? "s" : ""
    } ago`;
  } else if (diffTime < 86400) {
    result = `${Math.floor(diffTime / 3600)} hour${
      Math.floor(diffTime / 3600) > 1 ? "s" : ""
    } ago`;
  } else if (diffTime < 2592000) {
    result = `${Math.floor(diffTime / 86400)} day${
      Math.floor(diffTime / 86400) > 1 ? "s" : ""
    } ago`;
  } else if (diffTime < 31536000) {
    result = `${Math.floor(diffTime / 2592000)} month${
      Math.floor(diffTime / 2592000) > 1 ? "s" : ""
    } ago`;
  } else {
    result = `${Math.floor(diffTime / 31536000)} year${
      Math.floor(diffTime / 31536000) > 1 ? "s" : ""
    } ago`;
  }
  return result;
};
export const CONTENT_TYPE_LIST = [
  {
    name: "All Twygs",
    value: 1,
  },
  { name: "Art & Creativity", value: 2 },
  { name: "DIY & Crafts", value: 3 },
  { name: "Gaming", value: 4 },
  { name: "Technology", value: 5 },
];

export const CONTENT_TYPES = [
  {
    id: 2,
    name: "Image",
    desc: "Image type",
  },
  {
    id: 3,
    name: "Video",
    desc: "Video type",
  },
  {
    id: 1,
    name: "PDF",
    desc: "PDF type",
  },
];

export function convertCountLike(num, precision = 2) {
  if (num || num === 0) {
    const map = [
      { suffix: "B", threshold: 1e9 },
      { suffix: "M", threshold: 1e6 },
      { suffix: "K", threshold: 1e3 },
      { suffix: "", threshold: 1 },
    ];

    const found = map.find((x) => Math.abs(num) >= x.threshold);
    if (found) {
      const formatPrecision =
        found.threshold === 1 || num % found.threshold === 0 ? 0 : precision;

      let formatted;
      if (formatPrecision > 0) {
        formatted =
          (num / found.threshold)
            .toFixed(formatPrecision)
            .replace(/\.?0+$/, "") + found.suffix;
      } else {
        formatted = Math.round(num / found.threshold) + found.suffix;
      }

      return formatted;
    }
    return num.toString();
  }
  return 0;
}

export function classifyDevice() {
  const userAgent = navigator.userAgent.toLowerCase();
  const isTouchDevice =
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0;

  // Define screen size thresholds
  const screenSize = Math.min(window.screen.width, window.screen.height); // Use the smaller dimension for screen size
  const mobileMaxSize = 767; // Maximum screen size for mobile devices
  const tabletMaxSize = 1024; // Maximum screen size for tablets

  // Check if the device is a mobile
  if (screenSize <= mobileMaxSize) {
    return "Mobile";
  }

  // Check if the device is a tablet
  if (
    isTouchDevice &&
    screenSize > mobileMaxSize &&
    screenSize <= tabletMaxSize
  ) {
    if (/ipad|tablet|playbook|silk|kindle/.test(userAgent)) {
      return "Tablet";
    }
    // Some mobile devices might also fall into this category, so we need an additional check
    if (!/mobile/.test(userAgent)) {
      return "Tablet";
    }
  }

  // If it's not mobile or tablet, then it's likely a desktop
  return "Desktop";
}

export const isAndroi = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return /androi/.test(userAgent);
};

export const isIOS = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};

export const TYPE_IMAGES = ["jpeg", "jpg", "png", "gif"];

export const ALLOWED_FILE_TYPES = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
];

export const MAX_FILE_SIZE = 30 * 1000 * 1000;

export const getContentType = (fileName) => {
  const extension = fileName.split(".").pop();
  if (!extension) {
    return;
  }
  if (TYPE_IMAGES.includes(extension)) {
    return "image/" + extension;
  } else return `application/${extension}`;
};

export const MONTH_IN_YEAR = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const removeAllCookies = () => {
  // Get the domain from the environment variable
  const domain = process.env.REACT_APP_TWYGS_HOST;

  // Get all cookies
  const cookies = document.cookie.split(";");

  // Loop through all cookies and delete them with the specified domain
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

    // Include the domain when deleting the cookie
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.${domain};`;
  }
};

export const GENDER = [
  {
    value: "Male",
    icon: GenderMaleBlackIcon,
  },
  {
    value: "Female",
    icon: GenderFemaleBlackIcon,
  },
  {
    value: "Other",
    icon: GenderOtherBlackIcon,
  },
];
