import React, { useEffect, useState } from "react";
import {
  Box,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Spinner,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import {
  calculateFrameInfo,
  getDurationVideo,
  getImageFrame,
  handleDisplayTimeBySeconds,
} from "../twygs.helper";

const VideoTimeline = ({ data, setData, onFrameRangeChange }) => {
  const [trimData, setTrimData] = useState(null);
  const [sliderValue, setSliderValue] = useState([0, 1]);
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    if (data) {
      const trim = data.trim;
      if (trim?.frameArray?.length > 0) {
        const duration = Math.floor(data.asset.duration);
        setTrimData(trim);
        setSliderValue(trim.timeline || [0, duration]);
      } else {
        getImageFrameInfo(data);
      }
    }
  }, []);

  const getImageFrameInfo = async (data) => {
    if (data) {
      try {
        setIsloading(true);

        const asset = data.asset;

        if (!asset.duration) {
          const { duration } = await getDurationVideo(asset.src);
          asset.duration = duration;
        }

        asset.duration = Math.floor(asset.duration);

        const { frameWidth, frameArray } = calculateFrameInfo(asset);

        let newArr = await getImageFrame(asset.src, frameArray);

        if (newArr?.length > 0) {
          setTrimData({
            frameWidth,
            frameArray: newArr,
          });

          setData({
            ...data,
            trim: {
              frameWidth,
              frameArray: newArr,
            },
          });

          setSliderValue([0, data.asset.duration]);
        }
      } catch (e) {
        return null;
      } finally {
        setIsloading(false);
      }
    }
  };

  const handleSliderChange = (value) => {
    setSliderValue(value);
    onFrameRangeChange(value[0], value[1]);
  };

  const renderSliderImage = (value) => {
    if (value >= 0) {
      const frameList = trimData?.frameArray?.filter(
        (item) => item.timestamp <= value
      );

      if (frameList?.length > 0) {
        const item = frameList[frameList.length - 1];

        return (
          <Box
            w={`${item.widthFrameItem}px`}
            h={`${item.heightFrameItem}px`}
            background={`url(${item.src}) no-repeat center`}
            backgroundSize={"cover"}
          />
        );
      }
    } else {
      return "";
    }
  };

  return (
    <Box
      position={"relative"}
      width={trimData?.frameWidth ? `${trimData.frameWidth}%` : "100%"}
      height={"7.5rem"}
    >
      {!isLoading ? (
        trimData?.frameArray?.length > 0 && (
          <>
            <Box
              position={"absolute"}
              p={"0.625rem 0"}
              w={"full"}
              h={"full"}
              borderRadius={"0.375rem"}
              backgroundColor={"dim-gray"}
              top={0}
              left={0}
            >
              <Box position={"relative"} overflowX={"clip"}>
                {trimData.frameArray.map((item, index) => (
                  <Box
                    key={index}
                    position={"absolute"}
                    left={`${item.left}%`}
                    w={`${item.widthFrameItem}px`}
                    h={`${item.heightFrameItem}px`}
                    background={`url(${item.src}) no-repeat center`}
                    backgroundSize={"cover"}
                  />
                ))}
              </Box>
            </Box>
            {data?.asset?.duration > 0 && (
              <RangeSlider
                // eslint-disable-next-line jsx-a11y/aria-proptypes
                aria-label={["min", "max"]}
                defaultValue={sliderValue}
                min={0}
                max={data.asset.duration}
                minStepsBetweenThumbs={1}
                onChange={handleSliderChange}
                position={"absolute"}
                top={"0.625rem"}
                left={0}
              >
                <RangeSliderTrack height={"6.25rem"} bgColor="transparent">
                  <RangeSliderFilledTrack
                    height={"full"}
                    bgColor="transparent"
                    border={"1px solid"}
                    borderColor={"twygs-green"}
                  />
                </RangeSliderTrack>
                <RangeSliderThumb
                  boxSize={6}
                  index={0}
                  w={"1rem"}
                  height={"6.25rem"}
                  bgColor={"twygs-green"}
                  borderRadius={"0.5rem 0 0 0.5rem"}
                  _active={{}}
                  _focusVisible={{}}
                  position={"relative"}
                >
                  <Box
                    position={"absolute"}
                    bottom={"-1.75rem"}
                    p={"0.125rem 0.5rem"}
                    bgColor={"twygs-green"}
                    borderRadius={"1.875rem"}
                  >
                    <Box
                      position={"absolute"}
                      top={"-0.25rem"}
                      left={"1.5rem"}
                      width={"0.5rem"}
                      height={"0.5rem"}
                      transform={"rotate(45deg)"}
                      bgColor={"twygs-green"}
                    />
                    <Text fontSize={"tn"} fontWeight={800} color={"jet-black"}>
                      {handleDisplayTimeBySeconds(sliderValue[0])}
                    </Text>
                  </Box>
                  <Tooltip
                    label={renderSliderImage(sliderValue[0])}
                    placement={"top"}
                    p={0}
                  >
                    <Box position={"absolute"} w={"full"} h={"full"} />
                  </Tooltip>
                  <Box
                    w={"0.375rem"}
                    height={"3.125rem"}
                    bgColor={"twygs-pink"}
                    borderRadius={"0.5rem"}
                  />
                </RangeSliderThumb>
                <RangeSliderThumb
                  boxSize={6}
                  index={1}
                  w={"1rem"}
                  height={"6.25rem"}
                  bgColor={"twygs-green"}
                  borderRadius={"0 0.5rem 0.5rem 0"}
                  _active={{}}
                  _focusVisible={{}}
                  position={"relative"}
                >
                  <Box
                    position={"absolute"}
                    bottom={"-1.75rem"}
                    p={"0.125rem 0.5rem"}
                    bgColor={"twygs-green"}
                    borderRadius={"1.875rem"}
                  >
                    <Box
                      position={"absolute"}
                      top={"-0.25rem"}
                      left={"1.5rem"}
                      width={"0.5rem"}
                      height={"0.5rem"}
                      transform={"rotate(45deg)"}
                      bgColor={"twygs-green"}
                    />
                    <Text fontSize={"tn"} fontWeight={800} color={"jet-black"}>
                      {handleDisplayTimeBySeconds(sliderValue[1])}
                    </Text>
                  </Box>
                  <Tooltip
                    label={renderSliderImage(sliderValue[1])}
                    placement={"top"}
                    p={0}
                  >
                    <Box position={"absolute"} w={"full"} h={"full"} />
                  </Tooltip>
                  <Box
                    w={"0.375rem"}
                    height={"3.125rem"}
                    bgColor={"twygs-pink"}
                    borderRadius={"0.5rem"}
                  />
                </RangeSliderThumb>
              </RangeSlider>
            )}
          </>
        )
      ) : (
        <Box
          position={"absolute"}
          top={0}
          left={0}
          width={"100%"}
          height={"100%"}
          minWidth={"24px"}
          minHeight={"24px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="dark-green"
            size={{ base: "lg", "2xl": "xl" }}
          />
        </Box>
      )}
    </Box>
  );
};

export default VideoTimeline;
