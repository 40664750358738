import { useEffect, useState } from "react";

import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { hubInfoSchema } from "../edit-info.schema";
import { InputHookForm } from "../../../../Common/Form/input-with-hook-form";
import { formartNumber } from "../../../../../utils/helper.util";
import { TextAreaHookForm } from "../../../../Common/Form/textarea-hook-form";
import { useMutation } from "@tanstack/react-query";
import { saveTwygsHubInfo } from "../../../../../apis/studio.api";

export const EditModalComponent = ({
  isOpen,
  onClose,
  twygsHubInfo,
  refetch,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { mutateAsync: saveTwygsHubInfoAsync } = useMutation({
    mutationFn: saveTwygsHubInfo,
  });
  const methods = useForm({
    resolver: yupResolver(hubInfoSchema),
  });

  const hubDescription = useWatch({
    control: methods.control,
    name: "description",
  });

  useEffect(() => {
    if (twygsHubInfo) {
      methods.reset({
        ...twygsHubInfo,
      });
    }
  }, [methods, twygsHubInfo]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const newData = {
      ...data,
      name: "",
      website: data.website === "" ? null : data.website,
      email: data.email === "" ? null : data.email,
    };
    await saveTwygsHubInfoAsync(newData);
    setIsLoading(false);
    refetch();
    onClose();
  };

  return (
    <Modal isOpen={isOpen} isCentered onClose={onClose}>
      <ModalOverlay bgColor={"jet-black"} />
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <ModalContent
            p={"1.5rem"}
            width={{ base: "auto", md: "720px" }}
            maxWidth={{ base: "auto", md: "74.5rem" }}
            borderRadius={"0.75rem"}
            bgColor={"light.100"}
            boxShadow={
              "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)"
            }
            gap="24px"
          >
            <ModalHeader p={0}>
              <Text fontWeight={800} fontSize={{ base: "2xl", md: "xl" }}>
                Edit My Twygs Hub
              </Text>
            </ModalHeader>

            <ModalBody p={0} w={"100%"}>
              <Box
                p={{ base: "20px", md: "24px" }}
                rounded={"8px"}
                border={"1px solid"}
                borderColor={"anti-flash-white"}
              >
                <VStack gap={"24px"} w={"100%"}>
                  <Box w={"100%"}>
                    <Text
                      color="dim-gray"
                      mb="4px"
                      fontSize={"md"}
                      fontWeight={400}
                    >
                      Describe your hub
                    </Text>
                    <TextAreaHookForm
                      p={"0px 26px 8px 0px"}
                      bgColor={"white"}
                      border={"none"}
                      borderColor={"unset"}
                      borderRadius={"none !important"}
                      boxShadow={"none !important"}
                      borderBottom={"1px solid"}
                      borderBottomColor={"mid-gray"}
                      _focusVisible={{
                        borderColor: "twygs-green",
                      }}
                      name="description"
                      bottomContentProps={{
                        mt: "12px",
                        display: "flex",
                      }}
                      bottomRightContent={
                        <Text
                          marginLeft={"auto"}
                          fontWeight={400}
                          fontSize={"14px"}
                          color={"dim-gray"}
                        >
                          {formartNumber(hubDescription?.length) || 0}/1,000
                        </Text>
                      }
                      css={{
                        "&::-webkit-scrollbar": {
                          width: "8px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          background: "#e6e7eb",
                          borderRadius: "30px",
                        },
                      }}
                      maxLength={1000}
                    />
                  </Box>
                  <Box w={"100%"}>
                    <Text
                      color="dim-gray"
                      mb="4px"
                      fontSize={"md"}
                      fontWeight={400}
                    >
                      Website <i style={{ color: "#808080" }}>(Optional)</i>
                    </Text>
                    <InputHookForm
                      p={"0px 26px 8px 0px"}
                      border={"none"}
                      borderColor={"unset"}
                      borderRadius={"none !important"}
                      boxShadow={"none !important"}
                      borderBottom={"1px solid"}
                      borderBottomColor={"mid-gray"}
                      backgroundColor={"white"}
                      name="website"
                    />
                  </Box>
                  <Box w={"100%"}>
                    <Text
                      color="dim-gray"
                      mb="4px"
                      fontSize={"md"}
                      fontWeight={400}
                    >
                      Email <i style={{ color: "#808080" }}>(Optional)</i>
                    </Text>
                    <InputHookForm
                      p={"0px 26px 8px 0px"}
                      border={"none"}
                      borderColor={"unset"}
                      borderRadius={"none !important"}
                      boxShadow={"none !important"}
                      borderBottom={"1px solid"}
                      borderBottomColor={"mid-gray"}
                      backgroundColor={"white"}
                      name="email"
                    />
                  </Box>
                </VStack>
              </Box>
            </ModalBody>
            <ModalFooter p={0} gap="8px">
              <Button
                variant={"none"}
                border={"none"}
                shadow={"none"}
                px="20px"
                h={"36px"}
                fontSize={"sm"}
                fontWeight={900}
                onClick={() => {
                  onClose();
                  methods.reset({
                    ...twygsHubInfo,
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                isLoading={isLoading}
                variant={"dark-green"}
                px="20px"
                h={"36px"}
                type="submit"
                fontSize={"sm"}
              >
                Update
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </FormProvider>
    </Modal>
  );
};
