import {
  ALLOWED_FILE_TYPES,
  FILE_TYPES,
  IMAGE_TYPE,
  MAX_FILE_RESIZE_IMAGE,
  MAX_FILE_SIZE_4000,
  MAX_FILE_SIZE_50,
  MAX_VIDEO_DURATION_2H,
} from "../../../constants/image.constant";
import { ASSET_TYPE } from "../../../constants/value.constant";
import axios from "axios";
import Resizer from "react-image-file-resizer";
import { v4 as uuidv4 } from "uuid";

const ALLOWED_FILE_IMAGE_TYPES_UPLOAD = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/webp",
];

// validate file
// validate file
export const handleValidateImage = (
  files,
  limit = 10,
  maxSize,
  numberOfImage
) => {
  let newArr = [];
  let errList = {
    file: [],
    limit: false,
  };
  const totalFiles = numberOfImage + files.length;
  files.forEach((item) => {
    if (ALLOWED_FILE_TYPES.includes(item.type) && item.size < maxSize) {
      newArr.push({
        file: item,
        fileName: encodeURIComponent(item.name?.toLocaleLowerCase()),
        isUpdated: true,
      });
    }
    if (!ALLOWED_FILE_TYPES.includes(item.type)) {
      errList.file.push({
        fileName: item.name,
        errMess: "FORMAT",
      });
    } else if (item.size > maxSize) {
      errList.file.push({
        fileName: item.name,
        errMess: "SIZE",
      });
    }
  });

  if (totalFiles > limit) {
    errList.limit = true;
    const allowedFiles = limit - numberOfImage;
    newArr = newArr.filter((item, index) => index < allowedFiles);
  }

  return {
    arr: newArr,
    errList,
  };
};

// validate pdf
export const handleValidatePdf = (file) => {
  if (!FILE_TYPES.twygsPdf.split(",").includes(file.type)) {
    return {
      check: false,
      errorList: [{ fileName: file.name, errMess: "FORMAT" }],
    };
  } else if (file.size > MAX_FILE_SIZE_50)
    return {
      check: false,
      errorList: [{ fileName: file.name, errMess: "SIZE" }],
    };
  else return { check: true, errorList: [] };
};

export const getThumbnailVideo = (video) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    throw new Error("Canvas context is not available");
  }

  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  return new Promise((resolve, reject) => {
    // @ts-ignore
    canvas.toBlob((file) => {
      if (!file) {
        reject("Failed to create thumbnail blob");
        return;
      }
      const res = {
        file: file,
        thumbnailSrc: URL.createObjectURL(file),
      };
      resolve(res);
    });
  });
};

// get duration
export const getDurationVideo = (src, isGetThumbnail) => {
  const video = document.createElement("video");
  video.preload = "metadata";
  video.src = src;
  video.autoplay = true;
  video.muted = true;
  video.playsInline = true;

  return new Promise((resolve) => {
    video.addEventListener(
      "canplaythrough",
      function () {
        setTimeout(() => {
          video.currentTime = 0.1;
          let thumbnail;
          const isDurationInvalid = video.duration / MAX_VIDEO_DURATION_2H > 1;
          const ratio = parseFloat(
            (video.videoWidth / video.videoHeight).toFixed(2)
          );

          const isRatioValid =
            ratio === parseFloat((16 / 9).toFixed(2)) ||
            ratio === parseFloat((9 / 16).toFixed(2)) ||
            ratio === parseFloat((3 / 4).toFixed(2)) ||
            ratio === parseFloat((4 / 3).toFixed(2));
          if (isGetThumbnail)
            video.onseeked = async function () {
              const thumbnailRes = await getThumbnailVideo(video);
              thumbnail = {
                file: thumbnailRes.file,
                src: thumbnailRes.thumbnailSrc,
              };
              resolve({
                isInvalid: isDurationInvalid || !isRatioValid,
                duration: video.duration,
                assetType:
                  ratio === parseFloat((16 / 9).toFixed(2)) ||
                  ratio === parseFloat((4 / 3).toFixed(2))
                    ? ASSET_TYPE.LANDSCAPE
                    : ratio === parseFloat((9 / 16).toFixed(2)) ||
                      ratio === parseFloat((3 / 4).toFixed(2))
                    ? ASSET_TYPE.PORTRAIT
                    : "",
                thumbnail: thumbnail ?? null,
              });
            };
          else
            resolve({
              isInvalid: isDurationInvalid || !isRatioValid,
              duration: video.duration,
              assetType:
                ratio === parseFloat((16 / 9).toFixed(2))
                  ? ASSET_TYPE.LANDSCAPE
                  : ratio === parseFloat((9 / 16).toFixed(2))
                  ? ASSET_TYPE.PORTRAIT
                  : "",
            });
        }, 200);
      },
      { once: true }
    );

    video.load();
  });
};

export const handleValidateVideo = async (files, limit = 1) => {
  let errList = {
    file: [],
    limit: false,
  };
  const arr = files.filter((item) => {
    if (!FILE_TYPES.twygsVideo.includes(item.type)) {
      errList.file.push({
        fileName: item.name,
        errMess: "FORMAT",
      });
    } else if (item.size > MAX_FILE_SIZE_4000) {
      errList.file.push({
        fileName: item.name,
        errMess: "SIZE",
      });
    } else return item;
  });

  let newArr = await Promise.all(
    arr.map(async (item) => {
      const { isInvalid, assetType, duration, thumbnail } =
        await getDurationVideo(URL.createObjectURL(item), true);
      if (isInvalid) {
        if (duration / MAX_VIDEO_DURATION_2H > 1)
          errList.file.push({
            fileName: item.name,
            errMess: "DURATION",
          });
        else
          errList.file.push({
            fileName: item.name,
            errMess: "RATIO",
          });
      } else {
        const obj = {
          asset: {
            file: item,
            fileName: encodeURIComponent(item.name?.toLocaleLowerCase()),
            isUpdated: true,
            src: URL.createObjectURL(item),
            assetType,
            duration,
          },
          thumbnail: thumbnail
            ? {
                ...thumbnail,
                isUpdated: true,
                assetType,
                fileName: handleFileNameExtension(
                  encodeURIComponent(item.name?.toLocaleLowerCase()),
                  IMAGE_TYPE.PNG
                ),
              }
            : null,
        };
        return obj;
      }
    })
  );

  newArr = newArr.filter((item) => item !== null);

  if (newArr?.length > limit) {
    errList.limit = true;
    newArr = newArr.filter((_item, index) => index < limit);
  }
  return {
    arr: newArr,
    errList,
  };
};

// fetch url
export const fetchFile = async (url) => {
  try {
    if (!url) return null;
    const response = await fetch(url, {
      method: "GET", // *GET, POST, PUT, DELETE, etc.
      mode: "cors", // no-cors, *cors, same-origin
      cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        Accept: "text/*, text/html, text/html;level=1, */*",
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: "follow", // manual, *follow, error
      referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    });
    const data = await response.blob();
    return data;
  } catch (e) {
    // console.log(e);
  }
};

// get list file from url
export const fetchFileList = async (arr) => {
  if (arr?.length > 0) {
    let newArr = [];
    await Promise.all(
      arr.map(async (item) => {
        if (item.src) {
          const blobFile = await fetchFile(item.src);
          if (blobFile && blobFile.type && blobFile.type.includes("image")) {
            item.file = blobFile;
            item.src = URL.createObjectURL(blobFile);
          } else {
            item.isErrorFile = true;
          }
          item.rootSrc = item.src;
        }
        newArr.push(item);
      })
    );
    return newArr;
  }
};

export const resizeImage = (imgFile, option) => {
  return new Promise((resolve, reject) => {
    Resizer.imageFileResizer(
      imgFile,
      option?.maxWidth || 1200,
      option?.maxHeight || 1200,
      option?.compressFormat || "WEBP", // "JPEG" | "PNG " | "WEBP"
      option?.quality || 100,
      option?.rotation || 0,
      (res) => {
        if (res) {
          const output = {
            file: res,
            resizeImgSrc: URL.createObjectURL(res),
          };
          resolve(output);
        } else {
          reject(res);
        }
      },
      option?.outputType || "blob", // "base64" | "blob" | "file"
      option?.minWidth || 200,
      option?.minHeight || 200
    );
  });
};

// handle file name extension
export const handleFileNameExtension = (fileName, type) => {
  if (!fileName || !type) return null;
  const name = String(fileName).substring(0, String(fileName).lastIndexOf("."));
  let extension = "webp";
  if (ALLOWED_FILE_IMAGE_TYPES_UPLOAD.includes(type)) {
    extension = String(type).split("/")[1] || "webp";
  }
  const str = `${name}.${extension}`;
  return str;
};

// handle resize list image
export const handleResizeImg = async (arr) => {
  if (arr?.length > 0) {
    let newArr = [];
    await Promise.all(
      arr?.map(async (item) => {
        if (item.file.type === "image/gif") {
          const src = URL.createObjectURL(item.file);
          item.src = src;
          item.rootSrc = src;
          item.fileName = handleFileNameExtension(
            item.fileName,
            item.file.type
          );
          newArr.push({ ...item, id: uuidv4() });
        } else {
          const fileResize = await resizeImage(item.file);
          if (fileResize) {
            item.file = fileResize.file;
          }
          if (
            fileResize?.file?.size &&
            fileResize.file.size > MAX_FILE_RESIZE_IMAGE
          ) {
            let quality = 100;
            let file = fileResize;
            let iterationCount = 0;
            const maxIterations = 10; // Maximum number of iterations to prevent infinite loop
            while (
              file.file.size > MAX_FILE_RESIZE_IMAGE &&
              quality > 10 &&
              iterationCount < maxIterations
            ) {
              const option = {
                compressFormat: "WEBP",
                quality: quality,
              };
              const fileReduceQuality = await resizeImage(file.file, option);
              if (fileReduceQuality) {
                file = fileReduceQuality;
              }
              quality = quality * 0.9; // Reduce quality by 10%
              iterationCount++;
            }
            if (file) {
              item.file = file.file;
            }
          }
          const src = URL.createObjectURL(item.file);
          item.src = src;
          item.rootSrc = src;
          item.fileName = handleFileNameExtension(
            item.fileName,
            item.file.type
          );
          newArr.push({ ...item, id: uuidv4() });
        }
      })
    );
    return newArr;
  }
  return arr;
};

export const addInfoToCrop = (arr) => {
  if (arr?.length > 0) {
    let newArr = [];
    arr.forEach((item) => {
      const obj = {
        ...item,
        assetType: "SQUARE",
        crop: { x: 0, y: 0 },
        zoom: 1,
        croppedAreaPixels: null,
        aspect: 1,
      };
      newArr.push(obj);
    });
    return newArr;
  }
};

// fetch image info
export const getImageInfo = (url) =>
  new Promise((resolve, reject) => {
    const image = new window.Image();
    image.crossOrigin = "Anonymous";
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.src = url;
  });

// calculate for auto crop img
export const calculateCropSize = async (arr) => {
  if (arr?.length > 0) {
    let newArr = [];
    await Promise.all(
      arr.map(async (item) => {
        if (item.file && item.src) {
          const image = await getImageInfo(item.src);
          if (image) {
            if (image.width > image.height * item.aspect) {
              item.croppedAreaPixels = {
                width: image.height * item.aspect,
                height: image.height,
                x: (image.width - image.height * item.aspect) / 2,
                y: 0,
              };
            } else {
              item.croppedAreaPixels = {
                width: image.width,
                height: image.width / item.aspect,
                x: 0,
                y: (image.height - image.width / item.aspect) / 2,
              };
            }
          }
        }
        newArr.push(item);
      })
    );
    return newArr;
  }
};

// crop img
export const createCropFile = async (arr) => {
  if (arr?.length > 0) {
    let newArr = [];
    await Promise.all(
      arr.map(async (item) => {
        if (item.file && item.src) {
          const cropImg = await createCroppedImg(
            item.rootSrc,
            item.croppedAreaPixels
          );
          if (cropImg?.cropImgSrc) {
            item.file = cropImg.file;
            item.src = cropImg.cropImgSrc;
          }
        }
        newArr.push(item);
      })
    );
    return newArr;
  }
};

export const createCroppedImg = async (imageSrc, pixelCrop) => {
  try {
    const rotation = 0;
    const image = await getImageInfo(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    if (!ctx) {
      return null;
    }

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate((rotation * Math.PI) / 180);
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );

    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    );

    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, _reject) => {
      canvas.toBlob((file) => {
        const res = {
          file: file,
          cropImgSrc: URL.createObjectURL(file),
        };
        resolve(res);
      }, "image/webp");
    });
  } catch (e) {
    // console.log(e)
  }
};

export const CapitalizeFirstLetter = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const autoCropImageTwygs = async (arr) => {
  try {
    if (arr?.length > 0) {
      const handleResizeImgArr = await handleResizeImg(arr);
      return handleResizeImgArr;
    }
  } catch (e) {
    console.error(e);
  } finally {
  }
};

export const handleUploadAssetToCloud = async (arr) => {
  try {
    await Promise.allSettled(
      arr.map(async (item) => {
        if (item.uploadUrl && item.file) {
          try {
            const res = await axios.put(
              item.uploadUrl,
              item?.bufferBody ?? item.file,
              {
                headers: {
                  "content-disposition": "inline",
                  "Content-Type": item.file.type,
                  "x-amz-acl": "public-read",
                },
              }
            );

            if (res.status == 200 || res.status == 201) {
              item.isUpdated = false;
            }
          } catch (e) {
            console.log(e);
            return false;
          }
        }
      })
    );
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const cropImageFrame = (video) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) {
    return null;
  }
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      if (file) {
        const res = {
          file: file,
          src: URL.createObjectURL(file),
        };
        resolve(res);
      } else {
        reject(null);
      }
    }, IMAGE_TYPE.WEBP);
  });
};

export const getImageFrame = async (src, data) => {
  const video = document.createElement("video");
  video.preload = "metadata";
  video.crossOrigin = "anonymous";
  video.src = src;
  let current = 0;

  return new Promise(async (resolve, reject) => {
    video.onloadedmetadata = function () {
      if (video.readyState >= 3) {
        video.currentTime = data[current].timestamp;

        video.onseeked = async function () {
          const frame = await cropImageFrame(video);

          if (frame) {
            const option = {
              maxWidth: 200,
              maxHeight: 200,
              minWidth: 1,
              minHeight: 1,
            };
            const resizeFrame = await resizeImage(frame.file, option);
            if (resizeFrame) {
              data[current] = {
                ...data[current],
                src: resizeFrame.resizeImgSrc,
                file: resizeFrame.file,
              };
            } else {
              data[current] = {
                ...data[current],
                src: frame.src,
                file: frame.file,
              };
            }
          }

          if (data.length - 1 > current) {
            current += 1;
            video.currentTime = data[current].timestamp;
          } else {
            resolve(data);
          }
        };
      } else {
        reject(data);
      }
    };
  });
};

export const calculateFrameInfo = (data) => {
  if (data) {
    // Supports viewport width 1280px or less: 50 frames, 1440px: max 75 frames, 1920px or more: max 100 frames
    let maxFrameWidth = 0;

    let frameWidth = 100; // percent of max frame width

    const sideBarWidth = 280; // opened the sidebar

    const padding = 80; // component padding

    const duration = data.duration;

    let heightFrameItem = 100;

    let widthFrameItem = 100;

    let leftSpacing = 0; // left spacing

    // expected length = duration if seconds is not an odd number
    // expected length = duration + 1 if seconds is an odd number
    const lengthListFrameExpected = duration;

    // default max frame limit
    let limit = lengthListFrameExpected > 50 ? 50 : lengthListFrameExpected;

    // default max frame width: window.innerWidth - sideBarWidth - padding
    maxFrameWidth = 1280 - sideBarWidth - padding;

    if (window) {
      // Actual max frame width
      // eslint-disable-next-line no-restricted-globals
      maxFrameWidth = innerWidth - sideBarWidth - padding;

      // set limit image frame cover based on view width
      // eslint-disable-next-line no-restricted-globals
      if (innerWidth >= 1440) {
        limit = lengthListFrameExpected > 75 ? 75 : lengthListFrameExpected;
      }

      // eslint-disable-next-line no-restricted-globals
      if (innerWidth >= 1920) {
        limit = lengthListFrameExpected > 100 ? 100 : lengthListFrameExpected;
      }
    }

    // calculate width frame item
    if (data.assetType === ASSET_TYPE.LANDSCAPE) {
      widthFrameItem = heightFrameItem * (16 / 9);
    } else {
      widthFrameItem = heightFrameItem * (9 / 16);
    }

    // calculate left spacing to display each item
    leftSpacing = 1 / limit;

    // calculate width frame to display each item
    if (maxFrameWidth / limit >= widthFrameItem) {
      frameWidth = (widthFrameItem * limit * 100) / maxFrameWidth;
    }

    const frameArray = Array.from({ length: limit }, (_, index) => {
      const timestamp =
        index === limit - 1 ? duration : Math.ceil((index * duration) / limit);

      const left = leftSpacing * index * 100;

      return {
        timestamp,
        src: null,
        file: null,
        left,
        widthFrameItem,
        heightFrameItem,
      };
    });

    return {
      frameWidth,
      frameArray,
    };
  }
};

export const handleDisplayTimeBySeconds = (value) => {
  if (value >= 0) {
    const seconds = Math.round(value);

    const date = new Date(seconds * 1000);

    const hours = date.getUTCHours().toString().padStart(2, "0");

    const minutes = date.getUTCMinutes().toString().padStart(2, "0");

    const remainingSeconds = date.getUTCSeconds().toString().padStart(2, "0");

    return `${hours}:${minutes}:${remainingSeconds}`;
  }
};
