import FormPdfComponent from "../components/pdf-twygs/form-pdf.conponent";
import { Box } from "@chakra-ui/react";

const PdfTwygsPage = () => {
  return (
    <Box w={"full"} justifyContent="center">
      <FormPdfComponent />
    </Box>
  );
};

export default PdfTwygsPage;
