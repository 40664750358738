import {useCallback, useEffect, useRef} from "react";
import {Input, InputGroup, InputLeftElement, InputRightElement} from "@chakra-ui/react";

import {ReactComponent as SearchIcon} from "../../../../assets/search-icon.svg";
import {ReactComponent as ClearTextIcon} from "../../../../assets/clear-text-icon.svg";

import {debounce} from "lodash";

const InputSearchComponent = (props) => {
  const {
    placeholder,
    setPaginationSearch,
    icon,
    sizeIcon,
    fontSizeCustom,
    placeholderColor,
    isClearText,
    onFocusCustom,
    setLoading,
    ...rest
  } = props;

  const valueRef = useRef("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((nextValue) => {
      setPaginationSearch(nextValue);
      valueRef.current = nextValue;
      setLoading();
    }, 400),
    []
  );

  const onChangeInput = (e) => {
    e.preventDefault();
    debounceSearch(e.target.value);
  };

  useEffect(() => {
    return () => {
      debounceSearch.cancel();
    };
  }, [debounceSearch]);

  return (
    <InputGroup
      height={"42px"}
      bg={"white"}
      borderWidth={"1px"}
      borderColor={"anti-flash-white"}
      rounded={"8px"}
      overflow={"hidden"}
      alignItems={"center"}
      {...rest}
    >
      <InputLeftElement h={"100%"} px={"0.75rem"} w={"38px"}>
        <SearchIcon
            alt=""
            className="min-w-[1rem] min-h-[1rem]"
            style={sizeIcon ? { minWidth: sizeIcon, minHeight: sizeIcon } : {}}
        />
      </InputLeftElement>
      <Input
        fontSize={fontSizeCustom ?? "14px"}
        id="search-term"
        pl={"38px"}
        border={"none"}
        boxShadow="none"
        type="text"
        placeholder={placeholder ?? ""}
        _placeholder={{
          color: placeholderColor ?? "dime-gray"
        }}
        height={"100%"}
        onChange={onChangeInput}
        _focusVisible={{
          border: "none",
          boxShadow: "none"
        }}
        onFocus={onFocusCustom}
        autoComplete="off"
      />
      {valueRef.current.length > 0 && isClearText ? (
        <InputRightElement
          h={"100%"}
          w={"24px"}
          mr={"1rem"}
          cursor={"pointer"}
          _hover={{ opacity: 0.8 }}
          transition={"all 0.15s"}
          onClick={() => {
            valueRef.current = "";
            debounceSearch("");
            setLoading();
          }}
        >
          <ClearTextIcon
              alt=""
              className="min-w-[1.5rem] min-h-[1.5rem]"
          />
        </InputRightElement>
      ) : null}
    </InputGroup>
  );
};

export default InputSearchComponent;
