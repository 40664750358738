import {ReactComponent as ImageAdsIcon} from "../assets/image-ad.svg";
import {ReactComponent as VideoAdsIcon} from '../assets/video-ad.svg';
import {ReactComponent as PdfAdsIcon} from '../assets/pdf-ad.svg';
import {protectedRoutes} from "./routes";

export const OPTION_TWYGS = [
  {
    id: 1,
    icon: ImageAdsIcon,
    title: "Image",
    description: "Craft eye-catching visuals to captivate your audience.",
    url: protectedRoutes.CREATE_TWYGS_IMAGE,
    type: "IMAGE"
  },
  {
    id: 2,
    icon: VideoAdsIcon,
    title: "Video",
    description: "Bring your story to life with dynamic video.",
    url: protectedRoutes.CREATE_TWYGS_VIDEO,
    type: "VIDEO"
  },
  {
    id: 3,
    icon: PdfAdsIcon,
    title: "PDF",
    description: "Create engaging content, like short e-books.",
    url: protectedRoutes.CREATE_TWYGS_PDF,
    type: "PDF"
  }
];
