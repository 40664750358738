import { errorRequired } from "../../../../constants/error-message.constant";
import { boolean, mixed, object, string } from "yup";

export const schema = object()
  .shape({
    twygsName: string()
      .trim()
      .required(errorRequired)
      .max(100, "Twyg name maximum 100 characters"),
    description: string()
      .trim()
      .required(errorRequired)
      .max(2000, "Description maximum 2000 characters"),
    categoryId: mixed(),
    allowOtherRelatedContent: boolean().optional(),
  })
  .required();
