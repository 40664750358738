import React from "react";
import { Box } from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./index.css";

import { Pagination, Navigation } from "swiper";
import ImageComponent from "../Image";

const CarouselComponent = ({ data }) => {
  return (
    <Swiper
      pagination={{
        clickable: true,
      }}
      navigation={true}
      modules={[Pagination, Navigation]}
      className="mySwiper"
    >
      {data?.length > 0 &&
        data.map((item) => (
          <SwiperSlide key={item}>
            <Box
              width="100%"
              height={{ base: "350px", "2xl": "100%" }}
              display="flex"
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
              borderRadius="8px"
            >
              <ImageComponent
                src={item}
                borderRadius="8px"
                width="100%"
                height="100%"
                objectFit="cover"
                position={"fixed"}
                fallback={
                  <Box
                    width="100%"
                    height="100%"
                    backgroundColor="#fff"
                    border="1px solid #EAECF0"
                    borderRadius="8px"
                  />
                }
              />
            </Box>
          </SwiperSlide>
        ))}
    </Swiper>
  );
};

export default CarouselComponent;
