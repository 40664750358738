import axiosInstance from "./axios-instance";

export const fetchListTwyg = async (pagination) => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}explore/twygs?limit=${
      pagination.limit
    }&page=${pagination.page}&filter=${pagination.filter}${
      pagination.search?.length > 0
        ? `&search=${pagination.search}`
        : "" +
          (pagination.categoryId > -1
            ? `&categoryId=${pagination.categoryId}`
            : "") +
          (pagination.contentTypeId > -1
            ? `&contentTypeId=${pagination.contentTypeId}`
            : "")
    }`
  );
  if (result.status === 200) return result.data.data;
};

export const fetchTwygHubByCreatorId = async (username) => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}explore/creators/username/${username}`
  );
  if (result.status === 200) return result.data.data;
};

export const fetchListTwygsByCreatorId = async (username, pagination) => {
  const result = await axiosInstance.get(
    // if categoryId = 0 -> dont pass categoryId to params
    `${process.env.REACT_APP_TWYGS_API_URL}explore/twygs/${username}?limit=${
      pagination.limit
    }&page=${pagination.page}${
      pagination.filter === 0 ? "" : `&categoryId=${pagination.filter}`
    }`
  );
  if (result.status === 200) return result.data.data;
};

export const fetchListTwygBySearch = async (pagination) => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}explore/search?limit=${
      pagination.limit
    }&page=${pagination.page}${
      pagination.sortBy ? `&sortBy=${pagination.sortBy}` : ""
    }${pagination.sortOrder ? `&sortOrder=${pagination.sortOrder}` : ""}${
      pagination?.search?.length > 0
        ? `&search=${encodeURIComponent(pagination.search)}`
        : ""
    }${
      pagination?.categoryId?.length > 0
        ? `&categoryIds=${encodeURIComponent(pagination.categoryId.join(","))}`
        : ""
    }`
  );
  if (result.status === 200) return result.data.data;
};

export const fetchListCategoryTrending = async () => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}explore/twygs/categories/trending`
  );
  if (result.status === 200) return result.data.data;
};

export const fetchListTwygByCategory = async (id) => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}explore/twygs/categories/${id}?limit=6&page=1`
  );
  if (result.status === 200) return result.data.data;
};
