import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import IntroSection from "../intro-section/intro-section.component";
import VerifyOtpModal from "../modal/verify-otp";
import { VERIFY_OTP_MODAL, protectedRoutes } from "../constants";

import { useAtom } from "jotai";
import { disable2FA, sendOtpEmail } from "../../../apis/two-factor";
import { profileAtom } from "../../Settings/atom";

const ChangeTwoFactorAuthentication = ({ profileData }) => {
  const naviagte = useNavigate();
  const [profile, setProfile] = useAtom(profileAtom);

  const [isEnable2FA, setIsEnable2FA] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState({
    isOpen: false,
    title: "",
    desc: "",
    btnText: "",
    onClick: null,
  });
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setIsEnable2FA(profileData?.isEnable2FA);
  }, [profileData?.isEnable2FA]);

  const handleChangeStatus2FA = async (boo) => {
    try {
      setIsLoading(true);
      if (!boo) {
        naviagte(protectedRoutes.TWO_FACTOR_AUTHENTICATION);
      } else {
        if (profileData?.twoFAType === "EMAIL_OTP") {
          const payload = {
            email: profileData.email,
          };
          await sendOtpEmail(payload);
          handleModal({
            isOpen: true,
            onClick: (value) => handleVerify(value),
          });
        } else {
          handleModal({
            isOpen: true,
            desc: VERIFY_OTP_MODAL.desc.app,
            onClick: (value) => handleVerify(value),
          });
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerify = async (value) => {
    try {
      setIsLoading(true);
      const payload = {
        otp: value,
      };
      const data = await disable2FA(payload);
      if (data.success) {
        setProfile({
          ...profile,
          isEnable2FA: false,
          twoFAType: "",
        });
        setIsEnable2FA(false);
        handleModal();
      }
    } catch (e) {
      setErrorMessage(VERIFY_OTP_MODAL.errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModal = (value = {}) => {
    setModal({
      isOpen: value.isOpen || false,
      title: value.title,
      desc: value.desc,
      btnText: value.btnText,
      onClick: value.onClick || null,
    });
    setErrorMessage("");
  };

  const handleCloseModal = () => {
    handleModal();
  };

  return (
    <>
      <div className="flex flex-col md:flex-row justify-between items-center md:items-center gap-4 md:gap-0 mt-[24px] xl:max-w-[80%] max-w-[100%]">
        <div
          className="flex-1 cursor-pointer max-w-[67%]"
          onClick={() => naviagte(protectedRoutes.TWO_FACTOR_AUTHENTICATION)}
        >
          <IntroSection
            title="Two-Factor Authentication (2FA)"
            description="Protect your account with an additional layer of security by enabling Two-Factor Authentication (2FA). With 2FA, you'll need to provide a second verification method along with your password to access your account. This helps safeguard your data and prevents unauthorized access."
          />
        </div>
        <div className="flex items-center gap-x-3 mb-8 md:mb-0">
          <p className="text-lg 2xl:text-xl font-bold text-black">Enable 2FA</p>

          <label class="inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer form-switch h-6 w-12 rounded-full bg-gray-300 checked:bg-[#084F4B] transition-colors duration-200 outline-none"
              checked={isEnable2FA}
              onChange={() => handleChangeStatus2FA(isEnable2FA)}
              disabled={isLoading}
            />
            <div class="outline-none relative w-[50px] h-[28px] bg-gray-200 rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:bg-white after:border after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:bg-[#084F4B]"></div>
          </label>
        </div>
      </div>
      {modal.isOpen && (
        <VerifyOtpModal
          isOpen={modal.isOpen}
          title={modal.title}
          desc={modal.desc}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          onClick={(value) => {
            modal.onClick(value);
          }}
          onClose={handleCloseModal}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default ChangeTwoFactorAuthentication;
