import axiosInstance from "./axios-instance";

export const resignUrl = async (data) => {
  const result = await axiosInstance.post(
    `${process.env.REACT_APP_TWYGS_API_URL}user/gen-presigned-url`,
    data
  );
  if (result.status === 200) {
    return {
      data: result.data.data,
    };
  }
};

export const updateProfile = async (data) => {
  const result = await axiosInstance.post(
    `${process.env.REACT_APP_TWYGS_API_URL}user/update`,
    data
  );
  if (result.status === 200) return result.data.data;
};

export const updatePassword = async (data) => {
  const result = await axiosInstance.post(
    `${process.env.REACT_APP_TWYGS_API_URL}user/update-password`,
    data
  );
  if (result.status === 200) return result.data.data;
};
