import UploadIcon from "../../assets/upload-icon.svg";
import Dropzone from "react-dropzone";

const DropzoneComponent = ({
  title,
  onChange,
  validateFiles,
  desc,
  acceptFileTypes,
  isError,
  isLoading,
}) => {
  return isLoading ? (
    <div className="flex justify-center items-center h-140">
      <div className="animate-spin rounded-full border-4 border-gray-200 border-t-dark-green h-12 w-12"></div>
    </div>
  ) : (
    <Dropzone
      maxFiles={1}
      onDrop={(acceptedFiles) => {
        validateFiles([...acceptedFiles]);
      }}
    >
      {({ getRootProps, getInputProps }) => {
        const inputProps = getInputProps();
        inputProps.multiple = false;
        inputProps.accept = acceptFileTypes;
        return (
          <div
            {...getRootProps()}
            className={`py-[16px] px-[24px] w-full bg-light-200 rounded-12px border border-solid ${
              isError ? "border-other-200" : "border-[#CCCCCC]"
            } cursor-pointer rounded-[12px] flex flex-col items-center gap-[12px]`}
          >
            <img className="w-[40px] h-[40px]" src={UploadIcon} alt="" />
            <div className="flex flex-col items-center">
              <p className="text-dim-gray font-normal text-sm 2xl:text-base">
                <span className="!text-[#00A375] font-black mr-1 ">
                  {title}
                </span>
                or drag and drop
              </p>
              <p className="text-dim-gray font-normal text-xs mt-0.25">
                {desc}
              </p>
            </div>
            <input
              accept={acceptFileTypes}
              {...inputProps}
              className="w-full absolute inset-0 opacity-0 cursor-pointer z-10"
              id="fileInput"
              type="file"
              onChange={onChange}
            />
          </div>
        );
      }}
    </Dropzone>
  );
};

export default DropzoneComponent;
