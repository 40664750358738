import { Fragment, useEffect, useRef, useState } from "react";
import Cookie from "js-cookie";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

import useSearchParams from "../../hooks/router/useSearchParams";
import { fetchTwygsDetail, likeTwygs } from "../../apis/twygs-detail";
import LikeIcon from "../../assets/like-icon.svg";
import LikedIcon from "../../assets/like-solid-icon.svg";
import ShareIcon from "../../assets/share-icon.svg";
import ShareContentIcon from "../../assets/share-content-icon.svg";
import FlagIcon from "../../assets/flag.svg";
import OwnerIcon from "../../assets/owner-logo.svg";
import CommentIcon from "../../assets/comment-icon.svg";
import {
  CATEGORY_LIST,
  classifyDevice,
  convertCountLike,
  convertTimeCreatedAt,
} from "../../constants";
import RequireLoginPopupComponent from "../../components/PopUp/RequireLogin/index";
import ShowContentImageComponent from "../../components/TwygsDetailComponent/ShowContentImage";
import ModalReportComponent from "../../components/TwygsDetailComponent/ModalReportComponent";
import SuccessfulIcon from "../../assets/successful-icon.svg";
import SuccessModal from "../../components/SuccessModal";
import CommentSectionComponent from "../../components/TwygsDetailComponent/CommentComponent";
import ShareContentModal from "../../components/ShareContentModal";
import RightComponent from "../../components/TwygsDetailComponent/RightComponent";
import PopupCreatorDetail from "../../components/TwygsDetailComponent/PopupCreatorDetail";
import ShowContentPdfComponent from "../../components/TwygsDetailComponent/ShowContentPdf";
import useTruncatedElement from "../../hooks/useTruncatedElement ";
import { fetchAd } from "../../apis/ads.api";
import {
  createContainerElmAd,
  getRandomElementFromArray,
  randomElement,
  showAdFullPage,
  showAnchorBottom,
  showDiplayAdRight,
  showDisplayAdTop,
  showInPage,
  trackingAd,
  trackingAdGG,
} from "../../constants/ads";
import ShowContentVideoComponent from "../../components/TwygsDetailComponent/ShowContentVideo";
import { formatTextWithHashtags } from "../../utils/helper.util";
const staging = process.env.REACT_APP_IS_STAGING;

const TwygsDetails = () => {
  const { id, previousId } = useSearchParams().searchParams;
  const [twygs, setTwygs] = useState();
  const [isFetchAd, setIsFetchAd] = useState(false);
  const [modal, setModal] = useState("");
  const [inpage, setInpage] = useState(0);
  const [adRandom, setAdRandom] = useState();

  const readMoreRef = useRef(null);
  const { isTruncated, isReadingMore, toggleIsShowingMore } =
    useTruncatedElement({
      ref: readMoreRef,
      content: twygs?.twygDetails?.description,
    });

  const commentSectionRef = useRef(null);

  const scrollToCommentSection = () => {
    if (commentSectionRef.current) {
      commentSectionRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const token = Cookie.get(`${staging ? "staging" : ""}token`);

  const handleLike = async () => {
    if (token) {
      const updatedLikedState = !twygs.requestingUserLikedTwyg;
      await likeTwygs({
        twygId: twygs.twygDetails.id,
        isLike: updatedLikedState,
      });
      window.dataLayer.push({
        event: "like",
        twyg_id: twygs.twygDetails.id,
        twyg_category: twygs.twygDetails.contentCategory,
      });
      setTwygs((prevState) => ({
        ...prevState,
        requestingUserLikedTwyg: updatedLikedState,
        twygDetails: {
          ...prevState.twygDetails,
          numberOfLike: updatedLikedState
            ? prevState.twygDetails.numberOfLike + 1
            : prevState.twygDetails.numberOfLike - 1,
        },
      }));
    } else if (classifyDevice() === "Mobile") {
      toast.error("Please login to continue!");
    }
  };

  const handleReport = () => {
    if (token) {
      setModal("report-modal");
    } else if (classifyDevice() === "Mobile") {
      toast.error("Please login to continue!");
    }
  };

  const pushToDataLayer = (eventData) => {
    const gtmLoaded = () =>
      typeof window.dataLayer !== "undefined" &&
      window.dataLayer.push !== undefined;

    const retryPush = (retries = 5) => {
      if (gtmLoaded()) {
        window.dataLayer.push(eventData);
      } else if (retries > 0) {
        setTimeout(() => retryPush(retries - 1), 100);
      }
    };

    retryPush();
  };

  useEffect(() => {
    const fetchTwygs = async () => {
      const result = await fetchTwygsDetail(id);
      setTwygs(result);
      pushToDataLayer({
        event: "view_twyg",
        twyg_id: id,
        twyg_category: result.twygDetails.contentCategory,
      });
    };
    if (id) {
      fetchTwygs();
    }
  }, [id]);

  useEffect(() => {
    const fetchDataAd = async () => {
      const dataAd = await fetchAd(twygs.twygDetails.id);
      setIsFetchAd(true);
      let bottom = false;
      let right = false;
      const categoryId = CATEGORY_LIST.find(
        (elm) => elm.name === twygs.twygDetails.contentCategory
      ).id;
      const contentTypeId =
        twygs.twygDetails.contentType === "PDF"
          ? 1
          : twygs.twygDetails.contentType === "Video"
          ? 3
          : 2;

      if (dataAd) {
        const adRandom = getRandomElementFromArray(dataAd.adList);
        if (dataAd.position === "FULL_PAGE") {
          showAdFullPage(
            dataAd,
            adRandom,
            twygs.twygDetails,
            contentTypeId,
            categoryId
          );
        }

        if (dataAd.position === "DISPLAY_SIDE") {
          showDiplayAdRight(
            dataAd,
            adRandom,
            twygs.twygDetails,
            contentTypeId,
            categoryId
          );
        }

        if (dataAd.position === "DISPLAY_BOTTOM") {
          showDisplayAdTop(
            dataAd,
            adRandom,
            twygs.twygDetails,
            contentTypeId,
            categoryId
          );
        }

        if (dataAd.position === "ANCHOR_BOTTOM") {
          showAnchorBottom(
            dataAd,
            adRandom,
            twygs.twygDetails,
            contentTypeId,
            categoryId
          );
          bottom = true;
        }

        if (dataAd.position === "IN_PAGE") {
          showInPage(
            dataAd,
            adRandom,
            twygs.twygDetails,
            contentTypeId,
            categoryId
          );
        }

        if (
          dataAd.position === "IN_PAGE" &&
          twygs?.twygDetails?.contentType === "PDF"
        ) {
          setInpage(1);
          setAdRandom(adRandom);
        } else {
          setInpage(0);
        }

        var video = document.getElementById("twyg-video-ad");
        if (video) {
          video
            .play()
            .then(() => {
              if (dataAd.position === "IN_PAGE") {
                video.addEventListener("ended", function () {
                  const elmAdInfor = document.getElementById(
                    "content-ad-info-container"
                  );
                  const aElm = document.createElement("a");
                  elmAdInfor.style.background = "#fff";
                  aElm.href = adRandom.actionUrl?.includes("https")
                    ? adRandom.actionUrl
                    : "https://" +
                      adRandom.actionUrl +
                      "?a=" +
                      dataAd.campaignId +
                      "&b=" +
                      adRandom.id +
                      "&c=" +
                      categoryId +
                      "&d=" +
                      contentTypeId;
                  aElm.target = "_blank";
                  aElm.appendChild(elmAdInfor);

                  // Ensure the video element is not removed until the event listener has completed its work
                  const twygAdContainer =
                    document.getElementById("twyg-ad-container");
                  if (twygAdContainer) {
                    twygAdContainer.remove();
                  }

                  const elmMainContent =
                    document.getElementById("main-content");
                  elmMainContent.insertBefore(
                    aElm,
                    elmMainContent.childNodes[1]
                  );
                });
              }
            })
            .catch((error) => {
              console.error("Error attempting to play video:", error);
            });
        }
        if (document.getElementById("twyg-ad-container")) {
          // eslint-disable-next-line no-unused-expressions
          trackingAd(
            twygs.twygDetails.id,
            previousId,
            dataAd.campaignId,
            adRandom.id,
            contentTypeId,
            categoryId,
            false,
            false,
            true
          );
          setTimeout(
            () =>
              trackingAd(
                twygs.twygDetails.id,
                previousId,
                dataAd.campaignId,
                adRandom.id,
                contentTypeId,
                categoryId,
                true,
                false,
                false
              ),
            1500
          );
          document
            .getElementById("twyg-cta-btn")
            .addEventListener("click", function (e) {
              e.stopPropagation();
              trackingAd(
                twygs.twygDetails.id,
                previousId,
                dataAd.campaignId,
                adRandom.id,
                contentTypeId,
                categoryId,
                false,
                true,
                false
              );
            });
        }

        const closeBtn = document.getElementById("twyg-btn-close-ad");
        if (closeBtn)
          closeBtn.addEventListener("click", function (e) {
            e.stopPropagation();
            e.preventDefault();
            const elmAdContainer = document.getElementById("twyg-ad-container");
            if (elmAdContainer) {
              // document.body.className = "";
              elmAdContainer.remove();
            }
            const elmVideoContent = document.getElementById("video-twyg");
            if (elmVideoContent) elmVideoContent.play();
          });

        const skipBtn = document.getElementById("twyg-btn-skip-ad");
        if (skipBtn)
          skipBtn.addEventListener("click", function (e) {
            e.stopPropagation();
            e.preventDefault();
            const elmAdContainer = document.getElementById("twyg-ad-container");
            if (elmAdContainer) {
              const elmAdInfor = document.getElementById(
                "content-ad-info-container"
              );
              const aElm = document.createElement("a");
              elmAdInfor.style.background = "#fff";
              aElm.href = adRandom.actionUrl?.includes("https")
                ? adRandom.actionUrl
                : "https://" +
                  adRandom.actionUrl +
                  "?a=" +
                  dataAd.campaignId +
                  "&b=" +
                  adRandom.id +
                  "&c=" +
                  categoryId +
                  "&d=" +
                  contentTypeId;
              aElm.target = "_blank";
              aElm.appendChild(elmAdInfor);
              elmAdContainer.remove();
              const elmMainContent = document.getElementById("main-content");
              elmMainContent.insertBefore(aElm, elmMainContent.childNodes[1]);
            }
            const elmVideoContent = document.getElementById("video-twyg");
            if (elmVideoContent) {
              elmVideoContent.play();
            }
          });

        const elmCollapse = document.getElementById("twyg-btn-collapse-ad");
        if (elmCollapse) {
          elmCollapse.addEventListener("click", function (e) {
            e.stopPropagation();
            e.preventDefault();
            const elmAdContainer = document.getElementById("twyg-ad-container");
            if (
              (right
                ? elmAdContainer.clientWidth
                : elmAdContainer.clientHeight) > 10
            ) {
              const divHidden = document.createElement("div");
              divHidden.id = "collapse-ad";
              divHidden.style.cssText = `position: absolute; width: ${
                right ? "10px" : "100%"
              }; height: ${
                right ? "100%" : "10px"
              }; background: white; z-index: 99; border: 1px solid #F2F3F7; top: 0`;
              if (!right) {
                elmAdContainer.style.maxHeight = "10px";
                document.getElementById("content-ad").style.maxHeight = "10px";
                elmCollapse.style.transform = `rotate(${bottom ? 180 : 0}deg)`;
              } else {
                elmAdContainer.style.maxWidth = "10px";
                document.getElementById("content-ad").style.maxWidth = "10px";
                elmCollapse.style.transform = `rotate(${bottom ? 180 : 90}deg)`;
              }
              elmAdContainer.appendChild(divHidden);
            } else {
              document.getElementById("collapse-ad").remove();
              if (right) {
                document.getElementById("content-ad").style.maxWidth = "unset";
                elmAdContainer.style.maxWidth = "unset";
                elmCollapse.style.transform = `rotate(${bottom ? 0 : -90}deg)`;
              } else {
                document.getElementById("content-ad").style.maxHeight = "unset";
                elmAdContainer.style.maxHeight = "unset";
                elmCollapse.style.transform = `rotate(${bottom ? 0 : 180}deg)`;
              }
            }
          });
        }
      } else if (dataAd === null) {
        const elmAd = createContainerElmAd("DISPLAY_SIDE");
        const typeAds = randomElement(["SQUARE", "VERTICAL"]);
        if (typeAds === "SQUARE") {
          elmAd.innerHTML = `<div id="twygs-gg-ads"><ins class="adsbygoogle"
          style="display:block"
          data-ad-client="ca-pub-2525703558747216"
          data-ad-slot="3757962702"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins></div>`;
        } else
          elmAd.innerHTML = `<div id="twygs-gg-ads"><ins class="adsbygoogle"
          style="display:block"
          data-ad-client="ca-pub-2525703558747216"
          data-ad-slot="3329737237"
          data-ad-format="auto"
          data-full-width-responsive="true"></ins></div>`;
        const elmLeft = document.getElementById("left-content-container");
        elmLeft.style.display = "flex";
        elmLeft.style.width = "408px";
        elmLeft.style.padding = "0px 20px 20px 0";
        elmLeft.insertBefore(elmAd, elmLeft.firstChild);
        (window.adsbygoogle || []).push({});
        const containerAdsGG = document.getElementById("twygs-gg-ads");
        if (containerAdsGG) {
          containerAdsGG.addEventListener(
            "click",
            function (e) {
              if (e.target.closest("a")) {
                const adLink = e.target.closest("a").href;
                window.dataLayer.push({
                  event: "ad_click_adsense",
                  ad_link: adLink,
                });
              }
            },
            true
          );
          setTimeout(
            () =>
              trackingAdGG(
                twygs.twygDetails.id,
                previousId,
                dataAd?.campaignId,
                adRandom?.id,
                contentTypeId,
                categoryId,
                true,
                false,
                false
              ),
            1500
          );
        }
      }
    };

    if (twygs && !document.getElementById("twyg-ad-container") && !isFetchAd)
      fetchDataAd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twygs]);

  return (
    <Fragment>
      <Helmet>
        <title>Twygs Details</title>
        <meta property="og:url" content={window.location.href} />
      </Helmet>

      <div className="flex max-w-[1754px] mx-auto">
        <div className="flex flex-col md:flex-row gap-5 m-auto px-5 w-full pt-3 md:pt-5 pb-5 md:pb-6">
          <div
            className="flex-1"
            style={{
              maxWidth: `calc(100% - ${
                window.innerWidth > 1023 ? "408px" : "0px"
              })`,
            }}
          >
            {twygs ? (
              <div className="flex flex-col gap-5 md:gap-10">
                <div className="bg-[#fafafa] rounded-lg mx-[-20px] my-[-12px] md:m-0">
                  <div id="main-content" className="main-content">
                    <div
                      id="content"
                      className={`py-0 md:px-0 ${
                        twygs.twygDetails.contentType === "Video" ? "!px-0" : ""
                      }`}
                    >
                      {twygs && twygs.twygDetails.contentType === "Video" ? (
                        <ShowContentVideoComponent twygs={twygs.twygDetails} />
                      ) : (
                        ""
                      )}
                      {twygs && twygs.twygDetails.contentType === "Image" ? (
                        <ShowContentImageComponent twygs={twygs.twygDetails} />
                      ) : (
                        ""
                      )}
                      {twygs && twygs.twygDetails.contentType === "PDF" && (
                        <ShowContentPdfComponent
                          twygs={twygs.twygDetails}
                          inpage={inpage === 1}
                          adRandomDefault={adRandom}
                        />
                      )}
                    </div>
                    <div className="w-full flex rounded-5 max-w-full px-3 pb-2 pt-3 md:pt-5 md:p-6">
                      <div className="relative group h-14 cursor-pointer">
                        <a
                          href={`/creator-detail/${encodeURIComponent(
                            twygs?.twygCreator?.username || ""
                          )}`}
                          target="_self"
                        >
                          <img
                            className="max-w-8 min-w-8 max-h-8 min-h-8 md:min-w-14 md:min-h-14 md:max-h-14 md:max-w-14 rounded-full mr-2 md:mr-4 object-cover"
                            src={twygs.twygCreator.imgUrl || OwnerIcon}
                            alt="logo"
                          />
                        </a>
                        {classifyDevice() !== "Mobile" && (
                          <div className="absolute pl-2 left-[54px] top-0 hidden group-hover:!block z-10">
                            <PopupCreatorDetail id={twygs?.twygCreator?.id} />
                          </div>
                        )}
                      </div>
                      <div className="w-full flex flex-col">
                        <p
                          id="twyg-title"
                          className="text-base 2xl:text-xl mb-0.5 md:mb-2 font-extrabold"
                        >
                          {twygs.twygDetails.name}
                        </p>
                        <div>
                          <p
                            ref={readMoreRef}
                            className={`text-sm mb-1 md:mb-3 max-h-15 whitespace-pre-wrap break-all ${
                              !isReadingMore && "line-clamp-3"
                            }`}
                          >
                            {formatTextWithHashtags(
                              twygs.twygDetails.description
                            )}
                          </p>
                          {isTruncated && (
                            <div
                              onClick={toggleIsShowingMore}
                              className="cursor-pointer"
                            >
                              <p className="text-sm font-bold cursor-pointer mt-[-12px] mb-3">
                                {isReadingMore ? "...less" : "...more"}
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="flex items-center flex-wrap gap-3">
                          <a
                            href={`/creator-detail/${encodeURIComponent(
                              twygs.twygDetails.ownerUsername || ""
                            )}`}
                          >
                            <div className="py-2 px-3 flex items-center rounded-[40px] bg-[#e6e7eb] text-xs h-8 order-1 creator-name">
                              By
                              <p className="font-bold ml-1 max-w-[200px] truncate">
                                {twygs.twygCreator.username}
                              </p>
                            </div>
                          </a>
                          <a
                            href={`/?category=${encodeURIComponent(
                              twygs.twygDetails.contentCategory
                            )}`}
                          >
                            <div className="px-3 flex items-center rounded-[40px] bg-[#e6e7eb] text-xs h-8 order-2 creator-name">
                              <p>{twygs.twygDetails.contentCategory}</p>
                            </div>
                          </a>
                          <p
                            id="twyg-created"
                            className="text-[10px] font-bold text-[#808080] min-w-16 order-2"
                          >
                            {convertTimeCreatedAt(twygs.twygDetails.createdAt)}
                          </p>

                          <div className="md:ml-auto relative group py-2 px-3 rounded-[40px] bg-[#e6e7eb] text-[10px] h-8 flex items-center font-semibold order-6 md:order-4">
                            <div
                              className="flex items-center cursor-pointer"
                              onClick={handleLike}
                            >
                              {!twygs.requestingUserLikedTwyg ? (
                                <img
                                  className="w-5 !h-5"
                                  src={LikeIcon}
                                  alt="icon like"
                                />
                              ) : (
                                <img
                                  className="w-5 !h-5"
                                  src={LikedIcon}
                                  alt="icon like"
                                />
                              )}
                              <span className="text-sm ml-1">
                                {convertCountLike(
                                  twygs.twygDetails.numberOfLike
                                )}
                              </span>
                            </div>

                            {!token && classifyDevice() !== "Mobile" ? (
                              <div className="absolute hidden group-hover:!block left-[-12px] top-[-172px] pb-3 transition-display duration-300 z-10">
                                <RequireLoginPopupComponent
                                  title={"Like this Twyg?"}
                                  desc={"Log in share your thoughts."}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            className="divide-x-2 py-2 px-3 rounded-[40px] bg-[#e6e7eb] text-[10px] h-8 flex items-center cursor-pointer font-semibold order-7 md:order-5"
                            onClick={scrollToCommentSection}
                          >
                            <img
                              className="w-5 h-5"
                              src={CommentIcon}
                              alt="icon comment"
                            />
                            <span className="text-sm ml-1">
                              {convertCountLike(
                                twygs.twygDetails.numberOfComments
                              )}
                            </span>
                          </div>
                          <div className="bg-[#e6e7eb] text-[10px] h-8 rounded-full w-8 p-0 flex cursor-pointer order-3 md:order-6">
                            <img
                              className="w-5 !h-5 m-auto"
                              src={ShareIcon}
                              alt="icon share"
                              onClick={() => setModal("share")}
                            />
                          </div>
                          <div
                            onClick={handleReport}
                            className="bg-[#e6e7eb] cursor-pointer text-[10px] !h-8 !rounded-full w-8 p-0 flex relative group order-4 md:order-7"
                          >
                            <img
                              className="w-5 !h-5 m-auto "
                              src={FlagIcon}
                              alt="icon flag"
                            />
                            {!token && classifyDevice() !== "Mobile" ? (
                              <div className="absolute hidden group-hover:!block left-[-12px] top-[-168px] pb-3 transition-display duration-300 z-10">
                                <RequireLoginPopupComponent
                                  title={"Report this Twyg?"}
                                  desc={"Please log in to report this Twyg."}
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                          {modal === "report-modal" && token ? (
                            <ModalReportComponent
                              twygsId={id}
                              setModal={setModal}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div ref={commentSectionRef}>
                  <CommentSectionComponent twygs={twygs} />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="md:min-w-[388px] max-w-[388px]">
            <RightComponent
              idTwyg={twygs?.twygDetails?.id}
              isAllow={twygs?.twygDetails?.allowOtherRelatedContent}
            />
          </div>
        </div>
        {modal === "successful-modal" && (
          <SuccessModal
            setModal={setModal}
            title="Report submitted"
            titleBtn="Done"
            description="Thank you for bringing this to our attention. Your report has been submitted to Twygs staff for review."
            image={SuccessfulIcon}
          />
        )}

        {modal === "share" ? (
          <ShareContentModal
            contentCategory={twygs?.twygDetails?.contentCategory}
            isOpen
            srcImg={ShareContentIcon}
            onClick={() => setModal("")}
            onClose={() => setModal("")}
            imgUrl={twygs?.twygDetails.thumbnail}
          />
        ) : (
          ""
        )}
      </div>
    </Fragment>
  );
};

export default TwygsDetails;
