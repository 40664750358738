import React from "react";
import { Box, Spinner, Text } from "@chakra-ui/react";

import { ReactComponent as NoDataTableIcon } from "../../assets/no-data-table-icon.svg";

const NoDataComponent = (props) => {
  const { isFetching, desc, icon } = props;

  return isFetching ? (
    <Box
      display={"flex"}
      height={"121px"}
      alignItems={"center"}
      justifyContent={"center"}
      w={"100%"}
    >
      <Spinner
        mx={"auto"}
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="dark-green"
        size={{ base: "lg", "2xl": "xl" }}
      />
    </Box>
  ) : (
    <Box
      bg={"light.200"}
      borderRadius={"18px"}
      display={"flex"}
      flexFlow={"column"}
      alignItems={"center"}
      justifyContent={"center"}
      mx={"auto"}
      p={{ base: "24px 32px", "2xl": "32px 40px" }}
      w={"fit-content"}
    >
      {icon ? icon : <NoDataTableIcon alt="" className="mb-[0.5rem]" />}
      <Text color={"dim-gray"} fontSize={{ base: "sm", "2xl": "md" }}>
        {desc ? desc : "No data available"}
      </Text>
    </Box>
  );
};

export default NoDataComponent;
