export const errorRequired = "This field is required";
export const errorEmail = "Please enter a valid email";
export const errorPassword =
  "Password must include 8-64 characters, a number, an uppercase, a lowercase and a special character.";
export const errorName = "Please enter a valid name";
export const errorSize = "The specified ad size cannot be used for a display ad unit.";
export const errorNumber = "Enter a whole number";
export const maxCharacters = (name) => `${name} cannot exceed 255 characters`;
export const errorBorderWidth = "Border size value should be between 0 and 5px.";
export const errorPadding = "Padding value should be between 0 and 50px.";
export const errorWidthPercent = "Width should be between 20 and 100.";
export const errorWidthPercentAnother = "Width should be between 20 and 50.";
export const errorFontSize = "Font size should be between 8 and 24px.";
export const errorMaxLength30 = "Maximum 30 characters";
export const errorMaxLength90 = "Maximum 90 characters";
export const errorInvalidUrl = "URL is invalid";
export const errorTwygsNameExists = "Twyg name already exists";
