import { classifyDevice } from "../constants";
import axiosInstance from "./axios-instance";

export const fetchAd = async (id) => {
  const result = await axiosInstance.post(
    `${process.env.REACT_APP_TWYGS_API_URL}ad/deliver`,
    {
      twygsId: id,
      device: classifyDevice(),
      platform: "MAIN_WEBSITE",
    }
  );
  if (result.status === 200) return result.data.data;
};
