import {forwardRef} from "react";
import {Input as InputChakra} from "@chakra-ui/react";


const Input = forwardRef((props, ref) => {
  const { type, ...rest } = props;

  return <InputChakra type={type ?? "text"} ref={ref} {...rest} h={"auto"} />;
});

Input.displayName = "Input";

export { Input };
