export const SelectTheme = {
  baseStyle: {
    field: {
      background: "#FFFFFF",
      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
      _placeholder: {
        color: "#A3A3A3"
      },
      _focusVisible: {
        borderColor: "#D6BBFB",
        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF"
      }
    }
  },

  sizes: {
    sm: {
      field: {
        height: "55px",
        fontSize: "16px",
        border: "1px solid #D0D5DD",
        borderRadius: "8px",
        padding: "16px 20px"
      },
      icon: {
        color: "#667085"
      }
    }
  },

  defaultProps: {
    size: "sm",
    focusBorderColor: "#D6BBFB"
  }
};
