import {
  errorEmail,
  errorInvalidUrl,
  errorRequired,
} from "../../../../constants/error-message.constant";
import * as yup from "yup";
import { isValidEmail, isValidURL } from "../../../../utils/helper.util";

export const hubInfoSchema = yup.object().shape({
  description: yup.string().required(errorRequired).trim(),
  website: yup
    .string()
    .optional()
    .trim()
    .test("is-valid-url", errorInvalidUrl, isValidURL)
    .nullable(),
  email: yup
    .string()
    .optional()
    .trim()
    .test("is-valid-email", errorEmail, isValidEmail)
    .nullable(),
});
