import { useEffect, useRef, useState } from "react";
import debounce from "lodash/debounce"; // Import debounce from lodash

import PlusIcon from "../../assets/plus-white-icon.svg";
import { fetchListTwygBySearch } from "../../apis/explore.api";
import SearchIcon from "../../assets/search-icon.svg";
import CloseIcon from "../../assets/close-icon.svg";
import OwnerLogo from "../../assets/owner-logo.svg";
import { useClickOutside } from "../../hooks/useOutsideClick";
import { CATEGORY_LIST } from "../../constants";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

const HeaderActionComponent = () => {
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [resultSearch, setResultSearch] = useState({
    twygs: [],
    creators: [],
    hashtags: [],
  });
  const [activeIndex, setActiveIndex] = useState(-1);
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef(null);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const navigate = useNavigate();

  const submitSearch = () => {
    window.open(`/?search=${encodeURIComponent(searchTerm)}`, "_self");
    setIsInputFocused(false);
  };

  const handleChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    debouncedFetchData(term);
  };

  const handleClickOutside = () => {
    setIsInputFocused(false);
  };

  useClickOutside(inputRef, handleClickOutside);

  const fetchTwygs = async (term) => {
    if (!term) return;
    setLoadingSearch(true);
    try {
      const result = await fetchListTwygBySearch({
        page: 1,
        limit: 3,
        search: term,
        categoryId: CATEGORY_LIST.map((elm) => elm.id),
      });
      setResultSearch(result);
      setActiveIndex(-1); // Reset active index after fetching new data
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSearch(false);
    }
  };

  // Debounce the fetchData function
  const debouncedFetchData = useRef(
    debounce((term) => {
      fetchTwygs(term);
    }, 300)
  ).current;

  const handleSelection = () => {
    let selectedItem;
    const totalHashtags = resultSearch.hashtags.length;
    const totalTwygs = resultSearch.twygs.length;
    const totalCreators = resultSearch.creators.length;

    if (searchTerm.startsWith("#")) {
      if (activeIndex < totalHashtags) {
        selectedItem = resultSearch.hashtags[activeIndex];
        window.open(
          `/?search=${encodeURIComponent(selectedItem.name)}`,
          "_self"
        );
      } else if (activeIndex < totalHashtags + totalTwygs) {
        selectedItem = resultSearch.twygs[activeIndex - totalHashtags];
        window.open(`/twygs-detail?id=${selectedItem.id}`, "_self");
      }
    } else {
      if (activeIndex < totalTwygs) {
        selectedItem = resultSearch.twygs[activeIndex];
        window.open(`/twygs-detail?id=${selectedItem.id}`, "_self");
      } else if (activeIndex < totalTwygs + totalCreators) {
        selectedItem = resultSearch.creators[activeIndex - totalTwygs];
        window.open(
          `/creator-detail/${encodeURIComponent(selectedItem.username)}`,
          "_self"
        );
      }
    }

    setIsInputFocused(false);
  };

  const handleKeyDown = (e) => {
    const itemCount = getItemCount();
    if (itemCount === 0) {
      return;
    }

    if (e.key === "ArrowDown") {
      setActiveIndex((prev) => (prev + 1) % itemCount);
    } else if (e.key === "ArrowUp") {
      setActiveIndex((prev) => (prev - 1 + itemCount) % itemCount);
    } else if (e.key === "Enter") {
      handleSelection();
    }
  };

  const getItemCount = () => {
    if (searchTerm.startsWith("#")) {
      return resultSearch.hashtags.length + resultSearch.twygs.length;
    } else {
      return resultSearch.twygs.length + resultSearch.creators.length;
    }
  };

  useEffect(() => {
    return () => {
      debouncedFetchData.cancel(); // Cleanup debounce on component unmount
    };
  }, [debouncedFetchData]);

  return (
    <div className="flex items-center flex-col md:flex-row justify-between px-2 md:px-4 lg:px-8 gap-2">
      {Cookies.get(
        `${process.env.REACT_APP_IS_STAGING ? "staging" : ""}token`
      ) ? (
        <button
          onClick={() => navigate("/studio/create-twyg")}
          className="cursor-pointer bg-custom w-full md:w-auto h-[36px] 2xl:h-[40px] flex items-center justify-center !rounded-[40px]  hover:opacity-80 px-5"
        >
          <p className="whitespace-nowrap text-sm 2xl:text-lg !text-white font-black">
            Create Twygs
          </p>
          <img src={PlusIcon} alt="" className="w-6 h-6 ml-3" />
        </button>
      ) : (
        ""
      )}
      <div
        ref={inputRef}
        className="h-[36px] 2xl:h-[40px] relative w-full xl:mx-auto md:w-[250px] lg:w-[350px] xl:w-[500px] flex items-center px-3 md:px-3 bg-white !rounded-full lg:mt-0 xl:mt-0 group border border-[#A6D38D]"
      >
        <img
          src={SearchIcon}
          alt="Search"
          onClick={submitSearch}
          className="cursor-pointer w-4 h-4 md:w-6 md:h-6"
        />
        <input
          className="flex-1 mx-4 text-sm 2xl:text-base font-semibold border-none outline-none focus:outline-none"
          type="text"
          placeholder="Search Twygs or Creator"
          value={searchTerm}
          onChange={handleChange}
          onFocus={() => setIsInputFocused(true)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && activeIndex <= -1) {
              submitSearch();
            } else {
              handleKeyDown(e);
            }
          }}
        />
        <img
          src={CloseIcon}
          alt="Close"
          className="cursor-pointer"
          onClick={() => {
            setSearchTerm("");
            setActiveIndex(-1);
            setResultSearch({ twygs: [], creators: [], hashtags: [] });
          }}
        />
        <div
          className={`w-full shadow-custom !rounded-xl min-h-20 z-10 top-[46px] md:top-[56px] left-0 bg-white absolute ${
            searchTerm.startsWith("#") ? "max-h-[300px] overflow-y-auto" : ""
          } 
              ${isInputFocused && searchTerm.length > 0 ? "" : "hidden"}
              `}
        >
          {loadingSearch ? (
            <div className="flex">
              <div role="status" className="my-2 py-2 mx-auto">
                <svg
                  aria-hidden="true"
                  className="inline w-4 h-4 md:w-6 md:h-6 text-gray-200 animate-spin dark:text-gray-600 fill-gray-300"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="p-2">
              {resultSearch.hashtags.length > 0 &&
              searchTerm.startsWith("#") ? (
                <ul>
                  {resultSearch.hashtags.map((elm, index) => {
                    return (
                      <li
                        key={elm.id}
                        className={`p-2 hover:bg-[#E6E7EB] ${
                          activeIndex === index ? "bg-[#E6E7EB]" : ""
                        } !rounded-[8px] cursor-pointer`}
                        onClick={() => {
                          setSearchTerm(elm.name);
                          setIsInputFocused(false);
                          window.open(
                            `/?search=${encodeURIComponent(elm.name)}`,
                            "_self"
                          );
                        }}
                      >
                        <div onClick={() => {}}>
                          <p className="text-sm 2xl:text-base font-extrabold truncate">
                            {elm.name}
                          </p>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <></>
              )}
              {resultSearch.twygs.length > 0 ? (
                <div>
                  <div className="flex p-2 items-center justify-between h-10">
                    <p className="text-sm font-extrabold text-dim-gray">
                      Twygs
                    </p>
                    <p
                      className="text-sm font-extrabold text-go-green cursor-pointer"
                      onClick={() => {
                        setIsInputFocused(false);
                        submitSearch();
                      }}
                    >
                      View all
                    </p>
                  </div>
                  <ul>
                    {resultSearch.twygs.map((elm, index) => {
                      const total = searchTerm.startsWith("#")
                        ? index + resultSearch.hashtags.length
                        : index;
                      return (
                        <li
                          key={elm.id}
                          className={`p-2 hover:bg-[#E6E7EB] ${
                            activeIndex === total ? "bg-[#E6E7EB]" : ""
                          } !rounded-[8px] cursor-pointer`}
                          onClick={() => {
                            setIsInputFocused(false);
                          }}
                        >
                          <a
                            href={`/twygs-detail?id=${elm.id}`}
                            className="flex items-center"
                          >
                            <img
                              className="w-6 h-6 object-cover !rounded-[4px] mr-2"
                              src={elm.thumbnail}
                              alt=""
                            />
                            <p className="text-sm 2xl:text-base font-extrabold truncate">
                              {elm.name}
                            </p>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <></>
              )}
              {resultSearch.creators.length > 0 ? (
                <div>
                  <div className="flex p-2 items-center justify-between h-10">
                    <p className="text-sm font-extrabold text-dim-gray">
                      Creators
                    </p>
                    <p
                      className="text-sm font-extrabold text-go-green cursor-pointer"
                      onClick={() => {
                        setIsInputFocused(false);
                        window.open(
                          `/?search=${searchTerm}&tab=creator`,
                          "_self"
                        );
                      }}
                    >
                      View all
                    </p>
                  </div>
                  <ul>
                    {resultSearch.creators.map((elm, index) => {
                      return (
                        <li
                          key={elm.userId}
                          className={`p-2 hover:bg-[#E6E7EB] ${
                            activeIndex === index + resultSearch.twygs.length
                              ? "bg-[#E6E7EB]"
                              : ""
                          } !rounded-[8px] cursor-pointer`}
                          onClick={() => {
                            setIsInputFocused(false);
                          }}
                        >
                          <a
                            href={`/creator-detail/${encodeURIComponent(
                              elm.username
                            )}`}
                            className="flex items-center"
                          >
                            <img
                              className="w-6 h-6 !rounded-[4px] mr-2 object-cover"
                              src={
                                elm.image?.length > 0 ? elm.image : OwnerLogo
                              }
                              alt=""
                            />
                            <p className="text-sm 2xl:text-base font-extrabold truncate">
                              {elm.username
                                ? elm.username
                                : elm.firstName + " " + elm.lastName}
                            </p>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderActionComponent;
