import { Fragment, useState } from "react";
import {
  Box,
  Button,
  FormLabel,
  Input,
  Stack,
  Tag,
  TagLabel,
  Text,
} from "@chakra-ui/react";

import { ERROR_FILE_UPLOAD, RATIO_TYPES_LIST } from "../twygs.constant";

import { ReactComponent as BinRedIcon } from "../../../../assets/bin-red-icon.svg";
import { ReactComponent as CloseEditIcon } from "../../../../assets/close-edit-icon.svg";
import { ReactComponent as FailLoadImage } from "../../../../assets/failed-to-load.svg";
import { ReactComponent as PlusGreenIcon } from "../../../../assets/plus-green-icon.svg";
import { ReactComponent as TickEditIcon } from "../../../../assets/tick-edit-icon.svg";

import ImageComponent from "../image.component";
import Cropper from "react-easy-crop";
import {
  FILE_TYPES,
  MAX_FILE_SIZE_10,
} from "../../../../constants/image.constant";
import {
  autoCropImageTwygs,
  createCroppedImg,
  handleValidateImage,
} from "../twygs.helper";
import ConfirmModal from "../../../Modal/ConfirmModal";
import SuccessfullyModal from "../../../Modal/SuccessModal";
import { TopBar } from "../../../TopBar";

const EditImageComponent = (props) => {
  const {
    imageSelected,
    setImageSelected,
    setEditImage,
    setImageList,
    imageList,
  } = props;

  const [newImageSelected, setNewImageSelected] = useState(imageSelected);
  const [uploadMore, setUploadMore] = useState([]);
  const [modal, setModal] = useState("");
  const [imageEditing, setImageEditing] = useState(
    newImageSelected?.[0] ?? null
  );
  const [typeCrop, setTypeCrop] = useState(null);
  const [removeIndex, setRemoveIndex] = useState(-1);
  const [titleErr, setTitleErr] = useState();

  const handleUploadImage = async (value) => {
    const newFiles = [...value.target.files];
    const limit =
      10 - imageList.length - newImageSelected.length + imageSelected.length;
    const { arr, errList } = handleValidateImage(
      newFiles,
      limit,
      MAX_FILE_SIZE_10,
      0
    );

    setUploadMore(arr);
    if (errList.file.length > 0) {
      setTitleErr(errList.file);
      setModal("confirm-err");
    } else if (errList.limit) {
      setModal("success-limit");
    } else {
      const newArr = await autoCropImageTwygs(arr);
      const newListAfterUpload = [...newImageSelected, ...newArr];
      setImageEditing(newListAfterUpload[0]);
      setNewImageSelected(newListAfterUpload);
    }
  };

  const handleContinues = async () => {
    if (uploadMore.length > 0) {
      const newArr = await autoCropImageTwygs(uploadMore);
      setNewImageSelected([...newImageSelected, ...newArr]);
      setImageEditing([...newImageSelected, ...newArr][0]);
      setModal("");
    }
  };

  const handleCancel = () => {
    setUploadMore([]);
    setModal("");
  };

  const handleRemoveImage = () => {
    const newList = [...newImageSelected];
    const check =
      removeIndex === newList.findIndex((elm) => elm.id === imageEditing.id) ||
      newList.length === 0;
    newList.splice(removeIndex, 1);

    if (check) {
      setImageEditing(newList?.[0] ?? null);
      setTypeCrop(null);
    }
    setNewImageSelected(newList);
    setModal("");
  };

  const handleCropImage = async () => {
    try {
      const cropInfo = {
        rootSrc: typeCrop.rootSrc,
        croppedAreaPixels: typeCrop.croppedAreaPixels,
        assetType: typeCrop.assetType,
        aspect: typeCrop.aspect,
      };
      if (cropInfo.rootSrc && cropInfo.croppedAreaPixels) {
        const cropImg = await createCroppedImg(
          cropInfo.rootSrc,
          cropInfo.croppedAreaPixels
        );
        if (cropImg?.cropImgSrc) {
          const newList = [...newImageSelected];
          const index = newList.findIndex((elm) => elm.id === typeCrop.id);
          newList[index] = {
            ...newList[index],
            file: cropImg.file,
            src: cropImg.cropImgSrc,
            assetType: cropInfo.assetType,
            aspect: cropInfo.aspect,
            isUpdated: true,
          };
          setTypeCrop(null);
          setImageEditing(newList[index]);
          setNewImageSelected(newList);
        }
      }
    } catch (e) {
      /* console.log(e); */
    }
  };

  return (
    <Fragment>
      <TopBar />
      <Box
        display={"flex"}
        alignItems={"center"}
        py={"1.25rem"}
        px={{ base: "1rem", "2xl": "1.5rem" }}
        mb={{ base: "1.25rem", "2xl": "2.5rem" }}
        width={{ base: "100%", md: "auto" }}
        justifyContent={{ base: "space-between", md: "unset" }}
        flexWrap={"wrap"}
      >
        <Text fontSize={{ base: "xl", "2xl": "2xl" }} fontWeight={"800"}>
          Edit Image
        </Text>
        <Button
          variant={"dark-green-solid"}
          h={"2.25rem"}
          py={{ base: "8px", md: "0px" }}
          px={{ base: "14px", md: "0.875rem" }}
          border={0}
          shadow={"none"}
          bg={"unset"}
          mr={{ base: "0px", md: "8px" }}
          fontSize={"0.875rem"}
          color={"jet-black"}
          ml={{ base: "unset", md: "auto" }}
          onClick={() => setModal("discard-change")}
        >
          Discard changes
        </Button>
        <Button
          variant={"dark-green"}
          h={"2.25rem"}
          px={{ base: "20px", md: "1.25rem" }}
          py={{ base: "20px", md: "0rem" }}
          fontSize={"0.875rem"}
          onClick={() => {
            let newList = [
              ...imageList.filter((elm) => {
                return (
                  imageSelected.findIndex((image) => image.id === elm.id) === -1
                );
              }),
            ];
            newList = newList.map((elm) => {
              const getElm = newImageSelected.find((img) => img.id === elm.id);
              if (getElm) return getElm;
              return elm;
            });
            const newItems = [
              ...newImageSelected.filter((elm) => {
                return newList.findIndex((img) => img.id === elm.id) === -1;
              }),
            ];
            setImageList([...newList, ...newItems]);
            setImageSelected([]);
            setEditImage(false);
          }}
        >
          Save
        </Button>
      </Box>
      <Stack
        spacing={{ base: "1rem", "2xl": "1.5rem" }}
        justifyContent={{ base: "unset", md: "center" }}
        alignItems={{ base: "unset", md: "center" }}
      >
        {imageEditing && (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            columnGap={"0.5rem"}
            rowGap={"0.5rem"}
            transition={"width 0.3s ease"}
            flexWrap={"wrap"}
          >
            <Text fontSize={"md"} fontWeight={"800"}>
              Crop:
            </Text>
            {RATIO_TYPES_LIST.map((item, index) => {
              return (
                <Tag
                  key={index}
                  padding={"0.75rem 1.25rem 0.75rem 0.75rem"}
                  borderWidth={"2px"}
                  borderColor={"twygs-green"}
                  background={
                    typeCrop?.assetType === item.typeValue
                      ? "light-green"
                      : "#fff"
                  }
                  borderRadius={"38px"}
                  onClick={() => {
                    setTypeCrop({
                      ...imageEditing,
                      assetType: item.typeValue,
                      aspect: item.ratioValue,
                      crop: { x: 0, y: 0 },
                      zoom: 1,
                      croppedAreaPixels: null,
                      rootSrc: imageEditing?.rootSrc || imageEditing?.src,
                    });
                  }}
                  cursor={"pointer"}
                >
                  <item.icon className="mr-[10px]" alt="" />
                  <TagLabel
                    textTransform={"capitalize"}
                    color={"dark-green"}
                    fontSize={"0.75rem"}
                    fontWeight={900}
                  >
                    {item.ratio} {item.type}
                  </TagLabel>
                </Tag>
              );
            })}
            {typeCrop && (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={"0.5rem"}
                width={"auto"}
              >
                <Box
                  p={"0.5rem"}
                  bg={"light-green"}
                  rounded={"0.5rem"}
                  cursor={typeCrop ? "pointer" : "default"}
                  transition={"all 0.2s ease"}
                  _hover={{ opacity: "0.7" }}
                  onClick={handleCropImage}
                >
                  <TickEditIcon alt="Crop" title="Crop image" />
                </Box>
                <Box
                  p={"0.5rem"}
                  bg={"light-green"}
                  rounded={"0.5rem"}
                  cursor={typeCrop ? "pointer" : "default"}
                  transition={"all 0.2s ease"}
                  _hover={{ opacity: "0.7" }}
                  onClick={() => {
                    if (typeCrop) setTypeCrop(null);
                  }}
                >
                  <CloseEditIcon alt="Cancle" title="Cancle" />
                </Box>
              </Box>
            )}
          </Box>
        )}
        {!imageEditing ? (
          <Box
            w={{ base: "auto", md: "45rem" }}
            height={{ base: "22.5rem", md: "18.5rem", "2xl": "22.5rem" }}
            rounded={"0.5rem"}
            border={"1px solid"}
            borderColor={"mid-gray"}
          />
        ) : !typeCrop ? (
          <ImageComponent
            margin={"auto"}
            height={{ base: "22.5rem", md: "18.5rem", "2xl": "22.5rem" }}
            borderRadius="0.75rem"
            src={imageEditing.src}
            fallback={
              <Box
                width={"full"}
                height={{ base: "22.5rem", md: "18.5rem", "2xl": "22.5rem" }}
                backgroundColor={"#fff"}
                border={"1px solid #EAECF0"}
                borderRadius="0.75rem"
              />
            }
          />
        ) : (
          <Box
            position={"relative"}
            w={{ base: "auto", md: "45rem" }}
            height={{ base: "22.5rem", md: "18.5rem", "2xl": "22.5rem" }}
            rounded={"0.5rem"}
            overflow={"hidden"}
          >
            <Cropper
              image={typeCrop.rootSrc || typeCrop.src}
              crop={typeCrop.crop}
              zoom={typeCrop.zoom}
              zoomSpeed={0.05}
              aspect={typeCrop.aspect}
              onCropChange={(crop) => setTypeCrop({ ...typeCrop, crop: crop })}
              onZoomChange={(zoom) => setTypeCrop({ ...typeCrop, zoom: zoom })}
              onCropComplete={(croppedArea, croppedAreaPixels) =>
                setTypeCrop({
                  ...typeCrop,
                  croppedAreaPixels: croppedAreaPixels,
                })
              }
            />
          </Box>
        )}
        <Box
          pb={"4px"}
          display={"flex"}
          columnGap={"8px"}
          justifyContent={"center"}
          width={"auto"}
          flexWrap={"wrap"}
        >
          {newImageSelected.length > 0 &&
            newImageSelected?.map((item, index) => {
              return (
                <Box
                  key={item.id}
                  position={"relative"}
                  onClick={() => {
                    setImageEditing(item);
                    setTypeCrop(null);
                  }}
                  cursor={"pointer"}
                >
                  <Fragment>
                    <ImageComponent
                      width={"3.5rem"}
                      height={"3.5rem"}
                      borderRadius="12px"
                      border={"2px solid #fff"}
                      borderColor={
                        imageEditing?.id === item.id ? "twygs-green" : ""
                      }
                      src={item.src}
                      fallback={
                        <Box
                          width={"full"}
                          height={"3.5rem"}
                          backgroundColor={"#fff"}
                          border={"1px solid #EAECF0"}
                          borderRadius="12px"
                        />
                      }
                    />
                  </Fragment>
                  <BinRedIcon
                    className="absolute top-[4px] right-[4px] w-[16px] cursor-pointer"
                    alt=""
                    onClick={(e) => {
                      e.stopPropagation();
                      setRemoveIndex(index);
                      setModal("confirm-remove");
                    }}
                    zIndex={2}
                  />
                </Box>
              );
            })}
          {10 -
            imageList?.length -
            newImageSelected.length +
            imageSelected.length >
            0 && (
            <FormLabel htmlFor="avatar">
              <Box className="p-3.5 w-14 border-2 custom-border-dark-green  rounded-lg cursor-pointer">
                <PlusGreenIcon alt="" />
              </Box>
              <Input
                type="file"
                multiple
                accept={FILE_TYPES.twygsImage}
                htmlSize={4}
                width="auto"
                id="avatar"
                hidden
                onChange={handleUploadImage}
              />
            </FormLabel>
          )}
        </Box>
      </Stack>
      {modal === "confirm-err" ? (
        <ConfirmModal
          isOpen
          Icon={FailLoadImage}
          title={titleErr ?? ERROR_FILE_UPLOAD.SOMETHING_WRONG.TITLE}
          desc={ERROR_FILE_UPLOAD.SOMETHING_WRONG.DESC}
          titleBtn={"Next"}
          onClick={handleContinues}
          onClose={handleCancel}
          type="Image"
        />
      ) : null}
      {modal === "success-limit" && (
        <SuccessfullyModal
          isOpen
          Icon={FailLoadImage}
          title={ERROR_FILE_UPLOAD.EXCEED_LIMIT.IMAGE}
          desc={""}
          titleBtn={"Okay"}
          onClick={handleContinues}
        />
      )}
      {modal === "confirm-remove" && (
        <ConfirmModal
          isOpen
          Icon={FailLoadImage}
          title={"Are you sure you want to remove this file?"}
          desc={""}
          titleBtn={"Remove"}
          onClick={handleRemoveImage}
          onClose={() => setModal("")}
        />
      )}
      {modal === "discard-change" && (
        <ConfirmModal
          isOpen
          Icon={FailLoadImage}
          title={"Are you sure you want to discard changes made to this Twyg?"}
          desc={"Warning: Any unsaved changes will be lost."}
          titleBtn={"Discard"}
          onClick={() => {
            setImageSelected([]);
            setEditImage(false);
          }}
          onClose={() => setModal("")}
        />
      )}
    </Fragment>
  );
};

export default EditImageComponent;
