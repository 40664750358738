import { useEffect, useState } from "react";
import GalleryLayout from "../../components/ExploreComponent/GalleryLayout";
import { fetchListTwyg } from "../../apis/explore.api";
import { Loading } from "../../components/Loading";

const TopTwygs = () => {
  const [pagination, setPagination] = useState({
    limit: 11,
    page: 1,
    search: "",
    filter: "top",
    totalPage: 0,
  });
  const [listTwyg, setListTwyg] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await fetchListTwyg(pagination);
        if (pagination.page === 1) setListTwyg(result.twygs);
        else setListTwyg([...listTwyg, ...result.twygs]);
        if (pagination.totalPage !== result.totalPage)
          setPagination({ ...pagination, totalPage: result.totalPage });
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.filter, pagination.search, pagination.page]);

  return (
    <div>
      <p className="font-extrabold text-[20px] md:text-[48px] mb-5 md:mb-[40px] font-montserrat">
        Top Twygs
      </p>
      {loading ? <Loading /> : ""}
      <GalleryLayout
        loading={loading && pagination.page === 1}
        list={listTwyg}
        haveExploreMoreButton
        isHome
      />
    </div>
  );
};

export default TopTwygs;
