export const TextareaTheme = {
  baseStyle: {
    fontWeight: "400",
    border: "1px solid #E6E7EB",
    borderRadius: "10px",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
    lineHeight: "23px",
    padding: "16px 20px",
    _focus: {
      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #DCEED3"
    },

    _invalid: {
      borderColor: "#DE1414",
      _focus: {
        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #FEE4E2"
      }
    },
    _placeholder: {
      color: "#808080"
    },
    _disabled: {
      bg: "#F9FAFB",
      opacity: "1"
    }
  },

  sizes: {
    sm: {
      h: "55px",
      borderRadius: "8px",
      fontSize: "16px"
    }
  },

  defaultProps: {
    size: "sm"
  }
};
