import {Box, Text} from "@chakra-ui/react";

const Field = (props) => {
  const { children, label, description, colorDes } = props;

  return (
    <Box w={"full"}>
      {label && (
        <Text fontSize={"1.25rem"} fontWeight={"500"} mb={"25px"}>
          {label}
        </Text>
      )}
      {description && (
        <Text mb={"12px"} fontSize={"1rem"} color={colorDes ?? "dim-gray"}>
          {description}
        </Text>
      )}
      {children}
    </Box>
  );
};

export { Field };
