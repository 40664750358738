import axiosInstance from "./axios-instance";

export const sendOtpEmail = async (payload) => {
  const result = await axiosInstance.post(
    `${process.env.REACT_APP_TWYGS_API_URL}auth-gateway/two-factor/send-email-code`,
    payload
  );
  if (result.status === 200) return result.data;
};

export const disable2FA = async (otp) => {
  const result = await axiosInstance.post(
    `${process.env.REACT_APP_TWYGS_API_URL}auth-gateway/two-factor/disable`,
    otp
  );
  if (result.status === 200) return result.data;
};

export const verifySetup = async (payload) => {
  const result = await axiosInstance.post(
    `${process.env.REACT_APP_TWYGS_API_URL}auth-gateway/two-factor/verify-setup`,
    payload
  );
  if (result.status === 200) return result.data;
};

export const setup2FA = async (payload) => {
  const result = await axiosInstance.post(
    `${process.env.REACT_APP_TWYGS_API_URL}auth-gateway/two-factor/setup`,
    payload
  );
  if (result.status === 200) return result.data.data;
};

export const getQrCodeUrl = async (payload) => {
  const result = await axiosInstance.post(
    `${process.env.REACT_APP_TWYGS_API_URL}auth-gateway/two-factor/view-2fa-code`,
    payload
  );
  if (result.status === 200) return result.data.data;
};
