import moment from "moment-timezone";

export const dataPerformanceTemplate = [
  {
    title: "Page Views",
    value: "",
    data: [],
    key: "pageViews"
  },
  {
    title: "Page RPM",
    value: "$0.86",
    data: [],
    key: "pageRpm"
  },
  {
    title: "Impressions",
    value: "",
    data: [],
    key: "impressions"
  },
  {
    title: "Clicks",
    value: "",
    data: [],
    key: "clicks"
  },
  {
    title: "CPC",
    value: "$0.55",
    data: [],
    key: "cpc"
  },
  {
    title: "Page CTR",
    value: "3%",
    data: [],
    key: "pageCtr"
  }
];



// code template
export const returnCodeOptionOneSiteTag = (id) => {
  return `<!-- Twygs Ads Conversion Tracking -->
  <script async src="https://www.odeletagmanager.com/tag/js?id=${id}>
  </script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    tag('js', new Date());
    tag('config', ${id});
  </script>
  <!-- End Twygs Ads Conversion Tracking -->`;
};

export const returnCodeOptionTwoSiteTag = (id) => {
  return `tag('config', ${id});`;
};

export const returnCodeOptionOneEventSnippet = (id) => {
  return `<!-- Twygs Ads Conversion Tracking -->
  <script async src="https://www.odeletagmanager.com/tag/js?id=${id}>
  </script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    tag('js', new Date());
    tag('config', ${id});
  </script>
  <!-- End Twygs Ads Conversion Tracking -->`;
};

export const returnCodeOptionTwoEventSnippet = (id) => {
  return `tag('config', ${id});`;
};

export const returnCodeTempalteDisplay = (
  slotId,
  style,
  isSquare
) => {
  return `<script defer src="${process.env.NEXT_PUBLIC_ODELE_SCRIPT_TAG_URL}">
  </script>
<ins class="adsbyodele"
     style="${style}"
     data-ad-slot="${slotId}"
     data-ad-format="display"
     data-ad-image-type="${isSquare ? "square" : "landscape"}"></ins>
<script>
      (adsodele = window.OdeleSDK || []).push({});
</script>`;
};

export const returnCodeTempalteInFeed = (
  slotId,
  style,
  isSquare,
  type
) => {
  return `<script defer src="${process.env.NEXT_PUBLIC_ODELE_SCRIPT_TAG_URL}">
  </script>
<ins class="adsbyodele"
     style="${style}"
     data-ad-slot="${slotId}"
     data-ad-format="in-feed"
     data-ad-type="${type}"
     data-ad-image-type="${isSquare ? "square" : "landscape"}"></ins>
<script>
      (adsodele = window.OdeleSDK || []).push({});
</script>`;
};

export const returnCodeTempalteCarousel = (
  slotId,
  style,
  isSquare
) => {
  return `<script defer src="${process.env.NEXT_PUBLIC_ODELE_SCRIPT_TAG_URL}">
  </script>
<ins class="adsbyodele"
     style="${style}"
     data-ad-slot="${slotId}"
     data-ad-format="carousel"
     data-ad-image-type="${isSquare ? "square" : "landscape"}"></ins>
<script>
      (adsodele = window.OdeleSDK || []).push({});
</script>`;
};

export const returnCodeTempalteSlideshow = (
  slotId,
  style,
  isSquare
) => {
  return `<script defer src="${process.env.NEXT_PUBLIC_ODELE_SCRIPT_TAG_URL}">
  </script>
<ins class="adsbyodele"
     style="${style}"
     data-ad-slot="${slotId}"
     data-ad-format="slideshow"
     data-ad-image-type="${isSquare ? "square" : "landscape"}"></ins>
<script>
      (adsodele = window.OdeleSDK || []).push({});
</script>`;
};

// in-feed ad

export const FONT_FAMILY_LIST = [
  {
    name: "Arial",
    id: "Arial, sans-serif"
  },
  {
    name: "Helvetica",
    id: "Helvetica, sans-serif"
  },
  {
    name: "Verdana",
    id: "Verdana, sans-serif"
  },
  {
    name: "Tahoma",
    id: "Tahoma, sans-serif"
  },
  {
    name: "Georgia",
    id: "Georgia, serif"
  },
  {
    name: "Times New Roman",
    id: "Times New Roman, serif"
  },
  {
    name: "Courier New",
    id: "Courier New, monospace"
  },
  {
    name: "Comic Sans MS",
    id: "Comic Sans MS, cursive"
  },
  {
    name: "Impact",
    id: "Impact, fantasy"
  },
  {
    name: "Lucida Sans Unicode",
    id: "Lucida Sans Unicode, sans-serif"
  }
];

export const COLOR_LIST = [
  "FFFFFF",
  "D9D9D9",
  "727272",
  "2F2F2F",
  "000000",
  "FFF5BF",
  "FDF2FA",
  "DAFFDA",
  "DEFFFB",
  "ECEAFB"
];

export const IMAGE_ASPET_RATIO = [
  { id: true, name: "1:1" },
  { id: false, name: "1.91:1" }
];

// export const TEXT_ALIGN = [
//   {
//     id: 1,
//     icon: TextAlignLeftIcon.src,
//     value: "left"
//   },
//   {
//     id: 2,
//     icon: TextAlignCenterIcon.src,
//     value: "center"
//   },
//   {
//     id: 3,
//     icon: TextAlignRightIcon.src,
//     value: "right"
//   }
// ];



export const LIST_FILTER_BUTTON = [
  { id: 1, title: "Display" },
  { id: 2, title: "In-feed" },
  { id: 3, title: "Carousel" },
  { id: 4, title: "Slideshow" }
];


export const FREQUENCY_DROPDOWN = [
  {
    id: 1,
    name: "Daily"
  },
  {
    id: 2,
    name: "Weekly",
    subData: [
      {
        id: 3,
        name: "Monday"
      },
      {
        id: 4,
        name: "Tuesday"
      },
      {
        id: 5,
        name: "Wednesday"
      },
      {
        id: 6,
        name: "Thursday"
      },
      {
        id: 7,
        name: "Friday"
      },
      {
        id: 8,
        name: "Saturday"
      },
      {
        id: 9,
        name: "Sunday"
      }
    ]
  },
  {
    id: 10,
    name: "Monthly",
    subData: [
      {
        id: 11,
        name: "1st"
      },
      {
        id: 12,
        name: "2nd"
      },
      {
        id: 13,
        name: "3rd"
      },
      {
        id: 14,
        name: "4th"
      },
      {
        id: 15,
        name: "5th"
      },
      {
        id: 16,
        name: "6th"
      },
      {
        id: 17,
        name: "7th"
      },
      {
        id: 18,
        name: "8th"
      },
      {
        id: 19,
        name: "9th"
      },
      {
        id: 20,
        name: "10th"
      },
      {
        id: 21,
        name: "11th"
      },
      {
        id: 22,
        name: "12th"
      },
      {
        id: 23,
        name: "13th"
      },
      {
        id: 24,
        name: "14th"
      },
      {
        id: 25,
        name: "15th"
      },
      {
        id: 26,
        name: "16th"
      },
      {
        id: 27,
        name: "17th"
      },
      {
        id: 28,
        name: "18th"
      },
      {
        id: 29,
        name: "19th"
      },
      {
        id: 30,
        name: "20th"
      },
      {
        id: 31,
        name: "21st"
      },
      {
        id: 32,
        name: "22nd"
      },
      {
        id: 33,
        name: "23rd"
      },
      {
        id: 34,
        name: "24th"
      },
      {
        id: 35,
        name: "25th"
      },
      {
        id: 36,
        name: "26th"
      },
      {
        id: 37,
        name: "27th"
      },
      {
        id: 38,
        name: "28th"
      },
      {
        id: 39,
        name: "29th"
      },
      {
        id: 40,
        name: "30th"
      },
      {
        id: 41,
        name: "Last day"
      }
    ]
  }
];



export const OPTION_CHART_CAMPAIGN_COLOR = ["#DF1674", "#A6D38D", "#9B7BFF", "#FFD166"];

// export const OPTION_STATUS_CAMPAIGN = [
//   { name: "All", value: undefined },
//   { name: "Enabled", value: "ENABLED", icon: EnableStatusIcon.src },
//   { name: "Paused", value: "PAUSED", icon: PauseStatusIcon.src },
//   { name: "Removed", value: "REMOVED", icon: RemoveStatusIcon.src }
// ];

export const TAB_LIST = [
  {
    name: "Campaigns",
    value: ""
  },
  {
    name: "Ads",
    value: ""
  },
  {
    name: "Ad Schedule",
    value: ""
  },
  {
    name: "Categories",
    value: ""
  },
  {
    name: "Content Type",
    value: ""
  },
  {
    name: "Devices",
    value: ""
  },
  {
    name: "Locations",
    value: ""
  }
];

export const AD_SCHEDULE = [
  {
    id: 0,
    title: "All ad schedule",
    value: "ALL_SCHEDULES"
  },
  { id: 1, title: "Day & hour", value: "DAY_HOUR" },
  { id: 2, title: "Day", value: "DAY" },
  { id: 3, title: "Hour", value: "HOUR" }
];

export const LOCATION = [
  {
    title: "Targeted locations",
    value: "TARGETED"
  },
  { title: "Excluded", value: "EXCLUDED" }
];

export const STATUS_COLORS = {
  ENABLED: "#00A375",
  PAUSED: "#FFD166",
  REMOVED: "#DE1414"
};

export const convertTimezoneAmerican = ({
  start,
  end
}) => {
  return {
    startDate: start ? moment(start).tz("America/New_York") : "",
    endDate: end ? moment(end).tz("America/New_York") : ""
  };
};

export const convertFormatDateSingle = (date, format) => {
  return date ? moment(date).format(format) : "";
};



export const OPTION_CHART_HOME_COLOR = ["#DF1674", "#A6D38D", "#9B7BFF", "#FFD166"];





export const METRIC_DROPDOWN_OPERATOR = [
  {
    name: "> Greater than",
    value: ">"
  },
  {
    name: "≥ Greater than or equal to",
    value: ">="
  },
  {
    name: "= Equal to",
    value: "=="
  },
  {
    name: "≠ Not equal",
    value: "!=="
  },
  {
    name: "< Less than",
    value: "<"
  },
  {
    name: "≤ Less than or equal to",
    value: "<="
  }
];

export const defaultFilterWithoutBudget = {
  impressions: {
    name: "Impr.",
    value: "impressions",
    lable: "Impressions",
    operation: undefined,
    valueOption: undefined
  },
  clicks: {
    name: "Clicks",
    value: "clicks",
    lable: "Clicks",
    operation: undefined,
    valueOption: undefined
  },
  cost: {
    name: "Cost",
    value: "cost",
    lable: "Cost",
    operation: undefined,
    valueOption: undefined
  },
  conversions: {
    name: "Conv.",
    value: "conversions",
    lable: "Conversions",
    operation: undefined,
    valueOption: undefined
  },
  conversionValue: {
    name: "Conv. Value",
    value: "conversionValue",
    lable: "Conversion Value",
    operation: undefined,
    valueOption: undefined
  },
  avgCpc: {
    name: "Avg. CPC",
    value: "avgCpc",
    lable: "Average CPC",
    operation: undefined,
    valueOption: undefined
  },
  costPerConversion: {
    name: "Cost/Conv.",
    value: "costPerConversion",
    lable: "Cost Per Conversion",
    operation: undefined,
    valueOption: undefined
  },
  ctr: {
    name: "CTR",
    value: "ctr",
    lable: "Clicks Through Rate",
    operation: undefined,
    valueOption: undefined
  },
  conversionRate: {
    name: "Conv. Rate",
    value: "conversionRate",
    lable: "Conversion Rate",
    operation: undefined,
    valueOption: undefined
  },
  conversionValuePerCost: {
    name: "Conv. Value/Cost",
    value: "conversionValuePerCost",
    lable: "Conversion Value Per Cost",
    operation: undefined,
    valueOption: undefined
  }
};

export const defaultFilter = {
  budget: {
    name: "Budget",
    value: "budget",
    lable: "Daily Budget",
    operation: undefined,
    valueOption: undefined
  },
  impressions: {
    name: "Impr.",
    value: "impressions",
    lable: "Impressions",
    operation: undefined,
    valueOption: undefined
  },
  clicks: {
    name: "Clicks",
    value: "clicks",
    lable: "Clicks",
    operation: undefined,
    valueOption: undefined
  },
  avgCost: {
    name: "Avg. Cost",
    value: "avgCost",
    lable: "Average Cost",
    operation: undefined,
    valueOption: undefined
  },
  cost: {
    name: "Cost",
    value: "cost",
    lable: "Cost",
    operation: undefined,
    valueOption: undefined
  },
  conversions: {
    name: "Conv.",
    value: "conversions",
    lable: "Conversions",
    operation: undefined,
    valueOption: undefined
  },
  costPerConversion: {
    name: "Cost/Conv.",
    value: "costPerConversion",
    lable: "Cost Per Conversion",
    operation: undefined,
    valueOption: undefined
  },
  allConversions: {
    name: "All Conv.",
    value: "allConversions",
    lable: "All Conversion",
    operation: undefined,
    valueOption: undefined
  }
};

// export const GENDER = [
//   {
//     value: "Male",
//     icon: GenderMaleBlackIcon.src
//   },
//   {
//     value: "Female",
//     icon: GenderFemaleBlackIcon.src
//   },
//   {
//     value: "Other",
//     icon: GenderOtherBlackIcon.src
//   }
// ];

export const MONTH_IN_YEAR = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];





export const OPTION_TYPE_AD = [
  { name: "All types", value: undefined },
  { name: "Image", value: 2 },
  { name: "Video", value: 3 },
  { name: "PDF", value: 1 }
];

export const TYPE_AD = {
  IMAGE: "Image",
  VIDEO: "Video",
  PDF: "PDF"
};

export const CONVERT_CSV_TO_BASE64 = "data:text/csv;base64,YmxvY2tTaXRlcwpnb29nbGUubmwK";

export const ASSET_TYPE = {
  LANDSCAPE: "LANDSCAPE",
  PORTRAIT: "PORTRAIT",
  SQUARE: "SQUARE"
};
export const LIST_COLOR_CATEGORY = [
  {
    id: 1,
    name: "Art & Creativity",
    color: "#DF1674",
    bgColor: "#FFE5ED"
  },
  {
    id: 2,
    name: "Business & Finance",
    color: "#FFFFFF",
    bgColor: "#012BC2"
  },
  {
    id: 3,
    name: "DIY & Crafts",
    color: "#E5702E",
    bgColor: "#FFF2E8"
  },
  {
    id: 4,
    name: "Education & Learning",
    color: "#012BC2",
    bgColor: "#DBE0F2"
  },
  {
    id: 5,
    name: "Entertainment",
    color: "#471FC3",
    bgColor: "#F0E2FF"
  },
  {
    id: 6,
    name: "Gaming",
    color: "#FFFFFF",
    bgColor: "#C41212"
  },
  {
    id: 7,
    name: "Fashion & Beauty",
    color: "#7A053C",
    bgColor: "#F6A2C9"
  },
  {
    id: 8,
    name: "Fitness & Wellness",
    color: "#FFFFFF",
    bgColor: "#6D49DF"
  },
  {
    id: 9,
    name: "Food & Cooking",
    color: "#C41212",

    bgColor: "#FCEEEE"
  },
  {
    id: 10,
    name: "Health & Medical",
    color: "#333333",
    bgColor: "#B9DBA7"
  },
  {
    id: 11,
    name: "Lifestyle",
    color: "#FFFFFF",
    bgColor: "#E5702E"
  },
  {
    id: 12,
    name: "Parenting & Family",
    color: "#624D02",
    bgColor: "#FFD166"
  },
  {
    id: 13,
    name: "Pets & Animals",
    color: "#624D02",
    bgColor: "#FFF3C9"
  },
  {
    id: 14,
    name: "Science & Nature",
    color: "#DCEED3",
    bgColor: "#335521"
  },
  {
    id: 15,
    name: "Travel",
    color: "#FFFFFF",
    bgColor: "#016F76"
  },
  {
    id: 16,
    name: "Technology",
    color: "#016F76",
    bgColor: "#C4ECEE"
  }
];

export const VIDEO_PROCESS_STATUS = {
  PROCESSING: "PROCESSING",
  COMPLETE: "COMPLETE",
  FAIL: "FAIL"
};
