export const RadioTheme = {
  baseStyle: {
    control: {
      bg: "#FFFFFF",
      border: "1px solid #084F4B",
      _hover: {
        borderColor: "#a6d38d",
        bg: "#dceed3"
      },
      _disabled: {
        borderColor: "#D0D5DD",
        bg: "transparent",
        opacity: "0.5"
      },
      _checked: {
        bg: "#dceed3",
        borderColor: "#084F4B",
        colorScheme: "red",
        _hover: {
          borderColor: "#084F4B",
          bg: "#dceed3"
        },
        _before: {
          bg: "#084F4B"
        },
        _disabled: {
          borderColor: "#D0D5DD",
          bg: "transparent",
          opacity: "0.5"
        }
      }
    }
  },
  sizes: {
    sm: {
      control: {
        w: "16px",
        h: "16px"
      },
      label: {
        fontSize: "14px"
      }
    },

    md: {
      control: {
        w: "20px",
        h: "20px"
      },
      label: {
        fontSize: "16px"
      }
    }
  },
  defaultProps: {
    size: "md"
  }
};
