import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Text,
  Tooltip,
  useBreakpointValue,
  VStack,
} from "@chakra-ui/react";
import { useFormContext, useWatch } from "react-hook-form";

// import { InputHookForm } from "@Components/general/input-with-hook-form";
// import { MenuHookForm } from "@Components/general/menu-with-hook-form";
import { ReactComponent as ArrowRightIconSolid } from "../../../assets/arrow-right-solid.svg";

import { ReactComponent as FailLoadImage } from "../../../assets/failed-to-load.svg";

import { ReactComponent as HelpGreenIcon } from "../../../assets/help-green-icon.svg";
import { ReactComponent as DesTooltipIcon } from "../../../assets/des-tooltip-icon.svg";
import { ReactComponent as SaveIcon } from "../../../assets/save-icon.svg";

import { protectedRoutes } from "../../../constants/routes";
import ConfirmModal from "../../Modal/ConfirmModal";
import {
  DISCARD_CREATE_TWYG,
  DISCARD_DRAFT_TWYG,
  DISCARD_EDIT_TWYG,
  HASHTAG_SUPPORT,
  TEXT_ALLOWED_RELATED_CONTENT,
  TWYGS_STUDIO_ACTION,
  TWYGS_STUDIO_TYPE_MODAL,
} from "./twygs.constant";
import { errorTwygsNameExists } from "../../../constants/error-message.constant";
import { useNavigate, useSearchParams } from "react-router-dom";
import { formartNumber } from "../../../utils/helper.util";
import { InputHookForm } from "../../Common/Form/input-with-hook-form";
import { useQuery } from "@tanstack/react-query";
import { getContentCategories, getTwygNames } from "../../../apis/studio.api";
import { MenuHookForm } from "../../Common/Form/menu-with-hook-form";
import { TextAreaCustomHashtag } from "../../TextAreaCustom";

const RightContentComponet = ({
  saveDarft,
  onSubmit,
  loadingSaveDraft,
  disable,
  loadingPublish,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // const { data: twygsNameData } = TwygsService.useFetchListTwygsName();
  // const { data } = TwygsService.useGetListContentCategory();
  const { data } = useQuery({
    queryKey: ["content-categories"],
    queryFn: getContentCategories,
  });

  const { data: twygsNameData } = useQuery({
    queryKey: ["twyg-names"],
    queryFn: getTwygNames,
  });

  const { control, getValues, setError, register } = useFormContext();
  const twygsName = useWatch({ name: "twygsName", control });
  const description = useWatch({ name: "description", control });
  const [modal, setModal] = useState("");
  const [listNameExists, setListNameExists] = useState([]);
  const [isTooltipOpen, setIsTooltipOpen] = useState({
    relatedContent: false,
    description: false,
  });
  const tooltipRef = useRef(null);
  const tooltipMaxW = useBreakpointValue({ base: "310px", "2xl": "370px" });

  useEffect(() => {
    if (twygsNameData?.success) {
      setListNameExists(twygsNameData.data.twygsNames);
    }
  }, [twygsNameData]);

  const handleValidateName = (action) => () => {
    if (control._defaultValues.twygsName !== twygsName) {
      if (listNameExists?.length > 0 && listNameExists.includes(twygsName)) {
        setError("twygsName", {
          type: "NameExists",
          message: errorTwygsNameExists,
        });
      } else {
        action();
      }
    } else {
      action();
    }
  };

  const handleShowModal = (value) => () => {
    setModal(value);
  };

  const handleClickModal = (value) => () => {
    navigate(value);
  };

  const handleImageClick = (type) => {
    setIsTooltipOpen({
      ...isTooltipOpen,
      [type]: !isTooltipOpen[type],
    });
  };

  const handleMouseEnter = (type) => {
    setIsTooltipOpen({
      ...isTooltipOpen,
      [type]: true,
    });
  };

  const handleMouseLeave = (type) => {
    setIsTooltipOpen({
      ...isTooltipOpen,
      [type]: false,
    });
  };

  return (
    <Box
      ref={tooltipRef}
      display={"flex"}
      flexFlow={"column"}
      w={{ base: "auto", md: "20.5rem", "2xl": "27.5rem" }}
      h={{
        base: isTooltipOpen ? "575px" : "100%",
        md: "calc(100vh - 4.5rem)",
      }}
      position={{ base: "unset", md: "fixed" }}
      right={0}
      top={"4.5rem"}
      bg={"white"}
      borderLeft={"1px solid"}
      borderColor={"anti-flash-lite"}
      mb={{ base: isTooltipOpen ? "160px" : "210px", md: "0px" }}
    >
      <VStack
        alignItems={"start"}
        p={{ base: "1.25rem", "2xl": "2rem" }}
        spacing={"0.75rem"}
        width={"100%"}
        height={"100%"}
        overflow={"auto"}
      >
        <Text fontSize={{ base: "sm", "2xl": "md" }}>Twyg Name</Text>
        <InputHookForm
          p={{ base: "12px 12px", "2xl": "18px 24px" }}
          name="twygsName"
          placeholder="Name your Twyg"
          _placeholder={{
            color: "dim-gray",
          }}
          bottomContentProps={{
            mt: "12px",
            display: "flex",
          }}
          bottomRightContent={
            <Text
              marginLeft={"auto"}
              fontSize={{ base: "xs", "2xl": "sm" }}
              color={"dim-gray"}
            >
              {twygsName?.length ?? 0}/100
            </Text>
          }
          maxLength={100}
        />
        <Box display={"flex"} alignItems={"center"} gap={"0.5rem"}>
          <Text position="relative" fontSize={{ base: "sm", "2xl": "md" }}>
            Description
          </Text>
          <Tooltip
            rounded="0.75rem"
            label={HASHTAG_SUPPORT}
            placement={"top-start"}
            zIndex={0}
            bg="light.300"
            p={{ base: "10px", "2xl": "1rem" }}
            maxW={tooltipMaxW}
            color="main.100"
            fontSize={{ base: "xs", "2xl": "sm" }}
            left={{ base: "40%", md: "100px" }}
            top="0px"
            isOpen={isTooltipOpen.description}
            width="fit-content"
            minW="auto"
            transform={{
              base: "translatex(-55%) !important",
              md: "none !important",
            }}
          >
            <DesTooltipIcon
              cursor="pointer"
              className="w-[20px] h-[20px] z-0"
              alt=""
              onMouseEnter={() => handleMouseEnter("description")}
              onMouseLeave={() => handleMouseLeave("description")}
              onClick={() => handleImageClick("description")}
            />
          </Tooltip>
        </Box>
        <TextAreaCustomHashtag
          p={{ base: "8px 12px", "2xl": "18px 24px" }}
          placeholder="Write description"
          name="description"
          bottomContentProps={{
            mt: "12px",
            display: "flex",
          }}
          minHeight={"58px"}
          bottomRightContent={
            <Text
              marginLeft={"auto"}
              fontWeight={400}
              fontSize={{ base: "xs", "2xl": "sm" }}
              color={"dim-gray"}
            >
              {formartNumber(description?.length ?? 0)}/2,000
            </Text>
          }
          css={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
          maxLength={2000}
        />
        <Text fontSize={{ base: "sm", "2xl": "md" }}>Twyg Category</Text>
        <MenuHookForm
          p={{ base: "8px 12px", "2xl": "18px 24px" }}
          h={{ base: "46px", "2xl": "58px" }}
          name="categoryId"
          data={data?.data?.data ?? []}
          defaultValue={getValues("categoryId") ?? null}
        />
        <Flex
          gap="12px"
          alignItems={"start"}
          py={{ base: "1rem", "2xl": "2rem" }}
          mb={"2.5rem"}
        >
          <Checkbox {...register("allowOtherRelatedContent")} />
          <Box
            display="flex"
            gap="8px"
            alignItems={{ base: "flex-start", "2xl": "center" }}
          >
            <Text position="relative" fontSize={{ base: "sm", "2xl": "md" }}>
              {TEXT_ALLOWED_RELATED_CONTENT.content}
            </Text>
            <Tooltip
              rounded="0.75rem"
              label={TEXT_ALLOWED_RELATED_CONTENT.textInfo}
              placement="bottom-start"
              bg="light.300"
              p={{ base: "10px", "2xl": "1rem" }}
              maxW={tooltipMaxW}
              color="main.100"
              fontSize={{ base: "xs", "2xl": "sm" }}
              left={{ base: "60%", md: "10px" }}
              top={{ base: "10px", md: "0px" }}
              isOpen={isTooltipOpen.relatedContent}
              width="auto"
              minW="auto"
              transform={{
                base: "translatex(-55%) !important",
                md: "none !important",
              }}
            >
              <HelpGreenIcon
                cursor="pointer"
                className="cursor-pointer t-[5px] z-0"
                alt=""
                onMouseEnter={() => handleMouseEnter("relatedContent")}
                onMouseLeave={() => handleMouseLeave("relatedContent")}
                onClick={() => handleImageClick("relatedContent")}
              />
            </Tooltip>
          </Box>
        </Flex>
      </VStack>
      {searchParams.get("action") === TWYGS_STUDIO_ACTION.EDIT ? (
        <Box
          display={"flex"}
          flexDirection={"row"}
          position={{ base: "absolute", md: "absolute" }}
          alignItems={"center"}
          justifyContent={"end"}
          p={{ base: "0.625rem", "2xl": "1.25rem" }}
          bg={"light.100"}
          bottom={{ base: isTooltipOpen ? "-70px" : "-10px", md: "0px" }}
          ml={{ base: "-2px", md: "0px" }}
          mr={{ base: "-15px", md: "0px" }}
          borderWidth={"1px"}
          borderColor={"light.300"}
          w={{ base: "100%", md: "100%" }}
          gap={{ base: "12px", md: "0px" }}
          boxShadow={
            "0px -1px 2px 0px rgba(16, 24, 40, 0.06), 0px -1px 3px 0px rgba(16, 24, 40, 0.10)"
          }
        >
          <Button
            variant={"dark-green-solid"}
            height={{ base: "38px", "2xl": "42px" }}
            px={{ base: "0.625rem", "2xl": "1.25rem" }}
            onClick={handleShowModal(TWYGS_STUDIO_TYPE_MODAL.DISCARD_EDIT_TWYG)}
            fontSize={{ base: "sm", "2xl": "md" }}
            mr={{ base: "0", md: "0.75rem" }}
          >
            Cancel
          </Button>
          <Button
            variant={"dark-green"}
            pl={"1.25rem"}
            pr={"0.75rem"}
            height={{ base: "38px", "2xl": "42px" }}
            type="submit"
            isDisabled={disable}
            isLoading={loadingPublish}
            fontSize={{ base: "sm", "2xl": "md" }}
            rightIcon={<SaveIcon alt="" />}
            onClick={handleValidateName(onSubmit)}
          >
            Save
          </Button>
        </Box>
      ) : (
        <Box
          display={"flex"}
          flexDirection={{ base: "column", md: "row" }}
          position={{ base: "absolute", md: "absolute" }}
          alignItems={"center"}
          justifyContent={"end"}
          p={{ base: "0.625rem", "2xl": "1.25rem" }}
          bg={"light.100"}
          bottom={{ base: isTooltipOpen ? "-70px" : "-10px", md: "0px" }}
          ml={{ base: "-2px", md: "0px" }}
          mr={{ base: "-15px", md: "0px" }}
          borderWidth={"1px"}
          borderColor={"light.300"}
          w={{ base: "100%", md: "auto" }}
          gap={{ base: "12px", md: "0px" }}
          boxShadow={
            "0px -1px 2px 0px rgba(16, 24, 40, 0.06), 0px -1px 3px 0px rgba(16, 24, 40, 0.10)"
          }
        >
          <Button
            variant={"dark-green-solid"}
            height={{ base: "38px", "2xl": "42px" }}
            px={{ base: "0.625rem", "2xl": "1.25rem" }}
            w={"100%"}
            onClick={handleShowModal(
              searchParams.get("action")
                ? TWYGS_STUDIO_TYPE_MODAL.DISCARD_DRAFT_TWYG
                : TWYGS_STUDIO_TYPE_MODAL.DISCARD_CREATE_TWYG
            )}
            fontSize={{ base: "sm", "2xl": "md" }}
            mr={{ base: "0", md: "0.75rem" }}
          >
            Discard
          </Button>
          <Button
            variant={"dark-green-solid"}
            height={{ base: "38px", "2xl": "42px" }}
            px={{ base: "0.625rem", "2xl": "1.25rem" }}
            w={"100%"}
            onClick={handleValidateName(saveDarft)}
            fontSize={{ base: "sm", "2xl": "md" }}
            mr={{ base: "0", md: "0.75rem" }}
            isLoading={loadingSaveDraft}
            isDisabled={disable || loadingSaveDraft}
          >
            Save as draft
          </Button>
          <Button
            variant={"dark-green"}
            pl={{ base: "0.5rem", "2xl": "1.25rem" }}
            pr={{ base: "0.5rem", "2xl": "0.75rem" }}
            height={{ base: "38px", "2xl": "42px" }}
            w={"100%"}
            type="submit"
            isDisabled={disable}
            isLoading={loadingPublish}
            fontSize={{ base: "sm", "2xl": "md" }}
            rightIcon={<ArrowRightIconSolid alt="" />}
            onClick={handleValidateName(onSubmit)}
            mr={{ base: "0", md: "0.75rem" }}
          >
            Publish
          </Button>
        </Box>
      )}
      {modal === TWYGS_STUDIO_TYPE_MODAL.DISCARD_CREATE_TWYG && (
        <ConfirmModal
          isOpen
          Icon={FailLoadImage}
          title={DISCARD_CREATE_TWYG.title}
          desc={DISCARD_CREATE_TWYG.desc}
          titleBtn={"Discard"}
          onClick={handleClickModal(protectedRoutes.CREATE_TWYGS)}
          onClose={handleShowModal("")}
        />
      )}
      {modal === TWYGS_STUDIO_TYPE_MODAL.DISCARD_DRAFT_TWYG && (
        <ConfirmModal
          isOpen
          Icon={FailLoadImage}
          title={DISCARD_DRAFT_TWYG.title}
          desc={DISCARD_DRAFT_TWYG.desc}
          titleBtn={"Discard"}
          onClick={handleClickModal(protectedRoutes.CREATE_TWYGS)}
          onClose={handleShowModal("")}
        />
      )}
      {modal === TWYGS_STUDIO_TYPE_MODAL.DISCARD_EDIT_TWYG && (
        <ConfirmModal
          isOpen
          Icon={FailLoadImage}
          title={DISCARD_EDIT_TWYG.title}
          desc={DISCARD_EDIT_TWYG.desc}
          titleBtn={"Discard"}
          onClick={handleClickModal(protectedRoutes.MY_TWYGS)}
          onClose={handleShowModal("")}
        />
      )}
    </Box>
  );
};

export default RightContentComponet;
