import React from "react";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { ReactComponent as WarningErrorIcon } from "../../../assets/warning-error-icon.svg";

const ConfirmModal = (props) => {
  const {
    isOpen,
    onClick,
    Icon,
    title,
    desc,
    titleBtn,
    onClose,
    isLoading,
    titleBtnCancel,
    variant,
    type,
  } = props;
  return (
    <>
      <Modal isOpen={isOpen} isCentered onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          borderRadius={"1.5rem"}
          maxWidth={"30rem"}
          p={"3rem 3.28rem"}
          bgColor={"light.200"}
        >
          <ModalHeader fontSize={"4xl"} p={0} margin="auto">
            <Box w={"12.5rem"} h={"12.5rem"} p={"1.5625rem"}>
              <Icon alt={""} className="w-[9.375rem] h-[9.375rem] block" />
            </Box>
          </ModalHeader>
          <ModalBody p={0} mb={"35px"}>
            <Flex flexDirection={"column"} gap={"10px"}>
              {type && (
                <Text
                  fontSize={"32px"}
                  color={"dark-green"}
                  fontWeight={"900"}
                  textAlign={"center"}
                >
                  Error
                </Text>
              )}
              {typeof title === "string" ? (
                <Text
                  fontSize={"2rem"}
                  textAlign={"center"}
                  fontWeight={"900"}
                  color={"dark-green"}
                >
                  {title}
                </Text>
              ) : (
                <Box
                  bg={"#FFFFFF"}
                  p={"10px 8px"}
                  border={"1px solid #F2F3F7"}
                  rounded={"12px"}
                  maxH={"200px"}
                  overflow={"auto"}
                  sx={{
                    "&::-webkit-scrollbar": {
                      width: "6px",
                    },
                    "&::-webkit-scrollbar-track": {
                      width: "6px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#c6ceda",
                      borderRadius: "20px",
                    },
                  }}
                >
                  {title.map((elm, index) => {
                    return (
                      <Box
                        key={index}
                        display={"flex"}
                        alignItems={"center"}
                        gap={"8px"}
                        p={"8px 10px"}
                      >
                        <WarningErrorIcon
                          className="min-w-[16px] min-h-[16px]"
                          alt="warning icon"
                          mr={"8px"}
                        />
                        {type === "Image" ? (
                          <Text
                            fontSize={"14px"}
                            // textAlign={"center"}
                            fontWeight={"800"}
                            color={"dark-green"}
                            wordBreak={"break-word"}
                          >
                            {elm.fileName}
                            <span style={{ color: "#DE1414" }}>
                              {elm.errMess === "FORMAT"
                                ? " the file uploaded is in an unsupported format. We only support PNG, JPG, JPEG, GIF files"
                                : " the maximum file size is 10MB"}
                            </span>
                          </Text>
                        ) : type === "Video" ? (
                          <Text
                            fontSize={"14px"}
                            // textAlign={"center"}
                            fontWeight={"800"}
                            color={"dark-green"}
                          >
                            {elm.fileName}
                            <span style={{ color: "#DE1414" }}>
                              {elm.errMess === "FORMAT"
                                ? " the file uploaded is in an unsupported format. We only support MP4 files"
                                : elm.errMess === "SIZE"
                                ? " the maximum file size is 4GB"
                                : elm.errMess === "DURATION"
                                ? " your video is too long. Maximum duration is 2 hours"
                                : " the video ratio is wrong. We support 16:9 landscape and 9:16 portrait"}
                            </span>
                          </Text>
                        ) : (
                          <Text
                            fontSize={"14px"}
                            // textAlign={"center"}
                            fontWeight={"800"}
                            color={"dark-green"}
                          >
                            {elm.fileName}
                            <span style={{ color: "#DE1414" }}>
                              {elm.errMess === "FORMAT"
                                ? " the file uploaded is in an unsupported format. We only support PDF files"
                                : " the maximum file size is 50MB"}
                            </span>
                          </Text>
                        )}
                      </Box>
                    );
                  })}
                </Box>
              )}

              <Text fontSize={"1.125rem"} textAlign={"center"}>
                {desc}
              </Text>
            </Flex>
          </ModalBody>
          <ModalFooter p="0" gap={"10px"}>
            <Button
              type="submit"
              w="100%"
              variant={"dark-green-solid"}
              onClick={onClose}
              height={"58px"}
              fontSize={"18px"}
              _focusVisible={{
                boxShadow: "none",
              }}
            >
              {titleBtnCancel ?? "Cancel"}
            </Button>
            <Button
              w="100%"
              variant={variant ?? "red"}
              onClick={onClick}
              height={"58px"}
              fontSize={"18px"}
              isLoading={isLoading}
              _focusVisible={{
                boxShadow: "none",
              }}
            >
              {titleBtn}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ConfirmModal;
