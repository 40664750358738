import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Text,
} from "@chakra-ui/react";
import { CHILD_ROUTES, ROUTES } from "../../constants/routes";

import { ReactComponent as ChevronRight } from "../../assets/arrow-right-icon.svg";

import { TWYGS_STUDIO_ACTION } from "../CreateTwygsForm/components/twygs.constant";
import { useLocation, useSearchParams } from "react-router-dom";

export const TopBar = () => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const latestPath = location.pathname.replace(/\/$/, "").split("/").splice(1);

  return (
    <>
      <Box
        w={{ base: "100vw", md: "100%" }}
        display="flex"
        justifyContent="space-between"
        px={{ base: "1rem", "2xl": "2rem" }}
        py={{ base: "10px", "2xl": "0.5rem" }}
        backgroundColor="white"
        boxShadow="0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)"
        zIndex={10}
        borderBottom={"1px solid"}
        borderColor={"light.300"}
      >
        <Box>
          <Text fontWeight={800}>
            {
              ROUTES.find((route) =>
                route.path.includes(location.pathname.split("/")[1])
              )?.text
            }
          </Text>
          <Breadcrumb separator={<ChevronRight width="15px" alt="" />}>
            {latestPath
              .filter((elm) => !parseInt(elm))
              .slice(
                0,
                searchParams.get("action") === TWYGS_STUDIO_ACTION.EDIT
                  ? latestPath.length - 1
                  : latestPath.length
              )
              .map((route) => {
                const childRoute = [...ROUTES, ...CHILD_ROUTES].find((item) =>
                  item.path?.includes(route)
                );
                return (
                  <BreadcrumbItem
                    key={route}
                    fontSize="xs"
                    color={
                      latestPath[latestPath.length - 1] === route &&
                      latestPath.length > 1
                        ? "jet-black"
                        : "dim-gray"
                    }
                    isCurrentPage={
                      latestPath[latestPath.length - 1] === route ||
                      childRoute?.disableBreadcrumbLink
                    }
                  >
                    <BreadcrumbLink
                      fontSize="xs"
                      href={childRoute?.path}
                      fontWeight={
                        latestPath[latestPath.length - 1] === route &&
                        latestPath.length > 1
                          ? "600"
                          : "400"
                      }
                    >
                      {childRoute?.subtext}
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                );
              })}
          </Breadcrumb>
        </Box>
      </Box>
    </>
  );
};
