import { BrowserRouter as Router } from "react-router-dom";

import AppLayout from "./components/AppLayout";
import "./App.css";
import HeaderComponent from "./components/Header";
import { Helmet } from "react-helmet";
import { useEffect, useState } from "react";
import { odeleLightTheme } from "./theme";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

function App() {
  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenMenu = (value) => {
    setOpenMenu(value);
    localStorage.setItem("openNavbar", value);
  };

  useEffect(() => {
    const currentStatus = localStorage.getItem("openNavbar");
    setOpenMenu(
      window.innerWidth < 1537
        ? false
        : currentStatus !== "false" && !!currentStatus
    );
  }, []);

  return (
    <Router>
      <Helmet>
        <link rel="icon" href="/favicon.png" />
        <meta http-equiv="Accept-CH" content="DPR, Viewport-Width, Width" />
        <script>
          {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-${process.env.REACT_APP_GTM_ID}');
      `}
        </script>
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTM_ID}`}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.REACT_APP_GTM_ID}', {
                page_path: window.location.pathname,
              });
            `,
          }}
        />
      </Helmet>
      <HeaderComponent openMenu={openMenu} setOpenMenu={handleOpenMenu} />
      <div
        // style={{height: 'calc(100vh - 80px)'}}
        className={`mt-[56px] md:mt-[68px] 2xl:mt-[80px] ${
          openMenu && window.innerWidth > 1536
            ? window.location.pathname.includes("twygs-detail")
              ? "md:ml-[88px]"
              : "md:ml-[300px]"
            : "md:ml-[88px]"
        }`}
      >
        <QueryClientProvider client={queryClient}>
          <ChakraProvider theme={odeleLightTheme}>
            <AppLayout />
          </ChakraProvider>
        </QueryClientProvider>
      </div>
    </Router>
  );
}

export default App;
