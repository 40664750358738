import { Fragment, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Box, Center, Spinner } from "@chakra-ui/react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchTwygsDetail } from "../../../../apis/studio.api";
import FormImageComponent from "../../../CreateTwygsForm/components/image-twygs/form-image.conponent";
import EditImageComponent from "../../../CreateTwygsForm/components/image-twygs/edit-image.component";

const ImageTwygsPage = () => {
  const [searchParams] = useSearchParams();

  const [imageSelected, setImageSelected] = useState([]);
  const [editImage, setEditImage] = useState(false);
  const [imageList, setImageList] = useState([]);

  const [idTwyg, setIdTwyg] = useState(null);

  const { data, error, isLoading, isError } = useQuery({
    queryKey: ["twygsDetail", idTwyg],
    queryFn: () => fetchTwygsDetail(idTwyg),
    enabled: !!idTwyg,
  });

  useEffect(() => {
    if (searchParams.get("id")) {
      setIdTwyg(searchParams.get("id"));
    }
  }, [searchParams]);

  useEffect(() => {
    if (data) {
      setImageList(
        data.contentUrls.map((elm, index) => {
          return {
            id: uuidv4(),
            src: elm,
            fileName: data.contentKeys[index],
          };
        })
      );
    }
  }, [data]);

  if (isLoading && idTwyg)
    return (
      <Center h={"25rem"}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="dark-green"
          size="xl"
        />
      </Center>
    );

  return (
    <Fragment>
      <Box display={!editImage ? "block" : "none"}>
        <FormImageComponent
          imageSelected={imageSelected}
          setImageSelected={setImageSelected}
          imageList={imageList}
          setImageList={setImageList}
          setEditImage={setEditImage}
          currentData={data}
        />
      </Box>

      {editImage ? (
        <EditImageComponent
          imageSelected={imageSelected}
          setImageSelected={setImageSelected}
          setImageList={setImageList}
          setEditImage={setEditImage}
          imageList={imageList}
        />
      ) : null}
    </Fragment>
  );
};

export default ImageTwygsPage;
