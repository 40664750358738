import { Box, Text } from "@chakra-ui/react";

const IntroSection = (props) => {
  const { title, description, colorDesc } = props;

  return (
    <Box width="100%" display="flex" justifyContent="start">
      <Box>
        <Text fontSize={{ base: "xl", "2xl": "2xl" }} fontWeight={800}>
          {title}
        </Text>
        <Text
          fontSize={{ base: "sm", "2xl": "md" }}
          fontWeight={400}
          color={colorDesc ?? "dim-gray"}
        >
          {description}
        </Text>
      </Box>
    </Box>
  );
};

export default IntroSection;
