import React from "react";
import IntroSection from "../intro-section/intro-section.component";

import CheckIconSuccess from "../../../assets/check-icon-success.svg";

const ChooseAuthentication = ({ option, onOpenView, onSetup }) => {
  return (
    <>
      <IntroSection
        title="Two-Factor Authentication (2FA)"
        description="Choose your preferred method(s) for verification below."
      />
      <div className="mt-5 md:mt-10 flex flex-col gap-4">
        {option.map((item, index) => (
          <div
            key={index}
            className={`p-5 2xl:p-6 border border-gray-100 rounded-xl bg-white shadow-sm flex flex-col md:flex-row justify-between items-start md:items-center gap-6 md:gap-0 ${
              item.active ? "cursor-pointer" : "cursor-default"
            }`}
            onClick={() => {
              if (item.active) {
                onOpenView(item.value);
              }
            }}
          >
            <div className="flex items-center gap-3">
              {item.active ? <img src={CheckIconSuccess} alt="icon" /> : <></>}
              <p className="text-base 2xl:text-lg font-bold text-black">
                {item.title}
              </p>
            </div>
            {!item.active && (
              <button
                className="px-5 py-2.5 w-full md:w-auto bg-[#084F4B] text-sm 2xl:text-base !text-white font-bold rounded-lg"
                onClick={() => onSetup(item.value)}
              >
                Set up now
              </button>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default ChooseAuthentication;
