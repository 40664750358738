import { useEffect, useState } from "react";

import { ReactComponent as ArrowUpIconForAccordion } from "../../../../assets/arrow-upIcon-accordion.svg";
import { ReactComponent as ArrowDownIconForAccordion } from "../../../../assets/arrow-downIcon-accordion.svg";
import { ReactComponent as AvatarIcon } from "../../../../assets/ava-icon.svg";
import { ReactComponent as IncreaseIcon } from "../../../../assets/increase-arrow-icon.svg";
import { ReactComponent as MailIcon } from "../../../../assets/mail-icon.svg";
import { ReactComponent as PencilGreenIcon } from "../../../../assets/pencil-green-icon.svg";
import { ReactComponent as WebsiteIcon } from "../../../../assets/website-icon.svg";

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Link,
  Skeleton,
  SkeletonCircle,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
  Image,
} from "@chakra-ui/react";
import { useAtom } from "jotai";
import { EditModalComponent } from "./component/edit-modal.component";
import { profileAtom } from "../../../Settings/atom";
import { useNavigate } from "react-router-dom";
import { formartNumber, formatNumber } from "../../../../utils/helper.util";
import { protectedRoutes } from "../../../../constants/routes";
import { useQuery } from "@tanstack/react-query";
import { getTwygsHub } from "../../../../apis/studio.api";

export const TwygsInfoComponent = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [profile] = useAtom(profileAtom);
  const navigate = useNavigate();
  const [twygsHubInfo, setTwygsHubInfo] = useState({
    name: "",
    description: "",
    email: "",
    website: "",
  });

  const { data, isFetching, refetch } = useQuery({
    queryKey: ["twygs/get-twygs-hub"],
    queryFn: () => getTwygsHub(),
  });
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (data) {
      setTwygsHubInfo({
        name: data?.name,
        description: data?.description,
        email: data?.email,
        website: data?.website,
      });
    }
  }, [data]);

  return (
    <Stack
      flexDirection={{ base: "column", md: "row" }}
      justifyContent={"space-between"}
      spacing={{ base: "20px", md: "12px", "2xl": "20px" }}
      w={"100%"}
      overflowX={"auto"}
      pb={{ base: "8px", "2xl": "0" }}
      css={{
        "&::-webkit-scrollbar": {
          height: "8px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#e6e7eb",
          borderRadius: "30px",
        },
      }}
    >
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        gap={{ base: "12px", md: "24px" }}
        borderRadius={"12px"}
        bgColor={"light.100"}
        px={{ base: "16px", "2xl": "24px" }}
        py={{ base: "12px", "2xl": "16px" }}
        border="1px solid"
        borderColor="anti-flash-lite"
        boxShadow={"outline"}
        minW={{ base: "100%", md: "400px" }}
        w={{ base: "100%", md: "40%" }}
      >
        <Box display={"flex"} gap="12px" alignItems={"center"}>
          <Link
            href={`${
              process.env.REACT_APP_EXPLORE_URL
            }creator-detail/${encodeURIComponent(profile.username)}`}
            target="_blank"
          >
            {profile?.imgUrl ? (
              <Image
                border="1px solid"
                borderColor={"anti-flash-white"}
                rounded={"50%"}
                src={profile?.imgUrl}
                boxSize={100}
                minW={{ base: "80px", "2xl": "100px" }}
                minH={{ base: "80px", "2xl": "100px" }}
                maxW={{ base: "80px", "2xl": "100px" }}
                maxH={{ base: "80px", "2xl": "100px" }}
                alt="avatar"
                objectFit={"cover"}
              />
            ) : (
              <AvatarIcon
                alt="avatar"
                className="border border-anti-flash-white rounded-full w-24 h-24 min-w-[80px] max-w-[80px] min-h-[80px] max-h-[80px] 2xl:min-w-[100px] 2xl:max-w-[100px] 2xl:min-h-[100px] 2xl:max-h-[100px] object-cover"
              />
            )}
          </Link>

          {isFetching ? (
            <Box py="4px" maxW={"200px"}>
              <Skeleton
                mb="10px"
                startColor="white"
                endColor="light-gray"
                h={{ base: "1.25rem", "2xl": "1.5rem" }}
                w={"13rem"}
              />
              <Skeleton
                startColor="white"
                endColor="light-gray"
                h={{ base: "0.875rem", "2xl": "1rem" }}
                w={"15rem"}
              />
              <Flex gap="8px" alignItems={"center"} mt="8px">
                <SkeletonCircle
                  size="6"
                  startColor="white"
                  endColor="light-gray"
                  h={"1rem"}
                  w={"1rem"}
                />
                <Skeleton
                  startColor="white"
                  endColor="light-gray"
                  h={"0.875rem"}
                  w={"11rem"}
                />
              </Flex>
              <Flex gap="8px" alignItems={"center"}>
                <SkeletonCircle
                  size="6"
                  startColor="white"
                  endColor="light-gray"
                  h={"1rem"}
                  w={"1rem"}
                />
                <Skeleton
                  startColor="white"
                  endColor="light-gray"
                  h={"0.875rem"}
                  w={"11rem"}
                />
              </Flex>
            </Box>
          ) : (
            <Box py="4px">
              <Link
                href={`${
                  process.env.REACT_APP_EXPLORE_URL
                }creator-detail/${encodeURIComponent(profile.username)}`}
                target="_blank"
              >
                <Text
                  mb="8px"
                  fontWeight={800}
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                  wordBreak={"break-word"}
                  sx={{
                    display: "-webkit-inline-box",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                  }}
                  fontSize={{ base: "18px", md: "lg" }}
                >
                  {profile.username}
                </Text>
              </Link>
              <Box display={"flex"} flexDir={"column"} gap="4px">
                <Text
                  title={twygsHubInfo?.description}
                  fontSize={"xs"}
                  overflow={"hidden"}
                  textOverflow={"ellipsis"}
                  wordBreak={"break-word"}
                  sx={{
                    display: "-webkit-inline-box",
                    WebkitLineClamp: "2",
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {twygsHubInfo?.description}
                </Text>
                {twygsHubInfo.email && (
                  <Box display={"flex"} gap="8px" alignItems={"center"}>
                    <MailIcon className="w-[20px] h-[20px]" alt="email" />
                    <Text
                      title={twygsHubInfo?.email}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      wordBreak={"break-word"}
                      sx={{
                        display: "-webkit-inline-box",
                        WebkitLineClamp: "1",
                        WebkitBoxOrient: "vertical",
                      }}
                      fontSize={"xs"}
                    >
                      {twygsHubInfo.email}
                    </Text>
                  </Box>
                )}
                {twygsHubInfo.website && (
                  <Box display={"flex"} gap="8px" alignItems={"center"}>
                    <WebsiteIcon className="w-[20px] h-[20px]" alt="website" />
                    <Text
                      title={twygsHubInfo?.website}
                      overflow={"hidden"}
                      textOverflow={"ellipsis"}
                      wordBreak={"break-word"}
                      sx={{
                        display: "-webkit-inline-box",
                        WebkitLineClamp: "1",
                        WebkitBoxOrient: "vertical",
                      }}
                      fontSize={"xs"}
                    >
                      {twygsHubInfo.website}
                    </Text>
                  </Box>
                )}
              </Box>
            </Box>
          )}
        </Box>

        <PencilGreenIcon
          alt="icon"
          className="self-start w-5 h-5 2xl:w-6 2xl:h-6 cursor-pointer"
          onClick={onOpen}
        />
      </Box>

      {!isMobile && (
        <>
          <Box
            borderRadius={"12px"}
            bgColor={"light.100"}
            py="36px"
            px={{ base: "16px", "2xl": "24px" }}
            border="1px solid"
            borderColor="anti-flash-lite"
            boxShadow={"outline"}
            w={{ base: "100%", md: "20%" }}
            minW={{ base: "100%", md: "200px" }}
          >
            <Text
              mb={{ base: "8px", "2xl": "12px" }}
              fontSize={{ base: "sm", "2xl": "md" }}
            >
              Total Twygs Created
            </Text>
            <Text fontWeight={800} fontSize={{ base: "2xl", "2xl": "4xl" }}>
              {formartNumber(data?.finishedTwygsCount) ?? 0}
            </Text>
          </Box>
          <Box
            borderRadius={"12px"}
            bgColor={"light.100"}
            py="36px"
            px={{ base: "16px", "2xl": "24px" }}
            border="1px solid"
            borderColor="anti-flash-lite"
            boxShadow={"outline"}
            w={{ base: "100%", md: "20%" }}
            minW={{ base: "100%", md: "200px" }}
          >
            <Text
              mb={{ base: "8px", "2xl": "12px" }}
              fontSize={{ base: "sm", "2xl": "md" }}
            >
              Total Likes
            </Text>
            <Text
              title={data?.totalLikes}
              fontWeight={800}
              fontSize={{ base: "2xl", "2xl": "4xl" }}
            >
              {formatNumber(data?.totalLikes) ?? 0}
            </Text>
          </Box>
          <Box
            borderRadius={"12px"}
            bgColor={"light.100"}
            py="36px"
            px={{ base: "16px", "2xl": "24px" }}
            boxShadow={"outline"}
            border="1px solid"
            borderColor="anti-flash-lite"
            w={{ base: "100%", md: "20%" }}
            minW={{ base: "100%", md: "200px" }}
          >
            <Text
              mb={{ base: "8px", "2xl": "12px" }}
              fontSize={{ base: "sm", "2xl": "md" }}
            >
              Pending Draft(s)
            </Text>
            <Box display={"flex"} gap="12px">
              <Text fontWeight={800} fontSize={{ base: "2xl", "2xl": "4xl" }}>
                {formartNumber(data?.draftTwygsCount) ?? 0}
              </Text>
              <IncreaseIcon
                alt="See Pending Drafts"
                style={{
                  backgroundColor: "var(--chakra-colors-light-green)",
                  width: "40px",
                  height: "40px",
                }}
                className="self-center rounded-lg cursor-pointer p-2 min-w-[20px] min-h-[20px] hover:opacity-80"
                onClick={() => navigate(protectedRoutes.MY_DRAFTS)}
              />
            </Box>
          </Box>
        </>
      )}
      {isMobile && (
        <Accordion
          allowMultiple
          bg={"white"}
          rounded={"0.75rem"}
          borderWidth={"1px"}
          borderColor={"light.300"}
          boxShadow={"0px 4px 4px 0px rgba(242, 243, 247, 0.50)"}
          overflow={"hidden"}
          w={"100%"}
          display={{ base: "block", md: "none" }}
          defaultIndex={[0]}
        >
          <AccordionItem>
            {({ isExpanded }) => (
              <>
                <AccordionButton
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                  flexDirection={"column"}
                  bg="anti-flash-lite"
                  p="0.938rem"
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    bg="anti-flash-lite"
                    width={"100%"}
                    height={"25px"}
                  >
                    <Text fontSize="xl" fontWeight="800">
                      More info{" "}
                    </Text>
                    {isExpanded ? (
                      <ArrowUpIconForAccordion alt="isAccordionOpen" />
                    ) : (
                      <ArrowDownIconForAccordion alt="isAccordionOpen" />
                    )}
                  </Box>
                </AccordionButton>

                <AccordionPanel padding={"0 !important"}>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    flexDirection="column"
                    w={"100%"}
                    // minH={"228px"}
                    p={"0.938rem"}
                    justifyContent={"center"}
                    gap={"12px"}
                  >
                    <Box
                      h="148px"
                      borderRadius={"12px"}
                      bgColor={"light.100"}
                      py="36px"
                      px="24px"
                      border="1px solid"
                      borderColor="anti-flash-lite"
                      boxShadow={"outline"}
                      w={{ base: "100%", md: "20%" }}
                      minW={{ base: "100%", md: "200px" }}
                    >
                      <Text mb="12px">Total Twygs Created</Text>
                      <Text fontWeight={800} fontSize={"32px"}>
                        {formartNumber(data?.finishedTwygsCount) ?? 0}
                      </Text>
                    </Box>
                    <Box
                      h="148px"
                      borderRadius={"12px"}
                      bgColor={"light.100"}
                      py="36px"
                      px="24px"
                      border="1px solid"
                      borderColor="anti-flash-lite"
                      boxShadow={"outline"}
                      w={{ base: "100%", md: "20%" }}
                      minW={{ base: "100%", md: "200px" }}
                    >
                      <Text mb="12px">Total Likes</Text>
                      <Text
                        title={data?.totalLikes}
                        fontWeight={800}
                        fontSize={"32px"}
                      >
                        {formatNumber(data?.totalLikes) ?? 0}
                      </Text>
                    </Box>
                    <Box
                      h="148px"
                      borderRadius={"12px"}
                      bgColor={"light.100"}
                      p="36px"
                      px="24px"
                      boxShadow={"outline"}
                      border="1px solid"
                      borderColor="anti-flash-lite"
                      w={{ base: "100%", md: "20%" }}
                      minW={{ base: "100%", md: "200px" }}
                    >
                      <Text mb="12px">Pending Draft(s)</Text>
                      <Box display={"flex"} gap="12px">
                        <Text fontWeight={800} fontSize={"32px"}>
                          {formartNumber(data?.draftTwygsCount) ?? 0}
                        </Text>
                        <IncreaseIcon
                          style={{
                            backgroundColor: "var(--chakra-colors-light-green)",
                            width: "30px",
                            height: "30px",
                          }}
                          alt="increase"
                          className="self-center bg-light-green rounded-lg cursor-pointer min-w-[20px] min-h-[20px] hover:opacity-80"
                          onClick={() => navigate(protectedRoutes.MY_DRAFTS)}
                        />
                      </Box>
                    </Box>
                  </Box>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      )}
      <EditModalComponent
        twygsHubInfo={twygsHubInfo}
        isOpen={isOpen}
        onClose={onClose}
        refetch={refetch}
      ></EditModalComponent>
    </Stack>
  );
};
