import { Fragment, useState } from "react";
import {
  Box,
  Button,
  FormLabel,
  Input,
  Tag,
  TagLabel,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  ERROR_FILE_UPLOAD,
  VIDEO_RATIO_TYPES_LIST,
  DISPLAY_MODAL_STUDIO_VIDEO,
  STUDIO_VIDEO_MODAL,
} from "../twygs.constant";
import { ReactComponent as FailLoadImage } from "../../../../assets/failed-to-load.svg";
import { ReactComponent as CloseEditIcon } from "../../../../assets/close-edit-icon.svg";
import { ReactComponent as TickEditIcon } from "../../../../assets/tick-edit-icon.svg";
import { ReactComponent as UploadIconGoGreen } from "../../../../assets/upload-go-green-icon.svg";
import ImageComponent from "../image.component";
import Cropper from "react-easy-crop";
import { MAX_FILE_SIZE } from "../../../../constants";
import { FILE_TYPES } from "../../../../constants/image.constant";

import {
  autoCropImageTwygs,
  createCroppedImg,
  handleValidateImage,
} from "../twygs.helper";
import ConfirmModal from "../../../Modal/ConfirmModal";
import SuccessfullyModal from "../../../ModalSuccess";
import { TopBar } from "../../../TopBar";

const ThumbnailComponent = (props) => {
  const { assetData, setAssetData, setAction } = props;
  const [typeCrop, setTypeCrop] = useState(null);
  const [editThumbnail, setEditThumbnail] = useState(assetData?.thumbnail);
  const [modal, setModal] = useState("");

  const handleUploadImage = async (value) => {
    const newFiles = [...value.target.files];
    const { arr, errList } = handleValidateImage(newFiles, 1, MAX_FILE_SIZE, 0);

    if (errList.file.length > 0) {
      setModal(DISPLAY_MODAL_STUDIO_VIDEO.CONFIRM_ERR);
    } else {
      const newArr = await autoCropImageTwygs(arr);
      setEditThumbnail(newArr[0]);
    }
  };

  const handleCropImage = async () => {
    const cropInfo = {
      rootSrc: typeCrop.rootSrc,
      croppedAreaPixels: typeCrop.croppedAreaPixels,
      assetType: typeCrop.assetType,
      aspect: typeCrop.aspect,
    };
    if (cropInfo.rootSrc && cropInfo.croppedAreaPixels) {
      const cropImg = await createCroppedImg(
        cropInfo.rootSrc,
        cropInfo.croppedAreaPixels
      );
      if (cropImg?.cropImgSrc) {
        let newObj = { ...editThumbnail };
        newObj = {
          ...newObj,
          file: cropImg.file,
          src: cropImg.cropImgSrc,
          assetType: cropInfo.assetType,
          isUpdated: true,
        };
        setEditThumbnail(newObj);
        setTypeCrop(null);
      }
    }
  };

  const handleCancelEdit = () => {
    if (typeCrop) setTypeCrop(null);
  };

  const handleEdit = (value) => {
    setTypeCrop({
      ...editThumbnail,
      rootSrc: editThumbnail.rootSrc || editThumbnail.src,
      assetType: value.typeValue,
      aspect: value.ratioValue,
      crop: { x: 0, y: 0 },
      zoom: 1,
      croppedAreaPixels: null,
    });
  };

  const handleCropChange = (value) => {
    setTypeCrop({ ...typeCrop, crop: value });
  };

  const handleZoomChange = (value) => {
    setTypeCrop({ ...typeCrop, zoom: value });
  };

  const handleCropComplete = (croppedArea, croppedAreaPixels) => {
    setTypeCrop({ ...typeCrop, croppedAreaPixels: croppedAreaPixels });
  };

  const handleSave = () => {
    setAssetData((pre) => {
      return {
        ...pre,
        thumbnail: {
          ...editThumbnail,
        },
      };
    });
    setAction("");
  };

  const handleDiscardChanges = (value) => {
    setModal(value);
  };

  const handleContinues = async () => {
    setModal("");
  };

  const handleCancel = () => {
    setModal("");
  };

  return (
    <Fragment>
      <TopBar />
      <Box
        display="flex"
        alignItems="center"
        py={{ base: "1rem", md: "2rem" }}
        px={{ base: "1rem", "2xl": "2rem" }}
        width={{ base: "100%", md: "auto" }}
        justifyContent={{ base: "space-between", md: "unset" }}
        flexWrap="wrap"
      >
        <Text fontSize={{ base: "xl", "2xl": "2xl" }} fontWeight="800">
          Thumbnail
        </Text>
        <Button
          variant="dark-green-solid"
          h="2.25rem"
          py={{ base: "8px", md: "0px" }}
          px={{ base: "14px", md: "0.875rem" }}
          border={0}
          shadow="none"
          bg="unset"
          mr={{ base: "0px", md: "8px" }}
          fontSize="0.875rem"
          color="jet-black"
          ml={{ base: "unset", md: "auto" }}
          onClick={() =>
            handleDiscardChanges(DISPLAY_MODAL_STUDIO_VIDEO.DISCARD_CHANGES)
          }
          css={{
            "@media (max-width: 370px)": {
              width: "100%",
              marginBottom: "8px",
              fontSize: "16px",
            },
          }}
        >
          Discard changes
        </Button>
        <Button
          variant="dark-green"
          h="2.25rem"
          px={{ base: "20px", md: "1.25rem" }}
          py={{ base: "20px", md: "0rem" }}
          fontSize="0.875rem"
          onClick={handleSave}
          css={{
            "@media (max-width: 370px)": {
              width: "100%",
              fontSize: "16px",
            },
          }}
        >
          Save
        </Button>
      </Box>

      <VStack spacing={{ base: "1rem", "2xl": "1.5rem" }}>
        {!!editThumbnail?.src && (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"flex-start"}
            columnGap={"0.5rem"}
            rowGap={"0.5rem"}
            transition={"width 0.3s ease"}
            flexWrap={"wrap"}
          >
            <Text fontSize={"md"} fontWeight={"800"}>
              Crop:
            </Text>
            {VIDEO_RATIO_TYPES_LIST.map((item, index) => {
              return (
                <Tag
                  key={index}
                  padding={"0.75rem 1.25rem 0.75rem 0.75rem"}
                  borderWidth={"2px"}
                  borderColor={"twygs-green"}
                  background={
                    typeCrop?.assetType === item.typeValue
                      ? "light-green"
                      : "#fff"
                  }
                  borderRadius={"38px"}
                  onClick={() => handleEdit(item)}
                  cursor={"pointer"}
                >
                  <item.icon className={"mr-[10px]"} alt="icon" />
                  <TagLabel
                    textTransform={"capitalize"}
                    color={"dark-green"}
                    fontSize={"0.75rem"}
                    fontWeight={900}
                  >
                    {item.ratio} {item.type}
                  </TagLabel>
                </Tag>
              );
            })}
            {typeCrop && (
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gap={"0.5rem"}
                width={"auto"}
              >
                <Box
                  p={"0.5rem"}
                  bg={"light-green"}
                  rounded={"0.5rem"}
                  cursor={typeCrop ? "pointer" : "default"}
                  transition={"all 0.2s ease"}
                  _hover={{ opacity: "0.7" }}
                  // ml={"2.5rem"}
                  onClick={handleCropImage}
                >
                  <TickEditIcon alt="Crop" title="Crop image" />
                </Box>
                <Box
                  p={"0.5rem"}
                  bg={"light-green"}
                  rounded={"0.5rem"}
                  cursor={typeCrop ? "pointer" : "default"}
                  transition={"all 0.2s ease"}
                  _hover={{ opacity: "0.7" }}
                  // ml={".5rem"}
                  onClick={handleCancelEdit}
                >
                  <CloseEditIcon alt="Cancle" title="Cancle" />
                </Box>
              </Box>
            )}
          </Box>
        )}
        {!typeCrop ? (
          <ImageComponent
            margin={"auto"}
            height={{ base: "22.5rem", md: "20.5rem", "2xl": "22.5rem" }}
            borderRadius="0.75rem"
            src={editThumbnail?.src}
            fallback={
              <Box
                width={"full"}
                height={{ base: "22.5rem", md: "20.5rem", "2xl": "22.5rem" }}
                backgroundColor={"#fff"}
                border={"1px solid #EAECF0"}
                borderRadius="0.75rem"
              />
            }
          />
        ) : (
          <Box
            position={"relative"}
            w={"45rem"}
            height={{ base: "22.5rem", md: "20.5rem", "2xl": "22.5rem" }}
            rounded={"0.5rem"}
            overflow={"hidden"}
          >
            <Cropper
              image={typeCrop.rootSrc || typeCrop.src}
              crop={typeCrop.crop}
              zoom={typeCrop.zoom}
              zoomSpeed={0.05}
              aspect={typeCrop.aspect}
              onCropChange={handleCropChange}
              onZoomChange={handleZoomChange}
              onCropComplete={handleCropComplete}
            />
          </Box>
        )}
        <Box pb={"4px"} display={"flex"} columnGap={"8px"}>
          <FormLabel htmlFor="thumbnail">
            <Box
              display={"flex"}
              alignItems={"center"}
              columnGap={"0.5rem"}
              cursor={"pointer"}
            >
              <Text fontSize={"sm"} fontWeight={900} color={"go-green"}>
                Upload Thumbnail
              </Text>
              <UploadIconGoGreen alt="icon" />
            </Box>
            <Input
              type="file"
              accept={FILE_TYPES.twygsImage}
              htmlSize={4}
              width="auto"
              id="thumbnail"
              hidden
              onChange={handleUploadImage}
            />
          </FormLabel>
        </Box>
      </VStack>
      {modal === DISPLAY_MODAL_STUDIO_VIDEO.DISCARD_CHANGES && (
        <ConfirmModal
          isOpen
          Icon={FailLoadImage}
          title={STUDIO_VIDEO_MODAL.TITLE}
          desc={STUDIO_VIDEO_MODAL.DESC}
          titleBtn={STUDIO_VIDEO_MODAL.CONFIRM}
          onClick={() => setAction("")}
          onClose={handleCancel}
        />
      )}
      {modal === DISPLAY_MODAL_STUDIO_VIDEO.CONFIRM_ERR && (
        <SuccessfullyModal
          isOpen
          Icon={FailLoadImage}
          title={ERROR_FILE_UPLOAD.THUMBNAIL.TITLE}
          desc={ERROR_FILE_UPLOAD.THUMBNAIL.DESC.map((item, index) => (
            <Text
              key={index}
              ml={"1.5rem"}
              fontSize={"18px"}
              textAlign={"start"}
              fontWeight={"400"}
              fontStyle={"normal"}
            >
              {item}
            </Text>
          ))}
          titleBtn={"Okay"}
          onClick={handleContinues}
        />
      )}
    </Fragment>
  );
};

export default ThumbnailComponent;
