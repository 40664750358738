import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Center, Spinner, Text, useToast } from "@chakra-ui/react";
import { Fragment, useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { Buffer } from "buffer";
import Cookies from "js-cookie";

import { schema } from "./schema";
import TemplateCreateTwygsComponent from "../template-create-twygs.component";
import DropzoneTwygsComponent from "../dropzone-twygs.component";
import {
  DRAG_DROP_DESC,
  ERROR_FILE_UPLOAD,
  ERROR_MESSAGE,
  statsStatus,
  SUCCESS_DRAFT,
} from "../twygs.constant";

import { ReactComponent as FailLoadImage } from "../../../../assets/failed-to-load.svg";
import { ReactComponent as SuccessfullyIcon } from "../../../../assets/successful-icon.svg";
import { ReactComponent as ToastSuccess } from "../../../../assets/toast-success.svg";
import { ReactComponent as UploadIconGreen } from "../../../../assets/upload-green-icon.svg";

import { handleUploadAssetToCloud, handleValidatePdf } from "../twygs.helper";

import { useAtom } from "jotai";
import { FILE_TYPES } from "../../../../constants/image.constant";
import PaginationComponent from "../../../Pagination";
import ConfirmModal from "../../../Modal/ConfirmModal";
import SuccessfullyModal from "../../../ModalSuccess";
import { protectedRoutes } from "../../../../constants/routes";
import ImageContentModal from "../../../PreviewPopup";
import ToastMessage from "../../../ToastMessage";
import { useNavigate, useSearchParams } from "react-router-dom";
import { profileAtom } from "../../../Settings/atom";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  postAssetUpload,
  publishTwyg,
  saveTwygDraft,
} from "../../../../apis/studio.api";
import { fetchTwygsDetail } from "../../../../apis/studio.api";

import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import axios from "axios";
import { UPLOAD_PDF } from "../../../../apis/twygs.api";
import { hashtagAtom } from "../../../TextAreaCustom/atom";

pdfjs.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js";

const options = {
  standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`,
};

const FormPdfComponent = () => {
  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { mutateAsync: postAssetUploadAsync } = useMutation({
    mutationFn: postAssetUpload,
  });
  const { mutateAsync: publishTwygAsync } = useMutation({
    mutationFn: publishTwyg,
  });
  const { mutateAsync: saveTwygsDraftAsync } = useMutation({
    mutationFn: saveTwygDraft,
  });
  const [hashtag] = useAtom(hashtagAtom);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfUploaded, setPdfUploaded] = useState(null);
  const [modal, setModal] = useState("");
  const [progressUpload, setProgressUpload] = useState(statsStatus["default"]);
  const [loadingDraft, setLoadingDraft] = useState(false);
  const ref = useRef();
  const fileInputRef = useRef();
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [infoPublish, setInfoPublish] = useState(null);
  const [idTwyg, setIdTwyg] = useState(null);
  const toast = useToast();
  const [profile, setProfile] = useAtom(profileAtom);
  const [thumbnailPdf, setThumbnailPDf] = useState(null);
  const [titleErr, setTitleErr] = useState();

  const { data, error, isLoading, isError } = useQuery({
    queryKey: ["twygsDetail", idTwyg],
    queryFn: () => fetchTwygsDetail(idTwyg),
    enabled: !!idTwyg,
  });

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setProgressUpload(statsStatus["loaded"]);
  };

  const handleUploadPdf = (value) => {
    setPageNumber(1);
    setProgressUpload(statsStatus["loading"]);
    if (value) {
      let file = {
        file: value?.[0],
      };
      const { check, errorList } = handleValidatePdf(file.file);
      if (errorList.length > 0) {
        setTitleErr(errorList);
        setModal("error");
      } else if (check) {
        const src = URL.createObjectURL(file.file);
        setPdfUploaded({
          ...file,
          src,
          rootSrc: src,
        });
        handleSetThumbnailPdf(src);
      }
    }
  };

  const handleChangePage = (page) => {
    setProgressUpload(statsStatus["loading"]);
    setPageNumber(page);
    if (ref.current) ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleSetThumbnailPdf = async (url) => {
    let valuePdfDocument = await pdfjs
      .getDocument(url)
      .promise.then((pdfDocument) => {
        return pdfDocument;
      });

    valuePdfDocument
      .getPage(1)
      .then((page) => {
        const canvas = document.createElement("canvas");
        canvas.id = "pdfCanvas";
        canvas.style.margin = "0 auto 0 auto";
        canvas.style.borderRadius = "8px";
        canvas.style.display = "none";
        const viewport = page.getViewport({ scale: 0.5 });
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const context = canvas.getContext("2d");
        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };
        var task = page.render(renderContext);
        task.promise.then(function () {
          const value = canvas.toDataURL("image/jpeg");
          setThumbnailPDf(
            Buffer.from(value.substring(value.indexOf(",") + 1), "base64")
          );
        });
      })
      .catch((error) => {
        console.error("Error rendering PDF page:", error);
      });
  };

  // const handleAssetUpload = async () => {
  //   if (!pdfUploaded.file) {
  //     return [pdfUploaded.fileName];
  //   } else {
  //     const arrData = await postAssetUploadAsync({
  //       fileName: [pdfUploaded.file.name],
  //     });
  //     if (arrData.length > 0) {
  //       const uploadUrlArr = [
  //         {
  //           ...pdfUploaded,
  //           uploadUrl: arrData[0].url,
  //           fileName: arrData[0].key,
  //         },
  //       ];
  //       await handleUploadAssetToCloud(uploadUrlArr);
  //       return arrData.map((elm) => elm.key);
  //     }
  //   }
  // };

  const handleAssetUploadMultiple = async () => {
    const formData = new FormData();

    formData.append("file", pdfUploaded.file);

    const result = await axios.post(UPLOAD_PDF, formData, {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        Authorization: `Bearer ${Cookies.get(
          `${process.env.REACT_APP_IS_STAGING ? "staging" : ""}token`
        )}`,
      },
    });

    return result.data.data;
  };

  const handleAssetUploadThumbnail = async () => {
    const arrData = await postAssetUploadAsync({
      fileName: ["thumbnailPdf.jpeg"],
    });
    if (arrData.length > 0) {
      const uploadUrlArr = [
        {
          uploadUrl: arrData[0].url,
          fileName: arrData[0].key,
          file: {
            type: "image/jpeg",
          },
          bufferBody: thumbnailPdf,
        },
      ];
      await handleUploadAssetToCloud(uploadUrlArr);
      return arrData.map((elm) => elm.key);
    }
  };

  const submitForm = async (valueForm) => {
    try {
      setLoadingPublish(true);
      let payload = {
        ...valueForm,
        hashtag: hashtag,
        contentType: "PDF",
      };
      if (searchParams.get("action") && !pdfUploaded.file) {
        payload = {
          ...payload,
          contentKeys: data.contentKeys,
        };
      } else {
        const uploadData = await handleAssetUploadMultiple();
        payload = {
          ...payload,
          contentKeys: uploadData.fileKeys,
          originalPdf: uploadData.originalPdf,
          thumbnail: (await handleAssetUploadThumbnail())?.[0],
        };
        if (searchParams.get("action")) {
          payload = { ...payload, removedContentKeys: [...data.contentKeys] };
        }
      }

      const response = await publishTwygAsync(payload);
      setLoadingPublish(false);
      setInfoPublish({
        ...payload,
        createdAt: new Date(),
        ...response,
        contentUrlList: [pdfUploaded.src],
      });
      setModal("publish-success");
      if (!profile.creatorWallet) {
        setProfile({ ...profile, creatorWallet: response?.walletAddress });
      }

      /* User Post Event Conversion Location */
      //Add Impact APIs to .env
      //DEPRECATED, PENDING DELETION
      /* User Post Event Conversion Location */

      toast({
        render: () => (
          <ToastMessage
            backgroundColor="#FFD166"
            color="jet-black"
            Icon={ToastSuccess}
            title={"Twyg Under Review"}
          ></ToastMessage>
        ),
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      toast({
        render: () => (
          <ToastMessage
            backgroundColor="#FFD166"
            color="jet-black"
            Icon={ToastSuccess}
            title={"PDF Upload in progress"}
          ></ToastMessage>
        ),
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      setLoadingPublish(false);
      if (error?.data?.message === ERROR_MESSAGE.DUPLICATE_NAME) {
        methods.setError(
          "twygsName",
          { message: error?.data?.message },
          { shouldFocus: true }
        );
      }
    }
  };

  const saveDarft = async () => {
    try {
      setLoadingDraft(true);
      let payload = {
        ...methods.getValues(),
        hashtag: hashtag,
        contentType: "PDF",
      };
      if (searchParams.get("action") && !pdfUploaded.file) {
        payload = {
          ...payload,
          contentKeys: data.contentKeys,
        };
      } else {
        const uploadData = await handleAssetUploadMultiple();
        payload = {
          ...payload,
          contentKeys: uploadData.fileKeys,
          originalPdf: uploadData.originalPdf,
          thumbnail: (await handleAssetUploadThumbnail())?.[0],
        };
        if (searchParams.get("action"))
          payload = {
            ...payload,
            removedContentKeys: [...data.contentKeys],
          };
      }
      await saveTwygsDraftAsync(payload);
      setModal("draft-success");
      setLoadingDraft(false);
    } catch (error) {
      setLoadingDraft(false);
      if (error?.data?.message === ERROR_MESSAGE.DUPLICATE_NAME) {
        methods.setError(
          "twygsName",
          { message: error?.data?.message },
          { shouldFocus: true }
        );
      }
    }
  };

  const redirectRouter = (url) => () => {
    navigate(url);
    setModal("");
  };

  useEffect(() => {
    if (searchParams.get("id")) {
      setIdTwyg(searchParams.get("id"));
    }
  }, [searchParams]);

  useEffect(() => {
    if (data) {
      setPdfUploaded({
        src: data.originalContent,
        fileName: data.contentKeys,
      });
      methods.reset(data);
    }
  }, [data, methods]);

  if (isLoading && idTwyg)
    return (
      <Center h={"25rem"}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="dark-green"
          size={{ base: "lg", "2xl": "xl" }}
        />
      </Center>
    );

  return (
    <FormProvider {...methods}>
      <form>
        <TemplateCreateTwygsComponent
          saveDarft={saveDarft}
          onSubmit={methods.handleSubmit(submitForm)}
          loadingSaveDraft={loadingDraft}
          title="PDF Twyg"
          disable={!methods.formState.isValid || !pdfUploaded}
          loadingPublish={loadingPublish}
        >
          {pdfUploaded ? (
            <Box className="pdf-document" ref={ref}>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={(e) => handleUploadPdf(e.target.files)}
                accept={FILE_TYPES.twygsPdf}
              />
              <Button
                h={"2.25rem"}
                pl={"1.125rem"}
                pr={"0.75rem"}
                variant={"light-green"}
                rightIcon={<UploadIconGreen alt="" />}
                mb={{ base: "1rem", "2xl": "1.5rem" }}
                fontSize={"sm"}
                onClick={() => fileInputRef.current?.click()}
              >
                Upload
              </Button>

              <Fragment>
                <Document
                  className={"pdf-container"}
                  file={pdfUploaded.src}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={(error) =>
                    console.error("Error loading document", error)
                  }
                  options={options}
                  loading={
                    <Center h={"20rem"}>
                      <Spinner
                        thickness="4px"
                        speed="0.65s"
                        emptyColor="gray.200"
                        color="dark-green"
                        size={{ base: "lg", "2xl": "xl" }}
                      />
                    </Center>
                  }
                >
                  <Page
                    pageNumber={pageNumber}
                    renderMode="canvas"
                    onLoadSuccess={() =>
                      setProgressUpload(statsStatus["loaded"])
                    }
                    className={"page-canvas"}
                    loading={
                      <Center
                        h={ref.current ? ref.current.clientHeight : "auto"}
                      >
                        <Spinner
                          thickness="4px"
                          speed="0.65s"
                          emptyColor="gray.200"
                          color="dark-green"
                          size={{ base: "lg", "2xl": "xl" }}
                        />
                      </Center>
                    }
                  />
                </Document>
                {numPages > 0 && progressUpload !== statsStatus["loading"] ? (
                  <Box mt={"1.5rem"}>
                    <PaginationComponent
                      currentPage={pageNumber}
                      totalPages={numPages}
                      onChangePage={handleChangePage}
                    />
                  </Box>
                ) : null}
              </Fragment>
            </Box>
          ) : (
            <DropzoneTwygsComponent
              acceptFileTypes={FILE_TYPES.twygsPdf}
              notMutiple
              validateFiles={handleUploadPdf}
              desc={DRAG_DROP_DESC.pdf.map((item, index) => (
                <Text
                  key={index}
                  fontWeight="400"
                  color="dim-gray"
                  fontSize="xs"
                >
                  {item}
                </Text>
              ))}
            />
          )}
        </TemplateCreateTwygsComponent>
      </form>
      {modal === "error" && (
        <ConfirmModal
          isOpen
          Icon={FailLoadImage}
          title={titleErr ?? ERROR_FILE_UPLOAD.OVERVIEW.PDF}
          desc={""}
          titleBtn={"Try Again"}
          onClose={() => setModal("")}
          onClick={() => setModal("")}
          type="PDF"
        />
      )}
      {modal === "draft-success" && (
        <SuccessfullyModal
          isOpen
          Icon={SuccessfullyIcon}
          title={SUCCESS_DRAFT.title}
          desc={SUCCESS_DRAFT.desc}
          titleBtn={"Go to My Draft"}
          onClick={redirectRouter(protectedRoutes.MY_DRAFTS)}
        />
      )}
      {modal === "publish-success" && (
        <ImageContentModal
          isDisabled
          data={infoPublish}
          isOpen
          onClose={redirectRouter(protectedRoutes.MY_TWYGS)}
          onDelete={redirectRouter(protectedRoutes.MY_TWYGS)}
        />
      )}
    </FormProvider>
  );
};

export default FormPdfComponent;
