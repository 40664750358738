import { ReactComponent as AddFileGreenIcon } from "../../../assets/add-file-green-icon.svg";

import { Box, Center, Input, Spinner, Text, VStack } from "@chakra-ui/react";
import Dropzone from "react-dropzone";

const DropzoneTwygsComponent = ({
  validateFiles,
  desc,
  acceptFileTypes,
  isLoading,
  isSingle,
  notMutiple,
  height,
  hideContent,
}) => {
  return isLoading ? (
    <Center h={"140px"}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="dark-green"
        size={{ base: "lg", "2xl": "xl" }}
      />
    </Center>
  ) : (
    <Dropzone
      onDrop={(acceptedFiles) => {
        validateFiles([...acceptedFiles]);
      }}
      multiple={!notMutiple}
    >
      {({ getRootProps, getInputProps }) => {
        const inputProps = getInputProps();
        inputProps.multiple = true;
        inputProps.accept = acceptFileTypes;
        if (isSingle) inputProps.multiple = false;
        return (
          <VStack
            {...getRootProps()}
            w={"full"}
            backgroundColor="white"
            borderRadius="12px"
            position={"relative"}
            spacing={"1rem"}
            cursor={"pointer"}
            gap="16px"
            h={height ?? "360px"}
            className="dropzone-twygs"
            justifyContent={"center"}
          >
            <AddFileGreenIcon style={{ borderRadius: "50%" }} />
            <Box
              display={hideContent ? "none" : "flex"}
              flexFlow={"column"}
              gap="16px"
            >
              <Text
                color="dim-gray"
                fontSize={{ base: "sm", "2xl": "md" }}
                fontWeight={400}
              >
                Click to submit
                <Text
                  as="span"
                  fontSize="sm"
                  color="go-green"
                  fontWeight="900"
                  mx="4px"
                >
                  file(s)
                </Text>
                or
                <Text
                  as="span"
                  fontSize="sm"
                  color="go-green"
                  fontWeight="900"
                  mx="4px"
                >
                  folder
                </Text>
                or drag and drop
              </Text>
              {desc && typeof desc === "string" ? (
                <Text
                  fontWeight="400"
                  color="dim-gray"
                  fontSize="xs"
                  mt={"0.25rem"}
                >
                  {desc}
                </Text>
              ) : (
                <Box>{desc}</Box>
              )}
            </Box>
            <Input
              accept={acceptFileTypes}
              w="full"
              id="fileInput"
              type="file"
              position="absolute"
              inset={0}
              opacity={0}
              cursor="pointer"
              zIndex={10}
              // onChange={onChange}
              {...inputProps}
            />
          </VStack>
        );
      }}
    </Dropzone>
  );
};

export default DropzoneTwygsComponent;
