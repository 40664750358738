import React, { useState } from "react";
import { useAtom } from "jotai";
import SuccessfullyIcon from "../../../../assets/successful.svg";
import { profileAtom } from "../../../Settings/atom";
import { verifySetup } from "../../../../apis/two-factor";
import OtpInput from "../../otp-verify-code";
import SuccessfullyModal from "../../modal/successfully";
import { useNavigate } from "react-router-dom";
import { protectedRoutes } from "../../constants";

const Step3 = ({ appData }) => {
  const navigate = useNavigate();
  const [profile, setProfile] = useAtom(profileAtom);
  const [otp, setOtp] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [modal, setModal] = useState("");
  const [loading, setLoading] = useState(false);

  const handleVerifyOtp = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const convertOtp = otp.split("").splice(0, 6).join("");
      const payload = {
        twoFactorAuthType: "AUTHENTICATOR_APP_OTP",
        otp: convertOtp,
        twoFactorSecretKey: appData.twoFactorSecretKey,
      };
      const data = await verifySetup(payload);
      if (data.success) {
        setProfile({
          ...profile,
          isEnable2FA: true,
          twoFAType: "AUTHENTICATOR_APP_OTP",
        });
        setModal("success");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      window.dataLayer.push({
        event: "error",
        error_type: "2FA error",
        error_message: error?.data?.message,
      });
      setErrorMessage("The OTP code is invalid or expired.");
    }
  };

  const onChange = (value) => {
    setLoading(false);
    setOtp(value);
    setErrorMessage("");
  };

  const handleClickModal = () => {
    setModal("");
    navigate(protectedRoutes.PROFILE);
  };

  return (
    <>
      <div className="p-6 border border-gray-100 rounded-xl bg-white shadow-sm">
        <form onSubmit={handleVerifyOtp}>
          <p className="text-sm 2xl:text-base font-black">
            Step 3:{" "}
            <span className="font-semibold">
              Enter the 6-digit code found in your authenticator app
            </span>
          </p>
          <div className="mt-4 h-24">
            <OtpInput
              value={otp}
              valueLength={6}
              onChange={onChange}
              error={!!errorMessage}
            />
            {!!errorMessage && (
              <p className="!text-red-600 mt-2">{errorMessage}</p>
            )}
          </div>
          <button
            className="mt-2 px-4 py-2 h-10 bg-[#084F4B] !text-white font-black text-sm 2xl:text-base rounded-lg w-full md:w-fit cursor-pointer"
            type="submit"
            disabled={loading}
          >
            Verify
          </button>
        </form>
      </div>
      {modal === "success" && (
        <SuccessfullyModal
          isOpen={true}
          srcImg={SuccessfullyIcon}
          title={"Two-Factor Authentication (2FA) enabled successfully"}
          desc={"Your account is now extra secure."}
          titleBtn={"Done"}
          onClick={handleClickModal}
        />
      )}
    </>
  );
};

export default Step3;
