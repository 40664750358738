import React, { useState } from "react";
import { useAtom } from "jotai";
import IntroSection from "../intro-section/intro-section.component";
import OtpInput from "../otp-verify-code";
import SuccessfullyModal from "../modal/successfully";
import { useNavigate } from "react-router-dom";
import { profileAtom } from "../../Settings/atom";
import { protectedRoutes } from "../constants";
import SuccessfullyIcon from "../../../assets/successful.svg";
import { verifySetup } from "../../../apis/two-factor";

const EmailVerify = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useAtom(profileAtom);
  const [errorMessage, setErrorMessage] = useState("");
  const [otp, setOtp] = useState("");
  const [modal, setModal] = useState("");
  const [loading, setLoading] = useState(false);

  const handleVerifyOtp = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const convertOtp = otp.split("").splice(0, 6).join("");
      const payload = {
        twoFactorAuthType: "EMAIL_OTP",
        otp: convertOtp,
      };
      const data = await verifySetup(payload);
      if (data.success) {
        setProfile({
          ...profile,
          isEnable2FA: true,
          twoFAType: "EMAIL_OTP",
        });
        setModal("success");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      window.dataLayer.push({
        event: "error",
        error_type: "2FA error",
        error_message: error?.data?.message,
      });
      setErrorMessage("The OTP code is invalid or expired.");
    }
  };

  const onChange = (value) => {
    setOtp(value);
    setErrorMessage("");
  };

  const handleClickModal = () => {
    setModal("");
    navigate(protectedRoutes.PROFILE);
  };

  return (
    <>
      <IntroSection
        title="Email Verification Setup"
        description="Enter the code sent to your registered email address to complete 2FA setup."
        colorDesc={"!text-[#808080]"}
      />
      <div className="mt-6 md:mt-10">
        <form onSubmit={handleVerifyOtp}>
          <div className="md:h-22">
            <div className={`w-full ${errorMessage ? "border-red-500" : ""}`}>
              <OtpInput
                value={otp}
                valueLength={6}
                onChange={onChange}
                error={!!errorMessage}
              />
            </div>
            {errorMessage && (
              <p className="!text-red-600 mt-2 text-base">{errorMessage}</p>
            )}
          </div>
          <button
            type="submit"
            className={`mt-4 md:mt-6 py-2 px-4 h-10 bg-[#084F4B] !text-white font-black text-sm 2xl:text-base rounded-lg w-full md:w-auto hover:opacity-80 ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}
          >
            Verify
          </button>
        </form>
      </div>
      {modal === "success" && (
        <SuccessfullyModal
          isOpen={true}
          srcImg={SuccessfullyIcon}
          title={"Two-Factor Authentication (2FA) enabled successfully"}
          desc={"Your account is now extra secure."}
          titleBtn={"Done"}
          onClick={handleClickModal}
        />
      )}
    </>
  );
};

export default EmailVerify;
