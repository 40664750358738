import { Route, Routes } from "react-router-dom";
import CreateTwygsForm from "../../components/CreateTwygsForm";
import ImageType from "../../components/CreateTwygsForm/image-type";
import { TouchBackend } from "react-dnd-touch-backend";
import { DndProvider } from "react-dnd";
import VideoType from "../../components/CreateTwygsForm/video-type";
import PdfType from "../../components/CreateTwygsForm/pdf-type";

const CreateTwygsRoute = () => {
  return (
    <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
      <Routes>
        <Route path="/" element={<CreateTwygsForm />} />
        <Route path="/image-twyg" element={<ImageType />} />
        <Route path="/video-twyg" element={<VideoType />} />
        <Route path="/pdf-twyg" element={<PdfType />} />
      </Routes>
    </DndProvider>
  );
};

export default CreateTwygsRoute;
