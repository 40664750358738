import React, { Fragment } from "react";

import { ReactComponent as AddMethodIcon } from "../../assets/plus-white-icon.svg";

import { Box, Button, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { protectedRoutes } from "../../constants/routes";
import { TwygsInfoComponent } from "./components/twygs-info/twygs-info.component";
import { TwygsTableComponent } from "./components/twygs-info/twygs-table.component";
import IntroSection from "../IntroSection/intro-section.component";
import { TopBar } from "../TopBar";

const MyTwygs = () => {
  const navigate = useNavigate();
  return (
    <Fragment>
      <TopBar />
      <VStack
        spacing={{ base: "1.25rem", md: "1rem", "2xl": "2.5rem" }}
        p={{ base: "1rem", "2xl": "2rem" }}
      >
        <Box
          w="full"
          display="flex"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent={"space-between"}
          alignItems={{ base: "start", md: "center" }}
          gap={{ base: "12px", md: "0px" }}
          mb={{ base: "10px", md: "0px" }}
        >
          <IntroSection
            title="My Twygs Hub"
            description="Manage, Edit, and Share Your Twygs"
          />
          <Button
            h={{ base: "38px", "2xl": "42px" }}
            variant="dark-green"
            rightIcon={<AddMethodIcon alt=""></AddMethodIcon>}
            pl={{ base: "16px", "2xl": "20px" }}
            pr={{ base: "8px", "2xl": "12px" }}
            fontSize={{ base: "sm", "2xl": "md" }}
            onClick={() => navigate(protectedRoutes.CREATE_TWYGS)}
          >
            Create Twygs
          </Button>
        </Box>
        <TwygsInfoComponent />
        <TwygsTableComponent />
      </VStack>
    </Fragment>
  );
};

export default MyTwygs;
