import { useFormContext } from "react-hook-form";

import { FieldHookForm } from "../hook-form/field-hook-form";
import { Input } from "../input/input.component";

const InputHookForm = (props) => {
  const {
    isOnKeyword,
    description,
    label,
    name,
    fontSizeError,
    isHiddenBottomContent,
    bottomContentProps,
    isHiddenError,
    bottomRightContent,
    onKeyDown,
    colorDes,
    ...rest
  } = props;
  const {
    register,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  return (
    <FieldHookForm
      name={name}
      description={description}
      label={label}
      fontSizeError={fontSizeError}
      isHiddenBottomContent={isHiddenBottomContent}
      bottomContentProps={bottomContentProps}
      isHiddenError={isHiddenError}
      bottomRightContent={bottomRightContent}
      colorDes={colorDes}
    >
      <Input
        fontSize={{ base: "sm", "2xl": "md" }}
        p={"1.125rem 1.5rem"}
        rounded={"0.75rem"}
        {...(!isOnKeyword
          ? register(name, {
              onChange: () => (errors[name] ? clearErrors(name) : null),
            })
          : null)}
        h={"auto"}
        borderWidth={"px"}
        borderColor={errors?.[name] ? "other.200" : "light-gray"}
        color={"jet-black"}
        bg={"light.200"}
        _placeholder={{
          color: "dim-gray",
          fontSize: { base: "sm", "2xl": "md" },
        }}
        lineHeight={"1.25rem"}
        name={name}
        _hover={{
          borderColor: errors?.[name] ? "other.200 " : "inherit",
        }}
        _focus={{
          boxShadow: "none",
        }}
        _focusVisible={{
          borderColor: errors?.[name] ? "other.200" : "twygs-green",
          borderWidth: "1.5px",
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !onKeyDown) {
            e.preventDefault();
          }
        }}
        {...rest}
      />
    </FieldHookForm>
  );
};

InputHookForm.displayName = "InputHookForm";

export { InputHookForm };
