import { Button, HStack, Text, useBreakpointValue } from "@chakra-ui/react";

import { ReactComponent as ArrowLeftIcon } from "../../../../assets/arrow-left-solid-icon.svg";
import { ReactComponent as ArrowRightIcon } from "../../../../assets/arrow-right-solid-icon.svg";

const PaginationComponent = ({
  currentPage,
  totalPages,
  onChangePage,
  isSpacebBetween,
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const getDisplayedPages = (currentPage, totalPages) => {
    let pages = [];
    if (isMobile) {
      if (totalPages <= 5) {
        for (let i = 1; i <= totalPages; i++) {
          pages.push(i);
        }
      } else {
        if (currentPage <= 2) {
          pages = [1, 2, 3, "...", totalPages];
        } else if (currentPage >= totalPages - 1) {
          pages = [1, "...", totalPages - 2, totalPages - 1, totalPages];
        } else {
          pages = [1, "...", currentPage, "...", totalPages];
        }
      }
    } else {
      if (totalPages <= 7) {
        for (let i = 1; i <= totalPages; i++) {
          pages.push(i);
        }
      } else if (currentPage <= 4) {
        pages = [1, 2, 3, 4, 5, "...", totalPages - 1, totalPages];
      } else if (currentPage >= totalPages - 3) {
        pages = [
          1,
          2,
          "...",
          totalPages - 4,
          totalPages - 3,
          totalPages - 2,
          totalPages - 1,
          totalPages,
        ];
      } else {
        pages = [1, 2, "..."];
        for (let i = currentPage - 1; i <= currentPage + 1; i++) {
          pages.push(i);
        }
        pages.push("...", totalPages - 1, totalPages);
      }
    }
    return pages;
  };
  const handleClick = (page) => {
    if (page !== "..." && page !== currentPage) {
      onChangePage(page);
    }
  };

  const handlePrevClick = () => {
    if (currentPage > 1) {
      onChangePage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      onChangePage(currentPage + 1);
    }
  };

  const renderPages = () => {
    const pages = getDisplayedPages(currentPage, totalPages);
    return pages.map((page, index) => (
      <Button
        key={index}
        variant={page === currentPage ? "solid" : "outline"}
        boxShadow={"none"}
        border={0}
        bg={page === currentPage ? "anti-flash-lite" : "white"}
        onClick={() => handleClick(page)}
        fontSize={"0.875rem"}
        fontWeight={800}
        color={"dark-green"}
        height={{ base: "40px", md: "36px", "2xl": "40px" }}
        minW={{ base: "40px", md: "36px", "2xl": "40px" }}
        p={0}
        _hover={{
          bg: page === "..." ? "white" : "#EAECF0",
        }}
        disabled={page === "..."}
      >
        {page}
      </Button>
    ));
  };

  return (
    <HStack
      spacing={{ base: "8px", md: "12px" }}
      alignItems={"center"}
      justifyContent={{
        base: isSpacebBetween ? "space-between" : "center",
        md: isSpacebBetween ? "space-between" : "center",
      }}
      w={"100%"}
    >
      <Button
        onClick={handlePrevClick}
        isDisabled={currentPage === 1}
        height={{ base: "40px", md: "36px", "2xl": "40px" }}
        minW={
          isSpacebBetween
            ? "110px"
            : { base: "40px", md: "36px", "2xl": "40px" }
        }
        borderColor={currentPage === 1 ? "anti-flash-lite" : "mid-gray"}
        rounded={"0.5rem"}
        bg={"white"}
        p={0}
        boxShadow={"none"}
        _hover={{
          bg: currentPage === 1 ? "white" : "#EAECF0",
        }}
        cursor={currentPage === 1 ? "default" : "pointer"}
        _disabled={{
          opacity: 1,
          cursor: "not-allowed",
        }}
      >
        <ArrowLeftIcon
          opacity={currentPage === 1 ? 0.4 : 1}
          src={ArrowLeftIcon.src}
          alt=""
        />
        {isSpacebBetween && (
          <Text
            ml={"8px"}
            color={"dark.100"}
            fontWeight={"600"}
            fontSize={"14px"}
            lineHeight={"20px"}
          >
            Previous
          </Text>
        )}
      </Button>
      <HStack
        spacing={{ base: "2px", md: 2 }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {renderPages()}
      </HStack>
      <Button
        onClick={handleNextClick}
        isDisabled={currentPage === totalPages}
        borderColor={
          currentPage === totalPages ? "anti-flash-lite" : "mid-gray"
        }
        bg={"white"}
        boxShadow={"none"}
        rounded={"0.5rem"}
        height={{ base: "40px", md: "36px", "2xl": "40px" }}
        minW={
          isSpacebBetween
            ? "110px"
            : { base: "40px", md: "36px", "2xl": "40px" }
        }
        p={0}
        _hover={{
          bg: currentPage === totalPages ? "white" : "#EAECF0",
        }}
        _disabled={{
          opacity: 1,
          cursor: "not-allowed",
        }}
        cursor={currentPage === totalPages ? "default" : "pointer"}
      >
        {isSpacebBetween && (
          <Text
            mr={"8px"}
            color={"dark.100"}
            fontWeight={"600"}
            fontSize={"14px"}
            lineHeight={"20px"}
          >
            Next
          </Text>
        )}
        <ArrowRightIcon opacity={currentPage === totalPages ? 0.4 : 1} alt="" />
      </Button>
    </HStack>
  );
};

export default PaginationComponent;
