import { forwardRef, useState } from "react";

const PagesComponent = forwardRef((props, ref) => {
  const { width, height, index, isShowAd, url, handleRenderSuccess } = props;

  const [isLoading, setIsLoading] = useState(true);
  return (
    <div
      id={`page-cover-${index}`}
      className={`page page-cover !top-0 h-full w-full ${
        isLoading && !isShowAd ? "animate-pulse" : ""
      }`}
      ref={ref}
      data-density="soft"
      role="status"
    >
      <div
        className={`page-content`}
        style={{
          height: `${height}px`,
          width: `${width}px`,
        }}
      >
        {isShowAd ? (
          <div
            style={{
              height: `100%`,
              width: `${width}px`,
              display: !isShowAd ? "none" : "",
            }}
            className="relative bg-white"
            id="add-ad-later"
          ></div>
        ) : (
          <img
            src={url}
            alt={`Page ${index + 1}`}
            onLoad={() => {
              setIsLoading(false);
              handleRenderSuccess(index);
            }}
            style={{
              width: width,
              height: "auto",
            }}
          />
        )}
      </div>
    </div>
  );
});

export default PagesComponent;
