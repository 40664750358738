import { useMemo } from "react";
import {
  useSearchParams as useRouterSearchParams,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { parsedQueryString } from "./useParsedQueryString";

export default function useSearchParams() {
  const { search } = useLocation();
  const searchParams = useMemo(() => parsedQueryString(search), [search]);
  const navigate = useNavigate();
  const [searchParamsRouter, setSearchParamsRouter] = useRouterSearchParams();

  const setSearchParams = (params) => {
    if (Object.keys(params).length === 0) return;
    const urlSearchParams = new URLSearchParams(searchParamsRouter);
    for (const key in params) {
      if (!!key) {
        if (params[key]) {
          urlSearchParams.set(key, params[key] ?? "");
        } else {
          urlSearchParams.delete(key);
        }
      }
    }
    navigate(`?${urlSearchParams.toString()}`, { replace: true });
  };

  const setSearchParamsOnly = (params) => {
    if (Object.keys(params).length === 0) return;
    const urlSearchParams = new URLSearchParams();
    for (const key in params) {
      if (!!key) urlSearchParams.set(key, params[key]);
    }
    navigate(`?${urlSearchParams.toString()}`, { replace: true });
  };

  return { searchParams, setSearchParams, setSearchParamsOnly };
}
