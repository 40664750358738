import {ErrorMessage} from "@hookform/error-message";
import React from "react";
import {Text} from "@chakra-ui/react";
import {useFormContext} from "react-hook-form";


const ErrorMessageComponent = (props) => {
  const {
    formState: { errors }
  } = useFormContext();

  const { name, fontSizeError, mt } = props;
  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => (
        <Text
          fontSize={fontSizeError ?? "0.875rem"}
          ml={fontSizeError ? "0px" : "0.25rem"}
          color={"other.200"}
          mt={mt ?? "0px"}
        >
          {message}
        </Text>
      )}
    />
  );
};

export default ErrorMessageComponent;
