import { useCallback, useEffect, useMemo, useState } from "react";

import { ReactComponent as CarouselIcon } from "../../../../assets/carousel-icon.svg";
import { ReactComponent as ChevronDownGrayIcon } from "../../../../assets/chevron-down-solid-gray.svg";
import { ReactComponent as ChevronDownGreenIcon } from "../../../../assets/chevron-down-solid-green.svg";
import { ReactComponent as FailLoadImage } from "../../../../assets/failed-to-load.svg";
import { ReactComponent as FileIcon } from "../../../../assets/file-icon.svg";
import { ReactComponent as ImageIcon } from "../../../../assets/image-icon.svg";
import { ReactComponent as InformIcon } from "../../../../assets/inform-icon.svg";
import { ReactComponent as LikeIcon } from "../../../../assets/like-icon.svg";
import { ReactComponent as PencilGreenIcon } from "../../../../assets/pencil-green-icon.svg";
import { ReactComponent as ShareContentIcon } from "../../../../assets/share-content-icon.svg";
import { ReactComponent as ShareGreenIcon } from "../../../../assets/share-green-icon.svg";
import { ReactComponent as BinRedIcon } from "../../../../assets/bin-red-icon.svg";
import { ReactComponent as VideoIcon } from "../../../../assets/video-icon.svg";
import { ReactComponent as ExternalLinkIcon } from "../../../../assets/link-external-icon.svg";

import {
  Box,
  Button,
  Checkbox,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
  useMediaQuery,
} from "@chakra-ui/react";
import { MODAL_DRAFT, TYPES_DRAFT } from "../draft/draft.constant";
import { useNavigate } from "react-router-dom";
import {
  LIST_COLOR_CATEGORY,
  OPTION_TYPE_AD,
  VIDEO_PROCESS_STATUS,
} from "../../../../constants/value.constant";
import { protectedRoutes } from "../../../../constants/routes";
import {
  CONTENT_TYPE_ENUM,
  TWYGS_STUDIO_ACTION,
} from "../../../CreateTwygsForm/components/twygs.constant";
import ImageComponent from "../../../Common/Image";
import {
  formatDate,
  formatNumber,
  getTooltipLabel,
} from "../../../../utils/helper.util";
import ShareContentModal from "../../../ShareContentModal";
import NoDataComponent from "../../../NoData";
import TableComponent from "../../../Table";
import ImageContentModal from "../../../PreviewPopup";
import ConfirmModal from "../../../Modal/ConfirmModal";
import InputSearchComponent from "../../../Common/Form/input-search";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  deleteTwygs,
  getContentCategories as fetchContentCategories,
  getFinishedTwygs,
} from "../../../../apis/studio.api";
import PaginationComponent from "../../../PreviewPopup/pdf-preview/pagination";

export const TwygsTableComponent = () => {
  const navigate = useNavigate();
  const [listIdAd, setListIdAd] = useState([]);
  const [idAd, setIdAd] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [modal, setModal] = useState("");
  const [detailData, setDetailData] = useState({});
  const [url, setUrl] = useState("");
  const [deleteMultiple, setDeleteMutiple] = useState(false);
  const [pagination, setPagination] = useState({
    limit: 10,
    page: 1,
    sort: undefined,
    order: undefined,
    search: "",
  });
  const [type, setType] = useState({
    id: undefined,
    value: "",
  });
  const [category, setCategory] = useState({
    id: undefined,
    value: "",
  });

  const params = {
    ...pagination,
    contentTypeId: type.id,
    contentCategoryId: category.id,
  };

  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([, value]) => value !== undefined)
  );

  const {
    data: getListTwygs,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ["getListTwygs", filteredParams],
    queryFn: () => getFinishedTwygs(filteredParams),
  });
  const { data: getContentCategories } = useQuery({
    queryKey: ["content-categories"],
    queryFn: fetchContentCategories,
  });
  const { mutateAsync: deleteTwygsAsync, isPending: isDeleteTwygPending } =
    useMutation({
      mutationFn: deleteTwygs,
    });

  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const handleBlockActionWhenUnderReview = (value) => {
    const isUnderReview = value.underReview === true;
    const checkProcessStatus =
      value.videoProcessStatus !== VIDEO_PROCESS_STATUS.PROCESSING;

    return isUnderReview || !checkProcessStatus;
  };

  const handleChangePage = (page) => {
    setDataTable(() => []);
    setPagination((pre) => {
      return {
        ...pre,
        page,
      };
    });
  };

  const handleChangeSearch = (search) => {
    setListIdAd([]);
    setPagination((pre) => {
      return {
        ...pre,
        page: 1,
        search: search,
      };
    });
  };

  const handleChangeType = (id, name) => () => {
    setListIdAd([]);
    setType({ id: id, value: name });
    setPagination((pre) => {
      return {
        ...pre,
        page: 1,
      };
    });
  };

  const handleChangeCategory = (id, name) => () => {
    setListIdAd([]);
    setCategory({ id: id, value: name });
    setPagination((pre) => {
      return {
        ...pre,
        page: 1,
      };
    });
  };

  const handleCheckboxChange = useCallback(
    (adId, isChecked) => () => {
      if (isChecked) {
        setListIdAd((prevList) => [...prevList, adId]);
      } else {
        setListIdAd((prevList) => prevList.filter((id) => id !== adId));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    []
  );

  const handleDeleteInPreviewModal = () => {
    setModal("");
    refetch();
  };

  const handleDeleteTwygs = async () => {
    const listDelete = deleteMultiple ? idAd : listIdAd;
    await deleteTwygsAsync({ deleteIds: listDelete });
    const newListId = [...listIdAd].filter((elm) => {
      return listDelete.findIndex((id) => id === elm) === -1;
    });
    setPagination((pre) => {
      const newPre = {
        ...pre,
        page:
          Math.ceil((getListTwygs?.data?.count - listDelete.length) / 10) <
          pre.page
            ? Math.ceil((getListTwygs?.data?.count - listDelete.length) / 10)
            : pre.page,
      };
      refetch(newPre);
      return newPre;
    });
    setListIdAd(() => newListId);
    setModal("");
    setDeleteMutiple(false);
  };

  const handleShowDetail = (value) => () => {
    if (value.videoProcessStatus !== VIDEO_PROCESS_STATUS.PROCESSING) {
      const dataTransform = {
        ...value,
        thumbnailUrl: value.thumbnail,
      };
      setDetailData(dataTransform);
      setModal("popup-preview");
    }
  };

  const handleOnEdit = (value) => () => {
    navigate(
      `${protectedRoutes.EDIT_TWYGS}/${
        CONTENT_TYPE_ENUM[value.contentType]
      }?id=${value.id}&action=${TWYGS_STUDIO_ACTION.EDIT}`
    );
  };

  const handleOnShare = (value) => () => {
    setModal("share-content");
    setUrl(value.contentUrl);
  };

  const handleOnExternalLinkClick = (value) => () => {
    window.open(`${process.env.REACT_APP_EXPLORE_URL}twygs-detail?id=${value}`);
  };

  const convertAdName = useCallback(
    (row) => {
      const isChecked = listIdAd.includes(row?.id);
      return (
        <Box
          display={"flex"}
          alignItems={"center"}
          width={{ base: "calc(100vw - 128px)", md: "unset" }}
        >
          <Checkbox
            mr="12px"
            isChecked={isChecked}
            onChange={handleCheckboxChange(row?.id, !isChecked)}
          ></Checkbox>
          <Box
            position={"relative"}
            cursor={
              row.videoProcessStatus !== VIDEO_PROCESS_STATUS.PROCESSING
                ? "pointer"
                : "default"
            }
            onClick={handleShowDetail(row)}
          >
            <ImageComponent
              rounded={"0.5rem"}
              minW={"3.5rem"}
              minH={"3.5rem"}
              maxW={"3.5rem"}
              maxH={"3.5rem"}
              src={row?.thumbnail}
              alt="select"
              objectFit={"cover"}
              border={"1px solid #F2F3F7"}
            />

            {row?.contentUrlList?.length > 1 && (
              <CarouselIcon
                className="absolute left-[38px] top-[4px]"
                alt="icon"
              />
            )}
          </Box>
          <Box ml={"12px"}>
            <Box>
              <Text
                fontWeight={800}
                title={row?.name}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
                wordBreak={"break-word"}
                sx={{
                  display: "-webkit-inline-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                }}
                cursor={
                  row.videoProcessStatus !== VIDEO_PROCESS_STATUS.PROCESSING
                    ? "pointer"
                    : "default"
                }
                onClick={handleShowDetail(row)}
              >
                {row?.name}
              </Text>
            </Box>
            <Text
              wordBreak={"break-word"}
              fontSize={"sm"}
              title={row?.description}
              overflow={"hidden"}
              textOverflow={"ellipsis"}
              sx={{
                display: "-webkit-inline-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
              }}
            >
              {row?.description}
            </Text>
          </Box>
        </Box>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listIdAd]
  );

  const convertType = useCallback((row) => {
    let typeInfo = {
      Icon: ImageIcon.src,
      alt: TYPES_DRAFT["image"],
    };

    switch (row?.contentType) {
      case TYPES_DRAFT["video"]:
        typeInfo = {
          Icon: VideoIcon,
          alt: TYPES_DRAFT["video"],
        };
        break;
      case TYPES_DRAFT["pdf"]:
        typeInfo = {
          Icon: FileIcon,
          alt: TYPES_DRAFT["pdf"],
        };
        break;
      default:
        typeInfo = {
          Icon: ImageIcon,
          alt: TYPES_DRAFT["image"],
        };
        break;
    }

    return (
      <Box
        bgColor={"light-green"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        rounded={"1.25rem"}
        p={"0.625rem"}
        w={"40px"}
      >
        <typeInfo.Icon alt={typeInfo.alt} className="w-[1.25rem] h-[1.25rem]" />
      </Box>
    );
  }, []);

  const convertCategory = useCallback((row) => {
    const showColor = LIST_COLOR_CATEGORY.find(
      (item) => item.name === row?.contentCategory
    );
    return (
      <Box
        px="8px"
        py="10px"
        w={"fit-content"}
        borderRadius={"8px"}
        bgColor={showColor.bgColor}
      >
        <Text
          color={showColor.color}
          fontSize={"xs"}
          fontWeight={"800"}
          whiteSpace={"nowrap"}
        >
          {row?.contentCategory}
        </Text>
      </Box>
    );
  }, []);

  const convertLike = useCallback((row) => {
    return (
      <Box
        bgColor={"light-green"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        rounded={"1.25rem"}
        p={"0.625rem"}
        w={"fit-content"}
      >
        <LikeIcon className="min-w-[1.25rem] min-h-[1.25rem]" alt="icon" />
        <Text
          title={row?.numberOfLike}
          ml="4px"
          color="dark-green"
          fontWeight={600}
          fontSize={"xs"}
        >
          {formatNumber(row?.numberOfLike) ?? 0}
        </Text>
      </Box>
    );
  }, []);

  const convertAction = useCallback((row) => {
    return (
      <Box display="flex" gap="24px" alignItems={"center"}>
        <Tooltip label={getTooltipLabel(row)} aria-label="Edit item tooltip">
          <PencilGreenIcon
            className={`${
              handleBlockActionWhenUnderReview(row)
                ? "cursor-not-allowed opacity-50"
                : "cursor-pointer opacity-100"
            }`}
            alt="icon"
            onClick={() => {
              if (!handleBlockActionWhenUnderReview(row)) handleOnEdit(row)();
            }}
          />
        </Tooltip>
        <ShareGreenIcon
          className={`${
            handleBlockActionWhenUnderReview(row)
              ? "cursor-not-allowed opacity-50"
              : "cursor-pointer opacity-100"
          }`}
          alt="icon"
          onClick={() => {
            if (!handleBlockActionWhenUnderReview(row)) handleOnShare(row)();
          }}
        />
        <BinRedIcon
          alt="icon"
          className="cursor-pointer"
          onClick={() => {
            setIdAd([row?.id]);
            setDeleteMutiple(true);
            setModal("remove-twygs");
          }}
        />
        <ExternalLinkIcon
          p="10px"
          onClick={
            handleBlockActionWhenUnderReview(row)
              ? undefined
              : handleOnExternalLinkClick(row?.id)
          }
          opacity={handleBlockActionWhenUnderReview(row) ? 0.5 : 1}
          cursor={
            handleBlockActionWhenUnderReview(row) ? "not-allowed" : "pointer"
          }
          src={ExternalLinkIcon.src}
          alt="link-icon"
        />
      </Box>
    );
  }, []);

  const handleSort = (sortName) => {
    let objSort = {
      sort: pagination.sort,
      order: pagination.order,
    };
    if (sortName !== objSort.sort) objSort = { sort: sortName, order: "asc" };
    else if (sortName === objSort.sort && objSort.order === "asc")
      objSort = {
        ...objSort,
        order: "desc",
      };
    else if (sortName === objSort.sort && objSort.order === "desc")
      objSort = {
        ...objSort,
        order: "asc",
      };
    setPagination({ ...pagination, ...objSort });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Twygs",
        accessor: (row) => convertAdName(row),
        id: "name",
        isSortField: true,
        widthCustom: "calc(100% - 35.5rem)",
      },
      {
        Header: "Type",
        accessor: (row) => convertType(row),
        id: "contentType",
        widthCustom: "4rem",
        minWidthCustom: "4rem",
      },
      {
        Header: "Category",
        accessor: (row) => convertCategory(row),
        id: "contentCategory",
        widthCustom: "10rem",
        minWidthCustom: "10rem",
      },
      {
        Header: "Likes",
        accessor: (row) => convertLike(row),
        id: "like",
        widthCustom: "6rem",
        minWidthCustom: "6rem",
      },
      {
        Header: "Date Created",
        accessor: (row) => (
          <Text fontSize={"sm"}>{formatDate(row?.createdAt, true)}</Text>
        ),
        id: "createdAt",
        widthCustom: "10rem",
        minWidthCustom: "10rem",
        isSortField: true,
      },
      {
        Header: "Action",
        accessor: (row) => convertAction(row),
        widthCustom: "11rem",
        minWidthCustom: "11rem",
        id: "action",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      convertAdName,
      convertCategory,
      convertType,
      convertAction,
      convertLike,
      handleSort,
    ]
  );

  useEffect(() => {
    if (getListTwygs) {
      setDataTable(getListTwygs?.data?.data);
    }
  }, [getListTwygs, type.id, category.id]);

  return (
    <Box
      w={"full"}
      border={"1px solid"}
      borderColor={"anti-flash-lite"}
      borderRadius={"12px"}
      shadow={"outline"}
    >
      <Box
        bg={"white"}
        p={{ base: "16px", "2xl": "16px 24px" }}
        display={"flex"}
        alignItems={"start"}
        minH={"72px"}
        borderTopRadius={"12px"}
        justifyContent={"space-between"}
        overflow={"auto"}
      >
        <Box
          display={"flex"}
          gap="12px"
          flexDirection={{ base: "column", md: "row" }}
          justifyContent={"space-between"}
          width={"full"}
          overflow={"auto"}
        >
          <InputSearchComponent
            setPaginationSearch={handleChangeSearch}
            placeholder={"Search twyg"}
            setLoading={() => {}}
            width={{ base: "100%", md: "320px" }}
          />
          <Box
            display={isMobile ? "flex" : "none"}
            justifyContent={"space-between"}
            gap={"12px"}
          >
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={
                  <div className="ml-4">
                    {getListTwygs?.data?.totalCount === 0 ? (
                      <ChevronDownGrayIcon alt="isAccordionOpen" />
                    ) : (
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask id="path-1-inside-1_3739_9687" fill="white">
                          <path d="M5 7.5L10 12.5L15 7.5" />
                        </mask>
                        <path d="M5 7.5L10 12.5L15 7.5" fill="#084F4B" />
                        <path
                          d="M6.41421 6.08579C5.63317 5.30474 4.36683 5.30474 3.58579 6.08579C2.80474 6.86683 2.80474 8.13317 3.58579 8.91421L6.41421 6.08579ZM10 12.5L8.58579 13.9142C9.36684 14.6953 10.6332 14.6953 11.4142 13.9142L10 12.5ZM16.4142 8.91421C17.1953 8.13317 17.1953 6.86683 16.4142 6.08579C15.6332 5.30474 14.3668 5.30474 13.5858 6.08579L16.4142 8.91421ZM3.58579 8.91421L8.58579 13.9142L11.4142 11.0858L6.41421 6.08579L3.58579 8.91421ZM11.4142 13.9142L16.4142 8.91421L13.5858 6.08579L8.58579 11.0858L11.4142 13.9142Z"
                          fill="#084F4B"
                          mask="url(#path-1-inside-1_3739_9687)"
                        />
                      </svg>
                    )}
                  </div>
                }
                w={{ base: "calc(100%  - 6px)", md: "130px" }}
                variant={"dark-green-solid"}
                pl={"20px"}
                pr={"14px"}
                isDisabled={getListTwygs?.data?.totalCount === 0}
                _disabled={{
                  bgColor: "light.100",
                  borderColor: "anti-flash-lite",
                  cursor: "not-allowed",
                }}
              >
                <Text
                  color={
                    getListTwygs?.data?.totalCount === 0
                      ? "mid-gray"
                      : "dark-green"
                  }
                  fontWeight={getListTwygs?.data?.totalCount === 0 ? 800 : 900}
                  textAlign={"start"}
                >
                  {type.value === "" ? "Type" : type.value}
                </Text>
              </MenuButton>
              <MenuList
                p={"0.5rem"}
                borderRadius={"0.5rem"}
                minW={"130px"}
                shadow={"md"}
                border={"1px solid"}
                borderColor={"light-gray"}
              >
                {OPTION_TYPE_AD?.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      minW={"114px"}
                      onClick={handleChangeType(item.value, item.name)}
                      p={"8px 8px 8px 10px"}
                      _focus={{ bgColor: "white" }}
                      _hover={{
                        bgColor: "anti-flash-white",
                        "& .child-box": {
                          display: "block",
                        },
                      }}
                      rounded={"0.5rem"}
                      fontSize={"14px"}
                      lineHeight={"20px"}
                      fontWeight={"800"}
                    >
                      {item.name}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Menu>

            <Menu>
              <MenuButton
                as={Button}
                rightIcon={
                  <div className="ml-[16px]">
                    {getListTwygs?.data?.totalCount === 0 ? (
                      <ChevronDownGrayIcon alt="icon" />
                    ) : (
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask id="path-1-inside-1_3739_9687" fill="white">
                          <path d="M5 7.5L10 12.5L15 7.5" />
                        </mask>
                        <path d="M5 7.5L10 12.5L15 7.5" fill="#084F4B" />
                        <path
                          d="M6.41421 6.08579C5.63317 5.30474 4.36683 5.30474 3.58579 6.08579C2.80474 6.86683 2.80474 8.13317 3.58579 8.91421L6.41421 6.08579ZM10 12.5L8.58579 13.9142C9.36684 14.6953 10.6332 14.6953 11.4142 13.9142L10 12.5ZM16.4142 8.91421C17.1953 8.13317 17.1953 6.86683 16.4142 6.08579C15.6332 5.30474 14.3668 5.30474 13.5858 6.08579L16.4142 8.91421ZM3.58579 8.91421L8.58579 13.9142L11.4142 11.0858L6.41421 6.08579L3.58579 8.91421ZM11.4142 13.9142L16.4142 8.91421L13.5858 6.08579L8.58579 11.0858L11.4142 13.9142Z"
                          fill="#084F4B"
                          mask="url(#path-1-inside-1_3739_9687)"
                        />
                      </svg>
                    )}
                  </div>
                }
                w={{ base: "calc(100%  - 6px)", md: "230px" }}
                variant={"dark-green-solid"}
                pl={"20px"}
                pr={"12px"}
                isDisabled={getListTwygs?.data?.totalCount === 0}
                _disabled={{
                  bgColor: "light.100",
                  borderColor: "anti-flash-lite",
                  cursor: "not-allowed",
                }}
              >
                <Text
                  color={
                    getListTwygs?.data?.totalCount === 0
                      ? "mid-gray"
                      : "dark-green"
                  }
                  fontWeight={getListTwygs?.data?.totalCount === 0 ? 800 : 900}
                  textAlign={"start"}
                >
                  {category.value === "" ? "Category" : category.value}
                </Text>
              </MenuButton>
              <MenuList
                p={"0.5rem"}
                borderRadius={"0.5rem"}
                minW={"130px"}
                shadow={"md"}
                border={"1px solid"}
                borderColor={"light-gray"}
                maxH={"300px"}
                overflow={"auto"}
                css={{
                  "&::-webkit-scrollbar": {
                    width: "8px",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#e6e7eb",
                    borderRadius: "30px",
                  },
                }}
              >
                {[
                  { id: undefined, name: "All Categories", desc: "" },
                  ...(getContentCategories?.data?.data || []),
                ].map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      minW={"114px"}
                      onClick={handleChangeCategory(item.id, item.name)}
                      p={"8px 8px 8px 10px"}
                      _focus={{ bgColor: "white" }}
                      _hover={{
                        bgColor: "anti-flash-white",
                        "& .child-box": {
                          display: "block",
                        },
                      }}
                      rounded={"0.5rem"}
                      fontSize={"14px"}
                      lineHeight={"20px"}
                      fontWeight={"800"}
                    >
                      {item.name}
                    </MenuItem>
                  );
                })}
              </MenuList>
            </Menu>
          </Box>
          {!isMobile && (
            <>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={
                    <div className="ml-[16px]">
                      {getListTwygs?.data?.totalCount === 0 ? (
                        <ChevronDownGrayIcon alt="icon" />
                      ) : (
                        <ChevronDownGreenIcon alt="icon" />
                      )}
                    </div>
                  }
                  w={{ base: "calc(100%  - 6px)", md: "130px" }}
                  height={{ base: "38px", "2xl": "42px" }}
                  variant={"dark-green-solid"}
                  pl={"20px"}
                  pr={"14px"}
                  isDisabled={getListTwygs?.data?.totalCount === 0}
                  _disabled={{
                    bgColor: "light.100",
                    borderColor: "anti-flash-lite",
                    cursor: "not-allowed",
                  }}
                >
                  <Text
                    color={
                      getListTwygs?.data?.totalCount === 0
                        ? "mid-gray"
                        : "dark-green"
                    }
                    fontWeight={
                      getListTwygs?.data?.totalCount === 0 ? 800 : 900
                    }
                    fontSize={{ base: "sm", "2xl": "md" }}
                    textAlign={"start"}
                  >
                    {type.value === "" ? "Type" : type.value}
                  </Text>
                </MenuButton>
                <MenuList
                  p={"0.5rem"}
                  borderRadius={"0.5rem"}
                  minW={"130px"}
                  shadow={"md"}
                  border={"1px solid"}
                  borderColor={"light-gray"}
                >
                  {OPTION_TYPE_AD?.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        minW={"114px"}
                        onClick={handleChangeType(item.value, item.name)}
                        p={"8px 8px 8px 10px"}
                        _focus={{ bgColor: "white" }}
                        _hover={{
                          bgColor: "anti-flash-white",
                          "& .child-box": {
                            display: "block",
                          },
                        }}
                        rounded={"0.5rem"}
                        fontSize={"14px"}
                        lineHeight={"20px"}
                        fontWeight={"800"}
                      >
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>

              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={
                    <div className="ml-[16px]">
                      {getListTwygs?.data?.totalCount === 0 ? (
                        <ChevronDownGrayIcon alt="icon" />
                      ) : (
                        <ChevronDownGreenIcon alt="icon" />
                      )}
                    </div>
                  }
                  height={{ base: "38px", "2xl": "42px" }}
                  w={{ base: "calc(100%  - 6px)", md: "230px" }}
                  variant={"dark-green-solid"}
                  pl={"20px"}
                  pr={"12px"}
                  isDisabled={getListTwygs?.data?.totalCount === 0}
                  _disabled={{
                    bgColor: "light.100",
                    borderColor: "anti-flash-lite",
                    cursor: "not-allowed",
                  }}
                >
                  <Text
                    color={
                      getListTwygs?.data?.totalCount === 0
                        ? "mid-gray"
                        : "dark-green"
                    }
                    fontWeight={
                      getListTwygs?.data?.totalCount === 0 ? 800 : 900
                    }
                    textAlign={"start"}
                    fontSize={{ base: "sm", "2xl": "md" }}
                  >
                    {category.value === "" ? "Category" : category.value}
                  </Text>
                </MenuButton>
                <MenuList
                  p={"0.5rem"}
                  borderRadius={"0.5rem"}
                  minW={"130px"}
                  shadow={"md"}
                  border={"1px solid"}
                  borderColor={"light-gray"}
                  maxH={{ base: "200px", "2xl": "300px" }}
                  overflow={"auto"}
                  css={{
                    "&::-webkit-scrollbar": {
                      width: "8px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#e6e7eb",
                      borderRadius: "30px",
                    },
                  }}
                >
                  {[
                    { id: undefined, name: "All Categories", desc: "" },
                    ...(getContentCategories?.data?.data || []),
                  ].map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        minW={"114px"}
                        onClick={handleChangeCategory(item.id, item.name)}
                        p={"8px 8px 8px 10px"}
                        _focus={{ bgColor: "white" }}
                        _hover={{
                          bgColor: "anti-flash-white",
                          "& .child-box": {
                            display: "block",
                          },
                        }}
                        rounded={"0.5rem"}
                        fontSize={"14px"}
                        lineHeight={"20px"}
                        fontWeight={"800"}
                      >
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>
            </>
          )}
          {dataTable?.length > 0 && listIdAd.length > 0 ? (
            <Box
              display={"flex"}
              alignItems={"center"}
              ml="auto"
              gap="12px"
              width={{ base: "100%", md: "unset" }}
            >
              <Box
                py="12px"
                pl="12px"
                pr="20px"
                h="32px"
                display={"flex"}
                bgColor={"light-green"}
                rounded={"38px"}
                alignItems={"center"}
                gap="10px"
              >
                <InformIcon alt="icon"></InformIcon>
                <Text fontSize={"xs"}>{listIdAd.length} selected</Text>
              </Box>
              <BinRedIcon
                onClick={() => setModal("remove-twygs")}
                className="cursor-pointer"
                alt="icon"
              />
            </Box>
          ) : (
            <Box ml="auto"></Box>
          )}
        </Box>
      </Box>
      <Box
        bg={"white"}
        rounded={"0 0 0.75rem 0.75rem"}
        display={"flex"}
        minH={"550px"}
        flexFlow={"column"}
        overflow={{ base: "auto", md: "hidden" }}
        css={{
          "&::-webkit-scrollbar": {
            height: "8px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#e6e7eb",
            borderRadius: "30px",
          },
        }}
      >
        <TableComponent
          isLoading={isFetching}
          columns={columns}
          data={dataTable ?? []}
          headerHeight={"46px"}
          fontsizeTable={{ base: "sm", "2xl": "md" }}
          handleSort={handleSort}
          currentSort={{ sort: pagination.sort, order: pagination.order }}
          haveFooter={false}
        />
        {dataTable?.length === 0 && !isFetching ? (
          <Box
            minH={"500px"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <NoDataComponent isFetching={isFetching} />
          </Box>
        ) : null}

        {getListTwygs?.data?.totalPage > 0 ? (
          <Box
            borderBottomRadius={"12px"}
            p={"1.25rem 1.5rem"}
            mt={"auto"}
            borderTop="1px solid"
            borderColor={"anti-flash-lite"}
            background={"light.100"}
          >
            <PaginationComponent
              currentPage={pagination.page}
              totalPages={getListTwygs?.data?.totalPage}
              onChangePage={handleChangePage}
            />
          </Box>
        ) : null}
      </Box>
      {modal === "popup-preview" && detailData ? (
        <ImageContentModal
          isOpen={true}
          onClose={() => setModal("")}
          data={detailData}
          isDisabled={handleBlockActionWhenUnderReview(detailData)}
          onDelete={handleDeleteInPreviewModal}
        />
      ) : null}
      {modal === "remove-twygs" ? (
        <ConfirmModal
          isOpen
          Icon={FailLoadImage}
          title={
            listIdAd.length > 1
              ? MODAL_DRAFT["title-mutilple"]
              : MODAL_DRAFT.title
          }
          desc={MODAL_DRAFT.description}
          titleBtn={"Remove"}
          onClick={handleDeleteTwygs}
          isLoading={isDeleteTwygPending}
          onClose={() => {
            setDeleteMutiple(false);
            setModal("");
          }}
        />
      ) : null}
      {modal === "share-content" ? (
        <ShareContentModal
          isOpen
          url={url}
          Icon={ShareContentIcon}
          title={"Share your Twyg!"}
          titleBtn={"Done"}
          onClick={() => setModal("")}
          onClose={() => setModal("")}
        />
      ) : null}
    </Box>
  );
};
