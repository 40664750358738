import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper";

const ShowContentImageComponent = ({ twygs, ads }) => {
  return (
    <div className={`w-full pb-2`}>
      <Swiper
        modules={[Navigation, Pagination]}
        navigation={{
          nextEl: "#swiper-button-next-ex1",
          prevEl: "#swiper-button-prev-ex1",
        }}
        pagination={{ clickable: true }}
        breakpoints={{
          1024: {
            slidesPerView: 1,
            spaceBetween: 8,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 8,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 25,
          },
        }}
        className={`swiper mx-auto ${
          twygs.contentUrlList.length > 1 ? "px-5 md:px-10" : "px-0"
        }`}
        style={{
          width: `calc(100vw - ${
            localStorage.getItem("openNavbar") ? 688 : 388
          }px) !important`,
        }}
        id="slider1"
      >
        <div className="swiper-wrapper gap-10">
          {twygs.contentUrlList.map((item, i) => {
            return (
              <SwiperSlide key={i} className="h-full">
                <div className="flex items-center max-h-[750px]">
                  <img
                    src={item}
                    className="mx-auto !object-contain !rounded-[8px] max-h-[750px]"
                    alt="itemImage"
                  />
                </div>
              </SwiperSlide>
            );
          })}
        </div>
        <button
          id="swiper-button-prev-ex1"
          className="absolute top-1/2 z-[999] -translate-y-1/2 left-[7px] md:left-1.5 !border-0 !bg-transparent"
        >
          <svg
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="chevron-icon w-4 h-4 md:w-8 md:h-8"
          >
            <path d="M32.7063 7.14727L24.9234 18.2584C24.261 19.2534 24.261 20.7459 24.9234 21.741L32.8719 32.8521C34.0311 34.5105 32.8719 36.6663 30.8848 36.6663H19.9555C18.4652 36.6663 17.1404 36.003 16.1469 34.6763L7.53596 22.5702C6.3768 21.0776 6.3768 18.9217 7.53596 17.4292L16.1469 5.32306C16.9748 3.99636 18.4652 3.33301 19.9555 3.33301H30.7192C32.7063 3.33301 33.8655 5.48889 32.7063 7.14727Z" />
          </svg>
        </button>
        <button
          id="swiper-button-next-ex1"
          className="absolute top-1/2 z-[999] -translate-y-1/2 right-[7px] md:right-1.5 !border-0 !bg-transparent"
        >
          <svg
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="chevron-icon w-4 h-4 md:w-8 md:h-8"
          >
            <path d="M7.29362 7.14727L15.0766 18.2584C15.7389 19.2534 15.7389 20.7459 15.0766 21.741L7.12802 32.8521C5.96886 34.5105 7.12802 36.6663 9.11516 36.6663H20.0444C21.5347 36.6663 22.8595 36.003 23.8531 34.6763L32.464 22.5702C33.6231 21.0776 33.6231 18.9217 32.464 17.4292L23.8531 5.32306C23.0251 3.99636 21.5347 3.33301 20.0444 3.33301H9.28075C7.29362 3.33301 6.13446 5.48889 7.29362 7.14727Z" />
          </svg>
        </button>
      </Swiper>
    </div>
  );
};

export default ShowContentImageComponent;
