import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { CircleFlag } from "react-circle-flags";
import { FieldHookForm } from "../hook-form/field-hook-form";
import { ReactComponent as ArrowDownIcon } from "../../../../assets/arrow-down-icon.svg";

const MenuHookForm = (props) => {
  const {
    data,
    description,
    label,
    name,
    defaultValue,
    isFetching,
    isShowIcon,
    isShowFlag,
    nameDisplay,
    nameValue,
    isDisabled,
    minH,
    iconMenuButton,
    placeholder,
    colorDes,
    ...rest
  } = props;

  const { setValue } = useFormContext();

  const [menuData, setMenuData] = useState();

  const [menuListWidth, setMenuListWidth] = useState(0);

  const menuButtonRef = useRef();

  const { isOpen, onToggle } = useDisclosure();

  const onChangeValue = (value) => {
    setMenuData(value);
    setValue(name, nameValue ? value[nameValue] : value.id);
  };

  useEffect(() => {
    const buttonWidth = menuButtonRef.current.offsetWidth;
    setMenuListWidth(buttonWidth);
  }, []);

  useEffect(() => {
    if (data && data.length > 0 && !isFetching) {
      if (defaultValue) {
        setMenuData(data?.filter((elm) => elm.id === defaultValue)?.[0]);
      } else if (!placeholder) {
        setMenuData(data[0]);
        setValue(name, data[0]?.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, defaultValue, isFetching]);

  return (
    <Box w={"100%"}>
      <FieldHookForm
        name={name}
        description={description}
        label={label}
        colorDes={colorDes}
      >
        <Menu isOpen={isOpen} onClose={onToggle}>
          <MenuButton
            onClick={onToggle}
            ref={menuButtonRef}
            as={Button}
            rightIcon={
              <ArrowDownIcon
                className={`transition duration-300 ${
                  isOpen ? "transform rotate-180" : "transform rotate-0"
                }`}
                alt=""
              />
            }
            fontWeight={"400"}
            w={"full"}
            textAlign={"start"}
            _active={{
              bgColor: "gray.200",
            }}
            _hover={{
              bgColor: "light.200",
            }}
            p={"1.125rem 1.5rem"}
            rounded={"0.75rem"}
            borderWidth={"1px"}
            borderColor={"mid-gray"}
            bg={"light.200"}
            fontSize={{ base: "sm", "2xl": "md" }}
            lineHeight={"23px"}
            {...rest}
          >
            <Box
              display="flex"
              gap="10px"
              fontSize={{ base: "sm", "2xl": "md" }}
            >
              {iconMenuButton && (
                <Image src={iconMenuButton} alt="" mr={"0.5rem"} />
              )}
              {isShowIcon && <Image src={menuData?.icon.src} alt="" />}

              {menuData
                ? nameDisplay
                  ? menuData[nameDisplay]
                  : menuData.name
                : placeholder ?? ""}
            </Box>
          </MenuButton>
          <MenuList
            p={"0.625rem"}
            shadow={"md"}
            border={"1px solid light-gray"}
            borderRadius={"8px"}
            minW={menuListWidth}
            maxH={{ base: "250px", "2xl": "300px" }}
            overflow={"auto"}
            css={{
              "&::-webkit-scrollbar": {
                width: "6px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#c6ceda",
                borderRadius: "20px",
              },
            }}
          >
            {data?.map((elm, index) => {
              return (
                <MenuItem
                  isDisabled={isDisabled}
                  key={index}
                  onClick={() => onChangeValue(elm)}
                  minH={minH ?? { base: "40px", "2xl": "48px" }}
                  _hover={{
                    bgColor: "anti-flash-white",
                  }}
                  rounded={"0.5rem"}
                  _focus={{ bgColor: "anti-flash-white" }}
                  fontSize={"0.875rem"}
                  lineHeight={"1.25rem"}
                  fontWeight={"800"}
                >
                  <Box
                    display="flex"
                    gap="10px"
                    fontSize={{ base: "sm", "2xl": "md" }}
                  >
                    {isShowIcon && <Image src={elm.icon.src} alt="" />}
                    {isShowFlag && (
                      <CircleFlag
                        countryCode={elm.countryCode.toLowerCase()}
                        width="20px"
                        height="20px"
                      />
                    )}
                    {elm ? (nameDisplay ? elm[nameDisplay] : elm.name) : ""}
                  </Box>
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </FieldHookForm>
    </Box>
  );
};

MenuHookForm.displayName = "MenuHookForm";

export { MenuHookForm };
