export const protectedRoutes = {
  PROFILE: "/account-settings",
  TWO_FACTOR_AUTHENTICATION: "/account-settings/two-factor-authentication",
};

export const VERIFY_OTP_MODAL = {
  title: "Enter the OTP code",
  desc: {
    email: "A 6 digit OTP code has been sent to your email address.",
    app: "A 6 digit OTP code has been sent to your authenticator app.",
  },
  errorMessage: "The OTP code is invalid or expired.",
  btText: "Confirm",
};

export const GOOGLE_AUTHENTICATION_APP =
  "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US";
export const IOS_AUTHENTICATION_APP =
  "https://apps.apple.com/us/app/authenticator-app/id1538761576";
