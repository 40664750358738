export const colors = {
  primary: {
    50: "#F9F5FF",
    100: "#F4EBFF",
    200: "#E9D7FE",
    300: "#D6BBFB",
    600: "#7F56D9"
  },
  main: {
    100: "#3D3866",
    200: "#6C59FF",
    300: "#4658F9",
    400: "#8E80FF",
    500: "#6BEFDE"
  },
  light: {
    100: "#FFFFFF",
    200: "#FAFAFA",
    300: "#EAECF0",
    400: "#A3A3A3",
    500: "#98A2B3"
  },
  "light-gray": "#D3D3D3",
  dark: {
    100: "#344054",
    200: "#0D0D0D",
    600: "#727272"
  },
  gray: {
    50: "#F9FAFB",
    200: "#EAECF0",
    300: "#D0D5DD",
    400: "#947FA9",
    100: "#12091B",
    500: "#667085",
    600: "#475467",
    700: "#344054",
    800: "#1D2939"
  },
  "shadow-gray": "#636363",
  "dim-gray": "#808080",
  "mid-gray": "#CCCCCC",
  success: {
    700: "#027A48",
    500: "#12B76A",
    50: "#ECFDF3"
  },
  error: {
    10: "#FCE8E8",
    50: "#FEF3F2",
    200: "#BC0F0F",
    500: "#F04438",
    700: "#B42318"
  },
  other: {
    400: "#E4761B",
    200: "#DE1414",
    300: "#F2D01F",
    100: "#027A15"
  },
  blue: {
    50: "#EFF8FF",
    500: "#2E90FA",
    700: "#175CD3"
  },
  orange: {
    50: "#FFF4ED",
    500: "#EF6820",
    700: "#B93815"
  },
  opacity: {
    300: "#5F6EFA1A"
  },
  placeholder: "#393939",
  progressActive: {
    500: "#A6D38D"
  },
  pink: {
    500: "#EE46BC"
  },

  // Advertiser colors
  "flat-red": "#FF4242",
  "mystic-blue": "rgba(95, 110, 250, 0.10)",
  "grey-004": "#202020",
  "go-green": "#00A375",
  "dark-green": "#084F4B",
  "playful-green": "#50FFAF",
  "twygs-green": "#A6D38D",
  "jet-black": "#333333",
  "twygs-pink": "#FFB2E0",
  "light-pink": "#FFE5ED",
  "hot-pink": "#DF1674",
  "error-red": "#DE1414",
  "light-green": "#DCEED3",
  "anti-flash-lite": "#F2F3F7",
  "anti-flash-white": "#E6E7EB",
  "seasalt-white": "#FAFAFA",
  "hover-green": "#EEF6EA",
  "secondary-blue": "#012BC2",
  "dark-brown": "#624D02",
  "alert-yellow": "#FFD166",
  "dark-orange": "#E5702E",
  "light-orange": "#FFF2E8",
  "light-purple": "#F0E2FF",
  "dark-purple": "#471FC3",
  "new-green": "#016F76",
  "fade-green": "#C4ECEE",
  "light-blue": "#DBE0F2",
  "dark-red": "#C41212",
  "leaf-green": "#335521",
  "dark-pink": "#7A053C",
  "fade-pink": "#F6A2C9",
  "secondary-blue-10": "rgba(1, 43, 194, 0.10)"
};
