export const CloseButtonTheme = {
  baseStyle: {
    _focus: {
      boxShadow: "none"
    },
    _focusVisible: {
      boxShadow: "outline"
    }
  }
};
