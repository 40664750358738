import { Fragment, useState } from "react";
import { Box, Button, Text, useMediaQuery, VStack } from "@chakra-ui/react";
import {
  DISPLAY_MODAL_STUDIO_VIDEO,
  ERROR_FILE_UPLOAD,
  STUDIO_VIDEO_MODAL,
} from "../twygs.constant";
import { ReactComponent as FailLoadImage } from "../../../../assets/failed-to-load.svg";
import VideoComponent from "../../../Common/Video";
import { ASSET_TYPE } from "../../../../constants/value.constant";
import VideoTimeline from "./video-timeline.component";
import ConfirmModal from "../../../Modal/ConfirmModal";
import SuccessfullyModal from "../../../ModalSuccess";

const TrimComponent = (props) => {
  const { assetData, setAssetData, setAction } = props;
  const [sliderValue, setSliderValue] = useState([0, 1]);
  const [modal, setModal] = useState("");
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  const handleSetData = (value) => {
    setAssetData(value);
  };

  const handleSave = () => {
    setAssetData({
      ...assetData,
      trim: {
        ...assetData.trim,
        timeline: sliderValue,
      },
    });
    setAction("");
  };

  const handleDiscardChanges = (value) => () => {
    setModal(value);
  };

  const handleAction = (value) => () => {
    setAction(value);
  };

  const handleContinues = async () => {
    setModal("");
  };

  const handleCancel = () => {
    setModal("");
  };

  const handleFrameRangeChange = (startFrame, endFrame) => {
    setSliderValue([startFrame, endFrame]);
  };

  return (
    <Fragment>
      <Box
        display={"flex"}
        alignItems={"center"}
        py={{ base: "1rem", md: "2rem" }}
        px={{ base: "1rem", "2xl": "2rem" }}
        width={{ base: "100%", md: "auto" }}
        justifyContent={{ base: "space-between", md: "unset" }}
        flexWrap="wrap"
      >
        <Text fontSize={{ base: "xl", "2xl": "2xl" }} fontWeight={"800"}>
          Trim
        </Text>
        <Button
          variant={"dark-green-solid"}
          h={"2.25rem"}
          px={"0.875rem"}
          border={0}
          shadow={"none"}
          bg={"unset"}
          mr={"8px"}
          fontSize={"0.875rem"}
          color={"jet-black"}
          ml={"auto"}
          onClick={handleDiscardChanges(
            DISPLAY_MODAL_STUDIO_VIDEO.DISCARD_CHANGES
          )}
        >
          Discard changes
        </Button>
        <Button
          variant={"dark-green"}
          h={"2.25rem"}
          px={"1.25rem"}
          fontSize={"0.875rem"}
          onClick={handleSave}
        >
          Save
        </Button>
      </Box>
      {assetData?.asset?.src && (
        <VStack spacing={"1.5rem"} px={{ base: "1rem", "2xl": "2rem" }}>
          <VideoComponent
            src={assetData?.asset?.src}
            controls
            width={
              isMobile
                ? "auto"
                : assetData?.asset?.assetType === ASSET_TYPE.PORTRAIT
                ? `${(9 / 16) * 22.5}rem`
                : `${(16 / 9) * 22.5}rem`
            }
            height={isMobile ? "auto" : "22.5rem"}
            poster={assetData?.thumbnail?.src}
          />
          <VideoTimeline
            data={assetData}
            setData={handleSetData}
            onFrameRangeChange={handleFrameRangeChange}
          />
        </VStack>
      )}
      {modal === DISPLAY_MODAL_STUDIO_VIDEO.DISCARD_CHANGES && (
        <ConfirmModal
          isOpen
          Icon={FailLoadImage}
          title={STUDIO_VIDEO_MODAL.TITLE}
          desc={STUDIO_VIDEO_MODAL.DESC}
          titleBtn={STUDIO_VIDEO_MODAL.CONFIRM}
          onClick={handleAction("")}
          onClose={handleCancel}
        />
      )}
      {modal === DISPLAY_MODAL_STUDIO_VIDEO.CONFIRM_ERR && (
        <SuccessfullyModal
          isOpen
          icon={FailLoadImage}
          title={ERROR_FILE_UPLOAD.THUMBNAIL.TITLE}
          desc={ERROR_FILE_UPLOAD.THUMBNAIL.DESC.map((item, index) => (
            <Text
              key={index}
              ml={"1.5rem"}
              fontSize={"18px"}
              textAlign={"start"}
              fontWeight={"400"}
              fontStyle={"normal"}
            >
              {item}
            </Text>
          ))}
          titleBtn={"Okay"}
          onClick={handleContinues}
        />
      )}
    </Fragment>
  );
};

export default TrimComponent;
