import { useRef, useState } from "react";

import { convertCountLike, convertTimeCreatedAt } from "../../../../constants";
import LikeBlurIcon from "../../../../assets/like-blur-icon.svg";
import LikeSolidIcon from "../../../../assets/like-solid-icon.svg";
import ThreeDotsIcon from "../../../../assets/three-dot-icon.svg";
import TrashIcon from "../../../../assets/trash-icon.svg";
import AvatarIcon from "../../../../assets/ava-icon.svg";
import { deleteReply, postLikeReply } from "../../../../apis/twygs-detail";
import { useClickOutside } from "../../../../hooks/useOutsideClick";
import useTruncatedElement from "../../../../hooks/useTruncatedElement ";
import Cookies from "js-cookie";
const staging = process.env.REACT_APP_IS_STAGING;

const ReplyDetailComponent = (props) => {
  const { item2, userInfo, listReply, twygsId, commentId, setListReply } =
    props;

  const [isLike, setIsLike] = useState(item2.isLikedByUser);
  const [likeCount, setLikeCount] = useState(item2?.likeNumber);
  const [openPopover, setOpenPopover] = useState(false);

  const popoverRef = useRef(null);
  const readMoreRef = useRef(null);

  const token = Cookies.get(`${staging ? "staging" : ""}token`);

  useClickOutside(popoverRef, () => {
    setOpenPopover(false);
  });

  const { isTruncated, isReadingMore, toggleIsShowingMore } =
    useTruncatedElement({
      ref: readMoreRef,
    });

  const handleOpenPopover = () => {
    setOpenPopover(!openPopover);
  };

  const handleLike = async () => {
    if (token) {
      try {
        setIsLike((prev) => !prev);
        setLikeCount((prev) => (isLike ? prev - 1 : prev + 1));

        await postLikeReply({
          replyId: item2.id,
          isLike: !isLike,
        });
      } catch (error) {
        setIsLike((prevIsLike) => !prevIsLike);
        setLikeCount((prev) => (isLike ? prev - 1 : prev + 1));
      }
    }
  };

  const handleDelete = async () => {
    try {
      await deleteReply(twygsId, commentId, item2.id);
      setOpenPopover(false);
      setListReply(
        listReply.map((reply) =>
          reply.id === item2?.id ? { ...reply, isDeleted: true } : reply
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="py-[16px] relative">
      <div className="flex items-start gap-[12px] justify-start">
        <a
          href={`/creator-detail/${encodeURIComponent(
            item2?.createdByUser?.username
          )}`}
          target="_self"
        >
          <img
            src={item2?.createdByUser?.image || AvatarIcon}
            className="min-w-[32px] min-h-[32px] max-w-[32px] max-h-[32px] rounded-full"
            alt="icon"
          />
        </a>
        <div className="w-full">
          <div className="flex">
            <a
              href={`/creator-detail/${encodeURIComponent(
                item2?.createdByUser?.username
              )}`}
              target="_self"
            >
              <p className="text-xs font-semibold">
                {item2?.createdByUser?.username}
              </p>
            </a>
            <p className="ml-[12px] !text-[#808080] text-xs">
              {convertTimeCreatedAt(item2?.createdAt)}
            </p>
          </div>
          <pre
            ref={readMoreRef}
            className={`mt-[8px] font-sm text-sm 2xl:text-base font-nunito ${
              !isReadingMore && "line-clamp-4"
            } break-all	whitespace-pre-wrap`}
          >
            {item2?.isDeleted ? (
              <i className="!text-[#808080]">[Deleted By User]</i>
            ) : (
              item2?.text
            )}
          </pre>
          {isTruncated && (
            <div onClick={toggleIsShowingMore} className="cursor-pointer">
              <p className="text-xs !text-[#808080] cursor-pointer hover:opacity-80 mt-[8px]">
                {isReadingMore ? "Read less" : "Read more"}
              </p>
            </div>
          )}
          <div className="flex">
            <div className="flex mt-[12px] content-center">
              <img
                onClick={() => handleLike()}
                src={isLike ? LikeSolidIcon : LikeBlurIcon}
                className="hover:opacity-80 cursor-pointer"
                alt="icon"
              />
              <p className="ml-[4px] text-xs !text-[#808080]">
                {convertCountLike(likeCount)}
              </p>
            </div>
          </div>
        </div>
        {userInfo?.id === item2?.createdByUser?.userId && !item2?.isDeleted ? (
          <img
            onClick={() => {
              handleOpenPopover(item2?.id);
            }}
            className="w-[20px] cursor-pointer"
            src={ThreeDotsIcon}
            alt="dot"
          />
        ) : null}
      </div>
      {openPopover && (
        <div
          ref={popoverRef}
          onClick={() => handleDelete()}
          className="absolute right-0 top-[40px] bg-white p-[18px] cursor-pointer flex border border-1 border-[#F2F3F7] rounded-[12px] shadow-[0px_8px_12px_0px_#80808026] hover:bg-gray-100"
        >
          <img src={TrashIcon} alt="delete"></img>
          <p className="font-bold text-sm ml-[8px]"> Delete </p>
        </div>
      )}
    </div>
  );
};

export default ReplyDetailComponent;
