import {useEffect, useState} from "react";
import {Box} from "@chakra-ui/react";

const VideoComponent = ({
  src,
  controls,
  rounded,
  height,
  width,
  maxHeight,
  poster,
  loadMetadata
}) => {
  const [key, setKey] = useState(0);
  const [isErr, setIsErr] = useState(false);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [src]);

  const onError = () => {
    setIsErr(true);
  };

  return (
    <Box
      borderWidth={isErr ? "1px" : "0px"}
      borderStyle={"solid"}
      borderColor={"#EAECF0"}
      borderRadius={rounded ?? "12px"}
      overflow={"hidden"}
      display={"flex"}
    >
      <Box w={width ?? "100%"} h={height ?? "100%"} mx={"auto"}>
        {!isErr && (
          <Box position={"relative"}>
            <video
              style={{
                width: width ?? "100%",
                borderRadius: rounded ?? "12px",
                height: height ?? "100%",
                maxHeight: maxHeight,
                objectFit: "cover"
              }}
              controls={controls}
              autoPlay={false}
              key={key}
              poster={poster}
              onLoadedMetadata={loadMetadata}
            >
              <source src={src} onError={onError} type="video/mp4" />
            </video>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default VideoComponent;
