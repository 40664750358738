import { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import CalendarFrequencyIcon from "../../assets/calendar-icon.svg";
import ChevronDownGreenIcon from "../../assets/chevron-down-solid-green.svg";
import ChevronDownGrayIcon from "../../assets/chevron-down-solid-gray.svg";
import ChevronLeftGrayIcon from "../../assets/chevron-left-gray.svg";
import ChevronLeftDarkIcon from "../../assets/chevron-left-green.svg";
import { useFormContext, useWatch } from "react-hook-form";
import { MONTH_IN_YEAR } from "../../constants";

const BirthdayCalendarComponent = ({ name, placeholder, disable }) => {
  const { control, setValue, register } = useFormContext();
  const [isSelectMonth, setIsSelectMonth] = useState(false);
  const [isSelectYear, setIsSelectYear] = useState(false);

  const currentDate = useWatch({ name: name, control });

  const CustomInput = forwardRef(({ onClick }, ref) => {
    return (
      <div ref={ref} className="relative">
        <div className="flex items-center">
          <div className="h-10 w-12 flex items-center justify-center absolute">
            <img className="w-5" src={CalendarFrequencyIcon} alt="icon" />
          </div>
          <input
            {...register(name, { required: true })}
            className="bg-light.100 py-1/4 pl-[2.625rem] rounded-[8px] cursor-pointer border border-solid text-sm 2xl:text-base border-[#E6E7EB] h-[38px] 2xl:h-[42px] focus:outline-[#A6D38D] w-full"
            placeholder={placeholder}
            onClick={onClick}
            readOnly
          />
        </div>
      </div>
    );
  });

  const handleDatePicker = (date) => {
    setValue(name, moment(date).format("MM/DD/YYYY"));
  };

  // eslint-disable-next-line no-unused-vars
  const renderCustomDate = (date, _today) => {
    return (
      <div className="">
        <p className="date-custom flex justify-center p-[4px]">{date}</p>
      </div>
    );
  };

  const renderCustomHeader = ({
    monthDate,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    decreaseYear,
    increaseYear,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    prevYearButtonDisabled,
    nextYearButtonDisabled,
  }) => {
    return (
      <>
        <div className="flex items-center justify-between">
          {renderMonthYearControl({
            value: moment(monthDate).format("MMMM"),
            decrease: decreaseMonth,
            increase: increaseMonth,
            prevButtonDisabled: prevMonthButtonDisabled,
            nextButtonDisabled: nextMonthButtonDisabled,
            checkTypeSelect: isSelectYear,
            onClick: () => {
              setIsSelectMonth(!isSelectMonth);
              setIsSelectYear(false);
            },
          })}
          {renderMonthYearControl({
            value: moment(monthDate).format("YYYY"),
            decrease: decreaseYear,
            increase: increaseYear,
            prevButtonDisabled: prevYearButtonDisabled,
            nextButtonDisabled: nextYearButtonDisabled,
            checkTypeSelect: isSelectMonth,
            onClick: () => {
              setIsSelectYear(!isSelectYear);
              setIsSelectMonth(false);
            },
          })}
        </div>
        {(isSelectMonth || isSelectYear) && (
          <div className="absolute overflow-y-auto  p-4 w-full h-[302px] top-[112px] left-0 bg-white">
            {isSelectMonth &&
              renderOption("month", (value) => {
                setIsSelectMonth(false);
                changeMonth(value.index);
              })}
            {isSelectYear &&
              renderOption("year", (value) => {
                setIsSelectYear(false);
                changeYear(value.item);
              })}
          </div>
        )}
      </>
    );
  };

  const renderMonthYearControl = ({
    value,
    decrease,
    increase,
    prevButtonDisabled,
    nextButtonDisabled,
    checkTypeSelect,
    onClick,
  }) => {
    return (
      <div className="w-[152px] flex items-center justify-between">
        <img
          src={
            prevButtonDisabled || checkTypeSelect
              ? ChevronLeftGrayIcon
              : ChevronLeftDarkIcon
          }
          alt=""
          onClick={prevButtonDisabled || checkTypeSelect ? () => {} : decrease}
          className="cursor-pointer"
        />
        <div className="flex items-center cursor-pointer" onClick={onClick}>
          <p
            className={`font-black text-[16px] ${
              checkTypeSelect ? "!text-[#808080]" : "text-dark-green"
            }`}
          >
            {value}
          </p>
          <img
            src={checkTypeSelect ? ChevronDownGrayIcon : ChevronDownGreenIcon}
            alt=""
          />
        </div>
        <img
          src={
            nextButtonDisabled || checkTypeSelect
              ? ChevronLeftGrayIcon
              : ChevronLeftDarkIcon
          }
          alt=""
          className="cursor-pointer transform rotate-180"
          onClick={nextButtonDisabled || checkTypeSelect ? () => {} : increase}
        />
      </div>
    );
  };

  const renderOption = (type, onSelect) => {
    let option = [];
    if (type === "year") {
      const limit = moment().subtract(100, "years").year();
      for (
        const currentYear = moment();
        currentYear.year() > limit;
        currentYear.subtract(1, "years")
      ) {
        option.push(currentYear.year().toString());
      }
    } else {
      option = [...MONTH_IN_YEAR];
    }
    return (
      <div className="flex flex-col gap-4 scrollbar-w-[6px] scrollbar-track-w-[6px] scrollbar-thumb-bg-[#c6ceda]">
        {option.map((item, index) => (
          <div
            key={index}
            className="p-2 h-[36px] rounded-[8px] flex items-center gap-2 cursor-pointer hover:bg-[#E6E7EB] transition-all duration-300"
            onClick={() => onSelect({ item, index })}
          >
            <p className="font-black text-[16px]">{item}</p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={"datePicker w-[100%]"}>
      <DatePicker
        renderCustomHeader={renderCustomHeader}
        selected={currentDate ? new Date(currentDate) : undefined}
        onChange={(value) => handleDatePicker(value)}
        customInput={<CustomInput />}
        showPopperArrow={false}
        disabledKeyboardNavigation
        renderDayContents={renderCustomDate}
        minDate={moment().subtract(100, "years").toDate()}
        maxDate={moment().toDate()}
        disabled={disable}
      />
    </div>
  );
};

export default BirthdayCalendarComponent;
