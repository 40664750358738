import React from "react";

const IntroSection = (props) => {
  const { title, description, colorDesc } = props;

  return (
    <div>
      <p className="text-xl 2xl:text-2xl font-extrabold">{title}</p>
      <p
        className={`text-sm 2xl:text-base font-normal ${
          colorDesc ? colorDesc : "!text-[#808080]"
        }`}
      >
        {description}
      </p>
    </div>
  );
};

export default IntroSection;
