import { Navigate, Route, Routes } from "react-router-dom";

import ExplorPage from "../../routes/Explore";
import TwygsDetails from "../../routes/TwygsDetails";
import CreatorDetails from "../../routes/CreatorDetails";
import TopTwygs from "../../routes/TopTwygs";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Settings } from "../../routes/Settings";
import TwoFactorAuthentication from "../../routes/Settings/TwoFactorAuthentication";
import NotificationSettings from "../../routes/NotificationSettings";
import Cookies from "js-cookie";

import CreateTwygsRoute from "../../routes/CreateTwygs";
import MyTwygs from "../../routes/MyTwygs";
import { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";

const AppLayout = () => {
  const user = localStorage.getItem("user");
  const token = Cookies.get("token");

  useEffect(() => {
    if (user && token) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        user_id: JSON.parse(user)?.id,
        /*Impact Identify */
        event: "IdentifyInvoked",
        customerId: JSON.parse(user)?.id,
        customerEmail: JSON.parse(user)?.email,
        customProfileId: uuidv4(),
        /*Impact Identify */
      });
    } else {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        user_id: JSON.parse(user)?.id,
        /*Impact Identify */
        event: "IdentifyInvoked",
        customerId: "",
        customerEmail: "",
        customProfileId: "",
        /*Impact Identify */
      });
    }
  }, [user, token]);

  return (
    <div id="explore-react" className="explore-react">
      <Routes>
        <Route path="/" element={<ExplorPage />} />
        <Route path="/topTwygs" element={<TopTwygs />} />
        <Route path="/creator-detail/:username" element={<CreatorDetails />} />
        <Route path="/twygs-detail" element={<TwygsDetails />} />
        <Route path="/account-settings" element={<Settings />} />
        <Route
          path="/notification-settings"
          element={<NotificationSettings />}
        />
        <Route
          path="/account-settings/two-factor-authentication"
          element={<TwoFactorAuthentication />}
        />
        <Route
          path="/studio"
          element={<Navigate to="/studio/create-twyg" replace />}
        />
        <Route path="/studio/create-twyg/*" element={<CreateTwygsRoute />} />
        <Route path="/studio/my-twygs/*" element={<MyTwygs />} />
      </Routes>
      <ToastContainer />
    </div>
  );
};

export default AppLayout;
