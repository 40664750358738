const ShowContentVideoComponent = ({ twygs }) => {
  const isPortrait = twygs.videoAssetType === "PORTRAIT";

  return (
    <div
      className={`w-full relative mt-[8px] md:mt-0 ${
        isPortrait ? "pb-[150%] " : "pb-[56.25%]"
      } md:pb-[56.25%] lg:pb-[56.25%] xl:pb-[56.25%] 2xl:pb-[56.25%] h-0 bg-black rounded-0 md:rounded-lg overflow-hidden`}
      id="container-video"
    >
      <video
        src={`${twygs.contentUrlList[0]}#t=0.1`}
        className={`absolute top-0 left-0 w-full h-full ${
          isPortrait ? "object-cover sm:object-contain" : "object-cover"
        }`}
        id="video-twyg"
        playsInline
        controls
        muted
        preload="auto"
        // autoPlay
        crossOrigin="anonymous"
      >
        <source src={twygs.thumbnail} type="video/mp4" />
      </video>
    </div>
  );
};

export default ShowContentVideoComponent;
