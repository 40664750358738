import { useLayoutEffect, useState, useEffect } from "react";

const useTruncatedElement = ({ ref, content }) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const [isReadingMore, setIsReadingMore] = useState(false);

  useLayoutEffect(() => {
    const checkTruncation = () => {
      const { offsetHeight, scrollHeight } = ref.current || {};

      if (offsetHeight && scrollHeight && offsetHeight < scrollHeight) {
        setIsTruncated(true);
      } else {
        setIsTruncated(false);
      }
    };

    // Delay the check to ensure the content is fully rendered
    const timeoutId = setTimeout(checkTruncation, 100);

    return () => clearTimeout(timeoutId);
  }, [ref, content]);

  const toggleIsShowingMore = () => setIsReadingMore((prev) => !prev);

  return {
    isTruncated,
    isReadingMore,
    toggleIsShowingMore,
  };
};

export default useTruncatedElement;
