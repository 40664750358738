import React, { useEffect, useRef, useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { CATEGORY_LIST } from "../../../constants";
import useSearchParams from "../../../hooks/router/useSearchParams";

let totalWidth = 0;
let listNode;

const CarouselCategoryComponent = () => {
  const { searchParams, setSearchParams } = useSearchParams();
  const [translateX, setTranslateX] = useState(0);
  const [page, setPage] = useState(0);
  const ref = useRef();

  const nextAction = () => {
    setPage(page + 1);
    setTranslateX(
      translateX + listNode[page].clientWidth + 16 - (page === 0 ? 50 : 0)
    );
  };

  const prevAction = () => {
    setPage(page - 1);
    setTranslateX(
      translateX - listNode[page - 1].clientWidth - (page - 1 === 0 ? -32 : 16)
    );
  };

  const checkNextAction = () => {
    let currentWidth = 0;
    listNode.forEach((element, i) => {
      if (i >= page) currentWidth = currentWidth + element.clientWidth;
    });
    if (!ref.current) return false;
    return currentWidth > ref.current.clientWidth - 200;
  };

  useEffect(() => {
    if (ref.current) {
      listNode = ref.current.querySelectorAll("div");
      listNode.forEach((element) => {
        totalWidth = totalWidth + element.clientWidth;
      });
    }
  }, [ref]);

  return (
    <div className={`relative overflow-hidden`}>
      <div
        ref={ref}
        className="flex items-center gap-4 transition-all duration-150 ease-out"
        style={{ transform: `translate(${-translateX}px, 0)` }}
      >
        {CATEGORY_LIST.map((elm, index) => {
          return (
            <div
              key={index}
              onClick={() => {
                window.dataLayer.push({
                  event: "view_twyg_category",
                  twyg_category: elm.name,
                });
                setSearchParams({ category: encodeURIComponent(elm.name) });
              }}
              className={`creator-name flex items-center justify-center px-2 2xl:px-4 transition cursor-pointer !rounded-full h-10 2xl:h-[50px] ${
                decodeURIComponent(
                  searchParams.category || "" || "All Twygs"
                ) === elm.name
                  ? "custom-bg"
                  : "bg-white"
              }`}
            >
              <p className="text-xs md:text-sm 2xl:text-base font-extrabold custom-color whitespace-nowrap">
                {elm.name}
              </p>
            </div>
          );
        })}
      </div>

      {((listNode && checkNextAction()) || page === 0) && (
        <div className="absolute top-0 right-0 h-full flex items-center justify-end w-[50px] bg-gray-100">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="cursor-pointer arrow-icon"
            onClick={nextAction}
          >
            <path
              d="M13.4277 25L22.4277 16L13.4277 7"
              strokeWidth="2.57143"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
      {page !== 0 && (
        <div className="absolute top-0 left-0  h-full flex items-center justify-start w-[50px]  bg-gray-100">
          <svg
            className="cursor-pointer arrow-icon"
            onClick={prevAction}
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.5732 25L9.57324 16L18.5732 7"
              strokeWidth="2.57143"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
    </div>
  );
};

export default CarouselCategoryComponent;
