import React, { useState } from "react";
import { QRCodeSVG } from "qrcode.react";
import { classifyDevice } from "../../../../constants";

import CopyGreenIcon from "../../../../assets/copy-green-icon.svg";

const Step2 = ({ appData }) => {
  const [showInfo, setShowInfo] = useState(false);
  const [copied, setCopied] = useState({
    email: false,
    twoFactorSecretKey: false,
  });

  const handleShowInfo = () => {
    setShowInfo(!showInfo);
  };

  const handleCopy = (value, type) => {
    navigator.clipboard.writeText(value);
    if (type === "email") {
      setCopied({ ...copied, email: true });
    } else {
      setCopied({ ...copied, twoFactorSecretKey: true });
    }
    setTimeout(() => {
      setCopied({ ...copied, email: false, twoFactorSecretKey: false });
    }, 1000);
  };

  return (
    <div className="p-5 md:p-6 border border-gray-100 rounded-xl bg-white shadow-sm">
      <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-6 md:gap-0">
        <p className="text-sm 2xl:text-base font-black">
          Step 2:{" "}
          <span className="font-semibold">
            Add your account to the app by scanning this QR code
          </span>
        </p>
        <p
          className="text-sm font-black !text-[#00A375] cursor-pointer"
          onClick={handleShowInfo}
        >
          {!showInfo
            ? "Can’t scan the QR code? Enter a setup key instead"
            : "Scan the QR code instead"}
        </p>
      </div>
      {!showInfo ? (
        <div className="mt-6">
          <QRCodeSVG
            value={appData?.otpAuthUrl}
            fgColor={"#084F4B"}
            size={classifyDevice() === "Mobile" ? 100 : 200}
            level={"L"}
          />
        </div>
      ) : (
        <div className="mt-6 flex flex-col gap-4">
          <div className="p-4 border border-gray-300 rounded-xl bg-seasalt-white flex justify-between">
            <div className="flex flex-col gap-2 w-[75%] md:w-full">
              <p className="text-lg font-semibold text-jet-black">
                Account Email
              </p>
              <p
                className="text-lg font-bold text-jet-black"
                title={appData?.email}
              >
                {appData?.email}
              </p>
            </div>
            <div
              className="flex items-center cursor-pointer"
              onClick={() => handleCopy(appData?.email, "email")}
            >
              <p className="text-base font-semibold text-go-green">
                {copied.email ? "Copied" : "Copy"}
              </p>
              <img src={CopyGreenIcon} alt="" />
            </div>
          </div>
          <div className="p-4 border border-gray-300 rounded-xl bg-seasalt-white flex justify-between">
            <div className="flex flex-col gap-2">
              <p className="text-lg font-semibold text-jet-black">Setup Key</p>
              <p className="text-lg font-bold text-jet-black">
                {appData?.twoFactorSecretKey}
              </p>
            </div>
            <div
              className="flex items-center cursor-pointer"
              onClick={() =>
                handleCopy(appData?.twoFactorSecretKey, "twoFactorSecretKey")
              }
            >
              <p className="text-base font-semibold text-go-green">
                {copied.twoFactorSecretKey ? "Copied" : "Copy"}
              </p>
              <img src={CopyGreenIcon} alt="" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Step2;
