import {Box, Text} from "@chakra-ui/react";
import {useState} from "react";
import {ReactComponent as CloseToast} from '../../assets/close-toast-button.svg';


const ToastMessage = (props) => {
  const { backgroundColor, Icon, color, title } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box
      borderRadius={"8px"}
      py="10px"
      px="16px"
      display={isOpen ? "none" : "flex"}
      gap="16px"
      bg={backgroundColor}
    >
      <Icon />
      <Text color={color} fontWeight={800}>
        {title}
      </Text>
      <CloseToast
        ml="auto"
        cursor={"pointer"}
        onClick={() => setIsOpen(!isOpen)}
        alt=""
      ></CloseToast>
    </Box>
  );
};

export default ToastMessage;
