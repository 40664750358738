import axiosInstance from "./axios-instance";

export const fetchTwygsDetail = async (id) => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}explore/twygs/${id}/details`
  );
  if (result.status === 200) return result.data.data;
  return null
};

// Report function
export const fetchListReportReason = async () => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}twygs/get-report-reason`
  );
  if (result.status === 200) return result.data.data;
};

export const reportTwygs = async (data) => {
  const result = await axiosInstance.post(
    `${process.env.REACT_APP_TWYGS_API_URL}explore/twygs/report`,
    data
  );
  if (result.status === 200) return result.data.data;
};

// Comment function
export const fetchListComment = async (id, pagination) => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}explore/twygs/${id}/comments`,
    {
      params: pagination,
    }
  );
  if (result.status === 200) return result.data.data;
};

export const fetchListReply = async (id, commentId, pagination) => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}explore/twygs/${id}/comments/${commentId}/replies`,
    {
      params: pagination,
    }
  );
  if (result.status === 200) return result.data.data;
};

export const postComment = async (data, id) => {
  const result = await axiosInstance.post(
    `${process.env.REACT_APP_TWYGS_API_URL}explore/twygs/${id}/comments`,
    data
  );
  if (result.status === 200) return result.data.data;
};

export const postReply = async (data, id, commentId) => {
  const result = await axiosInstance.post(
    `${process.env.REACT_APP_TWYGS_API_URL}explore/twygs/${id}/comments/${commentId}/replies`,
    data
  );
  if (result.status === 200) return result.data.data;
};

export const postLikeComment = async (data) => {
  const result = await axiosInstance.post(
    `${process.env.REACT_APP_TWYGS_API_URL}explore/comments/reactions`,
    data
  );
  if (result.status === 200) return result.data.data;
};

export const postLikeReply = async (data) => {
  const result = await axiosInstance.post(
    `${process.env.REACT_APP_TWYGS_API_URL}explore/replies/reactions`,
    data
  );
  if (result.status === 200) return result.data.data;
};

export const deleteComment = async (twygId, commentId) => {
  const result = await axiosInstance.delete(
    `${process.env.REACT_APP_TWYGS_API_URL}explore/twygs/${twygId}/comments/${commentId}`
  );
  if (result.status === 200) return result.data.data;
};

export const deleteReply = async (twygId, commentId, replyId) => {
  const result = await axiosInstance.delete(
    `${process.env.REACT_APP_TWYGS_API_URL}explore/twygs/${twygId}/comments/${commentId}/replies/${replyId}`
  );
  if (result.status === 200) return result.data.data;
};

// Twygs reaction

export const likeTwygs = async (body) => {
  await axiosInstance.post(
    `${process.env.REACT_APP_TWYGS_API_URL}explore/twygs/reactions`,
    body
  );
};

// Relative content

export const getRelativeContent = async (params, data) => {
  const result = await axiosInstance.post(
    `${process.env.REACT_APP_TWYGS_API_URL}explore/twygs/relative-content?page=${params.page}&limit=${params.limit}&filter=${params.filter}`,
    data
  );
  if (result.status === 200) return result.data.data;
};

// User info

export const getUserInfo = async () => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}user/get-me`
  );
  if (result.status === 200) return result.data.data;
};
